import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement } from 'react';

// Interface and types
import { FamilyMemberProps } from 'types/family-management';
import { MembershipInvitationStatus } from 'types/membership';

// Utils
import { lowercaseAndCapitalize } from 'util/string';

// Styles
import './family-member.style.scss';

export const formatInvitationStatus = (invitationStatus: MembershipInvitationStatus) => {
    return invitationStatus === 'pending' ? 'pending response' : invitationStatus;
};

// Main component
const FamilyMember = ({ name, age, className, invitationStatus }: FamilyMemberProps): ReactElement => {
    const containerClasses = classNames('family-member', { [`${className}`]: className });
    const { t } = useTranslation();

    return (
        <div className={containerClasses}>
            <div className="family-member__name">{lowercaseAndCapitalize(name)}</div>
            {invitationStatus && (
                <div className={`family-member__invitation-status ${invitationStatus}`}>
                    {formatInvitationStatus(invitationStatus)}
                </div>
            )}
            {age && (
                <div className="family-member__age">
                    ({t('components.familyManagement.familyMember.age')}: {age})
                </div>
            )}
        </div>
    );
};

export default FamilyMember;
