import { AddressPayload } from 'types/account';
import { CreditCardPayload } from 'types/card';
import { CartPayload } from 'types/cart';
import { EasyRefillCartPayload, PaymentCard } from 'types/easy-refill';
import { OrderBillShip } from 'types/order-prescription';

import { cartState } from './cart.reducers';

/**
 * Returns the main cart from a list of carts based on the EpostPatientNum.
 *
 * @param {Array<cartState['cart']>} carts - The list of carts to search through.
 * @param {string | undefined} EpostPatientNum - The EpostPatientNum to match against.
 * @return {cartState['cart'] | undefined} The main cart that matches the EpostPatientNum, or undefined if no match is found.
 */
export const getMainCart = (carts: cartState['cart'], EpostPatientNum: string | undefined) => {
    const mainCart = carts?.find((cart) => {
        return EpostPatientNum === cart.EpostPatientNum;
    });

    return mainCart;
};

/**
 * Calculates the total order value for all items in the given carts.
 *
 * @param {cartState['cart']} carts - The array of carts containing the items.
 * @return {number} The total order value.
 */
export const getAllOrderTotal = (carts: cartState['cart']) => {
    return carts?.reduce((total, cart) => {
        return total + Number(cart?.Order?.orderTotal);
    }, 0);
};

/**
 * Calculates the total number of items in all the carts.
 *
 * @param {cartState['cart'][]} carts - The list of carts.
 * @return {number} The total number of items in all the carts.
 */
export const getAllItemsTotal = (carts: cartState['cart']) => {
    return carts?.reduce((total, cart) => {
        return total + cart?.Order.refillRxs?.length;
    }, 0);
};

export const getAllEpostsCarts = (carts: cartState['cart'], eposts: string[]) => {
    return carts?.filter((cart) => {
        return cart.EpostPatientNum ? eposts.includes(cart.EpostPatientNum) : false;
    });
};

export const getCurrentCardOrderBillship = (
    carts: CartPayload[] | EasyRefillCartPayload[],
    addresses: AddressPayload[]
) => {
    let selectedOrderBillShip = undefined;
    carts?.find((cart) => {
        addresses.map((address) => {
            if (
                address.addressSeqNum === (cart as CartPayload).Order?.orderBillShip.patientBillAddressSeq ||
                address.addressSeqNum === (cart as CartPayload).Order?.orderBillShip.dependentAddressSeqNum ||
                address.addressSeqNum === (cart as EasyRefillCartPayload).order?.orderBillShip.patientBillAddressSeq ||
                address.addressSeqNum === (cart as EasyRefillCartPayload).order?.orderBillShip.dependentAddressSeqNum
            ) {
                selectedOrderBillShip =
                    (cart as CartPayload).Order?.orderBillShip || (cart as EasyRefillCartPayload).order?.orderBillShip;
            }
        });
    });
    return selectedOrderBillShip ? (selectedOrderBillShip as OrderBillShip) : undefined;
};

export const getCurrentCartAddress = (
    carts: CartPayload[] | EasyRefillCartPayload[],
    addresses: AddressPayload[]
): AddressPayload | undefined => {
    let selectedAddress = null;
    addresses.map((address) => {
        carts?.map((cart) => {
            if (
                address.addressSeqNum === (cart as CartPayload).Order?.orderBillShip.patientShipAddressSeq ||
                address.addressSeqNum === (cart as CartPayload).Order?.orderBillShip.dependentAddressSeqNum ||
                address.addressSeqNum === (cart as EasyRefillCartPayload).order?.orderBillShip.patientShipAddressSeq ||
                address.addressSeqNum === (cart as EasyRefillCartPayload).order?.orderBillShip.dependentAddressSeqNum
            ) {
                selectedAddress = address;
            }
        });
    });

    if (selectedAddress === null) {
        return addresses?.find((address) => address.defaultAddress);
    }

    return selectedAddress ? (selectedAddress as AddressPayload) : undefined;
};

export const getCurrentCartCard = (
    carts: CartPayload[] | EasyRefillCartPayload[],
    creditCards: PaymentCard[] | CreditCardPayload[]
) => {
    const selectedCard = creditCards?.find((card) => {
        return carts?.some((cart: CartPayload | EasyRefillCartPayload) => {
            const parsedCardSeqNum = String(card.cardSeqNum);
            if (
                parsedCardSeqNum === (cart as CartPayload).Order?.orderBillShip.dependentPaymentSeqNum ||
                parsedCardSeqNum === (cart as CartPayload).Order?.orderBillShip.paymentCardSeqNum ||
                parsedCardSeqNum === (cart as EasyRefillCartPayload).order?.orderBillShip.dependentPaymentSeqNum ||
                parsedCardSeqNum === (cart as EasyRefillCartPayload).order?.orderBillShip.paymentCardSeqNum
            ) {
                return true;
            }
            return false;
        });
    });

    if (!selectedCard) {
        return creditCards?.find((card) => card.defaultCard) || (creditCards && creditCards[0]);
    }

    return selectedCard ? (selectedCard as CreditCardPayload) : undefined;
};
