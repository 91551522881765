import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit & Components
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import NoRefillsIcon from 'ui-kit/illustrations/no-refills/no-refills';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

import LearnMoreContentModal from 'display-components/auto-refill-modal-contents/learn-more';
import TermsAndConditionsContent from 'display-components/auto-refill-modal-contents/terms-and-conditions';
import EmptyState from 'display-components/empty-state/empty-state';

import AutoRefillOtherInfo from 'components/auto-refill-other-info/auto-refill-other-info.component';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import PrescriptionTabs from 'components/prescriptions-list/prescription-tabs/prescription-tabs.component';
import PrescriptionCard from 'components/prescriptions-list/prescriptions-card/prescriptions-card.component';

// Auto Refill
import {
    autoRefillGetPatientAdressesRoutine,
    autoRefillGetPatientDataRoutine,
    autoRefillGetPatientPaymentCardsRoutine
} from 'state/auto-refill/auto-refill.reducer';
import { autoRefillActions } from 'state/auto-refill/auto-refill.reducer';
import {
    autoRefillAccountIsCaliforniaUserSelector,
    autoRefillActiveTabSelector,
    autoRefillEligibleFamilyDataSelector,
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyDataSelector,
    autoRefillFirstNameSelector,
    autoRefillIsCaregiverSelector,
    autoRefillLoadingRxsSelector,
    autoRefillPatientPlanSelector,
    autoRefillRxsLoadedSelector,
    autoRefillToggleRxBusySelector,
    autoRefillUserBearerTokenSelector,
    selectedMemberDataSelector
} from 'state/auto-refill/auto-refill.selectors';
// Modals
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { RX_AVAILABLE_FLOWS } from 'enums/prescription';

import { lowercaseAndCapitalize } from 'util/string';

import { useAutoRefillToggle } from 'hooks/useAutoRefillToggle';
import usePrescriptionCards from 'hooks/usePrescriptionCards';

// Hoc & Types
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

import { EasyRefillErrorModal } from '../../easy-refill';
import './index.style.scss';

const AutoRefillPrescriptions = ({
    data
}: {
    data: GatsbyTypes.GetAutoRefillPrescriptionLanguageAndImageDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { blueSkyBackground } = data;

    // --------------------
    // Selectors
    // -------------------
    const firstName: string = useSelector(autoRefillFirstNameSelector);
    const autoRefillLoadingRxs = useSelector(autoRefillLoadingRxsSelector);
    const autoRefillBearerToken = useSelector(autoRefillUserBearerTokenSelector);
    const autoRefillRxsLoaded = useSelector(autoRefillRxsLoadedSelector);
    const autoRefillBusy = useSelector(autoRefillToggleRxBusySelector);
    const autoRefillePostPatientNumber = useSelector(autoRefillePostPatientNumberSelector);
    const autoRefillIsCaregiver = useSelector(autoRefillIsCaregiverSelector);
    const autoRefillFamilyData = useSelector(autoRefillFamilyDataSelector);
    const isCaliforniaUser = useSelector(autoRefillAccountIsCaliforniaUserSelector);
    const isFamilyHasRxs = useSelector(autoRefillEligibleFamilyDataSelector);
    const autoRefillPlanEligible = useSelector(autoRefillPatientPlanSelector);
    const selectedPatientData = useSelector(selectedMemberDataSelector);
    const { handleToggleAutoRefill } = useAutoRefillToggle();
    const activeTab = useSelector(autoRefillActiveTabSelector);
    const { prescriptionCards } = usePrescriptionCards();

    // --------------------
    // Local state
    // -------------------
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // --------------------
    // Memoized values
    // -------------------

    // Create a variable that returns dependents information
    const dependents = useMemo(() => {
        if (autoRefillFamilyData.length > 0) {
            return autoRefillFamilyData.map((item, key) => ({
                ePostPatientNum: item.epostPatientNum,
                familyMemberName: lowercaseAndCapitalize(`${item.firstName || ''}`),
                id: key,
                planAlias: item.planAlias
            }));
        } else {
            return [];
        }
    }, [autoRefillFamilyData]);

    const isPatientAutoRefillEligible = useMemo(() => {
        return autoRefillIsCaregiver ? selectedPatientData?.patientAutoRefill : autoRefillPlanEligible;
    }, [autoRefillIsCaregiver, autoRefillPlanEligible, selectedPatientData]);

    // --------------------
    // Callbacks
    // -------------------

    // Display error modal
    const handleShowErrorModal = useCallback(
        (errorText: string) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={errorText} />,
                    ctas: [
                        {
                            label: t('modals.autoRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
    };

    // Function that displays the learn more about auto refill modal
    const handleAutoRefillLearnMoreModal = useCallback(() => {
        dispatch(
            openModalComponent({
                title: t('pages.profile.autoRefill.learnMore.label'),
                isCloseable: true,
                customDialogClassName: 'learn-more-modal-header',
                hasDefaultFooter: false,
                hasModalHeader: true,
                variation: 'small',
                isCentered: true,
                hasCustomContent: false,
                content: <LearnMoreContentModal />,
                onClose: handleCloseModal
            })
        );
    }, [dispatch, t]);

    // Function that handles a tab change
    // TODO: When refactoring consider another approach to don't need to
    // pass an unused tab property.
    const handleTabItemClick = (tab = 'all', dependent: string | null) => {
        dispatch(autoRefillActions.setActiveTab(dependent));
    };

    // Handle Auto refill toggle click
    const onChangeAutoRefill = useCallback(
        (rxNumber: string, rxSeqNumber: string, autoRefillEnabled: boolean, isRenew?: boolean) => {
            const hasAnyRxAutorefillOn = prescriptionCards?.some((rx) => rx.autoRefillEnabled);
            handleToggleAutoRefill(
                { rxNumber: rxNumber, rxSeqNum: rxSeqNumber },
                autoRefillEnabled,
                hasAnyRxAutorefillOn,
                <TermsAndConditionsContent />,
                isRenew
            );
        },
        [handleToggleAutoRefill, t, prescriptionCards]
    );

    // Function that renders Rxs for family members.
    const renderPrescriptions = useCallback(() => {
        return (
            <>
                {prescriptionCards?.length === 0 ? (
                    <div
                        className={autoRefillIsCaregiver ? 'empty-state-container' : 'auto-refill-prescriptions-empty'}
                    >
                        <EmptyState
                            icon={NoRefillsIcon}
                            title={t('components.emptyState.noRefills.autoRefill.title')}
                            description={t('components.emptyState.noRefills.autoRefill.description')}
                        />
                    </div>
                ) : (
                    prescriptionCards &&
                    prescriptionCards.map((prescription, index) => (
                        <Col
                            className="p-0 mb-4 flex-fill auto-refill-rx-card"
                            key={`auto-refill-rx-card-${prescription.prescriptionName}-${index}`}
                        >
                            <PrescriptionCard
                                isAddingToCart={false}
                                isCollapsed={true}
                                {...prescription}
                                autoRefillToggle={onChangeAutoRefill}
                                isCaliforniaUser={isCaliforniaUser}
                                autoRefillToggleBusy={autoRefillBusy}
                                planAllowsAutoRefill={isPatientAutoRefillEligible}
                                flow={RX_AVAILABLE_FLOWS.AUTO_REFILL}
                            />
                        </Col>
                    ))
                )}
            </>
        );
    }, [
        autoRefillLoadingRxs,
        autoRefillBearerToken,
        isCaliforniaUser,
        autoRefillBusy,
        isPatientAutoRefillEligible,
        prescriptionCards
    ]);

    // --------------------
    // Use effect
    // -------------------

    // If the link has expired or there is no bearer token set, redirect to /link-expired page
    useEffect(() => {
        if (!autoRefillBearerToken) {
            navigate('/link-expired?flow=auto-refill');
        }
    }, [autoRefillBearerToken]);

    // If there is no eligible rxs redirect to /no-prescriptions-eligible
    useEffect(() => {
        if ((!isLoading && !isFamilyHasRxs) || (!isLoading && !autoRefillIsCaregiver && !autoRefillPlanEligible)) {
            navigate('/no-prescriptions-eligible?flow=auto-refill');
        }
    }, [isLoading, isFamilyHasRxs, autoRefillPlanEligible, autoRefillIsCaregiver]);

    // Listen loaded values to set loading value as false, allowing the user to interact properly with the UI
    useEffect(() => {
        if (autoRefillRxsLoaded) {
            setIsLoading(false);
        }
    }, [autoRefillRxsLoaded, isFamilyHasRxs]);

    // Get Patient Data Family
    useEffect(() => {
        // Load data if the token hasn't expired and data hasn't been loaded
        if (autoRefillBearerToken && !autoRefillRxsLoaded) {
            // Get the auto refill information from API
            dispatch(
                autoRefillGetPatientDataRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillRxsLoaded, autoRefillIsCaregiver, autoRefillBearerToken, autoRefillePostPatientNumber]);

    // Load Patient Adresses & Payment Cards
    useEffect(() => {
        // Validate if token is still valid
        if (autoRefillBearerToken) {
            dispatch(
                autoRefillGetPatientAdressesRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
            dispatch(
                autoRefillGetPatientPaymentCardsRoutine.trigger({
                    onFailure: (error: string) => {
                        if (error !== undefined) {
                            handleShowErrorModal(error);
                        }
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefillBearerToken]);

    return (
        <MarketingPageLayout
            headerImage={blueSkyBackground}
            headerImageClassName="profile-background"
            metaData={{ nodeTitle: 'Auto Refill' }}
            suppressApplicationPage={true}
            pageContentClassName="auto-refill-prescription-page"
        >
            <PageSection>
                <Container fluid className="auto-refill-prescriptions">
                    <InternalHeader
                        sectionIndex={0}
                        title={t('pages.autoRefill.prescriptions.headlineText')}
                        eyebrowText={t('pages.autoRefill.prescriptions.eyebrowText')}
                        body={
                            isLoading ? (
                                <Spinner isVisible={isLoading} t={t} />
                            ) : (
                                <>
                                    <p>{t('pages.autoRefill.prescriptions.greeting', { firstName })} </p>
                                    {isPatientAutoRefillEligible && (
                                        <button
                                            onClick={handleAutoRefillLearnMoreModal}
                                            className="auto-refill-greeting-learn-more-link"
                                            role="link"
                                        >
                                            {t('pages.autoRefill.prescriptions.learnMoreLink')}
                                        </button>
                                    )}
                                </>
                            )
                        }
                    />
                    <div className={`${isLoading ? 'd-none' : 'auto-refill-prescriptions-content'}`}>
                        <div>
                            {!autoRefillLoadingRxs && autoRefillIsCaregiver && (
                                <div>
                                    {autoRefillFamilyData.length > 1 && (
                                        <PrescriptionTabs
                                            isUnAuthFlow
                                            activeTab={'all'}
                                            activeDependentTab={activeTab || autoRefillePostPatientNumber}
                                            isPrescriptionsAvailable={false}
                                            onTabItemChange={handleTabItemClick}
                                            dependents={dependents}
                                            myEpostPatientNum={autoRefillePostPatientNumber}
                                            hasFilterTabs={false}
                                            hasAutoRefillFlag={false}
                                        />
                                    )}

                                    {!autoRefillLoadingRxs && !!autoRefillBearerToken && renderPrescriptions()}
                                </div>
                            )}

                            {!autoRefillLoadingRxs && !autoRefillIsCaregiver && !!autoRefillBearerToken && (
                                <>{renderPrescriptions()}</>
                            )}
                        </div>

                        {autoRefillRxsLoaded && (
                            <div>
                                <AutoRefillOtherInfo />
                                {isPatientAutoRefillEligible && (
                                    <div className="auto-refill-prescriptions-other-info-message">
                                        <div className="icon">
                                            <CircleInfo variant="secondary" />
                                        </div>
                                        <div>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        t('pages.autoRefill.otherInformation.message'),
                                                        {
                                                            allowedTags: ['strong']
                                                        }
                                                    )
                                                }}
                                            />
                                            <button
                                                onClick={handleAutoRefillLearnMoreModal}
                                                className="auto-refill-link"
                                                role="link"
                                            >
                                                {t('pages.autoRefill.prescriptions.learnMoreLink')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default withUnauthenticatedSessionExpiration(AutoRefillPrescriptions, 'auto-refill');

export const query = graphql`
    query GetAutoRefillPrescriptionLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
