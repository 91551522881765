import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import ShippingAddressChangeModalContent from 'display-components/shipping-address-change-modal';

import AddAddressForm, { AddressVerificationFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { AddressCardProps } from 'components/shipping-addresses-v2/address-card/address-card.props';
// Components & UI
import ShippingAddresses from 'components/shipping-addresses-v2/shipping-addresses.component';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import UpdateCartModalContent, {
    FailureUpdateCartModalContent
} from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import { FailureUpdateProfileModalContent } from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

import { accountAddAddressToProfileRoutine } from 'state/account/account.routines';
// Cart
import {
    accountProfileAddressesSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { setCartIsBusy } from 'state/cart/cart.reducers';
import { cartUpdateShippingRoutine, getCartRoutine } from 'state/cart/cart.routines';
import { cartOrderBillShipSelector } from 'state/cart/cart.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';
import { AddressParts } from 'state/usps/usps.reducers';

import { AddressPayload } from 'types/account';

import storageHelper from 'util/storageHelper';

import { useAddressVerification } from 'hooks/useAddressVerification';

import './shipping-address.style.scss';

const getDefaultAddress = (addresses: AddressCardProps[]): AddressCardProps | null => {
    if (!Array.isArray(addresses) || !addresses.length) {
        return null;
    }

    return addresses.find((address) => address.isChecked) || null;
};

const ShippingAddress: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const profileObject = useSelector(accountProfileSelector);

    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const isCaregiver = useSelector(accountProfilIsCaregiverSelector);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);

    const isShippingAddressChangeFlagSet = storageHelper.local.getShippingAddressChangePriceWarning();
    const userPlanAlias = profileObject?.planAlias;
    const isBRD02 = userPlanAlias === 'BRD02';

    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
        storageHelper.local.setShippingAddressChangePriceWarning();
    };

    const handleOpenShippingAddressChangeModal = () => {
        dispatch(
            openModalComponent({
                title: t('modals.shippingAddressChange.title'),
                headerIcon: 'warning',
                variation: 'legacy',
                hasDefaultFooter: true,
                hasModalHeader: true,
                hasCustomHeader: true,
                hasCustomContent: true,
                isCloseable: false,
                customDialogClassName: 'shipping-address-change-modal',
                isCentered: true,
                content: <ShippingAddressChangeModalContent />,
                continueButtonLabel: t('modals.shippingAddressChange.cta'),
                onContinue: handleCloseModal
            })
        );
    };

    const addresses: AddressCardProps[] = profileAddresses.map((address) => {
        const isCurrentCartShippingAddress = address.addressSeqNum === orderBillShip?.patientBillAddressSeq;

        return {
            addressSeqNum: address.addressSeqNum,
            defaultAddress: address.defaultShip,
            isChecked: isCurrentCartShippingAddress,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            country: address.country,
            state: address.state,
            zipcode: address.zipcode,
            zipcodeFour: address.zipcodeFour,
            defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
            addressType: address.addressTypeDesc,
            isProfile: false
        };
    });

    const handleShipToAddressClick = (address: AddressCardProps) => {
        const editAddress = profileAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        const updatedAddress = {
            ...orderBillShip,
            patientBillAddressSeq: editAddress?.addressSeqNum,
            patientShipAddressSeq: editAddress?.addressSeqNum,
            dependentAddressSeqNum: isCaregiver ? orderBillShip?.dependentAddressSeqNum : editAddress?.addressSeqNum
        };

        columnSectionRef?.current?.handleSaveChangesButtonClick();

        dispatch(
            cartUpdateShippingRoutine.trigger({
                ...updatedAddress,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        if (isBRD02 && !isShippingAddressChangeFlagSet) {
                                            handleOpenShippingAddressChangeModal();
                                        }
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCartError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleAddAddressFormSubmit = (values: AddressPayload) => {
        //we don't need to setCartIsBusy = false, because this happen on getCart...
        dispatch(setCartIsBusy(true));

        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                dispatch(
                    accountAddAddressToProfileRoutine.trigger({
                        ...values,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'UpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: ({ error }) => {
                if (values.onFailure && error && !error.message) {
                    values.onFailure(error);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('modals.addressVerificationFailure.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        handleAddNewAddressClick();
                                    },
                                    dataGALocation: 'AddressNotVerified'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.addAddressModal.title')}
                                handleFormCancel={() => dispatch(closeModal({}))}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                                showSetAsDefault={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const { address1, address2, city, state, zipcode, zipcodeFour, addressType } = getDefaultAddress(addresses) ?? {};

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="shipping-address__content"
            className="shipping-address"
            editModeContent={
                <ShippingAddresses
                    addressData={addresses}
                    onSetAsShipping={handleShipToAddressClick}
                    isProfile={false}
                    showLabels={true}
                    addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                />
            }
            headerClassName="shipping-address__header"
            title="Shipping Address"
        >
            {addresses.length > 0 ? (
                <address>
                    <div className="shipping-address__eyebrow">
                        <p className="shipping-address__type text-left">
                            <small>
                                {t('pages.profile.shippingAddress.addressType', {
                                    type: addressType?.toLowerCase()
                                })}
                            </small>
                        </p>
                    </div>
                    <p>
                        {address1} {address2}
                    </p>
                    <p>
                        {city}, {state} {zipcodeFour ? `${zipcode}-${zipcodeFour}` : zipcode}
                    </p>
                </address>
            ) : (
                <div className="selected-address-empty">
                    <ToastBox variant="warning" icon="warning">
                        <Trans i18nKey={t('pages.cart.emptyAddress')} />
                    </ToastBox>
                    <div className="add-address-btn-container">
                        <Button
                            plusIcon
                            IconType="secondary"
                            className="sm-full"
                            label={t('pages.reviewOrder.addAddress')}
                            type="button"
                            variant="text-blue"
                            onClick={handleAddNewAddressClick}
                            dataGAFormName="addresses"
                        />
                    </div>
                </div>
            )}
        </ColumnSectionEditModeToggle>
    );
};

export default ShippingAddress;
