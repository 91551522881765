import { ReactElement } from 'react';
import classNames from 'classnames';

import './info-detail-list.style.scss';

interface ItemList {
    label: string;
    value: string;
    highlightLabel?: boolean | undefined;
    highlightValue?: boolean | undefined;
}

interface InfoDetailListProps {
    itemList: ItemList[];
    highlightLabel?: boolean;
    highlightValue?: boolean;
    hideDivider?: boolean;
    className?: string;
}

const highlightItem = (
    item: string,
    localConfig: boolean | undefined,
    globalConfig: boolean
): string | React.ReactNode => {
    const highlightItem = localConfig !== undefined ? localConfig : globalConfig;

    if (highlightItem) {
        return <strong>{item}</strong>;
    }

    return item;
};

const InfoDetailList = ({
    itemList = [],
    highlightLabel = false,
    highlightValue = false,
    hideDivider = false,
    className
}: InfoDetailListProps): ReactElement => {
    const containerClasses = classNames('info-container-list', { [`${className}`]: className });

    return (
        <div className={containerClasses}>
            <div className="info-container-list__container">
                {itemList.map((item, index) => (
                    <div key={`info-container-list-item-${index}`}>
                        {!hideDivider && index > 0 && <span className="info-container-list__divider"> | </span>}
                        <span className="info-container-list__item">
                            <span className="info-container-list__item__label">
                                {highlightItem(item.label, item.highlightLabel, highlightLabel)}:{' '}
                            </span>
                            <span className="info-container-list__item__value">
                                {highlightItem(item.value, item.highlightValue, highlightValue)}
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InfoDetailList;
