import { Trans } from 'gatsby-plugin-react-i18next';

import './shipping-address-modal.style.scss';

const ShippingAddressChangeModalContent = () => {
    return (
        <div className="shipping-address-change-modal__content">
            <p>
                <Trans i18nKey="modals.shippingAddressChange.content" />
            </p>
        </div>
    );
};

export default ShippingAddressChangeModalContent;
