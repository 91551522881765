import { createSelector } from '@reduxjs/toolkit';

import { getCurrentCardOrderBillship, getCurrentCartAddress, getCurrentCartCard } from 'state/cart/cart.helpers';
import { drugSelector } from 'state/drug/drug.selectors';
import { MembershipPlanId } from 'state/membership/membership.selector';
import { RootState } from 'state/store';

import { RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';

import { mapEasyRefillFamilyMembers, mapEasyRefillFamilyPricingData } from 'util/easy-refill';
import { mapRxPrescriptionCards } from 'util/prescription';

import { getEasyRefillAllItemsTotal, getEasyRefillAllOrderTotal } from './easy-refill.helpers';
import { EasyRefillState } from './easy-refill.reducer';

export const easyRefillSelector = (state: RootState) => state.easyRefillReducer;

export const cartSelector = createSelector(easyRefillSelector, (cart) => cart.cart);

export const easyRefillEpostPatientNumSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.epostPatientNum;
});
// User Verification
export const easyRefillUserVerificationFailedSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.userVerified !== 'UserVerified';
    }
);

export const isLoadingCartSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return !!easyRefill.isLoadingCart;
});

export const isLoadingPrescriptionsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.isLoadingPrescriptions;
});

export const easyRefillIsLoadingHealthConditionsSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.isLoadingHealthConditions;
    }
);

export const easyRefillUserVerifiedSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.userVerified === 'UserVerified';
});

export const easyRefillSecurityTokenSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.securityToken;
});

export const easyRefillSecretKeyHashSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.secretKeyHash;
});

export const easyRefillErrorMessageSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.error.messageText ?? '';
});

export const easyRefillUserBearerTokenSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.bearerToken;
});

export const easyRefillFirstNameSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.firstName;
});

// Patient Data

export const easyRefillPatientAddressSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    let addr = null;

    const easyRefillAddressTypeNum1 = easyRefill.addresses.some(
        (x) => ((x.defaultShip ?? false) || x.addressTypeNum === '1') && (x.active ?? true)
    )
        ? easyRefill.addresses.find(
              (x) => ((x.defaultShip ?? false) || x.addressTypeNum === '1') && (x.active ?? true)
          ) || null
        : easyRefill.addresses.find((x) => x.active ?? true) || null;

    if (easyRefill.addresses.filter((x) => x.active ?? false).length === 1) {
        addr = easyRefill.addresses.find((x) => x.active ?? false) || null;
    } else if (easyRefill.addresses.some((x) => x.addressTypeNum === '5' && (x.active ?? false))) {
        const addrs = easyRefill.addresses.filter((x) => x.addressTypeNum === '5' && (x.active ?? false));

        const outAddrs = addrs.filter((a) => {
            const sd = new Date(a.startDate);
            const ed = new Date(a.endDate);

            return (
                isNaN(sd.getTime()) ||
                isNaN(ed.getTime()) ||
                (sd <= new Date() && ed >= new Date(new Date().setDate(new Date().getDate() - 1)))
            );
        });

        if (outAddrs.length > 0) {
            addr = outAddrs[outAddrs.length - 1];
        } else addr = easyRefillAddressTypeNum1;
    } else addr = easyRefillAddressTypeNum1;

    return addr;
});

export const easyRefillIsCaregiverSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.isCaregiver;
});

export const easyRefillPlanNumberSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.epostPlanNum;
});

export const easyRefillPlanAliasSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.planAlias;
});

export const easyRefillAdjudicatedRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.adjudicatedRxes;
});

export const easyRefillRxsLoadedSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.prescriptionsLoaded;
});

export const easyRefillAccountHasInsuranceSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.planAlias !== 'BRD01' && easyRefill.planAlias !== 'BRD02';
    }
);

export const easyRefillPatientAutoRefillSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.patientAutoRefill;
});

// AutoRefill

export const easyRefillEligibleRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    const eligibleRxs = easyRefill.autoRefillFlags.map((rx) => rx.rxNumber);
    return easyRefill.rxResults.filter((rx) => eligibleRxs.includes(rx.rxNumber));
});

export const easyRefillEligibleRxsCaregiverSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        const eligibleRxs = easyRefill.familyDependents
            .flatMap((item) => item.autoRefillFlags)
            .filter((item) => !!item)
            .map((rx) => rx.rxNumber);
        return easyRefill.familyDependents
            .flatMap((item) => item.rxResults)
            .filter((item) => !!item)
            .filter((rx) => eligibleRxs.includes(rx.rxNumber));
    }
);

export const easyRefillBusySelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.isBusy
);

export const easyRefillRemoveCartBtnDisabled = createSelector(
    easyRefillSelector,
    (medicineCabinet) => medicineCabinet.isRemoveDisabled
);

export const autoRefillFlagsSelector = createSelector(easyRefillSelector, (autoRefill) => autoRefill.autoRefillFlags);

export const easyRefillAutoRefillStatusSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.autoRefill;
});

// Address

export const easyRefillLoadingAddressSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.loadingAddress;
});

export const easyRefillAddressesSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.addresses;
});

export const easyRefillStateAddOrUpdateAddressSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.addressToAddOrUpdate;
    }
);

export const easyRefillPatientBillAddressSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        const addresses = easyRefill.addresses;
        return addresses.find((address) => address.defaultBill === true);
    }
);

export const easyRefillPatientShipAddressSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        const addresses = easyRefill.addresses;
        return addresses.find((address) => address.defaultShip === true);
    }
);

export const easyRefillPatientShipAddressZipCodeSelector = createSelector(
    easyRefillPatientShipAddressSelector,
    (address) => address?.zipcode
);

export const easyRefillAccountIsCaliforniaUserSelector = createSelector(
    easyRefillPatientShipAddressSelector,
    (easyRefillDefaultAddress) => {
        if (!easyRefillDefaultAddress) {
            return false;
        }
        return easyRefillDefaultAddress.state === 'CA';
    }
);

// Payment Cards

export const easyRefillLoadingPaymentCardSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.loadingPaymentCard;
    }
);

export const easyRefillPaymentCardsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.paymentCards.filter((card) => {
        return !card.cardIsExpired;
    });
});

export const easyRefillPatientPaymentCardSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        const paymentCards = easyRefill.paymentCards;
        return paymentCards.find((paymentCard) => paymentCard.defaultCard === true);
    }
);

export const easyRefillSelectedCardSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    const cartPayment = easyRefill.cart?.[0]?.order?.orderBillShip?.paymentCardSeqNum;

    if (cartPayment) {
        const selectedCard = easyRefill?.paymentCards.find((card) => card.cardSeqNum === cartPayment);

        return selectedCard;
    }

    return easyRefill.paymentCards.find((paymentCard) => paymentCard.defaultCard === true);
});

export const easyRefillPaymetricDetailsSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.paymetricDetails
);

// Refill Detail Cart

export const easyRefillcartItemsTotalSelector = createSelector(cartSelector, (carts) =>
    getEasyRefillAllItemsTotal(carts)
);

export const easyRefillcartSubtotalSelector = createSelector(cartSelector, (carts) =>
    getEasyRefillAllOrderTotal(carts)
);

export const easyRefillOrderSelector = createSelector(
    [cartSelector, easyRefillEpostPatientNumSelector, easyRefillIsCaregiverSelector],
    (carts, epostPatientNum, isCaregiver) => {
        const cart = carts?.find((cart) => cart?.order.orderBillShip.paymentCardSeqNum !== null);
        return !isCaregiver && cart?.order.orderBillShip.dependentPaymentSeqNum
            ? cart?.order.orderBillShip.dependentPaymentSeqNum
            : cart?.order.orderBillShip.paymentCardSeqNum;
    }
);

export const easyRefillExpeditedShippingSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.refillOrder.expeditedShipping;
});

export const easyRefillRxsToRefillSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.refillOrder.rxsToRefill;
});

export const easyRefillOrderNumberSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.orderNumber;
});

export const easyRefillOrderInvoiceNumberSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        let cartObject: any = easyRefill.cart;

        if (easyRefill.isCaregiver) {
            cartObject = cartObject?.filter((item: any) => {
                return item.type === 'Caregiver';
            });
        }

        cartObject = cartObject?.map((cart: any) => cart.order.orderHeader);

        return cartObject ? cartObject[0]?.orderInvoiceNumber : null;
    }
);

export const easyRefillLoadingCartSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.loadingCart;
});

// RXs Data

export const easyRefillLoadingRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.loadingPrescriptions;
});

export const easyRefillRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.rxResults;
});

export const easyRefillRxsToOrderSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    const rxs = easyRefill.refillOrder.rxsToRefill;
    return easyRefill.rxResults.filter((rxResult) => rxs.includes(rxResult.rxNumber));
});

export const easyRefillAllRxsSelector = createSelector(
    [easyRefillRxsSelector, easyRefillRxsToRefillSelector],
    (allRxs, selectedRxs) => {
        const allRxNumbers = allRxs.map((rx) => rx.rxNumber);
        return allRxNumbers.length === selectedRxs.length;
    }
);
export const easyRefillHealthConditionsSelector = createSelector(
    easyRefillSelector,
    ({ healthConditions }: EasyRefillState) => ({
        healthConditions
    })
);

export const easyRefillAllergiesSelector = createSelector(
    easyRefillSelector,
    (healthConditions) => healthConditions.healthConditions.allergies
);

export const easyRefillMedicalConditionsSelector = createSelector(
    easyRefillSelector,
    (healthConditions) => healthConditions.healthConditions.medicalConditions
);

export const easyRefillFamilyDependentsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.familyDependents;
});

export const easyRefillFamilyPlansMapSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.familyDependents.reduce((acc, item) => {
        return {
            ...acc,
            [item.epostPatientNum]: item.planAlias
        };
    }, {});
});

export const easyRefillFamilyDependentsPrescriptionsSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.familyDependents.flatMap((item) => item.rxResults).filter((item) => !!item);
    }
);

export const easyRefillCartSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.cart;
});

export const easyRefillCartItemsSelector = createSelector(easyRefillSelector, (state) =>
    state.cart?.flatMap((cart) => cart.order.refillRxs).filter((item) => !!item)
);
export const easyRefillCartItemsTotalSelector = createSelector(cartSelector, (carts) =>
    getEasyRefillAllItemsTotal(carts)
);

export const easyRefillOrderPaymentCardSelector = createSelector(
    [easyRefillPaymentCardsSelector, cartSelector, easyRefillIsCaregiverSelector],
    (cards, carts) => {
        if (!carts || !cards) return undefined;
        return getCurrentCartCard(carts, cards);
    }
);

export const easyRefillOrderAddressSelector = createSelector(
    [cartSelector, easyRefillAddressesSelector],
    (carts, addresses) => {
        if (!carts || !addresses) return undefined;
        return getCurrentCartAddress(carts, addresses);
    }
);

export const easyRefillOrderBillShipSelector = createSelector(
    [cartSelector, easyRefillAddressesSelector],
    (carts, addresses) => {
        if (!carts || !addresses) return undefined;
        return getCurrentCardOrderBillship(carts, addresses);
    }
);

export const easyRefillOrderAddressZipCode = createSelector(
    easyRefillOrderAddressSelector,
    (address) => address?.zipcode
);

export const easyRefillOrderBillShipMethodSelector = createSelector(
    [easyRefillOrderBillShipSelector],
    (orderBillShip) => {
        return orderBillShip?.shipMethodId;
    }
);

export const easyRefillToggleBusySelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.isToggleBusy
);

export const easyRefillFamilyRxSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.isFamilyMembersWithRx;
});

export const easyRefillPlansSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.patientPlans
);

export const easyRefillCartTotalSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.totalOrder
);

export const easyRefillCartZipCodeSelector = createSelector(
    [easyRefillOrderAddressZipCode, easyRefillBusySelector],
    (cartZipCode, cartIsBusy) => {
        return !cartIsBusy ? cartZipCode : null;
    }
);

export const easyRefillFamilyMembersSelector = createSelector(
    [easyRefillFamilyDependentsSelector],
    (easyRefillFamilyDependents) => {
        if (easyRefillFamilyDependents.length > 0) {
            const familyMembers = mapEasyRefillFamilyMembers(easyRefillFamilyDependents, true);
            return familyMembers;
        } else {
            return null;
        }
    }
);

export const easyRefillFamilyMembersPricingDataSelector = createSelector(
    [
        easyRefillFamilyDependentsSelector,
        easyRefillPlanAliasSelector,
        easyRefillPatientShipAddressZipCodeSelector,
        easyRefillEpostPatientNumSelector
    ],
    (easyRefillFamilyDependents, planAlias, mainUserZipCode, epostPatientNum) =>
        mapEasyRefillFamilyPricingData(
            easyRefillFamilyDependents,
            planAlias as BIRDI_PLANS,
            mainUserZipCode || '',
            epostPatientNum,
            // TODO: API is not giving us the value, ensure that this is replaced with the right property
            MembershipPlanId.NONE
        )
);

export const easyRefillActiveTabSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.activeTab;
});

export const easyRefillPrescriptionsCardsSelector = createSelector(
    [
        easyRefillFamilyDependentsSelector,
        easyRefillRxsSelector,
        easyRefillFamilyMembersPricingDataSelector,
        easyRefillCartItemsSelector,
        easyRefillCartZipCodeSelector,
        easyRefillPatientShipAddressZipCodeSelector,
        easyRefillActiveTabSelector,
        easyRefillIsCaregiverSelector,
        easyRefillEpostPatientNumSelector,
        drugSelector
    ],
    (
        familyData,
        allRxs,
        familyPricingData,
        cartItems,
        cartZipCode,
        mainUserZipCode,
        activeTab,
        isCaregiver,
        epostPatientNum,
        { drugDiscountPrices }
    ) => {
        let prescriptionCards: RxDetails[] = [];

        if (isCaregiver) {
            const activeTabData = familyData.find((dependent) => dependent.epostPatientNum === activeTab);
            if (!!activeTabData && activeTabData?.rxResults.length > 0) {
                prescriptionCards = activeTabData.rxResults;
            }
        } else {
            prescriptionCards = allRxs.map((prescription) => ({ ...prescription, epostPatientNum: epostPatientNum }));
        }

        return mapRxPrescriptionCards(
            prescriptionCards,
            familyPricingData,
            drugDiscountPrices,
            cartItems,
            null,
            mainUserZipCode,
            cartZipCode
        );
    }
);
