import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    accountAutoRefillEligibleSelector,
    accountHasHealthProfileCompletedSelector,
    accountIsCaliforniaUserSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { registrationContinueRegistrationSelector } from 'state/registration/registration.selector';

// Hook personalizado
export const useOnboardingRedirection = (shouldRedirect = true) => {
    const location = useLocation();
    const profileObject = useSelector(accountProfileSelector);
    const hasHealthProfile = useSelector(accountHasHealthProfileCompletedSelector);
    const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);

    const continueRegistrationResponseState = useSelector(registrationContinueRegistrationSelector);
    const { isRegistrationCompleted, isLoading: isLoadingRegistration } = continueRegistrationResponseState;

    const { shouldSetupProfile, isOnboardingCompleted, shouldSetHealthProfile, shouldSetupAutoRefill } = useMemo(() => {
        return {
            shouldSetupProfile: !profileObject && isRegistrationCompleted === false,
            shouldSetHealthProfile: profileObject && isRegistrationCompleted && !hasHealthProfile,
            shouldSetupAutoRefill: !isCAResident && isAutoRefillEligible,
            isOnboardingCompleted: isRegistrationCompleted === true && profileObject && hasHealthProfile === true
        };
    }, [profileObject, isRegistrationCompleted, hasHealthProfile, isAutoRefillEligible, isCAResident]);

    useEffect(() => {
        if (!shouldRedirect || isLoadingRegistration) return;

        const currentPath = location.pathname;
        const allowedPaths = [
            '/secure/onboarding/terms-of-use',
            '/secure/onboarding/profile-setup',
            '/secure/onboarding/health-profile',
            '/secure/onboarding/auto-refill',
            '/secure/onboarding/mobile-phone-confirm-your-number',
            '/secure/onboarding/mobile-phone-setup',
            '/secure/onboarding/mobile-phone-validation-setup'
        ];

        // ensure that other types of users are not redirected
        if (profileObject && profileObject?.hasMembership === false) {
            navigate('/secure/medicine-cabinet');
            return;
        }

        // avoid the redirect if the user is in the onboarding flow
        if ((shouldSetupProfile || shouldSetHealthProfile) && allowedPaths.includes(currentPath)) return;

        // redirect to start the profile complete
        if (shouldSetupProfile) {
            console.info('User must complete their profile');
            navigate('/secure/onboarding/terms-of-use');
            return;
        }

        // redirect to health profile
        if (shouldSetHealthProfile) {
            console.info('User must set their health profile');
            navigate('/secure/onboarding/health-profile');
            return;
        }

        // redirect to auto refill
        if (shouldSetupAutoRefill) {
            console.info('User must set autoRefill');
            navigate('/secure/onboarding/auto-refill');
            return;
        }

        if (isOnboardingCompleted) {
            console.info('Onboarding completed. Redirecting...');
            navigate('/secure/membership');
        }
    }, [
        shouldRedirect,
        location.pathname,
        shouldSetupProfile,
        isOnboardingCompleted,
        shouldSetHealthProfile,
        shouldSetupAutoRefill,
        isLoadingRegistration,
        profileObject
    ]);

    return {
        shouldSetupProfile,
        isOnboardingCompleted,
        shouldSetHealthProfile,
        shouldSetupAutoRefill
    };
};
