import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { SendInviteErrorModal } from 'display-components/add-family-member/modals';
import ContactInfoBox from 'display-components/contact-info-box';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { ModalComponentContent, ModalComponentFooter } from 'components/modal/modal.component';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { Dependent } from 'state/family-profile/family-profile.reducers';
import { familyProfileSendDependentInvitationRoutine } from 'state/family-profile/family-profile.routines';
import { familyProfileSelector } from 'state/family-profile/family-profile.selectors';
import { closeModalComponent } from 'state/modal/modal.reducer';

import { ContactMethods } from 'enums/contact-methods';

import { scrollToTop } from 'util/navigation';
import { convertToTitleCase } from 'util/string';

import './resend-invite.styles.scss';

export interface ResendInviteModalProps {
    dependent: Dependent;
    defaultComunicationMethod: ContactMethods | null;
    caregiverEpostPatientNum: string;
}

const ResendInviteModal = ({
    dependent,
    defaultComunicationMethod,
    caregiverEpostPatientNum
}: ResendInviteModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [contactMethod, setContactMethod] = useState<ContactMethods | null>(
        defaultComunicationMethod || ContactMethods.Email
    );

    const { hasEmailSendingFailed, hasSmsSendingFailed } = useSelector(familyProfileSelector);

    // DRX-4482: As we have a default selection of the email, but if the user doesn't have an email
    // and the only contact method that they have fails, we reset the default selection to avoid unexpected behaviors.
    useEffect(() => {
        if (!dependent.email && hasSmsSendingFailed) return setContactMethod(null);
    }, [dependent]);

    const handleRadio = (contactType: ContactMethods) => {
        if (!Object.values(ContactMethods).includes(contactType)) {
            return;
        }

        if (contactMethod !== contactType) {
            setContactMethod(contactType);
            setHasError(false);
        }
    };

    const handleSubmit = () => {
        if (contactMethod === null) {
            setHasError(true);
            return;
        }
        setIsSubmitting(true);

        dispatch(
            familyProfileSendDependentInvitationRoutine.trigger({
                data: {
                    CommunicationType: contactMethod,
                    EpostPatientNumber: dependent.ePostPatientNum,
                    CareGiverDependentId: dependent.id
                },
                onSuccess: () => {
                    setIsSubmitting(false);
                    dispatch(closeModalComponent());
                    scrollToTop({ top: 75 });
                },
                onFailure: (error: string[]) => {
                    setIsSubmitting(false);
                    dispatch(closeModalComponent());
                    scrollToTop({ top: 75 });
                    showGenericErrorModal(dependent.familyMemberFirstName, error);
                }
            })
        );
    };

    const showGenericErrorModal = useCallback(
        (memberName: string, errors: string[]) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => dispatch(closeModal({})),
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('components.membershipModals.errorTitle')} icon="alert" />
                    ),
                    bodyContent: (
                        <SendInviteErrorModal
                            t={t}
                            memberName={convertToTitleCase(memberName)}
                            onClose={() => dispatch(closeModal({}))}
                            errors={errors}
                            epostPatientNumCaregiver={caregiverEpostPatientNum}
                            epostPatientNumDependent={dependent.ePostPatientNum}
                        />
                    )
                })
            );
        },
        [dispatch, t]
    );

    return (
        <ModalComponentContent>
            <div className="resend-invite-modal">
                <section className="resend-invite-modal-content">
                    <>
                        <ContactInfoBox
                            id="resend-invitation-as-phone"
                            contactData={dependent.phoneNumber as string}
                            contactType={ContactMethods.Sms}
                            isChecked={!!(contactMethod === ContactMethods.Sms) && !hasSmsSendingFailed}
                            onChangeHandler={() => handleRadio(ContactMethods.Sms)}
                            className={!dependent.phoneNumber ? 'disabled' : ''}
                            methodFailed={hasSmsSendingFailed}
                        />

                        {contactMethod === ContactMethods.Sms && (
                            <ToastBox icon="info" variant="info" iconColor="cathams-blue">
                                <p>{t('components.contactInfoBox.phoneNote')}</p>
                            </ToastBox>
                        )}
                    </>
                    <ContactInfoBox
                        id="resend-invitation-as-email"
                        contactData={dependent.email as string}
                        contactType={ContactMethods.Email}
                        isChecked={!!(contactMethod === ContactMethods.Email) && !hasEmailSendingFailed}
                        onChangeHandler={() => handleRadio(ContactMethods.Email)}
                        className={!dependent.email ? 'disabled' : ''}
                        methodFailed={hasEmailSendingFailed}
                    />

                    {hasError && (
                        <ToastBox variant="danger" icon="danger" backgroundColor="white-smoke">
                            <span className="error-message">{t('components.contactInfoBox.emptySelection')}</span>
                        </ToastBox>
                    )}
                </section>
            </div>

            <ModalComponentFooter
                onContinue={handleSubmit}
                continueButtonLabel={t('modals.resendInvitation.sendInvite')}
                onCancel={() => dispatch(closeModalComponent())}
                cancelButtonLabel={t('modals.resendInvitation.cancel')}
                isCTABusy={isSubmitting}
                isCTADisabled={isSubmitting}
            />
        </ModalComponentContent>
    );
};

export default ResendInviteModal;
