import { Trans, useTranslation } from 'react-i18next';

import { ModalComponentContent } from 'components/modal/modal.component';

import { getPhoneNumber } from 'util/globalVariables';

import './terms-and-conditions.styles.scss';

const TermsAndConditionsContent = () => {
    const { t } = useTranslation();

    return (
        <ModalComponentContent>
            <section className="terms-and-conditions-modal__container">
                <p className="terms-and-conditions-modal__title">{t('pages.profile.autoRefill.autoRefillTC.label')}</p>
                <ol className="terms-and-conditions-modal__list">
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsOne')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsTwo')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsThree')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsFour')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsFive')}</li>
                    <li>
                        <Trans
                            i18nKey="pages.profile.autoRefill.autoRefillTC.termsSix"
                            values={{ phoneNumber: getPhoneNumber({ isEnd: true }) }}
                        />
                    </li>
                </ol>
            </section>
        </ModalComponentContent>
    );
};

export default TermsAndConditionsContent;
