import { Formik, FormikHelpers } from 'formik';
import { ReactElement } from 'react';

import { BIRDI_PRICE_SCHEMA, NEW_PRESCRIPTION_SCHEMA } from 'schema/prescription.schema';

import { PrescriptionTypes } from 'types/prescription';

import useBirdiPrice from 'hooks/useBirdiPrice';

export interface DrugFormWrapperProps {
    initialSelectValues?: any;
    renderFormikFunction: (...args: any[]) => void;
    handlePriceSuccess?: (...args: any[]) => void;
    handleDescriptionSuccess?: (...args: any[]) => void;
    resetSelectedDrug?: (...args: any[]) => void;
    isControlled?: boolean;
    isDrugNameEditable?: boolean;
    handleSelectedDrug?: (...args: any[]) => void;
}

export const DrugFormWrapper = ({
    initialSelectValues,
    renderFormikFunction,
    handlePriceSuccess,
    handleDescriptionSuccess,
    resetSelectedDrug,
    isControlled = false,
    isDrugNameEditable,
    handleSelectedDrug
}: DrugFormWrapperProps): ReactElement => {
    const { getPrescriptionPrice } = useBirdiPrice();

    const initialValues = initialSelectValues
        ? initialSelectValues
        : {
              drugName: '',
              drugDisplayName: '',
              drugForm: '',
              strength: '',
              gpi: '',
              ndc: '',
              qty: '',
              rxNumber: '',
              imageUrl: '',
              genericProductCode: ''
          };

    // Handle the form submit.
    const handleSubmit = (values: Partial<PrescriptionTypes>, actions: FormikHelpers<Partial<PrescriptionTypes>>) => {
        resetSelectedDrug && resetSelectedDrug(values);
        handleSelectedDrug && handleSelectedDrug(values);
        getPrescriptionPrice({ values, actions, handlePriceSuccess, handleDescriptionSuccess });
    };
    return (
        <Formik<Partial<PrescriptionTypes>>
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
            validationSchema={isDrugNameEditable ? NEW_PRESCRIPTION_SCHEMA(isControlled) : BIRDI_PRICE_SCHEMA()}
            initialValues={initialValues}
        >
            {(formik) => renderFormikFunction(formik)}
        </Formik>
    );
};
