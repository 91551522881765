import { useTranslation } from 'react-i18next';
import ButtonComponent from 'ui-kit-v2/button/button';

import {
    hasNegativeBalance,
    hasPositiveBalance,
    totalDueParser
} from 'display-components/balance-by-member-list/balance-by-member-list.helpers';
import IconComposer from 'display-components/icon-composer';

import withSkeleton from 'hoc/withSkeleton';

import './user-balance.styles.scss';

interface UserBalanceProps {
    value: number | undefined;
    handleOnClick?: () => void;
}

const UserBalance = ({ value, handleOnClick }: UserBalanceProps) => {
    const { t } = useTranslation();

    // Positive values = DEBIT | Negative values = CREDIT
    if (value === 0) return null;

    return (
        value !== undefined && (
            <div className={`user-balance ${hasPositiveBalance(value) && 'positive-balance'}`}>
                <div className="user-balance__icon-container">
                    <IconComposer iconType="money" subIconType={hasNegativeBalance(value) ? 'positive' : 'error'} />
                </div>

                <div className="user-balance__info-container">
                    <div>
                        <p className="user-balance__info-container__info">
                            {t(
                                `pages.profile.paymentHistory.userBalance.${
                                    hasNegativeBalance(value) ? 'negativeBalance' : 'positiveBalance'
                                }`
                            )}
                        </p>
                        <p className="user-balance__info-container__value">{totalDueParser(value)}</p>
                    </div>
                </div>
                {hasPositiveBalance(value) && (
                    <div className="user-balance__pay-btn">
                        <ButtonComponent
                            label={t('pages.profile.balanceByMember.btnAction')}
                            type="button"
                            variant="solid"
                            onClick={handleOnClick}
                        />
                    </div>
                )}
            </div>
        )
    );
};

export default withSkeleton(UserBalance);
