import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// UI Kit
import BirdiAccordion from 'ui-kit/accordion/accordion';
import Button from 'ui-kit/button/button';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import {
    AllergiesModalContentV2,
    HealthConditionsModalContentV2
} from 'components/health-profile-v2/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';

// Modal
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { medicalConditionsFetchHealthConditionsForPatientRoutine } from 'state/medical-conditions/medical-conditions.routines';

import { getPhoneNumber } from 'util/globalVariables';

import { useHealthConditionsByPatientNum } from 'hooks/useHealthConditionsByPatientNum';
import { usePatientsInCart } from 'hooks/usePatientsInCart';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import './health-conditions.style.scss';

export interface HealthConditionsProps {
    epostPatients: {
        epostPatientNum: string;
        firstName?: string;
        lastName?: string;
    }[];
}

interface HealthConditionsButtonProps {
    onClick: () => void;
    label: string;
}

const HealthConditionsAddConditionButton = ({ label, onClick }: HealthConditionsButtonProps) => (
    <div className="cart__health-conditions__link">
        <Button
            plusIcon
            IconType="secondary"
            className="p-0"
            dataGAFormName="healthConditions"
            label={label}
            onClick={onClick}
            type="button"
            variant="text-blue"
        />
    </div>
);

const HealthConditionsAddAllergiesButton = ({ label, onClick }: HealthConditionsButtonProps) => (
    <div className="cart__health-conditions__link">
        <Button
            plusIcon
            IconType="secondary"
            className="p-0"
            dataGAFormName="allergies"
            label={label}
            onClick={onClick}
            type="button"
            variant="text-blue"
        />
    </div>
);

// TODO: move this component outside of /cart folder and delete unused duplicates
const HealthConditions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { currentFlow } = usePrescriptionFlow();
    const [epostPatients] = usePatientsInCart();

    const healthConditionsInfoByPatientNumber = useHealthConditionsByPatientNum();

    const handleToggleAllergiesClick = (ePostPatientNum: string) => () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContentV2
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                        ePostPatientNum={ePostPatientNum}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'primary',
                size: 'lg',
                className: 'modal-health-profile-warning',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action({ ...update, currentFlow }));
                        },
                        dataGALocation: 'ReviewOrderHealthConditions'
                    }
                ]
            })
        );
    };

    const handleToggleHealthConditionsClick = (ePostPatientNum: string) => () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContentV2
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                        ePostPatientNum={ePostPatientNum}
                    />
                ),
                ctas: []
            })
        );
    };

    const flatEPostPatientNumbers = epostPatients.map((patient) => patient.epostPatientNum).join('-');

    useEffect(() => {
        epostPatients.forEach((user) => {
            const healthConditions = healthConditionsInfoByPatientNumber[user.epostPatientNum];

            if (!healthConditions) {
                dispatch(
                    medicalConditionsFetchHealthConditionsForPatientRoutine.trigger({
                        ePostPatientNumber: user.epostPatientNum
                    })
                );
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, flatEPostPatientNumbers, healthConditionsInfoByPatientNumber]);

    return (
        <div>
            {epostPatients.map((user) => {
                const healthConditions = healthConditionsInfoByPatientNumber[user.epostPatientNum];

                if (!healthConditions) return null;

                const {
                    existingConditions,
                    existingAllergies,
                    userHasNotSubmittedAllergies,
                    userHasNotSubmittedConditions,
                    isLoadingAllergies,
                    isLoadingMedicalConditions
                } = healthConditions;

                const isHealthProfileIncomplete =
                    !isLoadingAllergies && !isLoadingMedicalConditions
                        ? existingConditions.length === 0 || existingAllergies.length === 0
                        : false;

                return (
                    <div key={user.epostPatientNum} className="cart__health-conditions">
                        {epostPatients.length > 1 && (
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <h3 className="cart__health-conditions__patient-name">
                                            {user.firstName} {user.lastName}
                                        </h3>
                                    </Col>
                                </Row>
                            </Container>
                        )}
                        <Container fluid>
                            <Row>
                                <Col>
                                    {isHealthProfileIncomplete ? (
                                        <ToastBox variant="warning" icon="warning">
                                            <Trans
                                                i18nKey={
                                                    'pages.requestTelemedicineVisit.healthProfile.healthProfileConfirmation'
                                                }
                                            />
                                        </ToastBox>
                                    ) : (
                                        <div className="cart__health-conditions__text">
                                            {t('pages.reviewOrder.healthProfileConfirmation')}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h4 className="cart__health-conditions__title">
                                        {t('accordions.healthConditions.title').toUpperCase()}
                                    </h4>
                                </Col>
                                <Col className="text-right d-none d-lg-flex justify-content-lg-end">
                                    <HealthConditionsAddConditionButton
                                        label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                                        onClick={handleToggleHealthConditionsClick(user.epostPatientNum)}
                                    />
                                </Col>
                            </Row>
                            {userHasNotSubmittedConditions && !isLoadingMedicalConditions ? (
                                <Row>
                                    <Col>
                                        <div className="error">
                                            {t(
                                                'pages.requestTelemedicineVisit.healthProfile.errors.conditionsRequired'
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col>
                                        {isLoadingMedicalConditions ? (
                                            <SpinnerInline />
                                        ) : (
                                            <HealthConditionPills conditions={existingConditions} />
                                        )}
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col className="text-center d-block d-lg-none">
                                    <HealthConditionsAddConditionButton
                                        label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                                        onClick={handleToggleHealthConditionsClick(user.epostPatientNum)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BirdiAccordion.Spacer />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4 className="cart__health-conditions__title">
                                        {t('accordions.allergies.title').toUpperCase()}
                                    </h4>
                                </Col>
                                <Col className="text-right d-none d-lg-flex justify-content-lg-end">
                                    <HealthConditionsAddAllergiesButton
                                        label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                                        onClick={handleToggleAllergiesClick(user.epostPatientNum)}
                                    />
                                </Col>
                            </Row>
                            {userHasNotSubmittedAllergies && !isLoadingAllergies ? (
                                <Row>
                                    <Col>
                                        <div className="error">
                                            {t('pages.requestTelemedicineVisit.healthProfile.errors.allergiesRequired')}
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="mt-1">
                                    <Col>
                                        {isLoadingAllergies ? (
                                            <SpinnerInline />
                                        ) : (
                                            <HealthConditionPills conditions={existingAllergies} />
                                        )}
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col className="text-center d-block d-lg-none">
                                    <HealthConditionsAddAllergiesButton
                                        label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                                        onClick={handleToggleAllergiesClick(user.epostPatientNum)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                );
            })}
        </div>
    );
};

export default HealthConditions;
