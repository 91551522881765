// Hooks
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import AddAddressForm, { AddressVerificationFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { AddressCardProps } from 'components/shipping-addresses-v2/address-card/address-card.props';
// Components & UI
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import UpdateCartModalContent, {
    FailureUpdateCartModalContent
} from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import { FailureUpdateProfileModalContent } from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
// State
import { getEasyRefillMainCart } from 'state/easy-refill/easy-refill.helpers';
import {
    easyRefillAddAddressesRoutine,
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillUpdateShippingRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    cartSelector,
    easyRefillAddressesSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillLoadingCartSelector,
    easyRefillOrderBillShipSelector
} from 'state/easy-refill/easy-refill.selectors';
import { AddressParts } from 'state/usps/usps.reducers';

import { AddressPayload } from 'types/account';

import { useAddressVerification } from 'hooks/useAddressVerification';

// Styles
import './easy-refill-shipping-address.style.scss';
import ShippingAddresses from './shipping-addresses/shipping-addresses.component';

// Helper function to get the selected address data.
const getSelectedAddress = (addresses: AddressCardProps[]): AddressCardProps | null => {
    if (!Array.isArray(addresses) || !addresses.length) {
        return null;
    }

    return addresses.find((address) => address.isChecked) || { ...addresses[0], isChecked: true };
};

// Main component
const EasyRefillShippingAddress: React.FC = () => {
    // General
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [cardAddresses, setCardAddresses] = useState<AddressCardProps[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<AddressCardProps | null>(null);

    // Selectors
    const easyRefillAddresses = useSelector(easyRefillAddressesSelector);
    const cartsObject = useSelector(cartSelector);
    const epostPatientNum = useSelector(easyRefillEpostPatientNumSelector);
    const orderBillShip = useSelector(easyRefillOrderBillShipSelector);

    const cartObject = getEasyRefillMainCart(cartsObject, epostPatientNum);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);
    const isCartLoading = useSelector(easyRefillLoadingCartSelector);

    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();

    useEffect(() => {
        if (cartObject !== undefined && easyRefillAddresses.length == 0)
            dispatch(easyRefillGetPatientAddressesRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const cardAddressMapped: AddressCardProps[] = easyRefillAddresses.map((address) => {
            const isCurrentCartShippingAddress = address.addressSeqNum === orderBillShip?.patientBillAddressSeq;

            return {
                addressSeqNum: address.addressSeqNum,
                defaultAddress: address.defaultShip,
                isChecked: isCurrentCartShippingAddress,
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                country: address.country,
                state: address.state,
                zipcode: address.zipcode,
                zipcodeFour: address.zipcodeFour,
                defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
                addressType: address.addressTypeDesc,
                isProfile: false
            };
        });

        setCardAddresses(cardAddressMapped);
        setSelectedAddress(getSelectedAddress(cardAddressMapped));
    }, [easyRefillAddresses, cartObject, t]);

    const handleShipToAddressClick = (address: AddressCardProps) => {
        const selectedAddress = easyRefillAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        columnSectionRef?.current?.handleSaveChangesButtonClick();
        dispatch(
            easyRefillUpdateShippingRoutine.trigger({
                addressSeqNum: selectedAddress?.addressSeqNum || address.addressSeqNum,
                onSuccess: () => {
                    dispatch(easyRefillGetCartRoutine.trigger());
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleAddAddressFormSubmit = (values: AddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                dispatch(
                    easyRefillAddAddressesRoutine.trigger({
                        ...values,
                        epostPatientNum: epostPatientNum,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onSuccess: () => {
                            dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'UpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: ({ error }) => {
                if (values.onFailure && error && !error.message) {
                    values.onFailure(error);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('modals.addressVerificationFailure.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        handleAddNewAddressClick();
                                    },
                                    dataGALocation: 'AddressNotVerified'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal cart-address-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.addAddressModal.title')}
                                handleFormCancel={() => dispatch(closeModal({}))}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                                showSetAsDefault={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="shipping-address__content"
            className="shipping-address"
            editModeContent={
                <ShippingAddresses
                    addressData={cardAddresses}
                    onSetAsShipping={handleShipToAddressClick}
                    isProfile={false}
                    showLabels={true}
                    addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                />
            }
            headerClassName="shipping-address__header"
            title="Shipping Address"
        >
            {isCartLoading ? (
                <SpinnerInline />
            ) : cardAddresses.length > 0 ? (
                <address>
                    <div className="shipping-address__eyebrow">
                        <p className="shipping-address__type text-left">
                            <small>
                                {t('pages.profile.shippingAddress.addressType', {
                                    type: selectedAddress?.addressType?.toLowerCase()
                                })}
                            </small>
                        </p>
                    </div>
                    <p>
                        {selectedAddress?.address1} {selectedAddress?.address2}
                    </p>
                    <p>
                        {selectedAddress?.city}, {selectedAddress?.state}{' '}
                        {selectedAddress?.zipcodeFour
                            ? `${selectedAddress?.zipcode}-${selectedAddress?.zipcodeFour}`
                            : selectedAddress?.zipcode}
                    </p>
                </address>
            ) : (
                <div className="selected-address-empty">
                    <ToastBox variant="warning" icon="warning">
                        <Trans i18nKey={t('pages.cart.emptyAddress')} />
                    </ToastBox>
                    <div className="add-address-btn-container">
                        <Button
                            plusIcon
                            IconType="secondary"
                            className="sm-full"
                            label={t('pages.reviewOrder.addAddress')}
                            type="button"
                            variant="text-blue"
                            onClick={handleAddNewAddressClick}
                            dataGAFormName="addresses"
                        />
                    </div>
                </div>
            )}
        </ColumnSectionEditModeToggle>
    );
};
export default EasyRefillShippingAddress;
