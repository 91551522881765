import { createRoutine } from 'redux-saga-routines';

import {
    FamilyAccountSendDependentInvitationResponse,
    FamilyAccountSendDependentInvitationSagaPayload
} from 'types/family-management';
import { ContactMethods } from 'enums/contact-methods';

export const familyProfileGetDependentsRoutine = createRoutine('FAMILY_PROFILE/GET_DEPENDENTS');
export const familyProfileAddDependentRoutine = createRoutine('FAMILY_PROFILE/ADD_DEPENDENT');
export const familyProfileVerifyDependentRoutine = createRoutine('FAMILY_PROFILE/VERIFY_DEPENDENT');
export const familyProfileRemoveDependentRoutine = createRoutine('FAMILY_PROFILE/REMOVE_DEPENDENT');

export const familyProfileSendDependentInvitationRoutine = createRoutine('FAMILY_PROFILE/SEND_INVITATION', {
    trigger: (payload: FamilyAccountSendDependentInvitationSagaPayload) => payload,
    success: (response: FamilyAccountSendDependentInvitationResponse) => response,
    failure: (
        response: FamilyAccountSendDependentInvitationResponse['Errors'],
        contactMethod: ContactMethods
    ) => ({
        response,
        contactMethod
    })
});

export const familyProfileGetCaregiversRoutine = createRoutine('FAMILY_PROFILE/GET_CAREGIVERS');
export const familyProfilePostChangeCaregiversAccessRoutine = createRoutine(
    'FAMILY_PROFILE/POST_CHANGE_CAREGIVERS_ACCESS'
);
