import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { addTransferPrescriptionState, PrescriptionPayload } from './add-transfer-prescription.reducers';

export const AddTransferPrescriptionSelector = (state: RootState) => state.addTransferPrescriptionReducer;

export const addTransferPrescriptionSelector = createSelector(
    AddTransferPrescriptionSelector,
    (addTransferPrescription) => addTransferPrescription.addTransferPrescription
);

export const addTransferPrescriptionDrugDetailsSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => ({
        drugFormOptions: state.drugFormOptions,
        drugStrengthOptions: state.drugStrengthOptions,
        drugPackageOptions: state.drugPackageOptions // DRX-1056 enhancement
    })
);

export const addTransferPrescriptionStepSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.currentStep
);

export const addTransferPrescriptionTotalStepSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.totalSteps
);

export const addTransferPrescriptionFlowTypeSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.addTransferPrescription.RequestType || 'Transfer'
);

export const addTransferPrescriptionShowPaymentSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.showPaymentStep
);

export const addTransferPrescriptionShowAddressSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.showAddressStep
);

export const addTransferPrescriptionIsPhotoUploadedSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.isPhotoUploaded
);

export const addTransferFlowPrescriptionMissingDataSelector = createSelector(
    addTransferPrescriptionSelector,
    (prescriptionState: PrescriptionPayload) => {
        return (
            prescriptionState.PharmacyName.length > 0 ||
            prescriptionState.PharmacyPhone.length > 0 ||
            prescriptionState.PharmacyAddress.length > 0
        );
    }
);

export const addTransferPhotoProcessedSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => {
        return state.addTransferPrescription.ImageExtractedData ? true : false;
    }
);

export const addTransferPrescriptionMemberSelector = createSelector(
    AddTransferPrescriptionSelector,
    (state: addTransferPrescriptionState) => state.selectedMemberEpostPatientNum
);
