import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { accountIsLoggedInSelector } from 'state/account/account.selectors';
import { easyRefillUserBearerTokenSelector } from 'state/easy-refill/easy-refill.selectors';
import { EasyRefillService } from 'state/easy-refill/easy-refill.services';

import { Allergies, HealthConditionsByPatientNumber, MedicalConditions } from 'types/medical-conditions';

import { ApiStatus } from 'enums/api-status';
import { RX_AVAILABLE_FLOWS } from 'enums/prescription';

import { noop, UnknownFunction } from 'util/function';
import { TrackFlowComplete, TrackFormSuccess } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';
import storageHelper from 'util/storageHelper';

import {
    medicalConditionsAddOrUpdateAllRoutine,
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine,
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine,
    medicalConditionsFetchHealthConditionsForPatientRoutine
} from './medical-conditions.routines';
import { healthConditionsByPatientNumberSelector } from './medical-conditions.selector';
import MedicalConditionsService from './medical-conditions.services';

const FORM_NAME = {
    Allergies: 'Allergies',
    HealthConditions: 'HealthConditions'
};

export type GetMedicalConditionDetailsSaga = {
    epostPatientNumFamily: string;
    onSuccess?: UnknownFunction;
    onError?: UnknownFunction;
    currentFlow?: RX_AVAILABLE_FLOWS;
};

type AddOrUpdateMedicalConditionsSaga = MedicalConditions & {
    onSuccess?: UnknownFunction;
    onError?: UnknownFunction;
    currentFlow?: RX_AVAILABLE_FLOWS;
};

type AddOrUpdateAllergiesSaga = Allergies & {
    onSuccess?: UnknownFunction;
    onError?: UnknownFunction;
    currentFlow?: RX_AVAILABLE_FLOWS;
};

type AddOrUpdateConditionsAndAllergiesSaga = {
    medicalConditions: MedicalConditions;
    allergies: Allergies;
    onSuccess?: UnknownFunction;
    onError?: UnknownFunction;
};

type FetchHealthConditionsForPatient = {
    ePostPatientNumber: string;
    currentFlow?: RX_AVAILABLE_FLOWS;
};

function* getMedicalConditionDetailsSaga(action: PayloadAction<GetMedicalConditionDetailsSaga>) {
    const { onSuccess } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

    const isEasyRefill = action.payload.currentFlow === RX_AVAILABLE_FLOWS.EASY_REFILL;

    const serviceCall = isEasyRefill
        ? EasyRefillService.medicalConditions().getAll
        : MedicalConditionsService.medicalConditions().get;

    const payload = isEasyRefill
        ? {
              bearerToken,
              epostPatientNum: action.payload.epostPatientNumFamily
          }
        : {
              epostPatientNumFamily: action.payload.epostPatientNumFamily
          };

    yield baseEffectHandler({
        service: serviceCall,
        isAuthenticatedService: !isEasyRefill,
        isLoggedIn,
        data: payload,
        *onResponse(response) {
            /*
             *  DRX-4349: Short-term solution to allow the user to place order if added "other" health condition and allergy
             */
            const temporaryOther = storageHelper.cookies.getHealthConditionsTemOther()
                ? { TemporaryOther: storageHelper.cookies.getHealthConditionsTemOther() }
                : { TemporaryOther: undefined };

            const medicalConditions = isEasyRefill
                ? {
                      ...response?.patientCondition,
                      ePostPatientNum: response?.patientCondition?.EPostPatientNum
                  }
                : response;

            yield put(medicalConditionsDetailsRoutine.success({ ...medicalConditions, ...temporaryOther }));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(
                medicalConditionsDetailsRoutine.failure({
                    messageText: error?.response?.response?.data?.Errors || '',
                    ePostPatientNum: action.payload.epostPatientNumFamily
                })
            );
        }
    });
}

function* updateMedicalConditionDetailsSaga(action: PayloadAction<AddOrUpdateMedicalConditionsSaga>) {
    const { onSuccess, onError, TemporaryOther, currentFlow } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

    const isEasyRefill = currentFlow === RX_AVAILABLE_FLOWS.EASY_REFILL;

    const payload = isEasyRefill
        ? {
              ...action.payload,
              bearerToken
          }
        : action.payload;

    yield baseEffectHandler({
        service: isEasyRefill
            ? EasyRefillService.medicalConditions().post
            : MedicalConditionsService.medicalConditions().addOrUpdate,
        isAuthenticatedService: !isEasyRefill,
        isLoggedIn,
        data: payload,
        *onResponse(response) {
            yield put(medicalConditionsAddOrUpdateRoutine.success(action.payload));

            /*
             *  DRX-4349: Short-term solution to allow the user to place order if added "other" health condition and allergy
             */
            if (TemporaryOther) {
                storageHelper.cookies.setHealthConditionsTemOther(TemporaryOther);
            }

            TrackFormSuccess(FORM_NAME.HealthConditions);
            TrackFlowComplete(FORM_NAME.HealthConditions);
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(
                medicalConditionsAddOrUpdateRoutine.failure({
                    messageText: error?.response?.response?.data?.Errors || '',
                    // @ts-expect-error variable spelling is not consistent
                    ePostPatientNum: action.payload?.EPostPatientNum || action.payload.ePostPatientNum
                })
            );
            onError && onError(error);
        }
    });
}

function* getAllergiesDetailsSaga(action: PayloadAction<GetMedicalConditionDetailsSaga>) {
    const { onSuccess } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

    const isEasyRefill = action.payload.currentFlow === RX_AVAILABLE_FLOWS.EASY_REFILL;

    const serviceCall = isEasyRefill ? EasyRefillService.allergies().getAll : MedicalConditionsService.allergies().get;

    const payload = isEasyRefill
        ? {
              bearerToken,
              epostPatientNum: action.payload.epostPatientNumFamily
          }
        : {
              epostPatientNumFamily: action.payload.epostPatientNumFamily
          };

    yield baseEffectHandler({
        service: serviceCall,
        isAuthenticatedService: !isEasyRefill,
        isLoggedIn,
        data: payload,
        *onResponse(response) {
            /*
             *  DRX-4349: Short-term solution to allow the user to place order if added "other" health condition and allergy
             */
            const temporaryOther = storageHelper.cookies.getAllergiesTemOther()
                ? { TemporaryOther: storageHelper.cookies.getAllergiesTemOther() }
                : { TemporaryOther: undefined };

            const allergies = isEasyRefill
                ? {
                      ...response?.patientAllergies,
                      ePostPatientNum: response?.patientAllergies?.EPostPatientNum
                  }
                : response;

            yield put(medicalConditionsAllergiesDetailsRoutine.success({ ...allergies, ...temporaryOther }));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(
                medicalConditionsAllergiesDetailsRoutine.failure({
                    messageText: error?.response?.response?.data?.Errors || '',
                    ePostPatientNum: action.payload.epostPatientNumFamily
                })
            );
        }
    });
}

function* updateAllergiesDetailsSaga(action: PayloadAction<AddOrUpdateAllergiesSaga>) {
    const { onSuccess, onError, TemporaryOther, currentFlow } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

    const isEasyRefill = currentFlow === RX_AVAILABLE_FLOWS.EASY_REFILL;

    const payload = isEasyRefill
        ? {
              ...action.payload,
              bearerToken
          }
        : action.payload;

    yield baseEffectHandler({
        service: isEasyRefill ? EasyRefillService.allergies().post : MedicalConditionsService.allergies().addOrUpdate,
        isAuthenticatedService: !isEasyRefill,
        isLoggedIn,
        data: payload,
        *onResponse(response) {
            yield put(medicalConditionsAllergiesAddOrUpdateRoutine.success(action.payload));

            /*
             *  DRX-4349: Short-term solution to allow the user to place order if added "other" health condition and allergy
             */
            if (TemporaryOther) {
                storageHelper.cookies.setAllergiesTemOther(TemporaryOther);
            }

            TrackFormSuccess(FORM_NAME.Allergies);
            TrackFlowComplete(FORM_NAME.Allergies);
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(
                medicalConditionsAllergiesAddOrUpdateRoutine.failure({
                    messageText: error?.response?.response?.data?.Errors || '',
                    // @ts-expect-error variable spelling is not consistent
                    ePostPatientNum: action.payload?.EPostPatientNum || action.payload.ePostPatientNum
                })
            );
            onError && onError(error);
        }
    });
}

function* updateConditionsAndAllergiesSaga(action: PayloadAction<AddOrUpdateConditionsAndAllergiesSaga>) {
    const { onSuccess, onError, allergies, medicalConditions } = action.payload;

    try {
        yield all([
            call(updateAllergiesDetailsSaga as never, { payload: { ...allergies, onSuccess: noop, onError: noop } }),
            call(updateMedicalConditionDetailsSaga as never, {
                payload: { ...medicalConditions, onSuccess: noop, onError: noop }
            })
        ]);
        yield put(medicalConditionsAddOrUpdateAllRoutine.success());
        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(
            medicalConditionsAddOrUpdateAllRoutine.failure({
                messageText: error?.response?.response?.data?.Message || '',
                messageStatus: true
            })
        );
        onError && onError(error);
    }
}

function* fetchHealthConditionsForPatient(action: PayloadAction<FetchHealthConditionsForPatient>) {
    const healthConditionsByPatient: HealthConditionsByPatientNumber = yield select(
        healthConditionsByPatientNumberSelector
    );

    const healthConditions = healthConditionsByPatient[action.payload.ePostPatientNumber];

    if (!healthConditions || healthConditions.medicalConditionsApiStatus !== ApiStatus.LOADING) {
        yield put(
            medicalConditionsDetailsRoutine.trigger({
                epostPatientNumFamily: action.payload.ePostPatientNumber,
                currentFlow: action.payload.currentFlow
            })
        );
    }
    if (!healthConditions || healthConditions.allergiesApiStatus !== ApiStatus.LOADING) {
        yield put(
            medicalConditionsAllergiesDetailsRoutine.trigger({
                epostPatientNumFamily: action.payload.ePostPatientNumber,
                currentFlow: action.payload.currentFlow
            })
        );
    }
}

function* medicalConditionsSaga() {
    yield takeEvery(medicalConditionsFetchHealthConditionsForPatientRoutine.TRIGGER, fetchHealthConditionsForPatient);
    yield takeEvery(medicalConditionsDetailsRoutine.TRIGGER, getMedicalConditionDetailsSaga);
    yield takeLatest(medicalConditionsAddOrUpdateRoutine.TRIGGER, updateMedicalConditionDetailsSaga);
    yield takeEvery(medicalConditionsAllergiesDetailsRoutine.TRIGGER, getAllergiesDetailsSaga);
    yield takeLatest(medicalConditionsAllergiesAddOrUpdateRoutine.TRIGGER, updateAllergiesDetailsSaga);
    yield takeLatest(medicalConditionsAddOrUpdateAllRoutine.TRIGGER, updateConditionsAndAllergiesSaga);
}

export default medicalConditionsSaga;
