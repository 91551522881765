import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { registrationFormValuesSelector } from 'state/registration/registration.selector';

import useCustomerIo from 'hooks/useCustomerIo';

const INITIAL_PAGE = '/get-started-v2/membership';
const SETUP_ACCOUNT_PAGE = '/get-started-v2/setup-your-account';
const HEALTH_BENEFITS_PAGE = '/get-started-v2/health-benefits';
const PAYMENT_METHOD_PAGE = '/get-started-v2/payment-method';

const useRegistration = () => {
    const formValues = useSelector(registrationFormValuesSelector);
    const location = useLocation();
    const { resetCustomerIo } = useCustomerIo();

    const isCurrentPath = (path: string) => location.pathname === path;
    const isMembershipRegistrationPath = (path: string) =>
        [INITIAL_PAGE, SETUP_ACCOUNT_PAGE, HEALTH_BENEFITS_PAGE, PAYMENT_METHOD_PAGE].includes(path);

    useEffect(() => {
        // DRX-4747: Reset CustomerIO if user navigates away from registration flow
        if (!isMembershipRegistrationPath(location.pathname)) {
            resetCustomerIo();
            return;
        }

        const { zipCode, email, firstName, lastName, password } = formValues;

        if (!zipCode || !email) {
            if (!isCurrentPath(INITIAL_PAGE)) {
                navigate(INITIAL_PAGE);
            }
            return;
        }

        if (!firstName || !lastName || !password) {
            if (!isCurrentPath(SETUP_ACCOUNT_PAGE)) {
                navigate(SETUP_ACCOUNT_PAGE);
            }
        }
    }, [formValues, location.pathname, resetCustomerIo]);

    // DRX-4747: Add event listener for tab/window close
    useEffect(() => {
        const handleBeforeUnload = () => {
            resetCustomerIo();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [resetCustomerIo]);

    const handleGoBack = useCallback(() => {
        const pathsMap: Record<string, string | null> = {
            [INITIAL_PAGE]: null, // initial page - no goBack
            [SETUP_ACCOUNT_PAGE]: INITIAL_PAGE,
            [HEALTH_BENEFITS_PAGE]: SETUP_ACCOUNT_PAGE,
            [PAYMENT_METHOD_PAGE]: HEALTH_BENEFITS_PAGE
        };

        const currentPath = location.pathname;
        const previousPath = pathsMap[currentPath];

        if (previousPath) {
            navigate(previousPath);
        }
    }, [location.pathname]);

    return { handleGoBack };
};

export default useRegistration;
