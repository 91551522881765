import { useEffect } from 'react';

import { UnknownFunction } from 'util/function';

/**
 * A custom hook that listens to the browser's `popstate` event and calls a specified callback function.
 * Useful for handling browser navigation events (e.g., back and forward navigation).
 *
 * @param {function} callback - The function to be called when the `popstate` event occurs. This function can contain any logic to handle the event.
 * @param {Array} dependencies - The dependencies array for the `useEffect` hook. When any value in this array changes, the `useEffect` re-runs, reattaching the `popstate` event listener.
 *
 * @example
 * // Example usage:
 * usePopState(() => {
 *     console.log('User navigated using browser controls!');
 * }, [dependency1, dependency2]);
 *
 * @returns {void}
 */
const usePopState = (callback: UnknownFunction, dependencies: unknown[] = []): void => {
    useEffect(() => {
        const handlePopState = () => {
            callback();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, dependencies);
};

export default usePopState;
