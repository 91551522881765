import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { healthConditionsByPatientNumberSelector } from 'state/medical-conditions/medical-conditions.selector';

import { Allergies, MedicalConditions } from 'types/health-profile';

import { ApiStatus } from 'enums/api-status';

export interface UseHealthConditions {
    allergyChoices: string[];
    existingAllergies: string[];
    existingFreeformAllergies: string;
    conditionChoices: string[];
    existingConditions: string[];
    existingFreeformConditions: string;
    userHasNotSubmittedAllergies: boolean;
    userHasNotSubmittedConditions: boolean;
    isLoadingAllergies?: boolean;
    isLoadingMedicalConditions?: boolean;
    ePostPatientNum?: string;
}

export const removeNoneConditions = (reducedConditions: string[]) => {
    if (reducedConditions.length > 1 && reducedConditions.includes('None')) {
        return reducedConditions.filter((condition) => condition !== 'None');
    }
    return reducedConditions;
};

export const reduceExistingConditions = (conditions: MedicalConditions | Allergies) => {
    const reducedConditions = Object.entries(conditions)
        .map((keyValPair) => {
            const key = keyValPair[0];
            const val = keyValPair[1];

            if (typeof val === 'boolean' && Boolean(val) && key.toLocaleLowerCase() !== 'messagestatus') {
                return key;
            }
            return '';
        })
        .filter((cond) => cond && cond.length > 0)
        .sort();

    return removeNoneConditions(reducedConditions);
};

//  reduces condition choices to string array
const reduceConditions = (conditions: MedicalConditions | Allergies) => {
    return Object.entries(conditions)
        .map((keyValPair) => {
            const key = keyValPair[0];
            const val = keyValPair[1];

            if (typeof val === 'boolean' && key !== 'messageStatus') {
                return key;
            }
            return '';
        })
        .filter((cond) => cond && cond.length > 0)
        .sort();
};

const formatConditionsChoices = (conditions: MedicalConditions | Allergies) => {
    const reducedConditions = reduceConditions(conditions);
    const none = reducedConditions.find((condition: string) => condition === 'None');
    return [none, ...reducedConditions.filter((condition: string) => condition !== 'None')].filter(
        (condition): condition is string => !!condition
    );
};

export function useHealthConditionsByPatientNum(): {
    [epostPatientNumber: string]: UseHealthConditions | undefined;
};
export function useHealthConditionsByPatientNum(patientNumbers: string[]): {
    [epostPatientNumber: string]: UseHealthConditions | undefined;
} & {
    isMissingHealthConditions: boolean;
    isLoadingHealthConditions: boolean;
};
export function useHealthConditionsByPatientNum(patientNumbers: string): UseHealthConditions | undefined;
export function useHealthConditionsByPatientNum(patientNumbers?: string | string[]): unknown {
    const healthConditionsByPatientNum = useSelector(healthConditionsByPatientNumberSelector);

    const healthConditionsInfoByPatientNumber = useMemo(() => {
        return Object.entries(healthConditionsByPatientNum).reduce<{
            [ePostPatientNum: string]: UseHealthConditions;
        }>((acc, [ePostPatientNum, healthConditions]) => {
            if (patientNumbers && Array.isArray(patientNumbers) && !patientNumbers.includes(ePostPatientNum)) {
                return acc;
            }

            const existingAllergies = healthConditions.allergies
                ? reduceExistingConditions(healthConditions.allergies)
                : [];

            const existingConditions = healthConditions.medicalConditions
                ? reduceExistingConditions(healthConditions.medicalConditions)
                : [];

            //  temporary values
            const tempExistingFreeformConditions = healthConditions.medicalConditions
                ? healthConditions.medicalConditions.TemporaryOther
                : '';
            const tempExistingFreeformAllergies = healthConditions.allergies
                ? healthConditions.allergies.TemporaryOther
                : '';

            const allergyChoices = healthConditions.allergies
                ? formatConditionsChoices(healthConditions.allergies)
                : [];
            const conditionChoices = healthConditions.medicalConditions
                ? formatConditionsChoices(healthConditions.medicalConditions)
                : [];

            //  existing conditions
            const existingFreeformConditions = healthConditions.medicalConditions
                ? healthConditions.medicalConditions.Other
                : '';
            const existingFreeformAllergies = healthConditions.allergies ? healthConditions.allergies.Other : '';

            // booleans
            const userHasNotSubmittedConditions =
                existingConditions.length === 0 &&
                (!existingFreeformConditions || existingFreeformConditions.length === 0) &&
                (!tempExistingFreeformConditions || tempExistingFreeformConditions.length === 0);

            const userHasNotSubmittedAllergies =
                existingAllergies.length === 0 &&
                (!existingFreeformAllergies || existingFreeformAllergies.length === 0) &&
                (!tempExistingFreeformAllergies || tempExistingFreeformAllergies.length === 0);

            const isLoadingAllergies = healthConditions.allergiesApiStatus === ApiStatus.LOADING;
            const isLoadingMedicalConditions = healthConditions.medicalConditionsApiStatus === ApiStatus.LOADING;

            const healthConditionsInfo = {
                allergyChoices,
                existingAllergies,
                existingFreeformAllergies,
                conditionChoices,
                existingConditions,
                existingFreeformConditions,
                userHasNotSubmittedAllergies,
                userHasNotSubmittedConditions,
                isLoadingAllergies,
                isLoadingMedicalConditions,
                ePostPatientNum
            };

            return {
                ...acc,
                [ePostPatientNum]: healthConditionsInfo
            };
        }, {});
    }, [healthConditionsByPatientNum, patientNumbers]);

    const additionalHealthConditionsInfo = useMemo(() => {
        if (Array.isArray(patientNumbers)) {
            return {
                isMissingHealthConditions: patientNumbers.some((patient) => {
                    const healthConditions = healthConditionsInfoByPatientNumber[patient];

                    return (
                        !healthConditions ||
                        healthConditions.userHasNotSubmittedAllergies ||
                        healthConditions.userHasNotSubmittedConditions
                    );
                }),
                isLoadingHealthConditions: patientNumbers.some((patient) => {
                    const healthConditions = healthConditionsInfoByPatientNumber[patient];

                    return (
                        !healthConditions ||
                        healthConditions.isLoadingMedicalConditions ||
                        healthConditions.isLoadingAllergies
                    );
                })
            };
        }

        return null;
    }, [patientNumbers, healthConditionsInfoByPatientNumber]);

    if (Array.isArray(patientNumbers)) {
        return {
            ...healthConditionsInfoByPatientNumber,
            ...additionalHealthConditionsInfo
        };
    }

    if (typeof patientNumbers === 'string') {
        return healthConditionsInfoByPatientNumber[patientNumbers];
    }

    return healthConditionsInfoByPatientNumber;
}
