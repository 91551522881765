import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// UI
import ButtonComponent from 'ui-kit-v2/button/button';
import { SpinnerV2 } from 'ui-kit-v2/spinner/spinner';

import Skeleton from 'ui-kit/utils/skeleton/skeleton';

// Components
import BalanceByMemberList from 'display-components/balance-by-member-list';
import { parsePatientName } from 'display-components/balance-by-member-list/balance-by-member-list.helpers';
import MakePayment from 'display-components/payment-history/make-payment-modal/make-payment-modal.component';
import TransactionList from 'display-components/payment-history/transaction-list';
import UserBalance from 'display-components/payment-history/user-balance';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { TransactionCardVariant } from 'components/transaction-card/transaction-card.types';

// States
import { accountProfileSelector, accountStateSelector } from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { familyProfileDependentsSelector } from 'state/family-profile/family-profile.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';
import { paymentsV2GetPaymentHistoryRoutine } from 'state/payments/payments.routines';
import { paymentIsLoadingHistorySelector, paymentsStateSelector } from 'state/payments/payments.selectors';

// Types
import { PaymentHistoryV2Result } from 'types/payment-history';

import { ApiStatus } from 'enums/api-status';

// Utils
import { scrollToElement } from 'util/element.helper';
import { noop, parseObject } from 'util/function';
import { convertToTitleCase } from 'util/string';

import './payment-history.style.scss';

const PaymentHistory = ({ data }: { data: GatsbyTypes.PaymentHistoryDataQuery }) => {
    // Hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Selectors
    const { paymentHistoryV2 } = useSelector(paymentsStateSelector);
    const isLoadingPaymentHistoryData = useSelector(paymentIsLoadingHistorySelector);
    const familyProfileData = useSelector(familyProfileDependentsSelector);
    const profileObject = useSelector(accountProfileSelector);
    const { profileApiStatus } = useSelector(accountStateSelector);

    // Local State
    const [activeTab, setActiveTab] = useState(profileObject?.epostPatientNum || '');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [disabledPagination, setDisabledPagination] = useState(false);
    const [showPagination, setShowPagination] = useState(false);
    const [paymentsList, setPaymentsList] = useState<PaymentHistoryV2Result[]>([]);
    const [firstLoading, setFirstLoading] = useState(true);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    /*****************************
    Transactions Result Section */

    const fetchPaymentsHistory = useCallback(
        (epostPatientNum: string, pageNumber = 1, pageSize = 10) => {
            dispatch(
                paymentsV2GetPaymentHistoryRoutine.trigger({
                    page: pageNumber.toString(),
                    pageSize: pageSize.toString(),
                    includeAging: 'false',
                    epostPatientNum,
                    onSuccess: () => {
                        setFirstLoading(false);
                    }
                })
            );
        },
        [dispatch, pageSize]
    );

    const hasRecords = useMemo(() => {
        if (paymentHistoryV2?.totalRecords === 0) return false;

        return true;
    }, [paymentHistoryV2]);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(
            () => scrollToElement('.payment-history-container__loading', { offset: 0 }),
            300
        );
        return () => clearTimeout(timeoutRef.current!);
    }, [isLoadingPaymentHistoryData]);

    const parsePaymentHistoryV2Result = (data: Record<string, any>) => {
        const targetObject = {
            transactionInfo: {
                DMEItem: '',
                DMEOrder: '',
                EPostScriptId: '',
                GLDebitCredit: '',
                GLPaymentNumber: '',
                GLPostAmount: '',
                GLPostDatetime: '',
                GLPostNote: '',
                GLPostStatusDesc: '',
                GLPostStatusNum: '',
                GLPostType: '',
                GLPostUser: '',
                epostPatientNum: '',
                familyId: '',
                orderPaymentCardMonth: '',
                orderPaymentCardMonthNum: '',
                orderPaymentCardNumber: '',
                orderPaymentCardSeqNum: '',
                orderPaymentCardTypeDesc: '',
                orderPaymentCardTypeNum: '',
                orderPaymentCardYear: '',
                patientName: '',
                displayDateTime: '',
                displayDate: '',
                paidUsingCredit: '',
                accountsReceivableType: '',
                accountsReceivableStatus: ''
            },
            user: '',
            userType: ''
        };
        const parsedData = parseObject(targetObject, data);

        const isTransactionFromDependent = familyProfileData.find(
            (dep) => dep.ePostPatientNum === data.epostPatientNum
        );
        const isTransactionFromCurrentUser = data.epostPatientNum === profileObject?.epostPatientNum;

        // should replicate BE name convention - "LASTNAME,FISRTNAME"
        const patientName = isTransactionFromCurrentUser
            ? `${profileObject?.patientLastName},${profileObject?.patientFirstName}`
            : `${isTransactionFromDependent?.familyMemberLastName},${isTransactionFromDependent?.familyMemberFirstName}`;

        const isSoloUser = !profileObject?.isCaregiver && familyProfileData.length === 0;

        return {
            ...parsedData,
            user: isSoloUser ? '' : parsePatientName(patientName, 'capitalize'),
            userType: !isTransactionFromDependent ? 'caregiver' : 'dependent'
        };
    };

    /*********************
    Pagination Section */

    const resultsAmount = useMemo(() => {
        if (!paymentHistoryV2) return undefined;
        const resultsLeft = paymentHistoryV2.totalRecords - paymentsList.length;
        setDisabledPagination(resultsLeft === 0);
        setShowPagination(paymentHistoryV2.totalRecords > 10);
        return resultsLeft > 10 ? 10 : resultsLeft;
    }, [paymentsList]);

    const handleShowMore = () => {
        setCurrentPage(currentPage + 1);
        fetchPaymentsHistory(activeTab, currentPage + 1);
    };

    const handleShowAll = () => {
        fetchPaymentsHistory(activeTab, 1, paymentHistoryV2?.totalRecords);
    };

    useEffect(() => {
        setCurrentPage(1);
        setPageSize(10);
        setPaymentsList([]);
        if (profileObject?.epostPatientNum) {
            const currentTab = activeTab || profileObject?.epostPatientNum;
            setActiveTab(currentTab);
            fetchPaymentsHistory(currentTab, 1, 10);
        }
    }, [activeTab, fetchPaymentsHistory, profileObject]);

    /**********************
    User Balance Section */

    const creditBalance = useMemo(() => {
        if (!paymentHistoryV2) return undefined;

        return paymentHistoryV2.currentBalance;
    }, [paymentHistoryV2]);

    const debitFamilyBalance = useMemo(() => {
        const dependents = profileObject?.dependents ?? [];
        const familyMembers = [profileObject, ...dependents];

        return familyMembers.reduce((acc, dep) => {
            const patientBalance = dep?.patientBalance;
            const balance = patientBalance ? parseFloat((patientBalance as string).replace(/,/g, '')) : NaN;
            if (!isNaN(balance) && balance > 0) {
                acc += balance;
            }
            return acc;
        }, 0);
    }, [profileObject]);

    const getBalanceByMemberData = useCallback(() => {
        if (!profileObject?.isCaregiver || !paymentHistoryV2) return [];

        const dependents =
            profileObject.dependents?.map((patient: Partial<ProfileObjectPayload>) => {
                return {
                    epostPatientNum: patient.epostPatientNum,
                    // Ensures we are correctly treating the amount string for cases like "11,000.00"
                    currentBalance: parseFloat((patient.patientBalance as string).replace(/,/g, '')),
                    patientName: convertToTitleCase(`${patient.patientFirstName} ${patient.patientLastName}`),
                    isCaregiver: false
                };
            }) || [];

        return [
            {
                epostPatientNum: profileObject.epostPatientNum,
                currentBalance: parseFloat((profileObject.patientBalance as string).replace(/,/g, '')),
                patientName: convertToTitleCase(`${profileObject.patientFirstName} ${profileObject.patientLastName}`),
                isCaregiver: true
            },
            ...dependents
        ].filter((member) => {
            const balance = parseFloat(String(member.currentBalance));
            return !isNaN(balance) && balance !== 0;
        });
    }, [paymentHistoryV2, familyProfileData, profileObject]);

    const getUserBalance = useCallback(() => {
        if (debitFamilyBalance && debitFamilyBalance > 0) {
            return {
                balanceValue: debitFamilyBalance,
                onClickHandler: handleMakePaymentFullBalanceModal
            };
        } else if (!profileObject?.isCaregiver && creditBalance && creditBalance < 0) {
            return {
                balanceValue: creditBalance,
                onClickHandler: noop
            };
        }
        return null;
    }, [debitFamilyBalance, creditBalance, profileObject]);

    /*******************
    Payments Section */

    /* This comparison determines whether the `isTotalPayment` flag should be set to `true` or `false`.
    The flag is `true` only when:
    - The user is a Caregiver (`profileObject?.isCaregiver` is `true`).
    - The amount to pay includes contributions from both the owner and dependents, meaning the
    current balance (creditBalance) is less than the total family balance (debitFamilyBalance).

    If the current balance is equal to the total balance (`debitFamilyBalance === creditBalance`),
    it means the payment applies only to the owner, not the family group. In this case, the flag
    must be `false` to prevent treating an individual payment as a family payment, which could
    cause bugs in the API. */
    const isTotalPayment = useMemo(() => {
        const isTotalBalanceEqualToCurrentBalance = debitFamilyBalance === creditBalance;

        if (profileObject?.isCaregiver && !isTotalBalanceEqualToCurrentBalance) {
            return true;
        } else {
            return false;
        }
    }, [profileObject, paymentHistoryV2]);

    const handleMakePaymentByMemberModal = (patientName: string, amount: number, epostPatientNum: string) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: false,
                            dependentName: patientName,
                            amountDue: amount,
                            epostPatientNum
                        }}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const handleMakePaymentFullBalanceModal = (isCaregiver?: boolean) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: isCaregiver ? profileObject?.isCaregiver : false,
                            amountDue: debitFamilyBalance as number,
                            epostPatientNum: profileObject?.epostPatientNum as string,
                            isTotalPayment: isTotalPayment
                        }}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const mergePaymentResults = (existingList: PaymentHistoryV2Result[], newList: PaymentHistoryV2Result[]) => {
        const mergedMap = new Map();
        [...existingList, ...newList].forEach((payment) => {
            mergedMap.set(payment.GLPostDatetime, payment);
        });

        const arr = Array.from(mergedMap.values()).sort(
            (a, b) => new Date(b.GLPostDatetime).getTime() - new Date(a.GLPostDatetime).getTime()
        );

        if (paymentHistoryV2?.totalRecords !== undefined) {
            setDisabledPagination(arr.length >= paymentHistoryV2.totalRecords);
        }

        return arr;
    };

    useEffect(() => {
        if (paymentHistoryV2) {
            const newPayments = paymentHistoryV2.results || [];
            const dependentsPayments =
                paymentHistoryV2.dependents?.filter((dep) => dep.results.length > 0).flatMap((dep) => dep.results) ||
                [];

            setPaymentsList((prevPayments) => {
                const mergedResults = mergePaymentResults(prevPayments, [...newPayments, ...dependentsPayments]);
                return mergedResults;
            });
        }
    }, [paymentHistoryV2]);

    /*******************
    All Sections */

    const sections = useMemo(() => {
        const isLoadingPage =
            (isLoadingPaymentHistoryData && paymentsList.length === 0 && firstLoading) ||
            profileApiStatus === ApiStatus.LOADING;

        const balanceByMembersData = getBalanceByMemberData();

        const balanceByMember = {
            heading: t('pages.profile.balanceByMember.eyebrowText'),
            children: (
                <div className="balance-container">
                    <BalanceByMemberList onClickPay={handleMakePaymentByMemberModal} members={balanceByMembersData} />
                </div>
            ),
            suppressChildrenContainer: true
        };

        const balanceByMemberRender = isLoadingPage
            ? { children: <Skeleton skeletonHeight={200} isLoading={true} />, suppressChildrenContainer: true }
            : balanceByMember;

        const paymentHistory = {
            heading: t(`pages.profile.paymentHistory.heading`),
            children: (
                <div className="payment-history-container">
                    {!isLoadingPaymentHistoryData || currentPage >= 1 ? (
                        <TransactionList
                            variant={TransactionCardVariant.PAYMENT}
                            transactions={paymentsList.map((payment) => parsePaymentHistoryV2Result(payment))}
                            activeTab={activeTab}
                            onNavigate={setActiveTab}
                            isLoading={isLoadingPaymentHistoryData}
                        />
                    ) : undefined}

                    {isLoadingPaymentHistoryData && (
                        <div className="payment-history-container__loading">
                            <SpinnerV2 />
                        </div>
                    )}

                    {showPagination && hasRecords && paymentsList.length >= 10 && !isLoadingPaymentHistoryData && (
                        <Row className="payment-history__pagination">
                            <ButtonComponent
                                className="show-more-button"
                                type="button"
                                variant="link"
                                disabled={disabledPagination}
                                onClick={handleShowMore}
                                label={t('pages.profile.paymentHistory.ctas.showMore', {
                                    amount: resultsAmount,
                                    total: paymentHistoryV2?.totalRecords || 10
                                })}
                            />
                            <p className="payment-history__pagination-divider">|</p>
                            <ButtonComponent
                                disabled={disabledPagination}
                                className="show-more-button"
                                type="button"
                                variant="link"
                                onClick={handleShowAll}
                                label={t('pages.profile.paymentHistory.ctas.showAll')}
                            />
                        </Row>
                    )}
                </div>
            ),
            suppressChildrenContainer: true,
            showMemberTabs: true,
            showEveryone: false,
            activeTab: activeTab,
            onTabItemChange: setActiveTab
        };

        const paymentHistoryRender = isLoadingPage
            ? { children: <Skeleton skeletonHeight={400} isLoading={true} />, suppressChildrenContainer: true }
            : paymentHistory;

        const userBalanceData = getUserBalance();

        const userBalance = {
            children: (
                <UserBalance
                    value={userBalanceData?.balanceValue}
                    handleOnClick={userBalanceData?.onClickHandler}
                    isLoading={isLoadingPage}
                    skeletonHeight={75}
                />
            ),
            suppressChildrenContainer: true
        };

        const membersWithBalance = balanceByMembersData.filter((member) => member.currentBalance !== 0);
        if (membersWithBalance?.length === 0) {
            return [userBalance, paymentHistoryRender];
        }

        return [userBalance, balanceByMemberRender, paymentHistoryRender];
    }, [
        t,
        activeTab,
        isLoadingPaymentHistoryData,
        paymentHistoryV2,
        familyProfileData,
        profileObject,
        debitFamilyBalance,
        creditBalance,
        getBalanceByMemberData,
        handleMakePaymentByMemberModal
    ]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.paymentHistory.eyebrowText`)}
            title={t(`pages.profile.paymentHistory.title`)}
            suppressChildrenContainer={true}
            sections={sections}
        />
    );
};

export default PaymentHistory;

export const query = graphql`
    query PaymentHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
