import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// Ui-kit
import BorderedContainer from 'ui-kit/bordered-container/bordered-container';
import Button from 'ui-kit/button/button';
import IconBackgroundColor from 'ui-kit/icon-background-color';
import ClockCircleIcon from 'ui-kit/icons/clock-circle';
//Ui-kit: icons
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import PawIcon from 'ui-kit/icons/paw';
import UserCircleIcon from 'ui-kit/icons/user-circle';
import InfoDetailList from 'ui-kit/info-detail-list';
import ToastBox from 'ui-kit/toast-box/toast-box';
import TooltipContainer from 'ui-kit/tooltip-container';

import {
    removeMemberIsLoadingSelector,
    sendInvitationIsLoadingSelector
} from 'state/family-profile/family-profile.selectors';

import { formatDate } from 'const/options';

// Interfaces and types
import {
    FamilyAccountAdditionalInfoProps,
    FamilyAccountCardAccountInfoProps,
    FamilyAccountCardActionProps,
    FamilyAccountCardIconProps,
    FamilyAccountCardProps,
    FamilyAccountCardTooltipProps,
    FamilyMemberAccountType
} from 'types/family-management';

import FamilyManagementBadgeStatus from '../family-management-badge-status';
// Sub-components
import FamilyMember from '../family-member';
// Styles
import './family-account-card.style.scss';

// Helper function to avoid duplicate the implementation of this component
// as it's implemented in two different places inside the layout.
const FamilyAccountCardAccountInfo = ({ dod, insuranceId, t }: FamilyAccountCardAccountInfoProps): ReactElement => {
    return (
        <InfoDetailList
            itemList={[
                {
                    label: t('components.familyManagement.infoDetailList.dob'),
                    value: dod ? dod : t('components.familyManagement.infoDetailList.notAvailable')
                },
                {
                    label: t('components.familyManagement.infoDetailList.insuranceId'),
                    value: insuranceId
                        ? `#${insuranceId}`
                        : t('components.familyManagement.infoDetailList.notAvailable'),
                    highlightValue: insuranceId !== undefined
                }
            ]}
        />
    );
};

// Helper function to avoid duplicate the implementation of this component
// as it's implemented in two different places inside the layout.
const FamilyAccountCardAction = ({
    onRemoveFamilyMember,
    t,
    isMobile,
    accountStatus,
    isLoading
}: FamilyAccountCardActionProps): ReactElement => {
    const buttonLabel =
        isMobile && accountStatus === 'declined'
            ? t('components.familyManagement.actions.remove')
            : t('components.familyManagement.actions.removeFamilyMember');
    return (
        <Button
            async
            label={buttonLabel}
            type="button"
            variant="text"
            className="family-account-card__action"
            onClick={onRemoveFamilyMember}
            disabled={isLoading}
            isBusy={isLoading}
        />
    );
};

const FamilyAccountResendInvitationCardAction = ({
    onResendInvitationFamilyMember,
    onCancelInvitationFamilyMember,
    t,
    accountStatus,
    isLoading,
    isCancelLoading
}: FamilyAccountCardActionProps): ReactElement => {
    return (
        <>
            {accountStatus !== 'declined' && (
                <Button
                    async
                    label={t('components.familyManagement.actions.cancel')}
                    type="button"
                    variant="text"
                    className="family-account-card__action"
                    onClick={onCancelInvitationFamilyMember}
                    isBusy={isCancelLoading}
                    disabled={isCancelLoading}
                />
            )}
            <Button
                async
                label={t('components.familyManagement.actions.resentInvitation')}
                type="button"
                className="family-account-card__action"
                onClick={onResendInvitationFamilyMember}
                isBusy={isLoading}
                disabled={isLoading}
            />
        </>
    );
};

// Helper function to choose the card icon depending on the combination of
// account status and account type.
const FamilyAccountCardIcon = ({ accountType, accountStatus }: FamilyAccountCardIconProps): ReactElement => {
    switch (true) {
        case accountStatus === 'invitePending':
            return <ClockCircleIcon />;
        case accountType === 'pet':
            return <PawIcon />;
        default:
            return <UserCircleIcon />;
    }
};

const variantBasedOnAccountStatus = (accountStatus: string) => {
    switch (accountStatus) {
        case 'declined': {
            return 'danger';
        }
        case 'expired': {
            return 'warning';
        }
        default: {
            return 'primary';
        }
    }
};
// Helper function to show or not a tooltip depending on the account status.
const FamilyAccountCardTooltip = ({ accountStatus, t }: FamilyAccountCardTooltipProps): ReactElement => {
    if (!accountStatus || (accountStatus !== 'fullAccess' && accountStatus !== 'partialAccess')) {
        return <></>;
    }

    const tooltipId = (Math.floor(Math.random() * 6) + 1).toString();
    const tooltipText = t(`components.familyManagement.tooltip.familyMember.${accountStatus}`);

    return (
        <div className="status-info">
            <TooltipContainer tip={tooltipText} id={tooltipId} placement="top-start">
                <div className="icon">
                    <CircleInfo />
                </div>
            </TooltipContainer>
        </div>
    );
};

// Helper function to display the correct text for the note.
const getNoteText = (
    accountStatus: FamilyAccountCardProps['accountStatus'],
    note: FamilyAccountCardProps['note'],
    t: any,
    dateRequested?: string,
    expiredDate?: string
): React.ReactNode => {
    if (accountStatus === 'invitePending') {
        note = t('components.familyManagement.familyMemberInfo.note.invitePending', {
            dateRequested: dateRequested,
            expiredDate: expiredDate
        });
        return (
            <p
                className="mb-0"
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(note ?? '')
                }}
            ></p>
        );
    }

    if (accountStatus === 'declined') {
        return t('components.familyManagement.familyMemberInfo.note.declined');
    }

    if (accountStatus === 'expired') {
        return t('components.familyManagement.familyMemberInfo.note.expired');
    }

    return t(note);
};

const FamilyAccountInfoData = ({
    accountStatus,
    dateRequested,
    dateGrantedAccess
}: FamilyAccountAdditionalInfoProps): ReactElement => {
    switch (accountStatus) {
        case 'partialAccess': {
            return (
                <span className="requested-or-granted-info">Granted on {formatDate(dateGrantedAccess as string)}</span>
            );
        }
        case 'expired': {
            return (
                <span className="requested-or-granted-info">Requested on {formatDate(dateRequested as string)}</span>
            );
        }
        default: {
            return <span className="requested-or-granted-info"></span>;
        }
    }
};

const parseFamilyAccountType = (accountType: FamilyMemberAccountType) =>
    accountType.toLocaleLowerCase() === 'child' ? 'Minor' : accountType;

// Main component.
const FamilyAccountCard = ({
    familyMemberName,
    familyMemberAge,
    dob,
    dateRequested,
    dateGrantedAccess,
    expiredDate,
    insuranceId,
    accountType,
    accountStatus,
    note,
    onRemoveFamilyMember,
    onResendInvitationFamilyMember,
    onCancelInvitationFamilyMember
}: FamilyAccountCardProps): ReactElement => {
    const { t } = useTranslation();
    const isRemoveMemberLoading = useSelector(removeMemberIsLoadingSelector);
    const isSendInvitationLoading = useSelector(sendInvitationIsLoadingSelector);

    return (
        <BorderedContainer className="family-account-card">
            <div className="family-account-card__mobile-content">
                <div className="family-account-card__mobile-content__family-member-info___personal-info">
                    <FamilyMember name={familyMemberName} age={familyMemberAge} />
                </div>
            </div>
            {(note ||
                accountStatus === 'invitePending' ||
                accountStatus === 'expired' ||
                accountStatus === 'declined') && (
                <div className="family-account-card__notes">
                    <ToastBox variant={variantBasedOnAccountStatus(accountStatus)}>
                        {getNoteText(accountStatus, note, t, dateRequested, expiredDate)}
                    </ToastBox>
                </div>
            )}
            <div className="family-account-card__body">
                <div className="family-account-card__icon-container">
                    <IconBackgroundColor variant={variantBasedOnAccountStatus(accountStatus)}>
                        <FamilyAccountCardIcon accountType={accountType} accountStatus={accountStatus} />
                    </IconBackgroundColor>
                </div>
                <div className="family-account-card__content">
                    <div className="family-account-card__family-member-info">
                        <div className="family-account-card__family-member-info__personal-info">
                            <FamilyMember name={familyMemberName} age={familyMemberAge} />
                        </div>
                        <div className="family-account-card__family-member-info__account-info">
                            <FamilyAccountCardAccountInfo dod={dob} insuranceId={insuranceId} t={t} />
                        </div>
                    </div>
                    <div className="family-account-card__account-info-and-actions">
                        <div className="family-account-card__account-info">
                            <div className="family-account-card__mobile-content">
                                <FamilyAccountInfoData
                                    accountStatus={accountStatus}
                                    dateRequested={dateRequested}
                                    dateGrantedAccess={dateGrantedAccess}
                                />
                            </div>
                            <div className="family-account-card__account-info__type">
                                <div className="label">{t('components.familyManagement.accountInfo.accountType')}</div>
                                <div className="value">{parseFamilyAccountType(accountType)}</div>
                            </div>
                            <div className="family-account-card__account-info__status">
                                <div className="label">
                                    {t('components.familyManagement.accountInfo.accountStatus')}
                                </div>
                                <div className="value">
                                    <FamilyManagementBadgeStatus accountStatus={accountStatus} />
                                    <FamilyAccountCardTooltip accountStatus={accountStatus} t={t} />
                                </div>
                            </div>
                        </div>
                        <div className="family-account-card__account-actions">
                            {onRemoveFamilyMember && (
                                <FamilyAccountCardAction
                                    onRemoveFamilyMember={onRemoveFamilyMember}
                                    t={t}
                                    isLoading={isRemoveMemberLoading}
                                />
                            )}
                            {onResendInvitationFamilyMember && (
                                <FamilyAccountResendInvitationCardAction
                                    onResendInvitationFamilyMember={onResendInvitationFamilyMember}
                                    onCancelInvitationFamilyMember={onCancelInvitationFamilyMember}
                                    t={t}
                                    accountStatus={accountStatus}
                                    isLoading={isSendInvitationLoading}
                                    isCancelLoading={isRemoveMemberLoading}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="family-account-card__mobile-content">
                <div className="family-account-card__mobile-content__account-info">
                    <FamilyAccountCardAccountInfo dod={dob} insuranceId={insuranceId} t={t} />
                </div>
                <div
                    className={classNames({
                        'family-account-card__mobile-content__actions': true,
                        'justify-content-center': onRemoveFamilyMember && !onResendInvitationFamilyMember
                    })}
                >
                    {onRemoveFamilyMember && (
                        <FamilyAccountCardAction
                            onRemoveFamilyMember={onRemoveFamilyMember}
                            t={t}
                            isMobile
                            accountStatus={accountStatus}
                            isLoading={isRemoveMemberLoading}
                        />
                    )}
                    {onResendInvitationFamilyMember && (
                        <FamilyAccountResendInvitationCardAction
                            onResendInvitationFamilyMember={onResendInvitationFamilyMember}
                            onCancelInvitationFamilyMember={onCancelInvitationFamilyMember}
                            t={t}
                            accountStatus={accountStatus}
                            isLoading={isSendInvitationLoading}
                        />
                    )}
                </div>
            </div>
        </BorderedContainer>
    );
};

export default FamilyAccountCard;
