import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';

// UI Kit
import BackgroundImage from 'ui-kit/background-image/background-image';
import PageSection from 'ui-kit/page-section/page-section';

// Components
import BirdiModal from 'components/birdi-modal/birdi-modal';
import CookieBanner from 'components/cookie-banner/cookie-banner.component';
import Footer from 'components/footer/footer.component';
import { PageLayoutProps } from 'components/layouts/page/page.props';
import ModalComponent from 'components/modal/modal.component';
import Navigation from 'components/navigation/navigation.component';
import SEO from 'components/seo/seo.component';

// Utils
import { InitOptimize } from 'util/google_optimize/optimize_helper';

// Hoc
import withBackgroundImage from 'hoc/withBackgroundImage';
import withBasicAuth from 'hoc/withBasicAuth';
import withMaintenanceMode from 'hoc/withMaintenanceMode';
import withSecurity from 'hoc/withSecurity';
import withSessionExpiration from 'hoc/withSessionExpiration';

// Providers
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import './page.layout.scss';

const MarketingPageLayout = ({
    children,
    headerImage,
    headerImageStyle,
    headerImageClassName,
    isSecurePage,
    isLoggedIn,
    showMainMenu = false,
    pageContentClassName,
    metaData,
    suppressApplicationPage,
    mailingAddress,
    physicalAddress,
    specialtyPhysicalAddress,
    specialtyMailingAddress
}: PageLayoutProps) => {
    const data = MarketingMenusQuery();
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);
    const pageClasses = classNames('page');
    const pageContentClasses = classNames('page-content', pageContentClassName);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation === node.path?.alias?.toString()
    );

    useEffect(() => {
        InitOptimize();
    }, []);

    return (
        <div className={pageClasses}>
            <SEO
                nodeTitle={metaData?.nodeTitle}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                applicationPageMetaData={applicationPageParagraphs[0]?.field_meta_tags}
                pageType={'marketing'}
            >
                {children}
            </SEO>
            <Navigation isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} showMainMenu={showMainMenu} />
            {headerImage && (
                <BackgroundImage image={headerImage} style={headerImageStyle} className={headerImageClassName} />
            )}
            <div className={pageContentClasses}>
                {children}
                {!suppressApplicationPage &&
                    applicationPageParagraphs[0]?.relationships?.field_application_page_content.map(
                        (paragraph: any, index: number) => (
                            <PageSection key={`application_page_section_${index}`}>
                                {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                            </PageSection>
                        )
                    )}
            </div>
            <BirdiModal />
            <ModalComponent />
            <Footer
                isShortFooter={true}
                copyrightYear={new Date().getFullYear().toString()}
                legalPaths={data.allMenuLinkContentFooterLegal.nodes}
                physicalAddress={
                    physicalAddress ? physicalAddress : data.allBlockContentAddresses.nodes[0]?.field_physical_address
                }
                mailingAddress={
                    mailingAddress ? mailingAddress : data.allBlockContentAddresses.nodes[0]?.field_mailing_address
                }
                specialtyPhysicalAddress={
                    specialtyPhysicalAddress
                        ? specialtyPhysicalAddress
                        : data.allBlockContentAddresses.nodes[0]?.field_specialty_physical_address
                }
                specialtyMailingAddress={
                    specialtyMailingAddress
                        ? specialtyMailingAddress
                        : data.allBlockContentAddresses.nodes[0]?.field_specialty_mailing_address
                }
            />
            <CookieBanner />
        </div>
    );
};

export default withMaintenanceMode(
    withBasicAuth(withSessionExpiration(withSecurity(withBackgroundImage(MarketingPageLayout, ''))))
);

export const MarketingMenusQuery = () => {
    const MenusAndApplicationPage = useStaticQuery<any>(graphql`
        {
            allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
                nodes {
                    field_physical_address
                    field_mailing_address
                    field_specialty_mailing_address
                    field_specialty_physical_address
                }
            }
            allNodeApplicationPage {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    relationships {
                        field_application_page_content {
                            ...supportedParagraphs
                        }
                    }
                    path {
                        langcode
                        alias
                    }
                    field_meta_tags {
                        title
                        description
                        abstract
                        keywords
                    }
                }
            }
        }
    `);
    return MenusAndApplicationPage;
};
