import { Meta, Story } from '@storybook/react';
import { Provider } from 'react-redux';

import ModalComponent, { ModalComponentRender } from 'components/modal/modal.component';

import createStore from 'state/store';

import CompleteVisitModal from './complete-visit-modal';

const store = createStore();

export default {
    title: 'Display Components/Visit History/Modals/Complete Visit',
    component: ModalComponentRender,
    decorators: [
        (Story) => (
            <Provider store={store}>
                <Story />
            </Provider>
        )
    ],
    parameters: {
        layout: 'centered'
    }
} as Meta;

const Template: Story = () => {
    return (
        <>
            <ModalComponent />
            <CompleteVisitModal />
        </>
    );
};

export const Default = Template.bind({});
