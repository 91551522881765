import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { AddressPayload } from 'types/account';
import { CreditCardPayload } from 'types/card';
import { CartPayload } from 'types/cart';
import { OrderBillShip } from 'types/order-prescription';

import { ApiStatus } from 'enums/api-status';

import { DEFAULT_SHIPPING_ID } from 'util/cart';

import {
    accountCreditCardsSelector,
    accountProfileAddressesSelector,
    accountProfileApiStatusSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector,
    accountStateSelector
} from '../account/account.selectors';
import {
    getAllEpostsCarts,
    getAllItemsTotal,
    getAllOrderTotal,
    getCurrentCartAddress,
    getCurrentCartCard,
    getMainCart
} from './cart.helpers';

export const CartSelector = (state: RootState) => state.cartReducer;

export const cartSelector = createSelector(CartSelector, (cart) => cart.cart);

export const cartItemsSelector = createSelector(CartSelector, (state) =>
    state.cart?.flatMap((cart) => cart.Order.refillRxs)
);

export const cartItemsTotalSelector = createSelector(cartSelector, (carts) => getAllItemsTotal(carts));

export const cartSubtotalSelector = createSelector(cartSelector, (carts) => getAllOrderTotal(carts));

export const isLoadingAddressShippingCartSelector = createSelector(
    CartSelector,
    (cart) => cart.isLoadingAddressShipping
);
export const isLoadingPaymentsCartSelector = createSelector(CartSelector, (cart) => cart.isLoadingPayments);
export const isLoadingCancelPrescriptionsCartSelector = createSelector(
    CartSelector,
    (cart) => cart.isLoadingCancelPrescriptions
);

export const cartOrderBillShipSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector, accountProfilIsCaregiverSelector],
    (addresses, carts) => {
        let selectedOrderBillShip = undefined;
        carts?.find((cart) => {
            addresses.map((address) => {
                if (
                    address.addressSeqNum === cart.Order.orderBillShip.patientBillAddressSeq ||
                    address.addressSeqNum === cart.Order.orderBillShip.dependentAddressSeqNum
                ) {
                    selectedOrderBillShip = cart.Order.orderBillShip;
                }
            });
        });
        return selectedOrderBillShip ? (selectedOrderBillShip as OrderBillShip) : undefined;
    }
);

export const cartOrderBillShipMethodSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector],
    (carts, patientEpostNum) => {
        const currentCart = getMainCart(carts, patientEpostNum);
        return (
            currentCart?.Order?.orderBillShip?.shipMethodId ||
            carts?.find((cart) => cart.Order.orderBillShip.shipMethodId !== null)?.Order?.orderBillShip?.shipMethodId ||
            DEFAULT_SHIPPING_ID
        );
    }
);

export const cartOrderBillShipPaymentSelector = createSelector(
    [cartOrderBillShipSelector, accountCreditCardsSelector],
    (orderBillShipSelector, creditCards) => {
        return (
            orderBillShipSelector?.paymentCardSeqNum ||
            creditCards?.find((card) => card.defaultCard)?.cardSeqNum ||
            creditCards?.[0]?.cardSeqNum
        );
    }
);

export const cartOrderHighPriority = createSelector([cartSelector], (carts) => {
    const cart = carts?.find((cart) => cart?.Order.orderBillShip.shipMethodId !== null);
    return cart?.Order.orderHeader.orderHighPriority;
});

export const cartIsBusySelector = createSelector(
    [CartSelector, accountProfileApiStatusSelector, accountStateSelector],
    (cart, accountProfileApiStatus, accountSelector) =>
        cart.isBusy ||
        cart.isLoadingAddressShipping ||
        cart.isLoadingPayments ||
        cart.isLoadingAddressShipping ||
        cart.isLoadingCancelPrescriptions ||
        accountProfileApiStatus === ApiStatus.LOADING ||
        accountSelector?.isBusy
);

export const cartApiStatusSelector = createSelector(CartSelector, ({ cartApiStatus }) => cartApiStatus);

export const cartOrderShippingAddressSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector],
    (addresses, carts) => {
        return getCurrentCartAddress(carts as CartPayload[], addresses as AddressPayload[]);
    }
);

export const cartOrderBillingAddressSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector],
    (addresses, carts) => {
        return getCurrentCartAddress(carts as CartPayload[], addresses as AddressPayload[]);
    }
);

export const cartOrderPaymentCardSelector = createSelector(
    [accountCreditCardsSelector, cartSelector],
    (cards, carts): CreditCardPayload | undefined => {
        const currentCartCard = getCurrentCartCard(carts as CartPayload[], cards as CreditCardPayload[]);
        return (currentCartCard as CreditCardPayload) || undefined;
    }
);

export const cartOrderHeaderSelector = createSelector([cartSelector], (carts) => {
    /**
     * Bugfix DRX-2275: As caregiver accounts uses the Birdi Order Number information,
     * and Complete Order for non-caregiver accounts don't have the EpostNum to be
     * possible to use the getMainCart function, we can just return the first position
     * for the cart object.
     */
    return carts && carts[0]?.Order.orderHeader;
});

export const cartOrderBirdiOrderNumberSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector, accountProfileSelector, accountProfilIsCaregiverSelector],
    (carts, EpostPatientNum, profileObject, isCaregiver) => {
        if (!isCaregiver) return [];

        const epostTargets = [
            ...profileObject!.dependents.map((dependent) => dependent.epostPatientNum),
            EpostPatientNum
        ];
        const dependentCarts = getAllEpostsCarts(carts, epostTargets);

        return dependentCarts && dependentCarts[0].BirdiOrderNumber;
    }
);

export const cartOrderPlacedSelector = createSelector(CartSelector, (cart) => cart.orderPlaced);

export const isCartWithoutPaymentMethodSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector],
    (carts, epostPatientNum) => {
        const currentCart = getMainCart(carts, epostPatientNum);

        const noPaymentInCurrentCart = currentCart?.Order.orderBillShip.paymentCardSeqNum === null;

        return noPaymentInCurrentCart;
    }
);

export const cartShippingZipCodeSelector = createSelector(
    cartOrderShippingAddressSelector,
    (address) => address?.zipcode
);
