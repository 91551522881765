import Icon from 'ui-kit-v2/icon/icon';

import BadgeStatus from 'ui-kit/bagde/badge-status';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';

import IconComposer from 'display-components/icon-composer';

import { ModalComponentContent } from 'components/modal/modal.component';

import './complete-visit-content.styles.scss';

// DRX-3967: Currently the copy in this component is purely mocks, it should be populated by BE in future integration
const CompleteVisitModalContent = () => {
    return (
        <ModalComponentContent>
            <div className="complete-visit-container">
                <div className="complete-visit-container__diagnosis">
                    <Icon icon="birdi-pill" height="40" width="26" viewBox="0 0 26 40" />
                    <div className="complete-visit-container__diagnosis__badge">
                        <BadgeStatus label="Complete" variant="smoke" />
                    </div>

                    <div className="complete-visit-container__diagnosis__patient-info">
                        <p>Patient:</p>
                        <span>Alexa Smith</span>
                        <span>Minor (17 years old)</span>
                    </div>

                    <div className="complete-visit-container__diagnosis__info">
                        <p>Diagnosis:</p>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempor, libero eu sagittis
                            bibendum, nunc dui.
                        </span>
                    </div>

                    <div className="complete-visit-container__diagnosis__details">
                        <p className="complete-visit-container__diagnosis__details__title">Visit Details:</p>
                        <div className="complete-visit-container__diagnosis__details__line">
                            <span>Booked:</span>
                            <span>Jan 1, 2000 - 13:00</span>
                        </div>
                        <div className="complete-visit-container__diagnosis__details__line">
                            <span>Completed:</span>
                            <span>Jan 1, 2000 - 13:15</span>
                        </div>
                        <div className="complete-visit-container__diagnosis__details__line">
                            <span>Visit with:</span>
                            <span>Dr. Elizabeth Taylor</span>
                        </div>
                        <div className="complete-visit-container__diagnosis__details__line">
                            <span>Cost:</span>
                            <span>$49.00</span>
                        </div>
                        <div className="complete-visit-container__diagnosis__details__line">
                            <span>Payment method:</span>
                            <div className="complete-visit-container__diagnosis__details__line__payment-info">
                                <CreditCardIcon variant="MASTERCARD" />
                                <span>Mastercard ····0000</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="complete-visit-container__treatment">
                    <p className="complete-visit-container__treatment__title">Prescriptions</p>

                    <div className="complete-visit-container__treatment__prescription-box">
                        {/* This is a "birdi prescription", so it will have the birdi icon indicator and the arrow (probably a CTA to somewhere) */}
                        <div className="complete-visit-container__treatment__prescription-box__item">
                            <IconComposer content={<Icon icon="drug" />} variant="content" subIconType="birdi-small" />
                            <div className="complete-visit-container__treatment__prescription-box__item__description">
                                <p>Sertraline (Generic For Zoloft) 30mg Tablet</p>
                                <p>Qty: 30</p>
                                <p>Sent to Birdi</p>
                            </div>
                            <div className="complete-visit-container__treatment__prescription-box__item__arrow">
                                <Icon icon="chevron-down" />
                            </div>
                        </div>

                        {/* This is a "non-birdi prescription", so no birdi icon and no CTA */}
                        <div className="complete-visit-container__treatment__prescription-box__item">
                            <IconComposer content={<Icon icon="drug" />} variant="content" />
                            <div className="complete-visit-container__treatment__prescription-box__item__description">
                                <p>Insulin Lispro Prot & Lispro (Generic For Humanlog Mix 75/25) 50mg Tablet</p>
                                <p>Qty: 30</p>
                                {/* this should be a variable, {pharmacyName} */}
                                <p>Sent to Pharmacy name</p>
                            </div>
                        </div>
                    </div>

                    <div className="complete-visit-container__treatment__instructions">
                        <p className="complete-visit-container__treatment__title">Treatment Plan(s)</p>
                        <p>For 7 (seven) days:</p>
                        <ul>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempor, libero eu
                                sagittis bibendum, nunc dui
                            </li>
                            <li>
                                Donec tempor, libero eu sagittis bibendum, nunc dui placerat purus, quis pulvinar ex
                                sapien a erat. Vestibulum quis pulvinar enim, sed tincidunt est.
                            </li>
                        </ul>
                        <p>For 3 (seven) days:</p>
                        <ul>
                            <li>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempor, libero eu
                                sagittis bibendum, nunc dui
                            </li>
                            <li>
                                Donec tempor, libero eu sagittis bibendum, nunc dui placerat purus, quis pulvinar ex
                                sapien a erat. Vestibulum quis pulvinar enim, sed tincidunt est.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ModalComponentContent>
    );
};

export default CompleteVisitModalContent;
