interface ExtendedScrollOptions extends ScrollOptions {
    offset?: number;
}

export const scrollToElement = (elementSelector: string | HTMLElement, options: ExtendedScrollOptions = {}) => {
    const { behavior = 'smooth', offset = 0 } = options;

    let element: HTMLElement | null;

    if (typeof elementSelector === 'string') {
        element = document.querySelector(elementSelector);
    } else {
        element = elementSelector;
    }

    if (!element) {
        return;
    }

    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset + offset - 200;

    window.scrollTo({
        top: elementPosition,
        behavior
    });
};
