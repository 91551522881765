import axios from 'axios';
import qs from 'qs';
import xml2js from 'xml2js';
import builder from 'xmlbuilder';

export interface USPSVerifyAddressPayload {
    Revision: number;
    Address: USPSAddress;
}
export interface USPSAddress {
    Address1?: string;
    Address2: string;
    City: string;
    State: string;
    Zip5: string;
    Zip4: string;
    ReturnText?: string;
}

export interface USPSAdressFormat {
    city: string;
    state: string;
    zip: string;
}
export interface USPSApiError {
    Description: Array<string>;
}
export interface USPSVerifiedAddress extends USPSAddress {
    Error: USPSApiError;
    DeliveryPoint?: number;
    CarrierRoute?: number;
    Footnotes?: string;
    DPVConfirmation?: string;
    DPVCMRA?: string;
    DPVFootnotes?: string;
    Business?: string;
    CentralDeliveryPoint?: string;
    Vacant?: boolean;
}
export interface USPSOptions {
    addressVerifyApiUrl: string;
    uspsUsername: string;
}
export interface AddressValidateResponse {
    updatedAddress?: USPSAddress;
    currentAddress?: USPSAddress;
    responseCode?: 'invalid' | 'valid' | 'suggested' | 'missing-information';
    responseField?: string;
    responseMessage?: string;
    error?: AddressValidateError;
}
export interface ZipValidateResponse {
    address?: USPSAdressFormat;
    zip?: string;
    responseCode?: 'invalid' | 'valid';
    responseField?: string;
    responseMessage?: string;
    error?: AddressValidateError;
}
export interface AddressValidateError {
    message: string;
}
export interface USPSAddressValidateResponse {
    AddressValidateResponse?: AddressValidateResponse;
    error: string | undefined;
}

export interface USPSZipValidateResponse {
    ZipValidateResponse?: ZipValidateResponse;
    error: string | undefined;
}
class USPS {
    public addressVerifyApiUrl: string;
    private username: string;
    constructor(props: USPSOptions) {
        const { addressVerifyApiUrl, uspsUsername } = props;

        this.addressVerifyApiUrl = addressVerifyApiUrl;
        this.username = uspsUsername;
    }
    public async verify(address: USPSAddress): Promise<USPSAddressValidateResponse> {
        try {
            const compareAddresses = (origAddress: USPSAddress, verifiedAddress: USPSAddress) => {
                const errorObject: AddressValidateResponse = {};
                if (origAddress.City.toUpperCase() !== verifiedAddress.City.toUpperCase()) {
                    errorObject.responseMessage = 'Invalid City.';
                    errorObject.responseField = 'city';
                }
                if (origAddress.State !== verifiedAddress.State) {
                    errorObject.responseMessage = 'Invalid State.';
                    errorObject.responseField = 'state';
                }
                if (origAddress.Zip5 !== verifiedAddress.Zip5) {
                    errorObject.responseMessage = 'Invalid Zip Code.';
                    errorObject.responseField = 'zipcode';
                }
                return errorObject;
            };

            const payload: USPSVerifyAddressPayload = {
                Revision: 1,
                Address: address
            };
            const xml = builder
                .create({
                    AddressValidateRequest: {
                        ...payload,
                        '@USERID': this.username
                    }
                })
                .end();
            const params = {
                API: 'Verify',
                XML: xml
            };

            const response = await axios.post(this.addressVerifyApiUrl, qs.stringify(params));

            //  parse the response
            const parser = new xml2js.Parser({ explicitArray: false });
            //return await parser.parseStringPromise(response.data);
            const xmlResponse = await parser.parseStringPromise(response.data);

            // Init validationResponse.
            let validationResponse: AddressValidateResponse;

            // Set Address var.
            const Address = xmlResponse.AddressValidateResponse.Address;

            // Set is valid boolean.
            const isValid = !Address.Error;
            if (
                Address.DPVConfirmation !== undefined &&
                (Address.DPVConfirmation === 'D' || Address.DPVConfirmation === 'N')
            ) {
                validationResponse = {
                    currentAddress: address,
                    responseCode: 'invalid',
                    responseField: Address.DPVConfirmation === 'N' ? 'Address1' : 'Address2',
                    responseMessage: Address.DPVConfirmation === 'N' ? 'Address Not Found.' : 'Address2 required.'
                };

                if (
                    Address.ReturnText ===
                    'Default address: The address you entered was found but more information is needed (such as an apartment, suite, or box number) to match to a specific address.'
                ) {
                    validationResponse = {
                        ...validationResponse,
                        responseCode: 'missing-information'
                    };
                }
            }
            // Actions if the address is returned as valid.
            else if (isValid) {
                const compareResults = compareAddresses(address, Address);
                if (compareResults.responseField) {
                    validationResponse = {
                        currentAddress: address,
                        responseCode: 'invalid',
                        responseField: compareResults.responseField,
                        responseMessage: compareResults.responseMessage
                    };
                } else {
                    // If the address validates (DPVConfirmation is equal to 'Y'), there may stil be some suggested
                    // edits to the address, so compare all fields.
                    // -- ALSO --
                    // The "updatedAddress" may have Address2 and Address1 combined,
                    // if the USPS isn't sure of the correctness of Address1. So if
                    // that's the ONLY difference, still consider it "valid", even though
                    // the API returned DPVConfirmation is equal to 'S'
                    if (
                        ((!Address.DPVConfirmation || Address.DPVConfirmation === 'Y') &&
                            ((!Address.Address1 && !address.Address1) ||
                                (Address.Address1 &&
                                    Address.Address1?.toLowerCase() === address.Address1?.toLocaleLowerCase())) &&
                            Address.Address2.toLowerCase() === address.Address2.toLocaleLowerCase() &&
                            Address.City.toLowerCase() === address.City.toLocaleLowerCase() &&
                            Address.State.toLowerCase() === address.State.toLocaleLowerCase() &&
                            Address.Zip5 === address.Zip5 &&
                            ((!Address.Zip4 && !address.Zip4) || (Address.Zip4 && Address.Zip4 === address.Zip4))) ||
                        (Address.DPVConfirmation === 'S' &&
                            Address.Address2.toLowerCase() ===
                                address.Address2.toLocaleLowerCase() + ' ' + address.Address1.toLocaleLowerCase())
                    ) {
                        validationResponse = {
                            currentAddress: address,
                            responseCode: 'valid',
                            responseMessage: 'The address is valid.'
                        };
                    } else {
                        validationResponse = {
                            updatedAddress: { ...Address },
                            currentAddress: address,
                            responseCode: 'suggested',
                            responseMessage: 'Did you mean?'
                        };
                    }
                }
            } else {
                validationResponse = {
                    currentAddress: address,
                    responseCode: 'invalid',
                    responseMessage: Address.Error.Description
                };
            }

            return {
                AddressValidateResponse: validationResponse,
                error: undefined
            };
        } catch (err) {
            return Promise.reject({
                error: err
            });
        }
    }

    public async verifyZip(zip: string): Promise<USPSZipValidateResponse> {
        try {
            const compareAddresses = (zip: string, verifiedAddress: USPSAddress) => {
                const errorObject: ZipValidateResponse = {};
                if (zip !== verifiedAddress.Zip5) {
                    errorObject.responseMessage = 'Invalid Zip Code.';
                    errorObject.responseField = 'zipcode';
                }
                return errorObject;
            };

            const payload = {
                Zip5: zip
            };
            const xml = builder
                .create({
                    CityStateLookupRequest: {
                        '@USERID': this.username,
                        ZipCode: {
                            ...payload,
                            '@ID': 0
                        }
                    }
                })
                .end();
            const params = {
                API: 'CityStateLookup',
                XML: xml
            };

            const response = await axios.post(this.addressVerifyApiUrl, qs.stringify(params));

            //  parse the response
            const parser = new xml2js.Parser({ explicitArray: false });
            //return await parser.parseStringPromise(response.data);
            const xmlResponse = await parser.parseStringPromise(response.data);

            // Init validationResponse.
            let validationResponse: ZipValidateResponse;

            // Set Address var.
            const Address = xmlResponse.CityStateLookupResponse.ZipCode;

            // Set is valid boolean.
            const isValid = !Address.Error;

            // Actions if the address is returned as valid.
            if (isValid) {
                const compareResults = compareAddresses(zip, Address);
                if (compareResults.responseField) {
                    validationResponse = {
                        zip: zip,
                        responseCode: 'invalid',
                        responseField: compareResults.responseField,
                        responseMessage: compareResults.responseMessage
                    };
                } else {
                    validationResponse = {
                        address: {
                            city: Address.City,
                            state: Address.State,
                            zip: Address.Zip5
                        },
                        zip: zip,
                        responseCode: 'valid',
                        responseMessage: 'The address is valid.'
                    };
                }
            } else {
                validationResponse = {
                    zip: zip,
                    responseCode: 'invalid',
                    responseMessage: Address.Error.Description
                };
            }

            return {
                ZipValidateResponse: validationResponse,
                error: undefined
            };
        } catch (err) {
            return Promise.reject({
                error: err
            });
        }
    }
}

export default USPS;
