import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { membershipRegistrationFormValuesSelector } from 'state/membership-registration/membership-registration.selectors';

import useCustomerIo from './useCustomerIo';

// DRX-4747: Add the new membership registration flow paths
// TODO: Remove the old paths once the new flow is fully implemented
const INITIAL_PAGE = '/get-started/membership';
const GOV_INSURANCE_PAGE = '/get-started/gov-insurance';
const PERSONAL_DETAILS_PAGE = '/get-started/personal-details';
const SELECT_PLAN_PAGE = '/get-started/select-plan';
const CONFIRM_EMAIL_PAGE = '/confirm-email?flow=birdi-care';

const useMembershipRegistrationInit = () => {
    const { resetCustomerIo } = useCustomerIo();
    const location = useLocation();

    const formValues = useSelector(membershipRegistrationFormValuesSelector);

    const isMembershipRegistrationPath = (path: string) =>
        [INITIAL_PAGE, GOV_INSURANCE_PAGE, PERSONAL_DETAILS_PAGE, SELECT_PLAN_PAGE].includes(path);

    // TODO: Remove this useEffect once the new membership registration flow is fully implemented
    // Add new useEffect to handle navigation outside membership paths
    useEffect(() => {
        const handleLocationChange = () => {
            const currentPath = window.location.pathname;
            if (!isMembershipRegistrationPath(currentPath)) {
                resetCustomerIo();
            }
        };

        // Initial check on mount
        handleLocationChange();
    }, [resetCustomerIo]);

    // TODO: Remove this useEffect once the new membership registration flow is fully implemented
    useEffect(() => {
        const handleBeforeUnload = () => {
            resetCustomerIo();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [resetCustomerIo, location]);

    const handleContinueNavigation = useCallback(() => {
        const pathsMap: Record<string, string> = {
            [INITIAL_PAGE]: GOV_INSURANCE_PAGE,
            [GOV_INSURANCE_PAGE]: PERSONAL_DETAILS_PAGE,
            [PERSONAL_DETAILS_PAGE]: SELECT_PLAN_PAGE,
            [SELECT_PLAN_PAGE]: CONFIRM_EMAIL_PAGE
        };

        const currentPath = location.pathname;
        const nextPath = pathsMap[currentPath];
        return navigate(nextPath);
    }, [location.pathname]);

    const onInit = useCallback(() => {
        // checks for Uninsured : Step 1 : value(s)
        if (formValues?.email && formValues?.zipcode) {
            return true;
        }

        return false;
    }, [formValues]);

    const onInitInsured = useCallback(() => {
        // checks for Insured
        if (formValues?.formPersonalInfo?.firstName && formValues?.insuranceForm?.insuranceId) {
            return true;
        }

        return false;
    }, [formValues]);

    return { onInit, onInitInsured, handleContinueNavigation };
};

export default useMembershipRegistrationInit;
