import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// ui-kit
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';

// Components
import { BirdiModalHeaderDanger, BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { deleteCreditCardAndSelectANewOneIfExistsAsDefaultRoutine } from 'state/account/account.routines';
// Reducers
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

// Types
import { HTMLInputChange } from 'types/globals';
import { PaymentCardProps } from 'types/payment';

import { noop } from 'util/function';
import { getPhoneNumber } from 'util/globalVariables';
import { getCreditCardEnding } from 'util/payments';
// Utils
import { ellipsify, lowercaseAndCapitalize } from 'util/string';

// Styles
import './payment-card.style.scss';

const PaymentCard: React.FC<PaymentCardProps> = (props) => {
    const {
        cardHolder,
        cardType,
        endingDigits,
        expiryMonth,
        expiryYear,
        card,
        isDefaultCard = false,
        isSelectCardRadioInputChecked = false,
        onSelectCardRadioInputChange = noop,
        onSetDefaultClick = noop,
        showLabel = true,
        showRemoveCardLink = false,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        isInnerWidthViewport,
        isBorderlessCard = false,
        isMembershipSettings = false,
        isDefaultMembershipCard = false,
        isBusy = false
    } = props;

    const { t } = useTranslation();
    const handleSelectCardRadioInputClick = React.useCallback<NonNullable<HTMLInputChange>>(
        (event) => {
            onSelectCardRadioInputChange(event);
            event.currentTarget.blur();
        },
        [onSelectCardRadioInputChange]
    );

    const formattedExpiryMonth = parseInt(expiryMonth) < 10 ? `0${expiryMonth}` : expiryMonth;

    const showActionLinkSection = showRemoveCardLink || showSetDefaultLink;
    const dispatch = useDispatch();

    const customClass = classNames('payment-card', {
        'with-border': !isBorderlessCard,
        'borderless-card': isBorderlessCard
    });

    const handleRemoveCardAndSetANewOneAsDefaultIfExists = useCallback(() => {
        dispatch(
            deleteCreditCardAndSelectANewOneIfExistsAsDefaultRoutine.trigger({
                cardSeqNum: card.cardSeqNum
            })
        );
    }, [dispatch, card]);

    const handleIsMembershipCardError = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'xl',
                isModalCentered: true,
                headerContent: (
                    <BirdiModalHeaderWithBackgroundColor
                        headerText={t('modals.removeDefaultMembershipPayment.title')}
                        type="danger"
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('modals.removeDefaultMembershipPayment.body')}
                        note={t('modals.removeDefaultMembershipPayment.note', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        label: t('modals.removeDefaultMembershipPayment.cta'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                className: 'remove-default-membership-card'
            })
        );
    }, [dispatch, t]);

    const handleRemoveCard = useCallback(() => {
        if (isDefaultMembershipCard) {
            handleIsMembershipCardError();
        } else {
            dispatch(
                openModal({
                    showClose: true,
                    type: 'danger',
                    size: 'lg',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.removePaymentModal.title')} icon="alert" />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('modals.removePaymentModal.body', {
                                paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                    cardNumber: getCreditCardEnding(card.secureCardNumber),
                                    cardType: card.cardType
                                })}</strong>`
                            })}
                        />
                    ),
                    ctas: [
                        {
                            onClick: () => {
                                handleRemoveCardAndSetANewOneAsDefaultIfExists();
                                dispatch(closeModal({}));
                            },
                            label: 'Continue',
                            variant: 'primary'
                        },
                        {
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            label: 'Cancel',
                            variant: 'ghost'
                        }
                    ]
                })
            );
        }
    }, [dispatch, isDefaultCard, handleIsMembershipCardError, handleRemoveCardAndSetANewOneAsDefaultIfExists, t, card]);

    return (
        <div className={customClass}>
            {showSelectCardRadioInput && (
                <input
                    className="payment-radio-input"
                    checked={isSelectCardRadioInputChecked}
                    onChange={handleSelectCardRadioInputClick}
                    data-input-type="payment-option"
                    name="payment-option"
                    type="radio"
                />
            )}
            <div className="payment-card__content">
                {/* Payment Type tag */}

                {showLabel && (
                    <p className="payment-card__content__type">
                        {t(`pages.profile.payment.${isDefaultCard ? 'primaryPayment' : 'secondaryPayment'}`, {
                            isMembership:
                                isMembershipSettings && isDefaultCard
                                    ? t('pages.profile.payment.membershipPayment')
                                    : ''
                        })}
                    </p>
                )}
                <div className="payment-card__content__info">
                    <div className="payment-card__content__info__sections">
                        <span>
                            {`${
                                isInnerWidthViewport?.current
                                    ? ellipsify(lowercaseAndCapitalize(cardType))
                                    : lowercaseAndCapitalize(cardType)
                            } ${getCreditCardEnding(endingDigits, '····')}`}
                        </span>
                        <div>{cardHolder}</div>
                        <div>
                            {t('pages.profile.payment.expiry', {
                                month: formattedExpiryMonth,
                                year: expiryYear
                            })}
                        </div>
                    </div>
                    <CreditCardIcon className="credit-card-icon" variant={cardType.toUpperCase()} />
                </div>
                <div className="payment-card__content__actions">
                    {isBusy ? (
                        <SpinnerInline />
                    ) : (
                        showActionLinkSection && (
                            <ul className="card-actions">
                                {showRemoveCardLink && (
                                    <li>
                                        <button style={{ all: 'unset' }} onClick={handleRemoveCard}>
                                            {t('pages.profile.payment.labels.remove')}
                                        </button>
                                    </li>
                                )}
                                {showSetDefaultLink && isDefaultCard && (
                                    <li className="default-card">
                                        <span>{t('pages.profile.payment.labels.defaultPayment')}</span>
                                    </li>
                                )}
                                {showSetDefaultLink && !isDefaultCard && (
                                    <li>
                                        <button style={{ all: 'unset' }} onClick={onSetDefaultClick}>
                                            {t('pages.profile.payment.labels.setAsDefault')}
                                        </button>
                                    </li>
                                )}
                            </ul>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentCard;
