import { graphql, navigate, PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CartQuickLinks from 'components/cart/cart-quick-links/cart-quick-links';
import Disclaimer from 'components/disclaimer/disclaimer.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { accountProfileSelector } from 'state/account/account.selectors';
import { addTransferPrescriptionSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { familyProfileDependentsSelector } from 'state/family-profile/family-profile.selectors';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineCabinetActiveTabSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

import { defineNameFromEpostPatientNum } from 'util/depentent';
import { formatPhoneNumber } from 'util/string';

import './index.style.scss';

type PrescriptionConfirmationProps = PageProps<GatsbyTypes.PrescriptionConfirmationDataQuery>;

const PrescriptionConfirmation: React.FC<PrescriptionConfirmationProps> = (props) => {
    const { t } = useTranslation();

    const { data } = props;
    const { blueSkyBackground } = data;

    const transferPrescriptionState = useSelector(addTransferPrescriptionSelector);
    const familyMembersData = useSelector(familyProfileDependentsSelector);
    const profileObject = useSelector(accountProfileSelector);
    const activeDependentTab = useSelector(medicineCabinetActiveTabSelector);

    const isCaregiver = profileObject?.isCaregiver;

    const {
        DrugName: drugName,
        Qty: quantity,
        Strength: strength,
        DrugForm: drugForm,
        PharmacyName: pharmacyName,
        PharmacyPhone: pharmacyPhone,
        RxNumber: rxNumber,
        PackageDisplay: packageDisplay,
        DependentsEpostPatientNum: dependentsEpostPatientNum
    } = transferPrescriptionState;

    const hasPackageDisplay = !!packageDisplay;
    const hasRxNumber = !!rxNumber;

    const dispatch = useDispatch();

    // if no data, navigate back to main prescription screen
    useEffect(() => {
        if (!drugName) {
            navigate('/secure/prescription');
        }
    }, []);

    useEffect(
        () => () => {
            dispatch(
                medicineCabinetGetAllPrescriptions.trigger({
                    epostNumFamilyMember: activeDependentTab
                })
            );
        },
        [activeDependentTab, dispatch]
    );

    return (
        <WorkflowLayout
            backgroundImage={blueSkyBackground}
            className="prescription-request-confirmation"
            metaData={{ nodeTitle: t('pages.prescriptionConfirmation.title') }}
            useRoundedCorners={false}
        >
            <WorkflowLayoutFormWrapper
                className="prescription-request-confirmation-layout-form-wrapper"
                eyebrowText={t('pages.prescriptionConfirmation.eyebrowText')}
                title={t('pages.prescriptionConfirmation.headlineText')}
            >
                {/* Prescription Information */}
                <div className="prescription-request-confirmation__section">
                    <div className="prescription-request-confirmation__section-title">
                        <div className="form-instructions">
                            {t('pages.prescriptionConfirmation.sectionHeaders.prescriptionInformation')}
                        </div>
                        <div className="prescription-request-confirmation__section-title__divider"></div>
                    </div>
                    <div className="prescription-request-confirmation__section__content">
                        {isCaregiver && (
                            <span className="prescription-request-confirmation__caregiver__name">
                                {defineNameFromEpostPatientNum(
                                    profileObject,
                                    familyMembersData,
                                    dependentsEpostPatientNum as string
                                )}
                            </span>
                        )}
                        <div className="h3 prescription-request-confirmation__section__content__heading text-uppercase">
                            {drugName}
                        </div>
                        <p className="prescription-request-confirmation__section__content__subheading">
                            {t('prescriptionLayout.finalStep.qty', { Qty: quantity })}
                            {hasPackageDisplay && (
                                <>
                                    <span className="prescription-request-confirmation__punctuation"></span>
                                    {packageDisplay}
                                </>
                            )}
                            <span className="prescription-request-confirmation__punctuation"></span>
                            {strength}
                            <span className="prescription-request-confirmation__punctuation"></span>
                            {drugForm}
                            {hasRxNumber && (
                                <>
                                    <span className="prescription-request-confirmation__punctuation"></span>
                                    {rxNumber}
                                </>
                            )}
                        </p>
                    </div>
                </div>

                {/* Pharmacy Information */}
                <div className="prescription-request-confirmation__section">
                    <div className="prescription-request-confirmation__section-title">
                        <div className="form-instructions">
                            {t('pages.prescriptionConfirmation.sectionHeaders.pharmacyInformation')}
                        </div>
                        <div className="prescription-request-confirmation__section-title__divider"></div>
                    </div>
                    <div className="prescription-request-confirmation__section__content">
                        <div className="h3 prescription-request-confirmation__section__content__heading">
                            {pharmacyName}
                        </div>
                        <p className="prescription-request-confirmation__section__content__pharmacy-phone-number">
                            {t('pages.prescriptionConfirmation.pharmacyTelephoneNumber', {
                                phoneNumber: formatPhoneNumber(pharmacyPhone, 'parentheses')
                            })}
                        </p>
                    </div>
                </div>

                {/* Disclaimer */}
                <div className="prescription-request-confirmation__section">
                    <Disclaimer disclaimerText={t('pages.prescriptionConfirmation.orderNotification')} />
                </div>

                {/* Quick Links */}
                <div className="prescription-request-confirmation__section">
                    <div className="prescription-request-confirmation__section-title">
                        <div className="form-instructions">
                            {t('pages.prescriptionConfirmation.sectionHeaders.quickLinks')}
                        </div>
                        <div className="prescription-request-confirmation__section-title__divider"></div>
                    </div>
                    <div className="prescription-request-confirmation__quick-links">
                        <CartQuickLinks />
                    </div>
                </div>
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default PrescriptionConfirmation;

//
// --- GraphQL ---

export const query = graphql`
    query PrescriptionConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
                field_specialty_mailing_address
                field_specialty_physical_address
            }
        }
    }
`;
