import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMedicineCabinetShouldRefresh } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import { medicineCabinetLoadRoutine } from 'state/medicine-cabinet/medicine-cabinet.routines';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetShouldRefreshSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';

const useMedicineCabinetPrescriptions = () => {
    const dispatch = useDispatch();
    const activeTab = useSelector(medicineCabinetActiveTabSelector);
    const shouldRefreshMedCabinet = useSelector(medicineCabinetShouldRefreshSelector);

    useEffect(() => {
        if (shouldRefreshMedCabinet) {
            dispatch(
                medicineCabinetLoadRoutine.trigger({
                    fetchRxSubStatus: true,
                    selectedDependent: activeTab
                })
            );
            dispatch(setMedicineCabinetShouldRefresh(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRefreshMedCabinet]);
};

export default useMedicineCabinetPrescriptions;
