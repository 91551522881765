import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components & UI
import { AddressCardProps } from 'components/shipping-addresses-v2/address-card/address-card.props';
import ColumnSection from 'components/sidebar-column/column-section/column-section.component';

// Auto Refill
import { autoRefillAdressesSelector } from 'state/auto-refill/auto-refill.selectors';

import './default-address.style.scss';

const DefaultAddress: React.FC = () => {
    const autoRefillAdresses = useSelector(autoRefillAdressesSelector);

    const { t } = useTranslation();

    const getDefaultAddress = (addresses: AddressCardProps[]): AddressCardProps | null => {
        if (!Array.isArray(addresses) || !addresses.length) {
            return null;
        }
        return addresses.find((address) => address.defaultAddress) || null;
    };

    const hasProfileAddresses = Array.isArray(autoRefillAdresses) && autoRefillAdresses.length > 0;
    if (!hasProfileAddresses) {
        return null;
    }

    const addresses: AddressCardProps[] = autoRefillAdresses.map((address) => {
        return {
            addressSeqNum: address.addressSeqNum,
            defaultAddress: address.defaultShip,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            country: address.country,
            state: address.state,
            zipcode: address.zipcode,
            zipcodeFour: address.zipcodeFour,
            defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
            addressType: address.addressTypeDesc,
            isProfile: false
        };
    });

    const { address1, address2, city, state, zipcode, zipcodeFour } = getDefaultAddress(addresses) ?? {};

    return (
        <ColumnSection
            bodyClassName="shipping-address__content"
            className="shipping-address"
            headerClassName="shipping-address__header"
            title="Shipping Address"
        >
            {autoRefillAdresses.length === 0 ? (
                <Container fluid className="my-4">
                    <p>{t('pages.profile.shippingAddress.empty')}</p>
                </Container>
            ) : (
                <>
                    <div>
                        {t('pages.autoRefill.prescriptions.shippingInformation.sentenceOne')}
                        <a href="/sign-in" className="shipping-address__link">
                            {t('pages.autoRefill.prescriptions.shippingInformation.signIn')}
                        </a>
                        {t('pages.autoRefill.prescriptions.shippingInformation.sentenceTwo')}
                    </div>
                    <div className="default-shipping-address-type">
                        {t('pages.autoRefill.prescriptions.shippingInformation.defaultShippingAddress')}
                    </div>
                    <address>
                        <p>
                            {address1} {address2}
                        </p>
                        <p>
                            {city}, {state} {zipcodeFour ? `${zipcode}-${zipcodeFour}` : zipcode}
                        </p>
                    </address>
                </>
            )}
        </ColumnSection>
    );
};
export default DefaultAddress;
