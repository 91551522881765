import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import CheckboxComponent from 'ui-kit-v2/checkbox/checkbox';
import Icon from 'ui-kit-v2/icon/icon';

import { ModalComponentContent } from 'components/modal/modal.component';

import { accountIsLoadingAutoRefillStatus } from 'state/account/account.selectors';

import { getPhoneNumber } from 'util/globalVariables';

import './auto-refill-modal.styles.scss';

interface AutoRefillModalContentProps {
    onClose?: () => void;
    onContinue?: () => void;
}

const AutoRefillModalContent = ({ onClose, onContinue }: AutoRefillModalContentProps) => {
    const { t } = useTranslation();

    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false);
    const isLoadingAutoRefill = useSelector(accountIsLoadingAutoRefillStatus);

    const ref = useRef<HTMLDivElement>(null);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    // DRX-4428: Imperatively clicks the content to trigger modal floating header behavior
    useEffect(() => {
        if (ref.current) {
            ref.current.click();
        }
    }, [ref]);

    return (
        <ModalComponentContent>
            <div className="auto-refill-modal__container" ref={ref}>
                <section>
                    <p className="auto-refill-modal__description">{t('pages.profile.autoRefill.description')}</p>
                    <ButtonComponent
                        label={t('pages.profile.autoRefill.learnMore.label')}
                        type="button"
                        iconRight={<Icon icon={!showMore ? 'chevron-down' : 'chevron-up'} />}
                        variant="link"
                        onClick={handleShowMore}
                        className="learn-more-dropdown"
                    />
                </section>

                {showMore && (
                    <section className="auto-refill-modal__learn-more">
                        <p className="auto-refill-modal__title">
                            {t('pages.profile.autoRefill.learnMore.description')}
                        </p>

                        <ul className="auto-refill-modal__list--dotted">
                            <li>{t('pages.profile.autoRefill.learnMore.pointOne')}</li>
                            <li>{t('pages.profile.autoRefill.learnMore.pointTwo')}</li>
                            <li>{t('pages.profile.autoRefill.learnMore.pointThree')}</li>
                            <li>{t('pages.profile.autoRefill.learnMore.pointFour')}</li>
                            <li>{t('pages.profile.autoRefill.learnMore.pointFive')}</li>
                        </ul>
                        <p className="auto-refill-modal__title">{t('pages.profile.autoRefill.learnMore.pleaseNote')}</p>
                        <ul className="auto-refill-modal__list--dotted">
                            <li>{t('pages.profile.autoRefill.learnMore.note')}</li>
                        </ul>
                    </section>
                )}

                <div className="divider"></div>

                <section>
                    <p className="auto-refill-modal__title">{t('pages.profile.autoRefill.autoRefillTC.title')}:</p>
                    <ol className="auto-refill-modal__list--decimal">
                        <li>{t('pages.profile.autoRefill.autoRefillTC.termsOne')}</li>
                        <li>{t('pages.profile.autoRefill.autoRefillTC.termsTwo')}</li>
                        <li>{t('pages.profile.autoRefill.autoRefillTC.termsThree')}</li>
                        <li>{t('pages.profile.autoRefill.autoRefillTC.termsFour')}</li>
                        <li>{t('pages.profile.autoRefill.autoRefillTC.termsFive')}</li>
                        <li>
                            <Trans
                                i18nKey="pages.profile.autoRefill.autoRefillTC.termsSix"
                                values={{ phoneNumber: getPhoneNumber({ isEnd: true }) }}
                            />
                        </li>
                    </ol>
                </section>

                <section className="auto-refill-modal__info-box">
                    <div>
                        <Icon icon="information-circle" />
                    </div>
                    <p>{t('pages.profile.autoRefill.alert')}</p>
                </section>

                <section>
                    <CheckboxComponent
                        label={`${t('pages.profile.autoRefill.autoRefillTC.checkboxLabel')}.`}
                        id="manageAutoRefill"
                        name="manageAutoRefill"
                        onChange={(event) => setAcceptTerms(event.target.checked)}
                        className="checkbox-auto-refill"
                    />
                </section>
            </div>

            <section className="auto-refill-modal__action-container">
                <ButtonComponent
                    label={t('pages.profile.autoRefill.button.offAlt')}
                    type="button"
                    disabled={isLoadingAutoRefill}
                    onClick={onClose}
                />
                <ButtonComponent
                    label={t('pages.profile.autoRefill.button.on')}
                    type="button"
                    variant="solid"
                    disabled={!acceptTerms || isLoadingAutoRefill}
                    isLoading={isLoadingAutoRefill}
                    onClick={onContinue}
                />
            </section>
        </ModalComponentContent>
    );
};

export default AutoRefillModalContent;
