import classNames from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';
import { Form as ReactForm } from 'react-bootstrap';

import { RadioButtonProps } from './radio-button.props';
import './radio-button.styles.scss';

const RadioButtonComponent = (props: RadioButtonProps) => {
    const { name, value, label, isSelected, disabled, hasError, variation, onChange } = props;

    const mainClass = classNames('radio-button-component', {
        'radio-button--error': hasError,
        'radio-button--disabled': disabled,
        'radio-button--boxed': variation === 'boxed',
        'radio-button--in-card': variation === 'in-card'
    });

    const containerClass = classNames('radio-button-component--container', { highlight: props.isSelected });

    const handleLabelClick = useCallback(() => {
        const radioInput = document.getElementById(`${name}-${value}`) as HTMLInputElement;
        if (radioInput && !disabled) {
            radioInput.click();
        }
    }, [name, value, disabled]);

    const handleLabelKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if ((event.key === 'Enter' || event.key === ' ') && !disabled) {
                event.preventDefault();
                handleLabelClick();
            }
        },
        [handleLabelClick, disabled]
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
    };

    return (
        <div className={mainClass}>
            <div
                className={containerClass}
                onClick={handleLabelClick}
                onKeyDown={handleLabelKeyPress}
                role="radio"
                aria-checked={isSelected}
                aria-disabled={disabled}
                tabIndex={disabled ? -1 : 0}
            >
                <ReactForm.Check
                    type="radio"
                    {...props}
                    className="radio-button-component--input"
                    id={`${name}-${value}`}
                    onChange={handleChange}
                    checked={isSelected}
                    isInvalid={hasError}
                    aria-invalid={hasError}
                    aria-label={(label as string) || 'Radio button'}
                />
            </div>
        </div>
    );
};

export default RadioButtonComponent;
