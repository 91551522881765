// General
import { graphql, navigate } from 'gatsby';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Ui - Kit
import PageSection from 'ui-kit/page-section/page-section';

// Components
import PageLayout from 'components/layouts/page/page.layout';

// State
import { accountIsLoggedInSelector, accountProfileSelector } from 'state/account/account.selectors';

import { useOnboardingRedirection } from 'hooks/useOnboardingRedirection';

// Utils
import './auth.style.scss';

const AuthPage = () => {
    const { t } = useTranslation();
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const profileObject = useSelector(accountProfileSelector);

    const { isOnboardingCompleted } = useOnboardingRedirection(false);

    useEffect(() => {
        if (isLoggedIn && isOnboardingCompleted === undefined) return;

        // IF user has not complete your registration
        if (!isOnboardingCompleted) {
            // ensure that other types of users are not redirected to onboarding
            if (profileObject && profileObject?.hasMembership === false) {
                navigate('/secure/medicine-cabinet');
                return;
            }

            navigate('/secure/onboarding');
            return;
        }

        if (isLoggedIn && profileObject !== undefined) {
            // IF is already completed
            if (profileObject?.hasMembership === true) {
                navigate('/secure/membership');
                return;
            }
            navigate('/secure/medicine-cabinet');
            return;
        }
    }, [profileObject, isLoggedIn, isOnboardingCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PageLayout metaData={{ nodeTitle: t('Loading profile...') }}>
            <PageSection className="text-center loading-profile">
                <div className="loading-profile__container">
                    <h4 className="loading-profile__text">{t('Loading profile...')}</h4>
                </div>
            </PageSection>
        </PageLayout>
    );
};

export default AuthPage;

export const query = graphql`
    query AuthPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
