import { useTranslation } from 'gatsby-plugin-react-i18next';
import { noop } from 'lodash';
import { FC, ReactElement, useMemo } from 'react';
// Components & UI
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';

import AddAddressForm, {
    AddressFormSchema,
    AddressVerificationFailureModalContent
} from 'components/add-address-form/AddAddressForm';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import AddressCard from 'components/shipping-addresses-v2/address-card/address-card.component';
// Interfaces
import { AddressCardProps } from 'components/shipping-addresses-v2/address-card/address-card.props';
import { ShippingAddressesProps } from 'components/shipping-addresses-v2/shipping-addresses.props';

import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// Shipping Address & Services
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    easyRefillAddAddressesRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillSetPrimaryAddressRoutine,
    easyRefillUpdateAddressesRoutine
} from 'state/easy-refill/easy-refill.routines';
// EasyRefill
import {
    easyRefillAddressesSelector,
    easyRefillEpostPatientNumSelector
} from 'state/easy-refill/easy-refill.selectors';
import { AddressParts } from 'state/usps/usps.reducers';

import { AddressPayload } from 'types/account';

// Hooks
import { useAddressVerification } from 'hooks/useAddressVerification';

import './shipping-addresses.style.scss';

const ShippingAddresses: FC<ShippingAddressesProps> = ({
    addressData,
    onSetAsShipping,
    isProfile,
    showLabels = true,
    addNewAddressButtonLabel
}: ShippingAddressesProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Selectors
    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();
    const epostPatientNum = useSelector(easyRefillEpostPatientNumSelector);
    const easyRefillAddresses = useSelector(easyRefillAddressesSelector);

    const sortDefaultAddresses = useMemo(() => {
        // Add defaultAddress boolean to first address automatically to be addressed in DRX-115
        const addressesToSort = [...addressData];

        return addressesToSort.sort((a, b) => Number(b.defaultAddress) - Number(a.defaultAddress));
    }, [addressData]);

    const handleAddAddressFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal cart-address-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.addAddressModal.title')}
                                handleFormCancel={handleAddAddressFormCancel}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                                showSetAsDefault={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleAddAddressFormSubmit = (values: AddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                dispatch(
                    easyRefillAddAddressesRoutine.trigger({
                        ...values,
                        epostPatientNum: epostPatientNum,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onSuccess: () => {
                            dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'UpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: ({ error }) => {
                if (values.onFailure && error && !error.message) {
                    values.onFailure(error);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('modals.addressVerificationFailure.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        handleAddNewAddressClick();
                                    },
                                    dataGALocation: 'AddressNotVerified'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    const handleShippingAddressChange = (address: AddressCardProps) => {
        const setAsDefaultAddress = easyRefillAddresses.find(
            (easyRefillAddress) => easyRefillAddress.addressSeqNum === address.addressSeqNum
        );

        const newDefaultAddress = {
            ...setAsDefaultAddress,
            defaultShip: true,
            currentShipping: true,
            defaultBill: true
        };

        dispatch(
            easyRefillSetPrimaryAddressRoutine({
                address: newDefaultAddress,
                onSuccess: () => {
                    dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: isProfile ? (
                                <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                            ) : (
                                <BirdiModalContent
                                    icon={'success'}
                                    title={t('modals.updateProfile.title')}
                                    body={t('modals.updateCart.defaultAddressUpdated')}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: t('modals.updateProfile.title')
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            size: 'lg',
                            type: 'danger',
                            headerContent: (
                                <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                            ),
                            bodyContent: (
                                <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                                    },
                                    dataGALocation: 'UpdateProfileError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleEditAddressClick = (address: AddressCardProps) => {
        const editAddress: AddressFormSchema =
            address.zipcodeFour === ''
                ? { ...address, addressTypeDesc: address.addressType }
                : {
                      ...address,
                      zipcode: `${address.zipcode}-${address.zipcodeFour}`,
                      addressTypeDesc: address.addressType
                  };

        const editAddressIndex = easyRefillAddresses.findIndex(
            (easyRefillAddress) => easyRefillAddress.addressSeqNum === address.addressSeqNum
        );

        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal cart-address-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.editAddressModal.title')}
                                defaultValues={{ ...editAddress, addressSeqNum: address?.addressSeqNum }}
                                handleFormCancel={() => noop}
                                handleFormSubmit={(values) => {
                                    handleEditAddressSubmit(values, editAddressIndex);
                                }}
                                centerFormSubmit={true}
                                showCancel={false}
                                isAddressVerifying={isAddAddressBusy}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleEditAddressSubmit = (values: AddressPayload, editAddressIndex: number) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };

        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                const updateAddress = { ...values, zipcode: Zip5, zipcodeFour: Zip4, epostPatientNum: epostPatientNum };
                dispatch(
                    easyRefillUpdateAddressesRoutine({
                        ...updateAddress,
                        index: editAddressIndex,
                        onSuccess: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                                dispatch(easyRefillGetPatientAddressesRoutine.trigger());
                                            }
                                        }
                                    ]
                                })
                            );
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    size: 'lg',
                                    type: 'danger',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            headerText={t('modals.updateProfile.error')}
                                            icon="alert"
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            },
            onFailure: ({ error }) => {
                if (values.onFailure && error && !error.message) {
                    values.onFailure(error);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    return (
        <>
            {sortDefaultAddresses.length === 0 && (
                <Container fluid className="my-4">
                    <p>{t('pages.profile.shippingAddress.empty')}</p>
                </Container>
            )}
            {sortDefaultAddresses.length > 0 && (
                <div className="d-flex flex-column">
                    {sortDefaultAddresses.map((value, index) => {
                        return (
                            <AddressCard
                                key={index}
                                index={index}
                                defaultAddress={value.defaultAddress}
                                address1={value.address1}
                                address2={value.address2}
                                city={value.city}
                                state={value.state}
                                zipcode={value.zipcode}
                                zipcodeFour={value.zipcodeFour}
                                isChecked={value.isChecked}
                                openEdit={() => handleEditAddressClick(value)}
                                addressType={value.addressType}
                                showLabel={showLabels}
                                onSetAsDefault={() => handleShippingAddressChange(value)}
                                handleSetAsShipping={onSetAsShipping ? () => onSetAsShipping(value) : undefined}
                            />
                        );
                    })}
                </div>
            )}
            {addNewAddressButtonLabel && (
                <div className="add-address-btn-container">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="sm-full text-uppercase px-0"
                        label={addNewAddressButtonLabel}
                        type="button"
                        variant="text-blue"
                        onClick={handleAddNewAddressClick}
                        dataGAFormName="addresses"
                    />
                </div>
            )}
        </>
    );
};

export default ShippingAddresses;
