/* eslint-disable no-console */
import { CUSTOMER_IO_DEBUG, CUSTOMER_IO_PLANS, CUSTOMER_IO_SITE_ID, ENABLE_CUSTOMER_IO } from 'gatsby-env-variables';
import { useCallback, useMemo } from 'react';

import { BIRDI_PLANS } from 'enums/plans';

type CustomerIoIdentification = {
    id: string;
    created_at: number;
    first_name?: string;
    last_name?: string;
    /**
     * Relate people to objects
     * More info: https://docs.customer.io/sdk/javascript/identify/#relate-people-to-objects
     */
    cio_relationships?: CustomerIoRelationships;
};

type CustomerIoRelationshipActions = 'add_relationships' | 'remove_relationships';

type CustomerIoRelationships = {
    action: CustomerIoRelationshipActions;
    relationships: CustomerIoRelationshipsIdentifiers[];
};

type CustomerIoRelationshipsIdentifiers = {
    [key: string]: unknown;
};

type CustomerIoTrackEvent = {
    label: string;
    value: object;
    plan: BIRDI_PLANS | string;
};

type CustomerIoTrackPage = {
    path: string;
    plan: BIRDI_PLANS | string;
};

type CustomerIoIdentify = {
    email: string;
    plan: BIRDI_PLANS | string;
    firstName?: string;
    lastName?: string;
    /**
     * Relate people to objects
     * More info: https://docs.customer.io/sdk/javascript/identify/#relate-people-to-objects
     */
    cio_relationships?: CustomerIoRelationships;
};

type CustomerIoLog = {
    [key: string]: unknown;
};

const useCustomerIo = () => {
    const allowedPlans: string[] = useMemo(() => {
        // Regex to split a string by commas
        const division = /,\s?/;

        if (!!ENABLE_CUSTOMER_IO && !!CUSTOMER_IO_SITE_ID && CUSTOMER_IO_PLANS?.length > 0)
            // Split the value from CUSTOMER_IO_PLANS to get an array of valid plans
            return CUSTOMER_IO_PLANS ? CUSTOMER_IO_PLANS.split(division) : [];

        return [];
    }, []);

    const shouldTrackCustomerIo = useCallback(
        (plan: BIRDI_PLANS | string): boolean => {
            const shouldTrackEvents = allowedPlans.includes(plan);
            return shouldTrackEvents && typeof _cio !== 'undefined';
        },
        [allowedPlans]
    );

    const debugCustomerIo = useCallback((label: string, logs: CustomerIoLog[]) => {
        if (CUSTOMER_IO_DEBUG) {
            console.group(label);
            logs.forEach((log) => {
                const key = Object.keys(log).pop();
                if (key) {
                    console.log(key, log[key]);
                }
            });
            console.groupEnd();
        }
    }, []);

    const trackEventCustomerIo = useCallback(
        (data: CustomerIoTrackEvent) => {
            // Validates if the plan should track customer.io events
            const shouldTrack = shouldTrackCustomerIo(data.plan);
            // Create a list of logs that will be logged if debug mode is on
            const logs: CustomerIoLog[] = [];
            logs.push({ plan: data.plan });
            logs.push({ allowedPlans: allowedPlans });
            logs.push({ shouldTrack: shouldTrack });

            if (shouldTrack) {
                // If should track, add data to the logs
                logs.push({ label: data.label });
                logs.push({ value: data.value });

                // Track customer.io event
                _cio.track(data.label, data.value);

                // Once finished, proceed to log the information if debug mode is on
                debugCustomerIo('Tracking an event in customer.io', logs);
            }
        },
        [shouldTrackCustomerIo, debugCustomerIo, allowedPlans]
    );

    const trackPageCustomerIo = useCallback(
        (data: CustomerIoTrackPage) => {
            // Validates if the plan should track customer.io events
            const shouldTrack = shouldTrackCustomerIo(data.plan);
            // Create a list of logs that will be logged if debug mode is on
            const logs: CustomerIoLog[] = [];
            logs.push({ plan: data.plan });
            logs.push({ allowedPlans: allowedPlans });
            logs.push({ shouldTrack: shouldTrack });

            if (shouldTrack) {
                // If should track, add data to the logs
                logs.push({ path: data.path });

                // Track customer.io event
                _cio.page(data.path);

                // Once finished, proceed to log the information if debug mode is on
                debugCustomerIo('Tracking a page in customer.io', logs);
            }
        },
        [shouldTrackCustomerIo, debugCustomerIo, allowedPlans]
    );

    const identifyCustomerIo = useCallback(
        (data: CustomerIoIdentify) => {
            // Validates if the plan should track customer.io events
            const shouldTrack = shouldTrackCustomerIo(data.plan);
            // Create a list of logs that will be logged if debug mode is on
            const logs: CustomerIoLog[] = [];
            logs.push({ plan: data.plan });
            logs.push({ allowedPlans: allowedPlans });
            logs.push({ shouldTrack: shouldTrack });

            if (shouldTrack) {
                // Setup the data object that will be sent to customer.io
                const created_at = Math.floor(Date.now() / 1000);
                const trackData: CustomerIoIdentification = {
                    id: data.email,
                    // Strongly recommended when you first identify someone
                    created_at
                };

                if (data.firstName) {
                    trackData.first_name = data.firstName;
                }
                if (data.lastName) {
                    trackData.last_name = data.lastName;
                }

                // If marketing team decide to create custom object to relate to the current user.
                if (data.cio_relationships) {
                    trackData.cio_relationships = data.cio_relationships;
                }

                // Logging the whole object that is being sent to customer.io
                logs.push({ data: trackData });

                // Identify person in customer.io
                _cio.identify(trackData);

                // Once finished, proceed to log the information if debug mode is on
                debugCustomerIo('Identifying user in customer.io', logs);
            }
        },
        [shouldTrackCustomerIo, debugCustomerIo, allowedPlans]
    );

    const resetCustomerIo = useCallback(() => {
        // Reset a person in customer.io
        _cio?.reset();
        debugCustomerIo('Reset user in customer.io', [{ data: 'Reset user in customer.io' }]);
    }, []);

    return { shouldTrackCustomerIo, identifyCustomerIo, trackEventCustomerIo, trackPageCustomerIo, resetCustomerIo };
};

export default useCustomerIo;
