import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactNode } from 'react';

import { Icon } from 'display-components/icon-list/icon-list';

import './birdi-select-formulary-health-badge.styles.scss';

interface BirdiSelectFormularyHealthBadgesProps {
    icon: Icon;
}

interface HealthBadgeProps {
    icon: ReactNode;
    label: string;
}

const HealthBadge = ({ icon, label }: HealthBadgeProps) => {
    return (
        <div className="health-condition-badge-content__badge">
            <span>{icon}</span> {label}
        </div>
    );
};

const BirdiSelectFormularyHealthBadges = ({ icon }: BirdiSelectFormularyHealthBadgesProps) => {
    const { t } = useTranslation();

    if (!icon || typeof icon.id === 'undefined') {
        return <></>;
    }

    switch (icon.id) {
        case 1:
            return (
                <div className="health-condition-badge-content">
                    <HealthBadge icon={icon.icon} label={icon.title} />
                    <h4>{t('components.birdiSelect.formularyModal.healthConditionsBadges.womenHealth')}</h4>
                </div>
            );
        case 2:
            return (
                <div className="health-condition-badge-content">
                    <HealthBadge icon={icon.icon} label={icon.title} />
                    <h4>{t('components.birdiSelect.formularyModal.healthConditionsBadges.urgentCare')}</h4>
                </div>
            );
        case 3:
            return (
                <div className="health-condition-badge-content">
                    <HealthBadge icon={icon.icon} label={icon.title} />
                    <h4>{t('components.birdiSelect.formularyModal.healthConditionsBadges.mensHealth')}</h4>
                </div>
            );
        case 4:
            return (
                <div className="health-condition-badge-content">
                    <HealthBadge icon={icon.icon} label={icon.title} />
                    <h4>{t('components.birdiSelect.formularyModal.healthConditionsBadges.rxRefills')}</h4>
                </div>
            );
        case 5:
            return (
                <div className="health-condition-badge-content">
                    <HealthBadge icon={icon.icon} label={icon.title} />
                    <h4>{t('components.birdiSelect.formularyModal.healthConditionsBadges.skinCare')}</h4>
                </div>
            );
        case 6:
            return (
                <div className="health-condition-badge-content">
                    <HealthBadge icon={icon.icon} label={icon.title} />
                    <h4>{t('components.birdiSelect.formularyModal.healthConditionsBadges.mentalHealth')}</h4>
                </div>
            );
        default:
            return <></>;
    }
};

export default BirdiSelectFormularyHealthBadges;
