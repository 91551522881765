import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { MutableRefObject } from 'react';

import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';

import { HTMLInputChange } from 'types/globals';

import { noop } from 'util/function';
import { getCreditCardEnding } from 'util/payments';
import { ellipsify, lowercaseAndCapitalize } from 'util/string';

import './payment-item.style.scss';

export interface PaymentItemProps {
    index: number;
    cardHolder: string;
    cardType: string;
    endingDigits: string;
    expiryMonth: string;
    expiryYear: string;
    isDefaultCard?: boolean;
    isSelectCardRadioInputChecked?: boolean;
    onSelectCardRadioInputChange?: HTMLInputChange;
    onSetDefaultClick?: ButtonProps['onClick'];
    onShowPaymentMethods?: ButtonProps['onClick'];
    showSelectCardRadioInput?: boolean;
    showSetDefaultLink?: boolean;
    isInnerWidthViewport?: MutableRefObject<boolean>;
    isLoading?: boolean;
}

const PaymentItem: React.FC<PaymentItemProps> = (props) => {
    const {
        index,
        cardHolder,
        cardType,
        endingDigits,
        expiryMonth,
        expiryYear,
        isDefaultCard,
        isSelectCardRadioInputChecked,
        onSelectCardRadioInputChange = noop,
        onSetDefaultClick = noop,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        isInnerWidthViewport,
        isLoading = false
    } = props;

    const { t } = useTranslation();

    const handleSelectCardRadioInputClick = React.useCallback<NonNullable<HTMLInputChange>>(
        (event) => {
            event.currentTarget.blur();
            onSelectCardRadioInputChange(event);
        },
        [onSelectCardRadioInputChange]
    );

    const formattedExpiryMonth = parseInt(expiryMonth) < 10 ? `0${expiryMonth}` : expiryMonth;

    const showActionLinkSection = showSetDefaultLink;

    return (
        <section className="payment-item">
            <div className="payment-item-radio">
                {showSelectCardRadioInput && (
                    <input
                        disabled={isLoading}
                        type="radio"
                        name={`option-payment-${index}`}
                        id={`payment-${index}`}
                        checked={isSelectCardRadioInputChecked}
                        onChange={handleSelectCardRadioInputClick}
                    />
                )}
            </div>

            <div className="payment-item-data">
                {showSetDefaultLink && (
                    <small>
                        {t(
                            `components.medicineCabinetCart.paymentMethod.${
                                isDefaultCard ? 'primaryPayment' : 'secondaryPayment'
                            }`
                        )}
                    </small>
                )}

                <div className="card-info">
                    <div className="content">
                        <p>{`${
                            isInnerWidthViewport?.current ? ellipsify(lowercaseAndCapitalize(cardType)) : cardType
                        } ${getCreditCardEnding(endingDigits, '····')}`}</p>
                        <p>{cardHolder}</p>
                        <p>
                            {t('components.medicineCabinetCart.paymentMethod.expiry', {
                                month: formattedExpiryMonth,
                                year: expiryYear
                            })}
                        </p>
                    </div>

                    <CreditCardIcon className="payment-card__credit-card-icon" variant={cardType} />
                </div>

                {showActionLinkSection &&
                    (isLoading ? (
                        <SpinnerInline />
                    ) : (
                        <div>
                            {showSetDefaultLink && !isDefaultCard && (
                                <Button
                                    className="px-0 pt-0 text-cerulean no-min-width btn-demi"
                                    dataGAFormName="address-card"
                                    label={t('components.medicineCabinetCart.paymentMethod.labels.setAsDefault')}
                                    onClick={onSetDefaultClick}
                                    type="button"
                                    variant="text"
                                />
                            )}
                            {showSetDefaultLink && isDefaultCard && (
                                <p className="default-text">
                                    {t('components.medicineCabinetCart.paymentMethod.labels.defaultPayment')}
                                </p>
                            )}
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default PaymentItem;
