import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    AddressesAutoRefillRequest,
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    AutoRefillResponse,
    GetEasyAutoRefillPatientDataResponse,
    PaymentCardsAutoRefillRequest,
    ToggleAutoRefillRequest,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/auto-refill';

import { rxAutoRefillEligible, rxAutoRefillEnabled } from 'util/autorefill';
import { TrackError } from 'util/google_optimize/optimize_helper';
import { getRxDisplayStatus, getRxStatus } from 'util/payload-to-props';
import { baseEffectHandler } from 'util/sagas/sagas';

import { GetEasyAutoRefillPatientFamilyDataResponse } from './../../types/auto-refill.d';
import {
    autoRefillAuthenticateUserRoutine,
    autoRefillGetPatientAdressesRoutine,
    autoRefillGetPatientDataRoutine,
    autoRefillGetPatientPaymentCardsRoutine,
    autoRefillGetSecretKeyHashRoutine,
    autoRefillToggleAutoRefillRxStatusRoutine,
    autoRefillVerifyUserRoutine
} from './auto-refill.reducer';
import {
    autoRefillIsCaregiverSelector,
    autoRefillSecretKeyHashSelector,
    autoRefillSecurityTokenSelector,
    autoRefillUserBearerTokenSelector
} from './auto-refill.selectors';
import { AutoRefillService } from './auto-refill.services';

function* generateSecretKeySaga(
    action: PayloadAction<{
        onSuccess?: () => void;
        onFailure?: (response: string) => void;
    }>
) {
    try {
        const securityToken: string = yield select(autoRefillSecurityTokenSelector);

        yield baseEffectHandler({
            service: AutoRefillService.getSecretKeyHash().get,
            isAuthenticatedService: false,
            data: securityToken,
            *onResponse(response: AuthenticateUserResponse) {
                yield put(autoRefillGetSecretKeyHashRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError() {
                yield put(autoRefillGetSecretKeyHashRoutine.failure('The security token is invalid'));
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure('The security token is invalid');
                }
            }
        });
    } catch (error) {
        yield put(autoRefillGetSecretKeyHashRoutine.failure(error));
    }
}

function* authenticateUserSaga() {
    try {
        const securityToken: string = yield select(autoRefillSecurityTokenSelector);
        const secretKeyHash: string = yield select(autoRefillSecretKeyHashSelector);
        // if successful verification - authenticate user
        const authenticateData: AuthenticateUserRequest = {
            securityToken,
            secretKeyHash,
            authenticationType: 'EasyAutoRefill'
        };

        yield baseEffectHandler({
            service: AutoRefillService.authenticateUser().post,
            isAuthenticatedService: false,
            data: authenticateData,
            *onResponse(response: AuthenticateUserResponse) {
                if (response && response.messageText !== 'Authenticated') {
                    yield put(autoRefillAuthenticateUserRoutine.failure(response));
                    TrackError('auto-refill.sagas.ts', 'authenticateUserSaga', response.messageText);
                } else {
                    yield put(autoRefillAuthenticateUserRoutine.success(response));
                }
            },
            *onError(error) {
                yield put(autoRefillAuthenticateUserRoutine.failure({ messageText: 'An error has occurred' }));
            }
        });
    } catch (error) {}
}

function* verifyUserSaga(
    action: PayloadAction<{
        dateOfBirth: string;
        zipcode: string;
        onSuccess?: () => void;
        onFailure?: (response?: VerifyUserResponse) => void;
        onMaxNumAttemptsReached?: (response: VerifyUserResponse) => void;
    }>
) {
    try {
        const securityToken: string = yield select(autoRefillSecurityTokenSelector);
        const { dateOfBirth, zipcode, onFailure, onMaxNumAttemptsReached, onSuccess } = action.payload;
        const data: VerifyUserRequest = {
            dateOfBirth,
            zipcode,
            securityToken,
            authenticationType: 'EasyAutoRefill'
        };

        yield baseEffectHandler({
            service: AutoRefillService.verifyUser().post,
            isAuthenticatedService: false,
            data: data,
            *onResponse(response: VerifyUserResponse) {
                if (response && response.verificationStatus === 'MaxAttemptsReached') {
                    yield put(autoRefillVerifyUserRoutine.failure(response));
                    if (onMaxNumAttemptsReached) onMaxNumAttemptsReached(response);
                    if (onFailure) onFailure(response);
                } else if (
                    response &&
                    response.verificationStatus !== 'UserVerified' &&
                    response.verificationStatus !== 'TokenValid'
                ) {
                    yield put(autoRefillVerifyUserRoutine.failure(response));
                    TrackError('auto-refill.sagas.ts', 'verifyUserSaga', response.messageText);
                    if (onFailure) onFailure(response);
                } else {
                    yield put(autoRefillVerifyUserRoutine.success(response));
                    if (response.verificationStatus !== 'TokenValid') {
                        yield put(autoRefillAuthenticateUserRoutine.trigger());
                    }
                    if (onSuccess) onSuccess();
                }
            },
            *onError(error) {
                yield put(autoRefillVerifyUserRoutine.failure({ messageText: 'An error has occurred' }));
                if (onFailure) onFailure(error);
            }
        });
    } catch (error) {
        const { onFailure } = action.payload;
        if (onFailure) onFailure();

        console.log(error);
    }
}

function* getPatientDataSaga(
    action: PayloadAction<{ onFailure?: () => void; onSuccess?: () => void; withoutLoading?: boolean }>
) {
    try {
        const bearerToken: string = yield select(autoRefillUserBearerTokenSelector);

        const isCaregiver: boolean = yield select(autoRefillIsCaregiverSelector);

        yield baseEffectHandler({
            service: isCaregiver
                ? AutoRefillService.getPatientFamilyData().get
                : AutoRefillService.getPatientData().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: GetEasyAutoRefillPatientDataResponse & GetEasyAutoRefillPatientFamilyDataResponse) {
                const data: GetEasyAutoRefillPatientDataResponse[] = isCaregiver ? response?.data : [response];

                yield put(
                    autoRefillGetPatientDataRoutine.success({
                        data: data.map((patient) => ({
                            ...patient,
                            ...{
                                epostPatientNum: patient.epostPatientNum
                                    ? patient.epostPatientNum.toString()
                                    : patient.rxResults[0]?.epostPatientNum.toString()
                            },
                            ...{
                                rxResults: [...patient.rxResults].map((prescription) => ({
                                    ...prescription,
                                    // realRxCardStatus contains the "real" status, ignoring if the
                                    // prescription is in the cart.
                                    realRxCardStatus: getRxStatus(prescription),
                                    // rxCardStatus contains the status to display in
                                    // the rx card, includes in cart status.
                                    rxCardStatus: getRxDisplayStatus(prescription),
                                    // Ensures that every rx will contain a autoRefillEligible flag
                                    // according to what autoRefillFlags returns.
                                    autoRefillEligible: rxAutoRefillEligible(
                                        patient.autoRefillFlags,
                                        prescription.rxNumber
                                    ),
                                    // Ensures that every rx will contain a autoRefillEnabled flag
                                    // according to what autoRefillFlags returns.
                                    autoRefillEnabled: rxAutoRefillEnabled(
                                        patient.autoRefillFlags,
                                        prescription.rxNumber
                                    ),
                                    epostPatientNum: prescription.epostPatientNum.toString()
                                }))
                            }
                        })),
                        withoutLoading: action.payload.withoutLoading
                    })
                );

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError() {
                yield put(autoRefillGetPatientDataRoutine.failure({ messageText: 'An error has occured' }));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        yield put(autoRefillGetPatientDataRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

function* toggleAutoRefillForRxSaga(
    action: PayloadAction<{
        rxsToToggle: { rxSeqNum: string; newStatus: boolean; rxNumber: string };
        onFailure?: () => void;
        onSuccess?: () => void;
    }>
) {
    try {
        const { rxsToToggle, onSuccess } = action.payload;
        const bearerToken: string = yield select(autoRefillUserBearerTokenSelector);
        const data: ToggleAutoRefillRequest = {
            rxNum: rxsToToggle.rxNumber,
            newStatus: rxsToToggle.newStatus
        };

        yield baseEffectHandler({
            service: AutoRefillService.toggleAutoRefillForRx().get,
            data: { ...data, bearerToken: bearerToken },
            isAuthenticatedService: false,
            *onResponse(response: AutoRefillResponse) {
                if (response.messageStatus) {
                    yield put(
                        autoRefillToggleAutoRefillRxStatusRoutine.success({
                            rxNumber: rxsToToggle.rxNumber,
                            autoRefillEnabled: rxsToToggle.newStatus
                        })
                    );
                } else {
                    yield put(
                        autoRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occurred' })
                    );
                }
            },
            *onError() {
                yield put(autoRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occurred' }));
            }
        });
        if (onSuccess) onSuccess();
    } catch (error) {
        yield put(autoRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

function* getAdressesSaga(action: PayloadAction<{ onFailure?: () => void }>) {
    try {
        const bearerToken: string = yield select(autoRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: AutoRefillService.getAddresses().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: AddressesAutoRefillRequest) {
                yield put(autoRefillGetPatientAdressesRoutine.success(response));
            },
            *onError() {
                yield put(autoRefillGetPatientAdressesRoutine.failure({ messageText: 'An error has occurred' }));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        yield put(autoRefillGetPatientAdressesRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

function* getPaymentCards(action: PayloadAction<{ onFailure?: () => void }>) {
    try {
        const bearerToken: string = yield select(autoRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: AutoRefillService.getPaymentCards().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: PaymentCardsAutoRefillRequest) {
                yield put(autoRefillGetPatientPaymentCardsRoutine.success(response));
            },
            *onError() {
                yield put(autoRefillGetPatientPaymentCardsRoutine.failure({ messageText: 'An error has occurred' }));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        yield put(autoRefillGetPatientPaymentCardsRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

function* autoRefillSaga() {
    yield takeLatest(autoRefillVerifyUserRoutine.TRIGGER, verifyUserSaga);
    yield takeLatest(autoRefillAuthenticateUserRoutine.TRIGGER, authenticateUserSaga);
    yield takeLatest(autoRefillGetSecretKeyHashRoutine.TRIGGER, generateSecretKeySaga);
    yield takeLatest(autoRefillGetPatientDataRoutine.TRIGGER, getPatientDataSaga);
    yield takeEvery(autoRefillToggleAutoRefillRxStatusRoutine.TRIGGER, toggleAutoRefillForRxSaga);
    yield takeEvery(autoRefillGetPatientAdressesRoutine.TRIGGER, getAdressesSaga);
    yield takeEvery(autoRefillGetPatientPaymentCardsRoutine.TRIGGER, getPaymentCards);
}

export default autoRefillSaga;
