import { USPS_API_USERNAME, USPS_SHIPPING_API_URL } from 'gatsby-env-variables';

import { VerifyAddressRequest, VerifyZipRequest } from 'state/usps/usps.reducers';

import USPS, { USPSAddress, USPSAddressValidateResponse } from 'util/usps';

export default class USPSService {
    static verify() {
        return {
            post: async (payload?: VerifyAddressRequest): Promise<USPSAddressValidateResponse> => {
                if (!payload || !payload.address) return { error: 'Search address not supplied.' };

                if (!USPS_API_USERNAME || !USPS_SHIPPING_API_URL) {
                    return { error: 'Required inputs for API usage not supplied.' };
                }

                const { address } = payload;
                const usps = new USPS({
                    addressVerifyApiUrl: USPS_SHIPPING_API_URL,
                    uspsUsername: USPS_API_USERNAME
                });

                const Zip5 = address.zip.length > 5 ? address.zip.slice(0, 5) : address.zip;
                const Zip4 = address.zip.length > 5 ? address.zip.slice(-4) : '';

                const verifyPayload: USPSAddress = {
                    Address1: address.street2 || '',
                    Address2: address.street1,
                    City: address.city,
                    State: address.state,
                    Zip5,
                    Zip4
                };

                const verifiedAddressResponse = await usps.verify(verifyPayload);
                return verifiedAddressResponse;
            }
        };
    }

    static verifyZip() {
        return {
            post: async (payload?: VerifyZipRequest): Promise<USPSAddressValidateResponse> => {
                if (!payload || !payload.zip) return { error: 'Search zip not supplied.' };

                if (!USPS_API_USERNAME || !USPS_SHIPPING_API_URL) {
                    return { error: 'Required inputs for API usage not supplied.' };
                }

                const { zip } = payload;

                const usps = new USPS({
                    addressVerifyApiUrl: USPS_SHIPPING_API_URL,
                    uspsUsername: USPS_API_USERNAME
                });

                const verifiedAddressResponse = await usps.verifyZip(zip);
                return verifiedAddressResponse;
            }
        };
    }
}
