import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import { ENABLE_DISCOUNT_CARD } from 'gatsby-env-variables';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';

import DrugInfoModal from 'display-components/birdi-discount/modal-contents/drug-info.component';
import SearchAgain from 'display-components/birdi-discount/modal-contents/search-again.component';
import PrescriptionForm from 'display-components/birdi-discount/search-results/prescription-form';
import SearchResults from 'display-components/birdi-discount/search-results/search-results';

import Banner from 'components/discount-card/banner';
import LegalText from 'components/discount-card/legal-text/legal-text.component';
import PageLayout from 'components/layouts/page/page.layout';
import PrescriptionSearch from 'components/prescription-search';

import { DiscountCardErrors, formatDrugNameForURL } from 'state/discount-card/discount-card.helpers';
import { setCurrentLocationAddress, setInitialValues } from 'state/discount-card/discount-card.reducers';
import { discountCardSearchFilterRoutine, discountCardSearchRoutine } from 'state/discount-card/discount-card.routines';
import {
    discountCardCurrentDrugSelector,
    discountCardDrugFormOptionsSelector,
    discountCardErrorSelector,
    discountCardFilterIsLoadingSelector,
    discountCardIsLoadingSelector,
    discountCardPharmacyResultsSelector,
    discountCardUserLocationSelector
} from 'state/discount-card/discount-card.selector';
import { resetDrugDescription } from 'state/drug/drug.reducers';
import { drugDescriptionRoutine } from 'state/drug/drug.routines';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { DrugEditFormFieldsEnum, searchDrugsByNameParams, USPSPartialAddress } from 'types/discount-card';

import { isNullOrEmptyObject } from 'util/object';
import storageHelper from 'util/storageHelper';
import { convertToTitleCase } from 'util/string';
import { ZipValidateResponse } from 'util/usps';

import { useAddressVerification } from 'hooks/useAddressVerification';
import usePageRedirection from 'hooks/usePageRedirection';
import usePopState from 'hooks/usePopState';

import { discountCardLegalPaths } from '../index';
import './index.style.scss';

const PharmacyResults = () => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyZip } = useAddressVerification();

    // Selectors
    const drugInfo = useSelector(discountCardCurrentDrugSelector);
    const drugForm = useSelector(discountCardDrugFormOptionsSelector);
    const userLocationAddress = useSelector(discountCardUserLocationSelector);
    const pharmacyResults = useSelector(discountCardPharmacyResultsSelector);
    const isLoading = useSelector(discountCardIsLoadingSelector);
    const isFilterLoading = useSelector(discountCardFilterIsLoadingSelector);
    const discountCardError = useSelector(discountCardErrorSelector);

    const hasUnknownError =
        discountCardError && discountCardError?.messageText === DiscountCardErrors.somethingWentWrong;

    const { drugOptions } = drugForm || {};
    const selectedDrug = drugOptions?.find((f) => f.IsSelected);
    const selectedBrandDrug = drugOptions?.find((f) => f.BrandGeneric === 'B') || undefined;
    const selectedGenericDrug = drugOptions?.find((f) => f.BrandGeneric === 'G') || undefined;

    // Local State
    const [showDrugInfoModal, setShowDrugInfoModal] = useState<boolean | undefined>(undefined);
    const [queryParams, setQueryParams] = useState<{ drugName: string | undefined; zipCode: string | undefined }>({
        drugName: undefined,
        zipCode: undefined
    });

    // Local constants
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const searchedDrug = formatDrugNameForURL(params.get('drugName') as string, false);
    const searchedZipCode = params.get('zip');
    const hasLocation = userLocationAddress !== undefined;
    const currentResult = useMemo(
        () => ({
            currentDrug: drugInfo,
            formOptions: drugForm,
            pharmacies: pharmacyResults,
            currentLocation: userLocationAddress
        }),
        [drugInfo, drugForm, pharmacyResults, userLocationAddress]
    );

    const initializeFromLocalStorage = () => {
        const savedDrugInfo = storageHelper.local.getCurrentDrugInfo();
        if (!isNullOrEmptyObject(savedDrugInfo)) {
            const parsedDrugInfo = JSON.parse(savedDrugInfo as string);
            dispatch(
                setInitialValues({
                    currentDrug: parsedDrugInfo.currentDrug,
                    formOptions: parsedDrugInfo.formOptions,
                    pharmacies: parsedDrugInfo.pharmacies,
                    currentLocation: parsedDrugInfo.currentLocation
                })
            );
        }
    };

    const handleVerifyZipCode = (zipCode: string) => {
        verifyZip({
            zip: zipCode,
            onSuccess: (validationResponse: ZipValidateResponse) => {
                if (validationResponse.address) {
                    const validationPayload: USPSPartialAddress = {
                        city: validationResponse.address.city,
                        state: validationResponse.address.state,
                        zip: validationResponse.address.zip
                    };
                    dispatch(
                        setCurrentLocationAddress({
                            address: validationPayload
                        })
                    );
                }
            }
        });
    };

    const handleSearchAndSetLocation = (searchedDrug: string, searchedZipCode: string) => {
        dispatch(
            setInitialValues({
                isLoading: true
            })
        );
        const searchPayload: searchDrugsByNameParams = {
            drugName: searchedDrug,
            zipCode: searchedZipCode
        };
        dispatch(
            discountCardSearchFilterRoutine.trigger({
                data: { searchPayload },
                onSuccess: () => {
                    handleVerifyZipCode(searchedZipCode);
                },
                onFailure: (error: { messageText?: string }) => {
                    dispatch(
                        setInitialValues({
                            isLoading: false
                        })
                    );
                    if (error?.messageText === DiscountCardErrors.pharmaciesNotFound) {
                        handleVerifyZipCode(searchedZipCode);
                    }
                    if (error?.messageText === DiscountCardErrors.somethingWentWrong) {
                        navigate(`/discount-card/error`);
                    }
                }
            })
        );
    };

    /**
     * A callback function to handle changes in drug filter form.
     *
     * @param {string} filterParam - The filter parameter which could be a quantity and GSN or just a GSN.
     * @param {DrugEditFormFieldsEnum} fieldType
     * This function extracts the quantity and GSN from the filter parameter based on the field type
     *
     * @example
     * handlePrescriptionChange('30-123456', DrugEditFormFieldsEnum.Quantity);
     * handlePrescriptionChange('123456', DrugEditFormFieldsEnum.Brand);
     */
    const handlePrescriptionChange = useCallback(
        (filterParam: string, fieldType: DrugEditFormFieldsEnum) => {
            const { zip } = userLocationAddress?.address || {};

            const [quantity, GSN] =
                fieldType === DrugEditFormFieldsEnum.Quantity ? filterParam.split('-') : [undefined, filterParam];

            const [drugName, brand] =
                fieldType === DrugEditFormFieldsEnum.Brand ? filterParam.split('&&') : [undefined, undefined];

            const searchPayload: searchDrugsByNameParams = {
                drugName: drugName,
                zipCode: zip,
                qty: quantity,
                GSN,
                brandIndicator: brand
            };

            if (fieldType === DrugEditFormFieldsEnum.Brand) {
                delete searchPayload.GSN;
            }

            dispatch(
                discountCardSearchFilterRoutine.trigger({
                    // Load quantities data from /DrugFormStrength API
                    // When we are switching the strength field value
                    data: { searchPayload, loadQuantitiesData: fieldType === DrugEditFormFieldsEnum.Dosage }
                })
            );
        },
        [userLocationAddress, dispatch]
    );

    const handleSubmitSearch = useCallback(
        (drugName: string) => {
            if (!hasLocation) {
                dispatch(discountCardSearchRoutine.failure({ messageText: DiscountCardErrors.locationNotFound }));
                return;
            }

            const searchPayload: searchDrugsByNameParams = {
                drugName: formatDrugNameForURL(drugName, false),
                zipCode: userLocationAddress?.address?.zip
            };

            dispatch(
                discountCardSearchRoutine.trigger({
                    data: searchPayload,
                    onFailure: (error: { messageText?: string }) => {
                        if (
                            [DiscountCardErrors.pharmaciesNotFound, DiscountCardErrors.drugNotFound].includes(
                                error?.messageText as DiscountCardErrors
                            )
                        ) {
                            // reset drug information
                            dispatch(
                                setInitialValues({
                                    currentDrug: undefined,
                                    formOptions: undefined,
                                    pharmacies: undefined,
                                    currentLocation: userLocationAddress
                                })
                            );
                            // update the URL even if no results are found
                            navigate(
                                `/discount-card/results?drugName=${formatDrugNameForURL(searchPayload.drugName)}&zip=${
                                    searchPayload.zipCode
                                }`
                            );
                        }
                    }
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasLocation, userLocationAddress]
    );

    const handlePrescriptionSearch = () => {
        dispatch(
            openModalComponent({
                title: t('pages.discountCard.pharmacyResults.modal.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: <SearchAgain handleCloseModal={() => dispatch(closeModalComponent())} />,
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    const handleMoreDrugInfo = () => {
        const isGenericDrug = selectedDrug?.BrandGeneric === 'G' && selectedBrandDrug;
        const isBrandDrug = selectedDrug?.BrandGeneric === 'B' && selectedGenericDrug;
        const drugBrand = isGenericDrug
            ? t('pages.discountCard.pharmacyResults.prescriptionForm.genericName', {
                  brandName: convertToTitleCase(selectedBrandDrug?.DrugName || '')
              })
            : isBrandDrug
            ? t('pages.discountCard.pharmacyResults.prescriptionForm.brandName', {
                  brandName: convertToTitleCase(selectedGenericDrug?.DrugName || '')
              })
            : null;

        dispatch(
            openModalComponent({
                title: `${drugInfo?.genericName} ${drugBrand ? `(${drugBrand})` : ``}`,
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: (
                    <DrugInfoModal ndc={parseInt(drugInfo?.ndc as string)} gpi={parseInt(drugInfo?.ndc as string)} />
                ),
                variation: 'large',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    // addition of a new check for redirects in cases where there is no searchedDrug or searchedZipCode params
    useEffect(() => {
        if (!searchedDrug || !searchedZipCode) {
            navigate('/discount-card');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    useEffect(() => {
        // Initialize drug info from local storage if available
        initializeFromLocalStorage();

        // Save current state to local storage if necessary
        if (drugInfo && drugForm && pharmacyResults) {
            storageHelper.local.setCurrentDrugInfo(currentResult);
        } else {
            // Perform a new search if no data is available in state or local storage
            if (searchedDrug && searchedZipCode) {
                handleSearchAndSetLocation(searchedDrug, searchedZipCode);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // DRX-3533: If the user performs a new search, it replace the results.
    useEffect(() => {
        storageHelper.local.setCurrentDrugInfo(currentResult);
    }, [userLocationAddress, drugInfo, currentResult]);

    // DRX-3480: Get the Drug Info after the drug info is being loaded.
    useEffect(() => {
        dispatch(resetDrugDescription());

        if (drugInfo) {
            dispatch(
                drugDescriptionRoutine.trigger({
                    gpi: drugInfo.ndc,
                    ndc: drugInfo.ndc,
                    onSuccess: () => setShowDrugInfoModal(true),
                    onFailure: () => setShowDrugInfoModal(false)
                })
            );
        }
    }, [drugInfo, dispatch]);

    /**
     * This function monitors changes in the query parameters (`drugName` and `zipCode`)
     * whenever the browser's "Back" or "Forward" buttons are used (triggering the `popstate` event).
     *
     * - `checkQueryParams`: Retrieves the current URL parameters, formats the `drugName`, and compares both
     *   `drugName` and `zipCode` against the existing state. If there is a change in any of these parameters,
     *   it updates the state with the new values. If both `newSearchedDrug` and `newSearchedZipCode` are present,
     *   it calls the `handleSearchAndSetLocation` to redo the search
     *
     * - `handlePopState`: Triggers `checkQueryParams` whenever a `popstate` event occurs, ensuring that any changes
     *   in the query parameters due to browser navigation are detected and handled accordingly.
     *
     * Dependencies: The effect re-runs when the `queryParams` state (containing both `drugName` and `zipCode`) changes.
     */
    const checkQueryParams = () => {
        const params = new URLSearchParams(window.location.search);
        const newSearchedDrug = formatDrugNameForURL(params.get('drugName') as string, false);
        const newSearchedZip = params.get('zip') || undefined;

        if (newSearchedDrug !== queryParams.drugName || newSearchedZip !== queryParams.zipCode) {
            setQueryParams({ drugName: newSearchedDrug, zipCode: newSearchedZip });

            if (newSearchedDrug && newSearchedZip) {
                handleSearchAndSetLocation(newSearchedDrug, newSearchedZip);
            }
        }
    };

    usePopState(() => {
        checkQueryParams();
    }, [searchedDrug, searchedZipCode]);

    const isDiscountCardPageRedirected = usePageRedirection({
        featureFlag: ENABLE_DISCOUNT_CARD
    });

    if (isDiscountCardPageRedirected) {
        return <></>;
    }

    return (
        <div className="birdi-pharmacy-results-page">
            <PageLayout
                metaData={{ nodeTitle: t('pages.discountCard.pharmacyResults.nodeTitle') }}
                isShortFooter={true}
                hideSpecialtyAddress={true}
                legalPaths={discountCardLegalPaths}
            >
                <div className="birdi-pharmacy-results-page-content">
                    <div className="header">
                        <h2>{t('pages.discountCard.pharmacyResults.title')}</h2>
                        <div>
                            <PrescriptionSearch onSearch={handleSubmitSearch} isBusy={isLoading} />
                        </div>
                        <Button
                            type="button"
                            label={t('pages.discountCard.pharmacyResults.searchBtn')}
                            searchIcon
                            className="mobile"
                            variant="outline-primary"
                            onClick={handlePrescriptionSearch}
                        />
                    </div>
                    {!hasUnknownError && (
                        <PrescriptionForm
                            isLoading={isLoading}
                            skeletonHeight={200}
                            drugInfo={drugInfo}
                            drugForm={drugForm}
                            showModal={showDrugInfoModal}
                            onPrescriptionChange={handlePrescriptionChange}
                            onMoreDrugInfoClick={handleMoreDrugInfo}
                        />
                    )}
                    <SearchResults
                        isLoading={isLoading || isFilterLoading}
                        skeletonHeight={800}
                        pharmacies={pharmacyResults}
                    />
                    {!hasUnknownError && (
                        <>
                            <Banner
                                isLoading={isLoading || isFilterLoading}
                                skeletonHeight={150}
                                title={t('pages.discountCard.pharmacyResults.banner.title')}
                                description={t('pages.discountCard.pharmacyResults.banner.description')}
                                label={t('pages.discountCard.pharmacyResults.banner.btnLabel')}
                                handleClick={() => {
                                    navigate('/discount-card/get-your-card');
                                }}
                            />
                            <LegalText />
                        </>
                    )}
                </div>
            </PageLayout>
        </div>
    );
};

export default PharmacyResults;

export const query = graphql`
    query PharmacyResultsData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
