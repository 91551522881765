import { graphql, navigate, PageProps } from 'gatsby';
import { ENABLE_BRDSELECT_DRUGMODAL } from 'gatsby-env-variables';
import React, { useCallback, useEffect, useState } from 'react';
// Components & UI
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Display Components
import CategoriesMembership from 'display-components/categories-membership';
import HealthCareList from 'display-components/membership-dashboard/health-care-list';
import NewRxModal from 'display-components/modals/new-rx';
import QuickLinks from 'display-components/quick-links';
import { CurrentFlow } from 'display-components/quick-links/quick-links.props';
// Display Components
import TelemedicineAsyncContent from 'display-components/telemedicine/modal-contents/telemedicine-async.component';

// Components
import AccountBalance from 'components/account-balance/account-balance.component';
import AutoRefillModalContent from 'components/auto-refill-modal';
import { AutoRefillImage } from 'components/auto-refill-modal/auto-refill-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import {
    BirdiSelectFormularySimpleBody,
    BirdiSelectFormularySimpleHeader
} from 'components/birdi-select/birdi-select-formulary-modal-simple/birdi-select-formulary-modal-simple';
import BirdiSelectFormularyContent from 'components/birdi-select/birdi-select-formulary-modal/birdi-select-formulary-modal';
import MembershipLayout from 'components/layouts/membership/membership.layout';
import MembershipPlan, { MembershipPlanDetails } from 'components/membership-plan/membership-plan.component';
import PendingTelemedicinesComponent, {
    PendingTelemedicinePayload
} from 'components/pending-telemedicines/pending-telemedicines.component';

import { accountFetchProfileRoutine, accountUpdateAutoRefill } from 'state/account/account.routines';
// Redux & Services
import {
    accountAcknowledgementTermsStatusSelector,
    accountAutoRefillEligibleSelector,
    accountHasOutstandingBalance,
    accountIsCaliforniaUserSelector,
    accountProfileMembershipSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
// Modals
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { birdiModalIsOpenSelector } from 'state/birdi-modal/birdi-modal.selectors';
import { medicineShowNewPrescriptionModalSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
// Types
import {
    membershipGetTelehealthVisitsRoutine,
    membershipGetVisitsLinkRoutine
} from 'state/membership/membership.routines';
import {
    isMembershipAvailableSelector,
    membershipDetailSelector,
    membershipHasTelehealthVisitsSelector,
    membershipPreviousVisitsLinkSelector,
    membershipRequiresPaymentSelector,
    membershipTelehealthVisitsSelector
} from 'state/membership/membership.selector';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

// eslint-disable-line no-unused-vars
import { MembershipPlanStatusType, MembershipVisitStatus, MembershipVisitUIStatus } from 'types/membership';

import { validateAutoRefillModalDisplay } from 'util/autorefill';
// Utils
import dynamicGreeting from 'util/dynamicGreeting';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackNewPrescriptionNumber } from 'util/google_optimize/optimize_helper';
import storageHelper from 'util/storageHelper';
import { convertToTitleCase } from 'util/string';

const Membership: React.FC<PageProps<GatsbyTypes.MembershipDataQuery>> = ({ data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const profileObject = useSelector(accountProfileSelector);
    const membershipDetails = useSelector(membershipDetailSelector);
    const { membershipID, hasMembership } = useSelector(accountProfileMembershipSelector);
    const previousVisitsLink = useSelector(membershipPreviousVisitsLinkSelector);
    const epostPatientNum = useSelector(accountProfilEPostPatientNumSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);
    const telehealthVisitsObject = useSelector(membershipTelehealthVisitsSelector);
    const hasTelehealthVisits = useSelector(membershipHasTelehealthVisitsSelector);
    const hasOutstandingBalance = useSelector(accountHasOutstandingBalance);
    const isMembershipPaymentRequired = useSelector(membershipRequiresPaymentSelector);
    const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
    const isOtherModalOpen = useSelector(birdiModalIsOpenSelector);
    const newPrescriptionModal = useSelector(medicineShowNewPrescriptionModalSelector);
    const isMembershipAvailable = useSelector(isMembershipAvailableSelector);
    const hasAcceptedTerms = useSelector(accountAcknowledgementTermsStatusSelector);

    const [pendingTelemedicineData, setPendingTelemedicineData] = useState<PendingTelemedicinePayload>();
    const [hasNewRxModalOpened, setHasNewRxModalOpened] = useState<boolean>(false);

    const hasDismiss = storageHelper.local.getTelemedicineAsyncModalDismiss();

    const telemedicineAsyncModalStatusHandler = useCallback(() => {
        if (!hasDismiss) {
            storageHelper.local.setTelemedicineAsyncModalDismiss();
        }
    }, [hasDismiss]);

    const closeModalHandler = () => {
        dispatch(closeModalComponent());
        storageHelper.local.setAutoRefillToggleFlag();
    };

    const openAutorefillModal = useCallback(() => {
        dispatch(
            openModalComponent({
                title: t('pages.autoRefill.userVerification.title'),
                isCloseable: true,
                hasDefaultFooter: false,
                hasModalHeader: true,
                variation: 'side-view',
                isCentered: true,
                hasCustomContent: false,
                sideView: <AutoRefillImage />,
                content: (
                    <AutoRefillModalContent
                        onClose={() => {
                            closeModalHandler();
                            openTelemedicineAsyncModal();
                        }}
                        onContinue={handleToggleAutoRefill}
                    />
                ),
                onClose: () => {
                    closeModalHandler();
                    openTelemedicineAsyncModal();
                }
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, t]);

    const openTelemedicineAsyncModal = useCallback(() => {
        if (!isOtherModalOpen && !hasDismiss && hasMembership) {
            dispatch(
                openModalComponent({
                    hasDefaultFooter: false,
                    hasCustomContent: true,
                    content: (
                        <TelemedicineAsyncContent
                            onClose={() => {
                                telemedicineAsyncModalStatusHandler();
                                dispatch(closeModalComponent());
                                openNewRxModal();
                            }}
                        />
                    ),
                    customDialogClassName: 'telemedicine-async-custom-size-modal',
                    hasModalHeader: false,
                    isCentered: true,
                    onClose: () => {
                        telemedicineAsyncModalStatusHandler();
                        dispatch(closeModalComponent());
                        openNewRxModal();
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOtherModalOpen, hasDismiss, hasMembership]);

    const openNewRxModal = useCallback(() => {
        if (!hasNewRxModalOpened && newPrescriptionModal.prescriptions.length > 0) {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: <NewRxModal prescriptions={newPrescriptionModal.prescriptions} />,
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.newPrescriptions.labels.viewMedicineCabinet'),
                            variant: 'primary',
                            onClick: (event) => {
                                dispatch(closeModal({}));
                                navigate('/secure/medicine-cabinet');

                                const eventTarget = event?.target as HTMLElement;
                                if (newPrescriptionModal.prescriptions.length > 0 && eventTarget) {
                                    TrackNewPrescriptionNumber(eventTarget, newPrescriptionModal.prescriptions.length);
                                }
                            },
                            dataGALocation: t('modals.newPrescriptions.title.singlePrescription').replace(/ /g, '')
                        }
                    ]
                })
            );
            setHasNewRxModalOpened(true);
        }
    }, [dispatch, t, newPrescriptionModal, hasNewRxModalOpened]);

    useEffect(() => {
        if (!isMembershipAvailable) {
            navigate('/secure/medicine-cabinet');
        }
    }, [isMembershipAvailable]);

    useEffect(() => {
        // DRX-3209: When the user tries to go back from the SteadyMD page, some states were not updated.
        // Since the user needs the profileObject to perform any other action, we opted to listen for the 'pageshow'
        // event to handle page navigation, ensuring the page reloads instead of managing the state locally.
        // This behavior is standard in most browsers (Chrome, Mozilla Firefox, Safari), but because tab reloading
        // with JavaScript is disabled in Edge, we force the page to update here.
        // For more information on the 'pageshow' event, refer to:
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/pageshow_event

        const onPageshow = (event: Event) => {
            // Check if the event is persisted, indicating a page reload from cache,
            // or if the navigation type is back or forward.
            const persisted = (event as PageTransitionEvent).persisted;
            const navigationType = window.performance?.navigation?.type;

            if (persisted || navigationType === window.performance?.navigation?.TYPE_BACK_FORWARD) {
                // Page is loaded from cache (e.g., user navigated back)
                // Force reload to ensure it's up-to-date.
                window.location.reload();
            }
        };

        // Add event listener for 'pageshow' event
        window.addEventListener('pageshow', onPageshow);

        // Clean up event listener to prevent memory leaks
        return () => {
            window.removeEventListener('pageshow', onPageshow);
        };
    }, []);

    useEffect(() => {
        const memberVisits: PendingTelemedicinePayload['payload'] =
            telehealthVisitsObject?.Members?.filter((member) => {
                return member?.TelehealthVisit?.length > 0;
            }).flatMap((member) => {
                return member.TelehealthVisit?.map((visit) => {
                    return {
                        patientName: member.PatientFirstName + ' ' + member.PatientLastName,
                        specialty: visit.ProgramName,
                        date: visit.AppointmentStartsAt || visit.CreatedAt,
                        status: visit.NewStatus as MembershipVisitStatus,
                        uiStatus: visit.StatusUI as MembershipVisitUIStatus,
                        showPatientName: membershipDetails && membershipDetails.members.length > 1 ? true : false,
                        epostPatientNum: visit.EpostPatientNum
                    };
                });
            }) || [];

        const userVisits: PendingTelemedicinePayload['payload'] =
            telehealthVisitsObject?.TelehealthVisit?.map((visit) => {
                return {
                    patientName: telehealthVisitsObject.PatientFirstName + ' ' + telehealthVisitsObject.PatientLastName,
                    specialty: visit.ProgramName,
                    date: visit.AppointmentStartsAt || visit.CreatedAt,
                    status: visit.NewStatus as MembershipVisitStatus,
                    uiStatus: visit.StatusUI as MembershipVisitUIStatus,
                    showPatientName: membershipDetails && membershipDetails.members.length > 1 ? true : false,
                    epostPatientNum: visit.EpostPatientNum
                };
            }) || [];

        const visits = [...userVisits, ...memberVisits].sort((a, b) => {
            return new Date(String(b.date)).getTime() - new Date(String(a.date)).getTime();
        });

        const pendingTelemedicines: PendingTelemedicinePayload = {
            payload: visits
        };

        setPendingTelemedicineData(pendingTelemedicines);
    }, [membershipDetails, telehealthVisitsObject]);

    useEffect(() => {
        if (profileObject !== undefined) {
            dispatch(membershipGetVisitsLinkRoutine.trigger({ epostPatientNumber: epostPatientNum }));
        }
    }, [dispatch, membershipID, profileObject, membershipDetails, epostPatientNum]);

    useEffect(() => {
        if (epostPatientNum && !telehealthVisitsObject) {
            dispatch(membershipGetTelehealthVisitsRoutine.trigger({ epostPatientNumber: epostPatientNum }));
        }
    }, [dispatch, epostPatientNum, telehealthVisitsObject]);

    useEffect(() => {
        // Validating that we have already loaded membership details information.
        if (hasAcceptedTerms && membershipDetails !== undefined) {
            if (
                validateAutoRefillModalDisplay(isAutoRefillEligible, isCAResident) &&
                profileObject?.patientPlanAutoRefillFlag
            ) {
                openAutorefillModal();
            } else {
                openTelemedicineAsyncModal();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipDetails, isAutoRefillEligible, isCAResident, hasAcceptedTerms]);

    useEffect(() => {
        if (
            !isOtherModalOpen &&
            !hasNewRxModalOpened &&
            membershipDetails !== undefined &&
            hasDismiss &&
            hasAcceptedTerms
        ) {
            openNewRxModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipDetails, hasDismiss, hasAcceptedTerms, newPrescriptionModal]);

    const handleToggleAutoRefill = () => {
        dispatch(
            accountUpdateAutoRefill.trigger({
                epostPatientNumber: profileObject?.epostPatientNum,
                autoRefillFlag: !profileObject?.autoRefill,
                onSuccess: () => {
                    closeModalHandler();
                    dispatch(accountFetchProfileRoutine.trigger());

                    // only display success modal for toggling OFF auto refill
                    if (!profileObject?.autoRefill) {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        title={t('pages.profile.autoRefill.successModal')}
                                        body={
                                            <div className="success-content-container">
                                                <p className="success-content-container-subtitle">
                                                    {t('pages.profile.autoRefill.successToggleTitle')}
                                                </p>
                                            </div>
                                        }
                                        icon={'default'}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('pages.profile.autoRefill.successDoneBtn'),
                                        variant: 'primary',
                                        className: 'auto-refill-success-btn',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'AutoRefillToggleModalSuccess'
                                    }
                                ]
                            })
                        );
                    }
                },
                onFailure: (error: any) => {
                    // DRX-4428: Only closes the modal without setting any local flag, since it is a failure scenario
                    dispatch(closeModalComponent());

                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            onClose: () => dispatch(closeModal({})),
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('components.membershipModals.errorTitle')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt
                                    subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                                    note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const planDetails: MembershipPlanDetails = {
        planName: membershipDetails?.planName as string,
        planStatus: membershipDetails?.planStatus as unknown as MembershipPlanStatusType,
        prescriptionsLimit: membershipDetails?.prescriptionsLimit as number,
        prescriptionsMade: membershipDetails?.prescriptionsMade as number,
        virtualVisitsLimit: membershipDetails?.virtualVisitsLimit as number,
        virtualVisitsMade: membershipDetails?.virtualVisitsMade as number
    };

    const userNameUppercase = profileObject ? profileObject.patientFirstName : '';
    const userName = convertToTitleCase(userNameUppercase);

    const isMembershipOwner = membershipDetails?.isPlanOwner
        ? 'pages.membership.healthCare.paymentDue.ownerPlanPaymentDue'
        : 'pages.membership.healthCare.paymentDue.memberPlanPaymentDue';

    const { payload } = pendingTelemedicineData || {};
    const hasPendingTelemedicineData = hasTelehealthVisits && payload && payload.length > 0;

    const handlePaymentDue = (e: any) => {
        if (e?.target.className === 'link') return;

        if (isMembershipPaymentRequired) {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    contentClassName: 'modal-content-padding',
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.membership.healthCare.paymentDue.title')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t(isMembershipOwner)}
                            note={t('pages.membership.healthCare.paymentDue.note', {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('pages.membership.healthCare.paymentDue.button'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            }
                        }
                    ]
                })
            );
        } else {
            navigate('/secure/membership/request-visit');
        }
    };

    const title = React.useCallback((): string => {
        return dynamicGreeting(
            t('pages.membership.labels.title.morning'),
            t('pages.membership.labels.title.afternoon'),
            t('pages.membership.labels.title.evening')
        );
    }, [t]);

    const greetingMessage = profileObject ? `${title()}, ${userName}.` : `${title()}.`;

    const handleBirdiSelectModal = () => {
        // Adding a feature flag, as we won't launch the new birdiselect modal with 6.8 release
        if (ENABLE_BRDSELECT_DRUGMODAL) {
            dispatch(
                openModalComponent({
                    title: t('components.birdiSelect.formularyModal.title'),
                    content: <BirdiSelectFormularyContent />,
                    customDialogClassName: 'custom-birdi-select-formulary-modal',
                    hasModalHeader: true,
                    isCentered: true,
                    hasDefaultFooter: false,
                    hasCustomContent: true,
                    variation: 'large',
                    onClose: () => {
                        dispatch(closeModalComponent());
                    }
                })
            );
        } else {
            dispatch(
                openModal({
                    size: 'xl',
                    showClose: true,
                    className: 'scroll-modal',
                    headerContent: <BirdiSelectFormularySimpleHeader />,
                    bodyContent: <BirdiModalContent icon={'none'} body={<BirdiSelectFormularySimpleBody />} />,
                    ctas: [
                        {
                            label: t('modals.default.submit'),
                            variant: 'primary',
                            className: 'btn-bold',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'DiscountDrug'
                        }
                    ]
                })
            );
        }
    };

    if (!isMembershipAvailable) {
        return null;
    }

    return (
        <MembershipLayout greetingMessage={greetingMessage}>
            <div className="membership-telemedicine">
                <MembershipPlan {...planDetails} />
                {hasOutstandingBalance && (
                    <div className="membership-account-balance">
                        <AccountBalance variant="banner" />
                    </div>
                )}
                {hasPendingTelemedicineData && <PendingTelemedicinesComponent {...pendingTelemedicineData} />}
                <HealthCareList handleRequestTelemedicine={handlePaymentDue}>
                    <CategoriesMembership handleCardClick={handlePaymentDue} />
                </HealthCareList>
                <QuickLinks
                    currentFlow={CurrentFlow.MEMBERSHIP}
                    prescriptionModal={handleBirdiSelectModal}
                    previousVisits={previousVisitsLink}
                />
            </div>
        </MembershipLayout>
    );
};

export default Membership;

export const query = graphql`
    query MembershipData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
