import { useTranslation } from 'react-i18next';
import Icon from 'ui-kit-v2/icon/icon';

import { ModalComponentContent } from 'components/modal/modal.component';

import './learn-more-modal.styles.scss';

const LearnMoreContentModal = () => {
    const { t } = useTranslation();

    return (
        <ModalComponentContent>
            <section className="learn-more-modal__container">
                <p className="learn-more-modal__title">{t('pages.profile.autoRefill.learnMore.description')}</p>
                <ul className="learn-more-modal__list">
                    <li>{t('pages.profile.autoRefill.learnMore.pointOne')}</li>
                    <li>{t('pages.profile.autoRefill.learnMore.pointTwo')}</li>
                    <li>{t('pages.profile.autoRefill.learnMore.pointThree')}</li>
                    <li>{t('pages.profile.autoRefill.learnMore.pointFour')}</li>
                    <li>{t('pages.profile.autoRefill.learnMore.pointFive')}</li>
                </ul>

                <div className="learn-more-modal__info-box">
                    <Icon icon="information-circle" />
                    <p>{t('pages.profile.autoRefill.learnMore.note')}</p>
                </div>
            </section>
        </ModalComponentContent>
    );
};

export default LearnMoreContentModal;
