import { createSelector } from '@reduxjs/toolkit';

import { accountDefaultAddressZipCodeSelector, accountProfileSelector } from 'state/account/account.selectors';
import { addTransferPrescriptionMemberSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { cartIsBusySelector, cartItemsSelector, cartShippingZipCodeSelector } from 'state/cart/cart.selectors';
import { drugSelector } from 'state/drug/drug.selectors';
import { familyMembersPricingDataSelector } from 'state/family-profile/family-profile.selectors';
import { familyMembersPlansSelector } from 'state/family-profile/family-profile.selectors';
import { RootState } from 'state/store';

import { searchRxNumberInCart } from 'util/cart';
import { isRxOnHold, isRxOrderedInProgress, mapRxPrescriptionCards } from 'util/prescription';

export const MedicineCabinetSelector = (state: RootState) => state.medicineCabinetReducer;

export const medicineCabinetCartZipCodeSelector = createSelector(
    [cartShippingZipCodeSelector, cartIsBusySelector],
    (cartZipCode, cartIsBusy) => {
        return !cartIsBusy ? cartZipCode : null;
    }
);

export const medicineCabinetPrescriptionsSelector = createSelector(
    [MedicineCabinetSelector, cartItemsSelector],
    (medicineCabinet, cartItems) => {
        if (!medicineCabinet.currentPrescriptions || medicineCabinet.currentPrescriptions.length <= 0) return [];

        return medicineCabinet.currentPrescriptions.map((prescription) => {
            const isInCart = searchRxNumberInCart(prescription.rxNumber, cartItems);
            return { ...prescription, ...{ inOrderCart: isInCart } };
        });
    }
);

export const medicineCabinetPrescriptionsCardsSelector = createSelector(
    [
        medicineCabinetPrescriptionsSelector,
        familyMembersPricingDataSelector,
        accountProfileSelector,
        cartItemsSelector,
        medicineCabinetCartZipCodeSelector,
        accountDefaultAddressZipCodeSelector,
        addTransferPrescriptionMemberSelector,
        drugSelector
    ],
    (
        prescriptions,
        familyPricingData,
        profileObject,
        cartItems,
        cartZipCode,
        mainUserZipCode,
        selectedMember,
        { drugDiscountPrices }
    ) => {
        if (!profileObject || prescriptions.length <= 0) return [];

        return mapRxPrescriptionCards(
            prescriptions,
            familyPricingData,
            drugDiscountPrices,
            cartItems,
            selectedMember,
            mainUserZipCode,
            cartZipCode
        );
    }
);

export const medicineShowNewPrescriptionModalSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.showNewPrescriptionModal
);

export const medicineCabinetFilteredRxsForStatusSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        return currentPrescriptions.filter((rx) => !isRxOnHold(rx) && isRxOrderedInProgress(rx));
    }
);

export const medicineCabinetAutoRefillPlanEligibleSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        return currentPrescriptions.some((prescription) => prescription.autorefillPlanEligible);
    }
);

export const medicineCabinetAutoRefillEligibleSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        return currentPrescriptions.some((prescription) => prescription.autoRefillEligible);
    }
);

export const medicineCabinetShowToggleAutoRefillAllSelector = createSelector(
    [medicineCabinetAutoRefillPlanEligibleSelector, medicineCabinetPrescriptionsSelector],
    (autoRefillPlanEligible, currentPrescriptions) => {
        return autoRefillPlanEligible && currentPrescriptions.some((prescription) => prescription.autoRefillEligible);
    }
);

export const medicineCabinetAutoRefillRxsSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        const eligibleRxs = currentPrescriptions.filter((prescription) => prescription.autoRefillEligible);
        return eligibleRxs.map((rx) => rx.rxNumber);
    }
);

export const medicineCabinetAutoRefillToggleAllSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        const eligibleRxs = currentPrescriptions.filter((prescription) => prescription.autoRefillEligible);
        const rxsAutoRefillEnabled = eligibleRxs.filter((rx) => rx.autoRefillEnabled);
        return rxsAutoRefillEnabled.length === eligibleRxs.length;
    }
);

export const medicineCabinetAutoRefillToggleDisabledSelector = createSelector(
    [medicineCabinetAutoRefillPlanEligibleSelector, medicineCabinetPrescriptionsSelector],
    (autoRefillPlanEligible, currentPrescriptions) => {
        return autoRefillPlanEligible && currentPrescriptions.every((prescription) => !prescription.autoRefillEligible);
    }
);

export const medicineCabinetAutoRefillToggleBusySelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.autoRefillToggleBusy
);

export const medicineCabinetPopulatedSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.medicineCabinetLoaded
);

export const medicineCabinetRxLoadedSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.showNewPrescriptionModal.isRxLoaded
);

export const medicineCabinetActiveTabSelector = createSelector(
    [MedicineCabinetSelector, accountProfileSelector],
    (medicineCabinet, profileObject) => medicineCabinet.medicineCabinetActiveTab || profileObject?.epostPatientNum || ''
);

export const medicineCabinetFilterTabSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.medicineCabinetFilterTab || 'all'
);

export const medicineCabinetIsBusySelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.isBusy || false
);

export const medicineCabinetIsLoadingSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.medicineCabinetIsLoading
);

export const medicineCabinetActivePlanAliasSelector = createSelector(
    [medicineCabinetActiveTabSelector, familyMembersPlansSelector],
    (activeDependentTab, familyMembers) => {
        const activeDependent = familyMembers?.filter((member) => {
            return member.value === activeDependentTab;
        })[0];

        return activeDependent?.planAlias;
    }
);

export const medicineCabinetCachedSubStatusesSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.cachedSubStatuses
);

export const medicineCabinetShouldRefreshSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.shouldRefresh
);
