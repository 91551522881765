import { graphql, PageProps } from 'gatsby';
import { ENABLE_MEMBERSHIP, ENABLE_VISIT_HISTORY } from 'gatsby-env-variables';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'ui-kit-v2/button/button';

import TransactionList from 'display-components/payment-history/transaction-list';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { TransactionCardVariant } from 'components/transaction-card/transaction-card.types';

import { mockActiveVisitCards, mockVisitHistoryCards } from 'util/visit-history';

import usePageRedirection from 'hooks/usePageRedirection';

import './visit-history.styles.scss';

const VisitHistory: React.FC<PageProps<GatsbyTypes.VisitHistoryDataQuery>> = ({ data }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('');
    const [visitHistory, setVisitHistory] = useState<any>([]);

    const toggleVisitHistory = () => {
        setVisitHistory(visitHistory.length > 0 ? [] : mockVisitHistoryCards);
    };

    const sections = useMemo(() => {
        const toggleMock = {
            heading: 'Manage Mocks',
            children: (
                <>
                    <ButtonComponent label="Toggle visit history" variant="solid" onClick={toggleVisitHistory} />
                </>
            )
        };
        const activeVisits = {
            heading: t('pages.visitHistory.sections.activeVisits', {
                activeVisits: mockActiveVisitCards.length
            }),
            children: (
                <div className="visit-history-container">
                    <TransactionList
                        variant={TransactionCardVariant.HISTORY}
                        transactions={mockActiveVisitCards}
                        activeTab={activeTab}
                        onNavigate={setActiveTab}
                        showMonth={false}
                    />
                </div>
            ),
            suppressChildrenContainer: true,
            showMemberTabs: true,
            activeTab: activeTab,
            onTabItemChange: setActiveTab
        };

        const visitHistoryList = {
            heading: t('pages.visitHistory.sections.visitHistoryList'),
            children: (
                <div className="visit-history-container">
                    <TransactionList
                        variant={TransactionCardVariant.HISTORY}
                        transactions={visitHistory}
                        activeTab={activeTab}
                        onNavigate={setActiveTab}
                    />
                </div>
            ),
            suppressChildrenContainer: true,
            showMemberTabs: true,
            activeTab: activeTab,
            onTabItemChange: setActiveTab
        };

        return [toggleMock, activeVisits, visitHistoryList];
    }, [t, activeTab, visitHistory]);

    const hasMembershipRedirection = usePageRedirection({
        featureFlag: ENABLE_MEMBERSHIP && ENABLE_VISIT_HISTORY
    });

    if (hasMembershipRedirection) {
        return <></>;
    }

    return (
        <ProfileLayout
            withMenu={false}
            eyebrowText={t('pages.visitHistory.eyebrowText')}
            title={t('pages.visitHistory.title')}
            sections={sections}
        />
    );
};

export default VisitHistory;

export const query = graphql`
    query VisitHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
