/* eslint-disable no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';

import {
    accountHasOutstandingBalance,
    accountProfileMembershipSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { MembershipPlanStatusEnum } from 'state/membership/membership.helpers';
import { RootState } from 'state/store';

export enum MembershipPlanId {
    CARE = 1,
    CARE_PLUS = 2,
    ON_DEMAND = 3,
    NONE = 0
}

export const membershipSelector = (state: RootState) => state.membershipReducer;

export const membershipDependentToAddSelector = createSelector(
    membershipSelector,
    (membership) => membership.dependentToAdd
);

export const membershipDetailSelector = createSelector(
    membershipSelector,
    (membership) => membership.membershipDetails
);

export const membershipPlansDetailsSelector = createSelector(
    membershipSelector,
    (membership) => membership.plansDetails
);

export const membershipIsLoadingDetailsSelector = createSelector(
    membershipSelector,
    (membership) => membership.isLoadingDetails
);

export const membershipIsInviteeSelector = createSelector(membershipSelector, (membership) => {
    if (!membership.membershipDetails) return false;
    return !membership.membershipDetails?.isPlanOwner;
});

export const membershipIsOwnerSelector = createSelector(membershipSelector, (membership) => {
    if (!membership.membershipDetails) return false;
    return membership.membershipDetails?.isPlanOwner === true;
});

export const membershipPlanTypeSelector = createSelector(membershipSelector, (membership) => {
    if (!membership.membershipDetails) return 0;
    return membership.membershipDetails.planId;
});

export const membershipIsOnDemandSelector = createSelector(membershipSelector, (membership) => {
    if (!membership.membershipDetails) return false;
    return (
        membership.membershipDetails.planId === MembershipPlanId.ON_DEMAND &&
        ['on demand visit'].includes(membership.membershipDetails.planName.toLowerCase())
    );
});

export const membershipIsCarePlanSelector = createSelector(membershipSelector, (membership) => {
    if (!membership.membershipDetails) return false;
    return membership.membershipDetails.planId === MembershipPlanId.CARE;
});

export const membershipIsCarePlusPlanSelector = createSelector(membershipSelector, (membership) => {
    if (!membership.membershipDetails) return false;
    return membership.membershipDetails.planId === MembershipPlanId.CARE_PLUS;
});

export const membershipHasPendingInviteeSelector = createSelector(membershipSelector, (membership) => {
    if (!membership.membershipDetails) return false;
    return membership.membershipDetails.members.filter((f) => ['pending', 'expired'].includes(f.status));
});

export const membershipRequestVisitMemberIdSelector = createSelector(
    membershipSelector,
    (membership) => membership?.requestVisitDetails?.memberId
);

export const membershipIsLoadingAddMemberSelector = createSelector(
    membershipSelector,
    (membership) => membership.isLoadingAddMember
);

export const membershipIsLoadingPaymentMethodsSelector = createSelector(
    membershipSelector,
    (membership) => membership.isLoadingPaymentMethods
);

export const membershipIsLoadingSendInviteSelector = createSelector(
    membershipSelector,
    (membership) => membership.isLoadingSendInvite
);

export const membershipIsTelemedicineAvailableSelector = createSelector(membershipSelector, (membership) => {
    if (membership.membershipDetails?.freeTelehealthVisitsRemaining === 0) {
        return false;
    } else {
        return true;
    }
});

export const membershipNewMemberToAddSelector = createSelector(
    membershipSelector,
    (membership) => membership.memberToAdd
);

export const membershipPreviousVisitsLinkSelector = createSelector(
    membershipSelector,
    (membership) => membership.previousVisitLink
);

export const membershipBirdiSelectFomularySelector = createSelector(
    membershipSelector,
    (membership) => membership.membershipBirdiSelectFormulary
);

export const membershipInviteeMembersSelector = createSelector(membershipSelector, (membership) => {
    const { membershipDetails } = membership;
    if (membershipDetails?.isPlanOwner === true) {
        return null;
    }

    return membershipDetails?.members.filter((f) => f.epostPatientNum !== membershipDetails.epostPatientNum);
});

export const membershipTelehealthVisitsSelector = createSelector(
    membershipSelector,
    (membership) => membership.telehealthVisits
);

export const membershipIsLoadingVisitsSelector = createSelector(
    membershipSelector,
    (membership) => membership.isLoadingPreviousVisitsLink
);

export const membershipHasTelehealthVisitsSelector = createSelector(membershipSelector, (membership) => {
    const hasTelehealthVisitsForUser =
        membership.telehealthVisits && membership.telehealthVisits?.TelehealthVisit?.length > 0;
    const hasTelehealthVisitsForMember =
        membership.telehealthVisits &&
        membership.telehealthVisits.Members.map((member) => member.TelehealthVisit).flat().length > 0;

    return hasTelehealthVisitsForUser || hasTelehealthVisitsForMember;
});

export const membershipIsPlanUpgradableSelector = createSelector(
    membershipSelector,
    (membership) => membership.membershipDetails?.isPlanUpgradable
);

export const membershipUpgradePlanPaymentModelSelector = createSelector(membershipSelector, (membership) => {
    return membership.paymentModel;
});

export const membershipUpgradePlanPricingDetailsSelector = createSelector(membershipSelector, (membership) => {
    return membership.upgradePricingDetails;
});

export const membershipSelectedPlanToUpgradeSelector = createSelector(membershipSelector, (membership) => {
    return membership.selectedPlanToUpgrade;
});

export const membershipIsLoadingUpgradePlanSelector = createSelector(
    membershipSelector,
    (membership) => membership.isLoadingUpgradePlan
);

export const membershipUpgradedPlanSelector = createSelector(membershipSelector, (membership) => {
    return membership.upgradedPlan;
});

export const membershipIsValidPromoCodeSelector = createSelector(membershipSelector, (membership) => {
    return membership.isValidPromoCode;
});

export const membershipRequiresPaymentSelector = createSelector(
    [accountHasOutstandingBalance, membershipSelector],
    (accountHasOutstandingBalance, membership) => {
        return (
            accountHasOutstandingBalance ||
            (membership &&
                membership.membershipDetails &&
                [MembershipPlanStatusEnum.PaymentDue, MembershipPlanStatusEnum.OnHold].includes(
                    membership.membershipDetails.planStatus
                ))
        );
    }
);

export const membershipInvitationsSelector = createSelector(membershipSelector, (membership) => {
    return membership.invitations;
});

export const isMembershipAvailableSelector = createSelector(
    [accountProfileSelector, accountProfileMembershipSelector],
    (profileObject, profileMembership) => {
        const { hasMembership } = profileMembership;
        return ENABLE_MEMBERSHIP && (!profileObject || (profileObject && hasMembership));
    }
);

export const membershipMemberToInviteSelector = createSelector(membershipSelector, (membership) => {
    return membership.memberToInvite;
});

export const membershipGenerateScheduleLinkSelector = createSelector(
    membershipSelector,
    (membership) => membership.generatedLink
);
