import { Col, Container, Row } from 'react-bootstrap';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { formatPrice } from 'schema/price.schema';

import './cart-total.style.scss';
import { ReviewCartTotalProps } from './types';

export const CartTotal = ({
    t,
    hasUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    accountHasInsurance,
    fullCart
}: ReviewCartTotalProps) => {
    return (
        <Container fluid className="cart-total d-flex flex-column mt-4 mb-4">
            <Row>
                <Col sm={12} lg={12}>
                    <div className="cart-total--title d-flex justify-content-between">
                        <div>{t('pages.cart.subTotal')}:</div>
                        <div>{formatPrice(initialOrderPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--title d-flex justify-content-between mt-3">
                        <div>{t('pages.cart.shipping')}</div>
                        <div>{formatPrice(currentShippingPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-between mt-3 pt-4">
                        <div>
                            {fullCart?.some((cart) => cart.showEstimatedTotal)
                                ? t('pages.cart.estTotal')
                                : t('pages.cart.total')}
                        </div>
                        <div>{formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}</div>
                    </div>
                </Col>
            </Row>
            {hasUnknownPrice && accountHasInsurance && (
                <Row className="mt-4">
                    <ToastBox variant="info">
                        <div className="cart-total--footer-info">{t('pages.cart.totalDisclaimer')}</div>
                    </ToastBox>
                </Row>
            )}
        </Container>
    );
};
