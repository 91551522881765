// General
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

// Ui - Kit
import PageSection from 'ui-kit/page-section/page-section';

// Components
import PageLayout from 'components/layouts/page/page.layout';

import { useOnboardingRedirection } from 'hooks/useOnboardingRedirection';

const OnboardingPage = () => {
    const { t } = useTranslation();

    useOnboardingRedirection();

    return (
        <PageLayout metaData={{ nodeTitle: t('Loading profile...') }}>
            <PageSection className="text-center loading-profile">
                <div className="loading-profile__container">
                    <h4 className="loading-profile__text">{t('Loading profile...')}</h4>
                </div>
            </PageSection>
        </PageLayout>
    );
};

export default OnboardingPage;

export const query = graphql`
    query AuthPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
