import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMessage from 'ui-kit/loading-message/loading-message';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import PaymentMethods from 'components/payment-methods/payment-methods.component';

import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountIsLoadingPaymentMethodsSelector,
    accountIsMembershipSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { medicineCabinetAutoRefillEligibleSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

import './payment.scss';

const PaymentSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const allPaymentData = useSelector(accountCreditCardsSelector);
    const displayAutoRefillMessage = useSelector(medicineCabinetAutoRefillEligibleSelector);
    const profileObject = useSelector(accountProfileSelector) as ProfileObjectPayload;
    const hasMembership = useSelector(accountIsMembershipSelector);
    const isLoadingPaymentMethods = useSelector(accountIsLoadingPaymentMethodsSelector);

    useEffect(() => {
        if (!profileObject) return;
        dispatch(accountGetAllCreditCardsRoutine.trigger());
    }, [dispatch, profileObject]);

    const showLoading = allPaymentData === undefined;

    // DRX-3020: Add check to display auto-refill message for BRD02 accounts
    const shouldDisplayAutoRefillMessage = displayAutoRefillMessage && profileObject?.patientPlanAutoRefillFlag;

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.payment.eyebrowText`)}
            title={t(`pages.profile.payment.title`)}
            heading={t(`pages.profile.payment.heading`)}
        >
            <Container fluid className="profile__payment">
                {shouldDisplayAutoRefillMessage && <div>{t('pages.profile.payment.autoRefillSubhead')}</div>}
                <LoadingMessage isVisible={showLoading} text={t(`pages.profile.payment.loading`)} />
                <div className="pt-4">
                    {allPaymentData && (
                        <PaymentMethods
                            showLabel
                            showSetDefaultLink
                            hasMultipleColumns
                            withNewPaymentButton
                            paymentData={allPaymentData}
                            showRemoveCardLink={allPaymentData.length > 1}
                            hasMembership={hasMembership}
                            isBusy={isLoadingPaymentMethods}
                        />
                    )}
                </div>
            </Container>
        </ProfileLayout>
    );
};

export default PaymentSection;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
