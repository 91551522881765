import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useState } from 'react';

import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';

import useBirdiPrice from 'hooks/useBirdiPrice';

import { DrugPriceInfoCardProps } from './drug-price-info-card.props';
import './drug-price-info-card.style.scss';

const DrugPriceInfoCard = ({ prescription, price, isBirdiSelect, isBRD02 }: DrugPriceInfoCardProps): ReactElement => {
    const [dismissed, setDismissed] = useState<boolean>(false);
    const { t } = useTranslation();
    const { debugTransferRxPrice } = useBirdiPrice();

    const isPriceUndefinedOrZero = ['$0.00', 'undefined', undefined, 0, 0.0, '0', '0.00', null, 'null', 'NA'].includes(
        price
    );

    const defineTitle = () => {
        if (isPriceUndefinedOrZero) {
            return t('components.drugPriceInfoCard.titles.priceUnavailable');
        }
        if (isBirdiSelect && isBRD02) {
            return t('components.drugPriceInfoCard.titles.birdiSelect');
        }
        return t('components.drugPriceInfoCard.titles.cashPrice');
    };

    return (
        <>
            <div className="drug-price-info-card">
                {dismissed ? (
                    <div className="view-price-information-link">
                        <button type="button" className="btn btn-text" onClick={() => setDismissed(false)}>
                            <div className="btn-label">{t('components.drugPriceInfoCard.viewPriceInfoLink')}</div>
                        </button>
                    </div>
                ) : (
                    <div className="info-card-container">
                        <div className="header">
                            <div className="title">{defineTitle()}</div>
                            <button type="button" className="close" onClick={() => setDismissed(true)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M19 5.00003L5 19M5 5.00003L19 19"
                                        stroke="#03A4DC"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div
                            className="drug-details-container"
                            onClick={() => {
                                debugTransferRxPrice(prescription);
                            }}
                            role="presentation"
                        >
                            <div className="drug-name-and-prescription">
                                <div className="drug-name">
                                    {prescription?.drugName}
                                    {isBRD02 && isBirdiSelect && !isPriceUndefinedOrZero && (
                                        <div className="drug-name-badge">
                                            <PlanBadge variant="birdi-select-blue" />
                                        </div>
                                    )}
                                </div>
                                <div className="prescription-details">
                                    <div className="quantity">
                                        {t('components.drugPriceInfoCard.labels.qty')} {prescription?.qty}
                                    </div>
                                    <div className="strength">{prescription?.strength}</div>
                                    {prescription?.strengthWithPackage && (
                                        <div className="package">{prescription?.strengthWithPackage}</div>
                                    )}
                                    <div className="form">{prescription?.drugForm}</div>
                                </div>
                            </div>

                            <div className="price">
                                {!isPriceUndefinedOrZero ? (
                                    <>
                                        {price}
                                        <sup>*</sup>
                                    </>
                                ) : (
                                    <>
                                        <span>{t('components.drugPriceInfoCard.priceUnavailable')}</span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="foot-notes">
                            {!isPriceUndefinedOrZero && (
                                <>
                                    <sup>*</sup> {t('components.drugPriceInfoCard.footNotes')}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DrugPriceInfoCard;
