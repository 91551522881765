/* eslint-disable no-unused-vars */
enum RaceID {
    ASIAN = 6,
    UNKNOWN = 9,
    AMERICAN_INDIAN_ALASKA_NATIVE = 10,
    NATIVE_ISLANDER = 11,
    WHITE = 12,
    BLACK_AFRICAN_AMERICAN = 13,
    HISPANIC_LATINO = 14,
    TWO_OR_MORE = 15
}

export type Race = {
    raceId: RaceID;
    raceDesc: string;
    active: string;
};

export type FormattedRaces = {
    value: number;
    label: string;
    key: string;
};

export const filterAndFormatRaces = (races: Race[]): FormattedRaces[] => {
    return races
        .filter((item) => !['other', 'unknown'].includes(item.raceDesc.toLowerCase()))
        .map((item) => ({
            key: `race-${item.raceId}`,
            label: item.raceDesc,
            value: item.raceId
        }))
        .sort((a, b) => {
            if (a.value === RaceID.TWO_OR_MORE) return 1;
            if (b.value === RaceID.TWO_OR_MORE) return -1;
            return a.label.localeCompare(b.label);
        });
};
