import { useLocation } from '@reach/router';
import { useMatch } from '@reach/router';
import classNames from 'classnames';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
// Bootstrap
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';

// Components
import BirdiModal from 'components/birdi-modal/birdi-modal';
import CookieBanner from 'components/cookie-banner/cookie-banner.component';
import ModalComponent from 'components/modal/modal.component';
import Navigation from 'components/navigation/navigation.component';
import SEO from 'components/seo/seo.component';
import { SEOProps } from 'components/seo/seo.props';

// State
import { accountIsLoggedInSelector, accountProfileSelector } from 'state/account/account.selectors';

// Util
import { InitOptimize } from 'util/google_optimize/optimize_helper';

// Hoc
import withBackgroundImage from 'hoc/withBackgroundImage';
import withBasicAuth from 'hoc/withBasicAuth';
import withMaintenanceMode from 'hoc/withMaintenanceMode';
import withSecurity from 'hoc/withSecurity';
import withSessionExpiration from 'hoc/withSessionExpiration';

import './workflow.layout.scss';

const WorkflowLayout = ({
    children,
    className,
    metaData,
    anonymousOnly,
    hideNavLinks = false
}: {
    children: any;
    className?: string;
    metaData?: SEOProps;
    anonymousOnly?: boolean;
    hideNavLinks?: boolean;
}) => {
    const data = AlertBannerAndBlocksQuery();
    const location = useMatch('/secure/*');
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);
    const isSecurePage = location !== null;
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const profileObject = useSelector(accountProfileSelector);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation === node.path?.alias?.toString()
    );

    useEffect(() => {
        InitOptimize();
    }, []);

    const classes = classNames('page', className);

    if (anonymousOnly && isLoggedIn && profileObject) {
        navigate('/secure/medicine-cabinet');
        return null;
    }

    return (
        <div className={`workflowLayout ${classes}`}>
            <SEO
                nodeTitle={metaData?.nodeTitle}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                applicationPageMetaData={applicationPageParagraphs[0]?.field_meta_tags}
            >
                {children}
            </SEO>
            <Navigation isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} showMainMenu={!hideNavLinks} />
            <Container fluid={'xl'} className="px-0 px-xl-3">
                <Row className="workflowContentContainer no-gutters">
                    <Col xs={12} md={{ span: 8, offset: 2 }}>
                        {children}
                    </Col>
                </Row>
            </Container>
            <BirdiModal />
            <ModalComponent />
            <CookieBanner />
        </div>
    );
};

export default withMaintenanceMode(
    withBasicAuth(withSessionExpiration(withSecurity(withBackgroundImage(WorkflowLayout, 'workflowPageBackground'))))
);

export const AlertBannerAndBlocksQuery = () => {
    return useStaticQuery<any>(graphql`
        {
            allBlockContentAlertBanner(
                sort: { fields: changed, order: DESC }
                filter: { field_alert_active: { eq: true } }
            ) {
                nodes {
                    field_alert_text
                    field_alert_priority_level
                    field_alert_permanent
                    field_alert_link {
                        title
                        uri
                    }
                }
            }
            allNodeApplicationPage {
                nodes {
                    path {
                        langcode
                        alias
                    }
                    field_meta_tags {
                        title
                        description
                        abstract
                        keywords
                    }
                }
            }
        }
    `);
};
