import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useEffect } from 'react';

import WelcomeCardsList, { WelcomeCardListProps } from 'display-components/welcome-card-list';

import { ClickableCardProps } from 'components/clickable-card/clickable-card.props';
// UI Kit & Components
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import './index.style.scss';

const parseWelcomeCards = (
    cards: GatsbyTypes.GetStartedDataQuery['allBlockContentWelcomeCard']['nodes'],
    isMembershipEnabled: boolean
): WelcomeCardListProps['cards'] => {
    const getIconType = (iconType: string): ClickableCardProps['icon'] => {
        const iconMap: { [key: string]: string } = {
            membership_icon: 'birdi-price',
            mail_order_icon: 'mail-order',
            assist_others_icon: 'birdi-cash',
            pets_icon: 'pets'
        };
        return iconMap[iconType] as ClickableCardProps['icon'];
    };

    const sortFields = (
        fields: GatsbyTypes.GetStartedDataQuery['allBlockContentWelcomeCard']['nodes']
    ): GatsbyTypes.GetStartedDataQuery['allBlockContentWelcomeCard']['nodes'] => {
        return fields.slice().sort((a, b) => {
            const orderA = a.field_display_order !== null ? parseInt(a.field_display_order as string, 10) : Infinity;
            const orderB = b.field_display_order !== null ? parseInt(b.field_display_order as string, 10) : Infinity;
            return orderA - orderB;
        });
    };

    const filterByFeatureFlag = (
        field: Pick<GatsbyTypes.block_content__welcome_card, 'field_is_membership_card'>
    ): boolean => {
        const isMembershipCard = !!field.field_is_membership_card;

        if (!isMembershipEnabled) {
            return !isMembershipCard;
        }

        return true;
    };

    const filterByActive = (field: Pick<GatsbyTypes.block_content__welcome_card, 'field_is_active_card'>): boolean => {
        if (field.field_is_active_card === undefined || field.field_is_active_card === null) {
            return true;
        }
        return !!field.field_is_active_card;
    };

    if (!cards) return [];

    return sortFields(cards)
        .filter(filterByActive)
        .filter(filterByFeatureFlag)
        .map((card) => {
            return {
                to: card.field_redirection_to?.[0]?.uri?.replace('internal:', '') || '',
                title: card.field_title as string,
                description: card.field_description as string,
                footNote: card.field_foot_note as string,
                icon: getIconType(card.field_icon_type as string)
            };
        });
};

const GetStarted = ({ location, data }: { location: any; data: GatsbyTypes.GetStartedDataQuery }): ReactElement => {
    const { t } = useTranslation();
    const welcomeCards = parseWelcomeCards(data?.allBlockContentWelcomeCard?.nodes, ENABLE_MEMBERSHIP);

    // Redirects immediately if there is only one card with a valid link.
    useEffect(() => {
        if (welcomeCards?.length === 1 && welcomeCards[0]?.to) {
            navigate(welcomeCards[0].to);
        }
    }, [welcomeCards]);

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('registration.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                className="get-started-receive-hallway"
                currentFlow={'membership-registration'}
                eyebrowText={t('membership.getStarted.receiveHallway.eyebrowText')}
                title={t('membership.getStarted.receiveHallway.title')}
            >
                <div className="get-started-receive-hallway-subtitle">
                    <p>{t('membership.getStarted.receiveHallway.subtitle')}</p>
                </div>
                <WelcomeCardsList cards={welcomeCards} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};
export default GetStarted;

export const query = graphql`
    query GetStartedData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentWelcomeCard {
            nodes {
                field_description
                field_display_order
                field_foot_note
                field_icon_type
                field_title
                field_redirection_to {
                    uri
                }
                field_is_membership_card
                field_is_active_card
            }
        }
    }
`;
