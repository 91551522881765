import classNames from 'classnames';
import { TFunction } from 'i18next';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'ui-kit-v2/icon/icon';
import RadioButtonComponent from 'ui-kit-v2/radio-button/radio-button';

// Ui-kit
import BorderedContainer from 'ui-kit/bordered-container/bordered-container';
import IconBackgroundColor from 'ui-kit/icon-background-color';

import { ContactMethods } from 'enums/contact-methods';

// Utils
import { maskEmail, maskPhoneNumber } from 'util/string';

// Styles
import './contact-info-box.style.scss';

// Interface for the main component
interface ContactInfoBoxProps {
    id: string;
    contactType: ContactMethods;
    contactData: string;
    isChecked?: boolean;
    hasError?: boolean;
    methodFailed?: boolean;
    className?: string;
    onChangeHandler: () => void;
}

const ContactInfoBoxIcon = ({
    contactType,
    isDisabled
}: {
    contactType: ContactMethods;
    isDisabled: boolean;
}): ReactElement => {
    switch (contactType) {
        case ContactMethods.Email:
            return (
                <IconBackgroundColor
                    variant={isDisabled ? 'smoke' : 'info'}
                    className="contact-info-box__icon-container"
                >
                    <Icon
                        icon="email"
                        height="1.5rem"
                        width="1.5rem"
                        viewBox="0 0 24 24"
                        color={isDisabled ? 'pewter-blue' : undefined}
                    />
                </IconBackgroundColor>
            );

        case ContactMethods.Sms:
            return (
                <IconBackgroundColor
                    variant={isDisabled ? 'smoke' : 'info'}
                    className="contact-info-box__icon-container"
                >
                    <Icon
                        icon="phone"
                        height="1.5rem"
                        width="1.5rem"
                        viewBox="0 0 24 24"
                        color={isDisabled ? 'pewter-blue' : undefined}
                    />
                </IconBackgroundColor>
            );

        default:
            return <></>;
    }
};

const getMaskedData = (
    contactData: string | null | undefined,
    contactType: ContactMethods,
    t: TFunction<'translation', undefined>
): string => {
    if (!contactData) {
        if (contactType === ContactMethods.Email) {
            return t('components.contactInfoBox.noEmail');
        }
        return t('components.contactInfoBox.noEmail');
    }

    if (contactType === ContactMethods.Email) {
        return maskEmail(contactData);
    } else {
        return maskPhoneNumber(contactData);
    }
};

// Main component
const ContactInfoBox = ({
    contactType,
    contactData,
    isChecked,
    hasError,
    className,
    methodFailed,
    onChangeHandler
}: ContactInfoBoxProps) => {
    const { t } = useTranslation();

    const containerClasses = classNames(
        'contact-info-box',
        className,
        { error: hasError },
        { selected: isChecked },
        { unavailable: methodFailed && className !== 'disabled' }
    );

    const isBoxDisabled = methodFailed || className === 'disabled';

    return (
        <BorderedContainer className={containerClasses}>
            <ContactInfoBoxIcon contactType={contactType} isDisabled={isBoxDisabled} />

            <div className="contact-info-box__content-container">
                <div className="label">{t(`components.contactInfoBox.${contactType}`)}</div>

                <div className="value">
                    {methodFailed
                        ? t('components.contactInfoBox.methodUnavialabe')
                        : getMaskedData(contactData, contactType, t)}
                </div>
            </div>

            <RadioButtonComponent
                key={`resend-invitation-as-${contactType}`}
                id={`resend-invitation-as-${contactType}`}
                isSelected={isChecked}
                onChange={onChangeHandler}
                value={contactType}
                variation="in-card"
                disabled={isBoxDisabled}
            />
        </BorderedContainer>
    );
};

export default ContactInfoBox;
