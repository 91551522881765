import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BadgeMembership from 'ui-kit/bagde/badge-membership';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';

import MembershipManagementBadgeStatus from 'components/membership-management-badge/membership-management-badge.component';
import MemberShipUsageProgressBar, {
    MemberShipUsageProgressBarDetails
} from 'components/membership-usage-progress-bar/membership-usage-progress-bar.component';

import { definePlanStatus } from 'state/membership/membership.helpers';
import { membershipIsOnDemandSelector } from 'state/membership/membership.selector';

import { MembershipPlanStatusType } from 'types/membership';

import { MembershipPlanName } from 'enums/membership-plans';

import './membership-plan.style.scss';

export interface MembershipPlanDetails {
    planName: string;
    planStatus: MembershipPlanStatusType;
    prescriptionsMade: number;
    prescriptionsLimit: number;
    virtualVisitsMade: number;
    virtualVisitsLimit: number;
}

const MembershipPlan = ({
    planName,
    planStatus,
    prescriptionsMade,
    prescriptionsLimit,
    virtualVisitsMade,
    virtualVisitsLimit
}: MembershipPlanDetails) => {
    const planDetails = {
        planStatus,
        formattedPlanStatus: definePlanStatus(planStatus)
    };
    const { t } = useTranslation();
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);

    const prescirptionsUsagePayload: MemberShipUsageProgressBarDetails = {
        phrase: t('pages.membership.pages.dashboard.labels.prescriptions'),
        limit: prescriptionsLimit,
        usage: prescriptionsMade
    };

    const virtualVisitsPayload: MemberShipUsageProgressBarDetails = {
        phrase: t('pages.membership.pages.dashboard.labels.visits'),
        limit: virtualVisitsLimit,
        usage: virtualVisitsMade
    };

    return (
        <div className="membership-plan">
            <div className="container px-0">
                <Row className="membership-plan-details-card">
                    <div className="membership-plan-details-card__main-section">
                        <div className="membership-plan-details-card__main-section_membership-first-card">
                            <div className="plan-details">
                                <p>{t('pages.membership.pages.dashboard.labels.plan')}</p>
                                <div className="membership-plan-details-card__main-section__membership-details">
                                    {planName ? (
                                        <>
                                            <BadgeMembership variant={planName.toLowerCase()} />
                                            {planName.toLowerCase() !== MembershipPlanName.ON_DEMAND && (
                                                <MembershipManagementBadgeStatus {...planDetails} />
                                            )}
                                        </>
                                    ) : (
                                        <SpinnerInline />
                                    )}
                                </div>
                            </div>
                            <div className="plan-management-menu">
                                {planName ? (
                                    <>
                                        <ul className={planName.toLowerCase().replaceAll(' ', '-')}>
                                            <li>
                                                <a href="/secure/profile/membership">
                                                    {t('pages.membership.pages.dashboard.links.membership')}
                                                </a>
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                    {planName && !isOnDemandPlan && (
                        <div className="membership-plan-details-card__metrics">
                            <MemberShipUsageProgressBar {...prescirptionsUsagePayload} />
                            <MemberShipUsageProgressBar {...virtualVisitsPayload} />
                        </div>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default MembershipPlan;
