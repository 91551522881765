const buildQueryParams = <T extends Record<string, any>>(params: T): string => {
    return Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
        .join('&');
};

const isNullOrEmptyObject = (variable: any): boolean => {
    if (variable === null || variable === undefined) {
        return true;
    }

    if (typeof variable === 'string') {
        try {
            const parsedVariable = JSON.parse(variable);
            if (typeof parsedVariable === 'object' && Object.keys(parsedVariable).length === 0) {
                return true;
            }
        } catch (e) {
            return false;
        }
    }

    if (typeof variable === 'object' && Object.keys(variable).length === 0) {
        return true;
    }

    return false;
};

const isDeepEqual = (object1: any, object2: any) => {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) return false;

    for (const key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        const isObjects = isObject(value1) && isObject(value2);

        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false;
        }
    }
    return true;
};

const isObject = (object: object) => {
    return object != null && typeof object === 'object';
};

export { isDeepEqual, buildQueryParams, isNullOrEmptyObject };
