import { Col, Container, Row } from 'react-bootstrap';

import { formatPrice } from 'schema/price.schema';

import { CartOrderedTotalProps } from 'types/easy-refill';

export const CartOrderedTotal = ({ t, estimatedOrderPrice, shippingPrice, fullCart }: CartOrderedTotalProps) => {
    return (
        <Container fluid className="cart-total d-flex flex-column">
            <Row>
                <Col>
                    <div className="cart-total--subTotal d-flex justify-content-end">
                        <h3 className="cart-total--title mt-4">
                            {t('pages.cart.subTotal')}
                            <span className="cart-total--price-container">{formatPrice(estimatedOrderPrice)}</span>
                        </h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--shipping d-flex justify-content-end">
                        <h3 className="cart-total--title">
                            {t('pages.cart.shippingTotal')}
                            <span className="cart-total--price-container">{formatPrice(shippingPrice)}</span>
                        </h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-end mt-3">
                        <h3 className="cart-total--title font-weight-bold mt-3">
                            {fullCart?.some((cart) => cart.showEstimatedTotal)
                                ? t('pages.cart.estTotal')
                                : t('pages.cart.total')}
                            <span className="cart-total--price-container">
                                {formatPrice(String(Number(estimatedOrderPrice) + Number(shippingPrice)))}
                            </span>
                        </h3>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
