import { PharmacySearchItem } from 'types/pharmacy';

export const capitalizeFirstLetter = (s: string): string => {
    if (s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
        return '';
    }
};

/** capitalize first letter of each word of the string */
export const convertToTitleCase = (s?: string): string => {
    if (s) {
        const splitStr = s.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        // Directly return the joined string
        return splitStr.join(' ');
    } else {
        return '';
    }
};

type PhoneNumberFormat = 'dashes' | 'parentheses';

/**
 * Formats a phone number string into either 'dashes' (XXX-XXX-XXXX) or 'parentheses' ((XXX) XXX-XXXX) format.
 *
 * @param {string | null} phoneNumber - The phone number to format.
 * @param {PhoneNumberFormat} [formatType='dashes'] - The format type, 'dashes' for XXX-XXX-XXXX or 'parentheses' for (XXX) XXX-XXXX.
 * @returns {string} - The formatted phone number or an empty string if the input is null or invalid.
 */
export const formatPhoneNumber = (phoneNumber?: string, formatType: PhoneNumberFormat = 'dashes'): string => {
    if (!phoneNumber) {
        return '';
    }

    // Remove all non-digit characters
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Ensure we are working with the last 10 digits
    const phoneNumberValue = cleanedPhoneNumber.slice(-10);

    if (phoneNumberValue.length < 10) {
        return phoneNumber; // Return the current phone without formmatig
    }

    // Format the phone number based on the specified format type
    if (formatType === 'dashes') {
        // Format as XXX-XXX-XXXX
        return `${phoneNumberValue.slice(0, 3)}-${phoneNumberValue.slice(3, 6)}-${phoneNumberValue.slice(6)}`;
    } else if (formatType === 'parentheses') {
        // Format as (XXX) XXX-XXXX
        return `(${phoneNumberValue.slice(0, 3)}) ${phoneNumberValue.slice(3, 6)}-${phoneNumberValue.slice(6)}`;
    }

    return '';
};

export const CSSstring = (styles: string): object | undefined => {
    try {
        const css_json = `{"${styles.replace(/; /g, '", "').replace(/: /g, '": "').replace(';', '')}"}`;
        const obj = JSON.parse(css_json);
        const keyValues = Object.keys(obj).map((key) => {
            const camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
            return { [camelCased]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    } catch (e) {
        return undefined;
    }
};

export const buildPharmacyAddressString = (pharmacy: PharmacySearchItem): string => {
    const { PhysicalLocationZipCode: originalZipCode } = pharmacy;

    // some zip codes come in format "123456789", convert to "12345-6789"
    const zip5 = originalZipCode.length > 5 ? originalZipCode.slice(0, 5) : originalZipCode;
    const zip4 = originalZipCode.length > 5 ? originalZipCode.slice(-4) : '';
    const zipCode = zip4 ? `${zip5}-${zip4}` : zip5;

    const addressPieces = [
        pharmacy.PhysicalLocationAddress1,
        pharmacy.PhysicalLocationAddress2,
        pharmacy.PhysicalLocationCity,
        `${pharmacy.PhysicalLocationStateCode} ${zipCode}`
    ];

    return addressPieces.filter((addressPiece) => !!addressPiece).join(', ');
};

export const padStartOfString = (stringToPad: string, padString: string, numberOfPadding: number) => {
    return stringToPad.toString().padStart(numberOfPadding, padString);
};

export const replaceStringWith = (stringToSearch: string, searchChar: RegExp, replaceChar: string) => {
    if (!stringToSearch) return '';
    return stringToSearch.replace(searchChar, replaceChar);
};

export const maskEmail = (email: string): string => {
    const emailParts = email.toLowerCase().split('@');
    return `${emailParts[0].slice(0, 3)}****@${emailParts[1]}`;
};

export const maskPhoneNumber = (phoneNumber: string): string => {
    const allNumbers = phoneNumber.replace(/\D/g, '');
    return `(***) ***-${allNumbers.slice(allNumbers.length - 4)}`;
};

export const formatBirthDate = (year: string, month: string, day: string) => {
    return `${padStartOfString(month, '0', 2)}/${padStartOfString(day, '0', 2)}/${year}`;
};

export const removeHtmlTagsFromString = (val: string) => {
    return val.replace(/(<([^>]+)>)/gi, '');
};

/**
 * Utility function that splits the text by commas and removes any leading or trailing spaces
 * @param text Plain text separeted by commas
 * @returns An Array of strings
 */
export function stringToArray(text: string): string[] {
    if (text == null) return [];
    const strArray: string[] = text.split(',').map((str) => str.trim());
    return strArray;
}
export const removeChars = (phoneStr: string) => phoneStr.replace(/[()-\s]/g, '');

export const removeLastSpace = (str: string): string => {
    return str.replace(/\s+$/, '');
};

/**
 * Tests if a given string matches a wildcard pattern.
 *
 * @param {string} str - The input string to test against the pattern.
 * @param {string} rule - The wildcard pattern where '*' can match any sequence of characters.
 * @returns {boolean} - Returns true if the input string matches the pattern, otherwise false.
 */
export const matchStringWithWildCard = (str: string, rule: string) => {
    const escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
};

/**
 * Tests if any of the strings in the given array match the input string with wildcard pattern.
 *
 * @param {string[]} stringsWithWildCards - An array of strings with wildcard patterns where '*' can match any sequence of characters.
 * @param {string} string - The input string to test against the patterns.
 * @returns {boolean | undefined} - Returns true if any of the strings in the array match the input string, otherwise undefined.
 */
export const matchArrayItemsWithWildCard = (stringsWithWildCards: string[], string: string): boolean | undefined => {
    return !!stringsWithWildCards.find((stringsWithWildCard) => {
        return matchStringWithWildCard(string, stringsWithWildCard);
    });
};

export const transformTextToId = (text: string): string => {
    if (/^[a-z]+([A-Z][a-z0-9]*)*$/.test(text)) {
        return text;
    }

    return text
        .replace(/[-_\s]+(.)?/g, (_, char) => (char ? char.toUpperCase() : ''))
        .replace(/^\w/, (char) => char.toLowerCase())
        .replace(/[^a-zA-Z0-9]/g, '');
};

// DRX-1658: These two functions were necessary to treat data in the frontend.
// We need to define whether the returned data will change or continue to be
// treated in the frontend.

export function lowercaseAndCapitalize(item: string): string {
    try {
        const lowercaseStr = item.toLowerCase();
        const words = lowercaseStr.split(' ');
        for (let i = 0; i < words.length; i++) {
            if (words[i] !== '') words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    } catch (error) {
        return item;
    }
}

export function ellipsify(str: string, maxCharacthers = 11): string {
    if (str?.length > maxCharacthers) {
        return str.substring(0, maxCharacthers) + '...';
    } else {
        return str;
    }
}