import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { drugDescriptionRoutine, drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { DiscountLookupValues, DrugDescriptionObjectPayload } from 'state/drug/drug.services';

import { formatPrice } from 'schema/price.schema';

import { PrescriptionCardProps, PrescriptionTypes, RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';
import { RX_AVAILABLE_FLOWS } from 'enums/prescription';
import { PRICING_API_LOCATION, PRICING_UNAUTH_AREA } from 'enums/pricing';

import { findDrugInHistory, getDrugPricingPlanAlias, getDrugPricingZipCode } from 'util/drug';
import { getRxDrugCode, isRxCardsListEqual } from 'util/prescription';

import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

interface getPriceParameters {
    values: Partial<PrescriptionTypes>;
    actions?: FormikHelpers<Partial<PrescriptionTypes>>;
    handlePriceSuccess?: (...args: any[]) => void;
    handleDescriptionSuccess?: (...args: any[]) => void;
    handleIsBirdiSelect?: (...args: any[]) => void;
    handleIsBrd02?: (...args: any[]) => void;
}

const useBirdiPrice = () => {
    const dispatch = useDispatch();
    const { drugDiscountPrices } = useSelector(drugSelector);
    const { currentFlow, familyPricingData, ownerPlan, cartZipCode, cartItems, mainUserZipCode } =
        usePrescriptionFlow();
    const [priceEligibleRxs, setPriceEligibleRxs] = useState<PrescriptionCardProps[]>([]);

    const setPriceEligiblePrescriptions = useCallback(
        (prescriptions: PrescriptionCardProps[]) => {
            if (!isRxCardsListEqual(prescriptions, priceEligibleRxs)) {
                setPriceEligibleRxs(prescriptions);
            }
        },
        [priceEligibleRxs]
    );

    const getPatientPricingData = useCallback(
        (epostPatientNum: string) =>
            familyPricingData.find((dependent) => dependent.epostPatientNum === epostPatientNum),
        [familyPricingData]
    );

    const getPricingZipCode = useCallback(
        (epostPatientNum: string) => {
            const patientPricingData = getPatientPricingData(epostPatientNum);
            return getDrugPricingZipCode(patientPricingData, mainUserZipCode, cartItems, cartZipCode);
        },
        [getPatientPricingData, cartItems, cartZipCode, mainUserZipCode]
    );

    const getPricingPlanAlias = useCallback(
        (epostPatientNum: string, rxNumber?: string) => {
            const pricingDetails = getPatientPricingData(epostPatientNum);
            return getDrugPricingPlanAlias({
                rxNumber,
                pricingDetails,
                cartItems,
                mainUserPlan: ownerPlan as BIRDI_PLANS
            });
        },
        [getPatientPricingData, cartItems, ownerPlan]
    );

    // Find if the price has been already gotten for a certain Drug
    const findPrescriptionBirdiPrice = useCallback(
        (prescription: Partial<RxDetails> | RxDetails) => {
            if (!prescription.dispensedProductNumber || !prescription.epostPatientNum) return;

            const drugLookupData: DiscountLookupValues = {
                drugCode: prescription.dispensedProductNumber,
                quantity: prescription.fillQuantity,
                clientAcCode: getPricingPlanAlias(prescription.epostPatientNum, prescription.rxNumber),
                zipCode: getPricingZipCode(prescription.epostPatientNum)
            };
            return findDrugInHistory(drugLookupData, drugDiscountPrices);
        },
        [getPricingZipCode, getPricingPlanAlias, drugDiscountPrices]
    );

    const getPrescriptionPrice = useCallback(
        ({
            values,
            actions,
            handlePriceSuccess,
            handleDescriptionSuccess,
            handleIsBirdiSelect,
            handleIsBrd02
        }: getPriceParameters) => {
            // Convert values into a RxDetails object, since that is what it expects
            const prescription: Partial<RxDetails> = {
                dispensedProductNumber: values.ndc,
                fillQuantity: values.qty,
                fillDaysSupply: '',
                epostPatientNum: values.dependentEpostPatientNum
            };

            if (!prescription.dispensedProductNumber) return;

            const priceFound: DrugWithDiscountPrice | undefined = findPrescriptionBirdiPrice(prescription);

            const onSuccess = (drugPriceResponse: DrugWithDiscountPrice) => {
                // Update the submitting state of the prescription info form
                // in order to reset the loading state of the submit button.
                actions && actions.setSubmitting(false);

                // If the price is not included in the response or is an empty string, then set the value to "NA".

                const priceValue = !isNaN(Number(drugPriceResponse.price))
                    ? formatPrice(drugPriceResponse.price)
                    : drugPriceResponse.price;

                const isBirdiSelect = drugPriceResponse.isBirdiSelect;
                const isBrd02 = drugPriceResponse.planAlias === BIRDI_PLANS.BRD_02;
                // need to provide successHandler to set showPrice, SelectedDrug and scrallTo
                handlePriceSuccess && handlePriceSuccess(priceValue);
                handleIsBirdiSelect && handleIsBirdiSelect(isBirdiSelect);
                handleIsBrd02 && handleIsBrd02(isBrd02);
            };

            if (!priceFound) {
                // Fetch the drug price.
                dispatch(
                    drugDiscountPriceRoutine.trigger({
                        prescriptions: [prescription],
                        onSuccess,
                        onFailure: () => {
                            // On failure, we still scroll down, but we show an error message.
                            actions && actions.setSubmitting(false);
                            handlePriceSuccess && handlePriceSuccess();
                        }
                    })
                );
            } else {
                onSuccess(priceFound);
            }

            // Fetch the drug description.
            if (values.gpi) {
                dispatch(
                    drugDescriptionRoutine.trigger({
                        gpi: values.gpi,
                        onSuccess: (response: DrugDescriptionObjectPayload) => {
                            handleDescriptionSuccess && handleDescriptionSuccess(response);
                        },
                        onFailure: () => {
                            handleDescriptionSuccess && handleDescriptionSuccess();
                        }
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [findPrescriptionBirdiPrice]
    );

    // Function that triggers the
    const getPrescriptionListPrices = useCallback(
        (prescriptions: RxDetails[], location?: PRICING_API_LOCATION) => {
            const unauthAreaValue: Record<RX_AVAILABLE_FLOWS, PRICING_UNAUTH_AREA | undefined> = {
                [RX_AVAILABLE_FLOWS.EASY_REFILL]: PRICING_UNAUTH_AREA.EASY_REFILL,
                [RX_AVAILABLE_FLOWS.MEDICINE_CABINET]: undefined,
                [RX_AVAILABLE_FLOWS.AUTO_REFILL]: PRICING_UNAUTH_AREA.AUTO_REFILL
            };

            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions,
                    location: location ?? PRICING_API_LOCATION.MEDICINE_CABINET,
                    unAuthArea: unauthAreaValue[currentFlow]
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [familyPricingData, findPrescriptionBirdiPrice]
    );

    // Function that will help us to debug the price that will be rendered for an Rx cart
    const debugRxPrice = useCallback(
        (prescription?: PrescriptionCardProps) => {
            if (!prescription) return;

            const priceFoundInHistory = findPrescriptionBirdiPrice(prescription.fullRxItem);
            const patientPricingData = getPatientPricingData(prescription.fullRxItem?.epostPatientNum);

            console.group(`___Debugging Rx price___`);
            console.log('History of rx prices api calls:', drugDiscountPrices);
            console.log(
                `Cart zip code (cartZipCode): ${
                    cartZipCode && cartItems && cartItems?.length > 0 ? cartZipCode : 'No zip code, the cart is empty'
                }`
            );

            console.group(`Drug data`);
            console.log(`Prescription number (rxNumber): ${prescription.fullRxItem.rxNumber}`);
            console.log(`Quantity (fillQuantity): ${prescription.fullRxItem.fillQuantity}`);
            console.log(`Drug code (drugCode): ${getRxDrugCode(prescription.fullRxItem)}`);
            console.log(`Product name (dispensedProductName): ${prescription.fullRxItem.dispensedProductName}`);

            console.groupEnd();

            console.group(`Patient data`);
            console.log(`Patient's epostPatientNum: ${patientPricingData?.epostPatientNum}`);
            console.log(`Patient's plan alias: ${patientPricingData?.planAlias}`);
            console.log(`Plan type: ${patientPricingData?.planType}`);
            console.log(`Is on demand plan: ${patientPricingData?.isOnDemandPlan}`);
            console.log(`Patient's zip code: ${patientPricingData?.zipCode}`);
            console.groupEnd();

            console.group(`Pricing data`);
            console.log(`Plan alias: ${getPricingPlanAlias(prescription.fullRxItem.epostPatientNum)}`);
            console.log('Zip code: ', getPricingZipCode(prescription.fullRxItem.epostPatientNum));
            console.log(`Price found in History:`, priceFoundInHistory);
            console.groupEnd();
            console.groupEnd();
        },
        [
            drugDiscountPrices,
            cartItems,
            cartZipCode,
            getPricingPlanAlias,
            getPricingZipCode,
            findPrescriptionBirdiPrice,
            getPatientPricingData
        ]
    );

    // Function that will help us to debug the price that will be rendered for an Rx cart
    const debugTransferRxPrice = useCallback(
        (prescription: PrescriptionTypes | null | undefined) => {
            if (prescription && prescription.dependentEpostPatientNum) {
                const patientPricingData = getPatientPricingData(prescription.dependentEpostPatientNum);

                // Convert values into a RxDetails object, since that is what it expects
                const transferRx: Partial<RxDetails> = {
                    dispensedProductNumber: prescription.ndc,
                    fillQuantity: prescription.qty,
                    fillDaysSupply: '',
                    epostPatientNum: prescription.dependentEpostPatientNum
                };

                if (!transferRx.epostPatientNum || !transferRx.dispensedProductNumber) return;

                const priceFound: DrugWithDiscountPrice | undefined = findPrescriptionBirdiPrice(transferRx);

                console.group(`___Debugging Transfer Rx price___`);
                console.log('History of rx prices api calls:', drugDiscountPrices);

                console.group(`Drug data`);
                console.log(`Quantity (qty): ${prescription.qty}`);
                console.log(`Drug code (ndc): ${prescription.ndc}`);
                console.log(`Product name (drugDisplayName): ${prescription.drugDisplayName}`);

                console.groupEnd();

                console.group(`Patient data`);
                console.log(`Patient's epostPatientNum: ${patientPricingData?.epostPatientNum}`);
                console.log(`Patient's plan alias: ${patientPricingData?.planAlias}`);
                console.log(`Plan type: ${patientPricingData?.planType}`);
                console.log(`Is on demand plan: ${patientPricingData?.isOnDemandPlan}`);
                console.log(`Patient's zip code: ${patientPricingData?.zipCode}`);
                console.groupEnd();

                console.group(`Pricing data`);
                console.log(`Plan alias: ${getPricingPlanAlias(prescription.dependentEpostPatientNum)}`);
                console.log('Zip code: ', getPricingZipCode(prescription.dependentEpostPatientNum));
                console.log(`Price found in History:`, priceFound);
                console.groupEnd();
                console.groupEnd();
            }
        },
        [drugDiscountPrices, findPrescriptionBirdiPrice, getPricingPlanAlias, getPricingZipCode, getPatientPricingData]
    );

    // When we receive price eligible Rx we trigger corresponding API calls
    useEffect(() => {
        if (priceEligibleRxs.length > 0) {
            getPrescriptionListPrices(priceEligibleRxs.map((rx) => rx.fullRxItem));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceEligibleRxs, cartZipCode]);

    return {
        getPrescriptionPrice,
        getPrescriptionListPrices,
        setPriceEligiblePrescriptions,
        debugRxPrice,
        debugTransferRxPrice
    };
};

export default useBirdiPrice;
