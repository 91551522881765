import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import Icon from 'ui-kit-v2/icon/icon';

import BadgeNew from 'ui-kit/icons/badge-new/badge-new';

import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import AutoRefillModalContent from '.';
import autoRefillImage from '../../assets/images/auto-refill-onboarding.png';

export const AutoRefillImage = () => {
    return <img className="auto-refill-modal-sideview-image" src={autoRefillImage} alt="Auto refill onboarding" />;
};

const AutoRefillModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
    };

    const handleOpenModal = () => {
        dispatch(
            openModalComponent({
                title: t('pages.autoRefill.userVerification.title'),
                isCloseable: true,
                hasDefaultFooter: false,
                hasModalHeader: true,
                variation: 'side-view',
                isCentered: true,
                hasCustomContent: false,
                badge: <BadgeNew />,
                sideView: <AutoRefillImage />,
                content: <AutoRefillModalContent onClose={handleCloseModal} />,
                onClose: handleCloseModal
            })
        );
    };

    useEffect(() => {
        handleOpenModal();
    }, []);

    return (
        <ButtonComponent
            type="button"
            variant="ghost"
            iconLeft={<Icon icon="plus-circle" />}
            onClick={handleOpenModal}
        />
    );
};

export default AutoRefillModal;
