import { TextProps } from './text.props';
import './text.styles.scss';

const weightMap = {
    400: 'regular',
    500: 'regular',
    600: 'demi',
    700: 'demi',
    800: 'bold',
    900: 'extra-bold'
};

export const Text = ({
    as: Component = 'p',
    children,
    size = 14, //PX Will be converted to REM
    color = 'ebony-clay', // ThemeColors
    weight = 500, // FontWeight
    lineHeight = 21, // PX Will be converted to REM
    letterSpacing = 0.08, //px
    className
}: TextProps) => {
    const weightClass = weightMap[weight] || 'regular';
    const sizeClass = typeof size === 'number' ? `text-component--${pxToRem(size)}` : `text-component--${size}`;
    const colorClass = `text-component--${color}`;

    const customClassName = `text-component ${sizeClass} ${colorClass} ${weightClass}  ${className ?? ''}`;
    const styledLetterSpacing = pxToRem(letterSpacing);
    const styledLineHeight = pxToRem(lineHeight);

    return (
        <Component
            className={customClassName}
            style={{ letterSpacing: styledLetterSpacing, lineHeight: styledLineHeight }}
        >
            {children}
        </Component>
    );
};

export const pxToRem = (px: number, baseFontSize = 16): string => {
    return `${px / baseFontSize}rem`;
};
