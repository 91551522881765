import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';

import { accountIsLoggedInSelector } from 'state/account/account.selectors';

import { baseEffectHandler } from 'util/sagas/sagas';

import {
    registrationContinueRegistrationRoutine,
    registrationCreateWebAccountRoutine,
    registrationMinimalFinalizeRoutine
} from './registration.routines';
import RegistrationService from './registration.service';
import {
    BaseResponse,
    CreateWebAccountRequest,
    MinimalFinalizeRegistrationRequest,
    Registration,
    RegistrationResponse
} from './registration.types';

function* createWebAccount(action: PayloadAction<CreateWebAccountRequest>) {
    const { payload } = action;

    yield baseEffectHandler({
        service: RegistrationService.CreateWebAccount().post,
        isAuthenticatedService: false,
        data: payload,
        onResponse: function* (response: Registration) {
            yield put(registrationCreateWebAccountRoutine.success(response));
            action?.payload?.onSuccess?.(response);
        },
        onError: function* (error) {
            yield put(registrationCreateWebAccountRoutine.failure());
            action?.payload?.onFailure?.(error.response);
        },
        onComplete: () => {
            action?.payload?.onComplete?.();
        }
    });
}

function* getRegistrationContinueRegistration(action: PayloadAction<BaseResponse>) {
    const isLoggedIn: boolean = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: RegistrationService.ContinueRegistration().get,
        isAuthenticatedService: true,
        isLoggedIn: isLoggedIn,
        onResponse: function* (response: RegistrationResponse) {
            yield put(registrationContinueRegistrationRoutine.success(response));
            action?.payload?.onSuccess?.(response);
        },
        onError: function* (error) {
            yield put(
                registrationContinueRegistrationRoutine.failure({
                    messageErrorText: error.response.message
                })
            );
            action?.payload?.onFailure?.(error);
        },
        onComplete: () => {
            action?.payload?.onComplete?.();
        }
    });
}

function* saveMinimalRegistration(action: PayloadAction<MinimalFinalizeRegistrationRequest>) {
    yield baseEffectHandler({
        service: RegistrationService.MinimalFinalizeRegistration().post,
        isAuthenticatedService: false,
        data: action.payload.registration,
        onResponse: function* (response: Registration) {
            yield put(registrationMinimalFinalizeRoutine.success(response));
            action?.payload?.onSuccess?.(response);
        },
        onError: function* (error) {
            yield put(registrationMinimalFinalizeRoutine.failure());
            action?.payload?.onFailure?.(error.response);
        },
        onComplete: () => {
            action?.payload?.onComplete?.();
        }
    });
}

function* registrationSaga() {
    yield takeLatest(registrationCreateWebAccountRoutine.TRIGGER, createWebAccount);
    yield takeLatest(registrationContinueRegistrationRoutine.TRIGGER, getRegistrationContinueRegistration);
    yield takeLatest(registrationMinimalFinalizeRoutine.TRIGGER, saveMinimalRegistration);
}

export default registrationSaga;
