import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { accountProfileSelector } from 'state/account/account.selectors';
import { cartSelector } from 'state/cart/cart.selectors';
import { easyRefillCartItemsSelector, easyRefillSelector } from 'state/easy-refill/easy-refill.selectors';

import { RX_AVAILABLE_FLOWS } from 'enums/prescription';

import usePrescriptionFlow from './usePrescriptionFlow';

export type PatientsInCart = {
    epostPatientNum: string;
    firstName?: string;
    lastName?: string;
};

export function usePatientsInCart(): [PatientsInCart[], string] {
    const { currentFlow } = usePrescriptionFlow();
    const profileObject = useSelector(accountProfileSelector);
    const medicineCabinetCart = useSelector(cartSelector);
    const easyRefillObject = useSelector(easyRefillSelector);
    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);

    const [patientsInCart, setPatientsInCart] = useState<PatientsInCart[]>([]);

    const caregiver =
        currentFlow === RX_AVAILABLE_FLOWS.EASY_REFILL
            ? {
                  epostPatientNum: easyRefillObject.epostPatientNum,
                  firstName: easyRefillObject.firstName,
                  lastName: easyRefillObject.lastName
              }
            : {
                  epostPatientNum: profileObject?.epostPatientNum || '',
                  firstName: profileObject?.patientFirstName,
                  lastName: profileObject?.patientLastName
              };

    const flatPatientsInCart = patientsInCart.map((patient) => patient.epostPatientNum).join('-');

    useEffect(() => {
        const patients: PatientsInCart[] =
            currentFlow === RX_AVAILABLE_FLOWS.EASY_REFILL
                ? easyRefillCartItems?.map((item) => ({
                      epostPatientNum: String(item.prescriptionDetail?.epostPatientNum || ''),
                      firstName: item.prescriptionDetail?.patientFirstName,
                      lastName: item.prescriptionDetail?.patientLastName
                  })) || []
                : medicineCabinetCart
                      ?.filter((item) => item.Order.refillRxs.length || item.Order.newRxs.length)
                      ?.map((item) => ({
                          epostPatientNum: item.EpostPatientNum || '',
                          firstName: item.FirstName,
                          lastName: item.LastName
                      })) || [];

        const allPatients = [
            caregiver,
            ...patients.filter((patient) => patient.epostPatientNum !== caregiver.epostPatientNum)
        ];

        const flatPatients = allPatients.map((patient) => patient.epostPatientNum).join('-');

        if (flatPatients !== flatPatientsInCart) {
            setPatientsInCart(allPatients);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medicineCabinetCart, easyRefillCartItems, profileObject, flatPatientsInCart]);

    return [patientsInCart, flatPatientsInCart];
}
