// General
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMessage from 'ui-kit/loading-message/loading-message';
// Ui-kit
import Spinner from 'ui-kit/spinner/spinner';

// Components
import ProfileLayout from 'components/layouts/profile/profile.layout';
// Types
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';

// Redux & Services
import {
    accountAutoRefillPlanEligibleSelector,
    accountProfileAddressesSelector,
    accountProfilEPostPatientNumSelector
} from 'state/account/account.selectors';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import {
    medicineCabinetAutoRefillEligibleSelector,
    medicineCabinetPopulatedSelector,
    medicineCabinetPrescriptionsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';

// Styles
import './shipping-address.scss';

// Main component
const ShippingAddress = () => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Selectors
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const accountEpostNum = useSelector(accountProfilEPostPatientNumSelector);
    const displayAutoRefillMessage = useSelector(medicineCabinetAutoRefillEligibleSelector);
    const currentPrescriptions = useSelector(medicineCabinetPrescriptionsSelector);
    const populatedRxs = useSelector(medicineCabinetPopulatedSelector);
    const autoRefillPlanEligible = useSelector(accountAutoRefillPlanEligibleSelector);

    const shouldDisplayAutoRefillMessage = displayAutoRefillMessage && autoRefillPlanEligible;

    // Addresses object
    const addresses: AddressCardProps[] = profileAddresses.map((address) => ({
        addressSeqNum: address.addressSeqNum,
        defaultAddress: address.defaultShip,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        country: address.country,
        state: address.state,
        zipcode: address.zipcode,
        zipcodeFour: address.zipcodeFour && address.zipcodeFour.length > 0 ? address.zipcodeFour : '',
        defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
        addressType: address.addressTypeDesc,
        isProfile: true
    }));

    /**
     * Ticket DRX-3021:
     * As currentPrescriptions object is filled just inside the Medicine Cabinet,
     * if the user reloads this page, there is any function triggering the API
     * responsible to fulfill this object, and the Auto Refill Message will not
     * be displayed.
     *
     * To avoid this issue, first we check if the currentPrescriptions object is filled,
     * and if not, we trigger the Get All Prescriptions API to fill it. After that, the
     * currentPrescriptions object will be filled and it will be possible to use the
     * displayAutoRefillMessageSelector.
     */
    useEffect(() => {
        if (currentPrescriptions.length < 1 && !populatedRxs) {
            setIsLoading(true);

            if (!isLoading) {
                dispatch(
                    medicineCabinetGetAllPrescriptions.trigger({
                        epostNumFamilyMember: accountEpostNum
                    })
                );
            }
        } else {
            setIsLoading(false);
        }
    }, [dispatch, populatedRxs, isLoading, accountEpostNum, currentPrescriptions]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.shippingAddress.title`)}
            heading={t(`pages.profile.shippingAddress.heading`)}
        >
            <Spinner isVisible={isLoading} t={t} />
            {isLoading ? (
                <LoadingMessage isVisible={isLoading} text={t(`pages.profile.shippingAddress.loading`)} />
            ) : (
                <>
                    {shouldDisplayAutoRefillMessage && (
                        <div className="px-4">{t('pages.profile.shippingAddress.autoRefillSubhead')}</div>
                    )}
                    <div className="px-4 pt-4">
                        <ShippingAddresses
                            addressData={addresses}
                            isProfile={true}
                            addNewAddressButtonLabel={t('pages.profile.shippingAddress.labels.addNewAddress')}
                            showRadioButton={false}
                        />
                    </div>
                </>
            )}
        </ProfileLayout>
    );
};

export default ShippingAddress;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
