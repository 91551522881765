// General
import React, { useEffect, useState } from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Ui-kit
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import Spinner from 'ui-kit/spinner/spinner';

// Components
import EmptyCart from 'components/medicine-cabinet-cart/empty-cart/empty-cart.component';
import HealthProfile from 'components/medicine-cabinet-cart/health-profile/health-profile.component';
import PaymentMethod from 'components/medicine-cabinet-cart/payment-method/payment-method.component';
import PrescriptionInformation from 'components/medicine-cabinet-cart/prescription-information/prescription-information.component';
import ShippingAddress from 'components/medicine-cabinet-cart/shipping-address/shipping-address.component';
import ShippingMethod from 'components/medicine-cabinet-cart/shipping-method/shipping-method.component';
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';

import {
    accountAcCodeSelector,
    accountCreditCardsSelector,
    accountHasInsuranceSelector,
    accountIsLoadingPaymentMethodsSelector,
    accountIsMembershipSelector,
    accountPlansSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
// States
import {
    cartIsBusySelector,
    cartItemsTotalSelector,
    cartOrderBillShipMethodSelector,
    cartOrderBillShipSelector,
    cartOrderPaymentCardSelector,
    cartOrderShippingAddressSelector,
    cartSelector,
    cartSubtotalSelector,
    isLoadingAddressShippingCartSelector,
    isLoadingPaymentsCartSelector
} from 'state/cart/cart.selectors';
import { drugSelector } from 'state/drug/drug.selectors';
import { healthConditionsByPatientNumberSelector } from 'state/medical-conditions/medical-conditions.selector';
import {
    medicineCabinetIsBusySelector,
    medicineCabinetPrescriptionsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';

import { BREAKPOINTS } from 'const/breakpoints';

import { CartPayload, ExtendedCartObjectPayload } from 'types/cart';
import { RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { ApiStatus } from 'enums/api-status';

// Util
import { EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID, processCart } from 'util/cart';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { CartTotal } from './cart-total/total-cart.component';
// Styles
import './medicine-cabinet-cart.style.scss';

// Main component
const MedicineCabinetCart: React.FC = () => {
    // Hooks
    const { t } = useTranslation();

    // Selectors
    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const selectedPaymentMethod = useSelector(cartOrderPaymentCardSelector);
    const cartItemsTotal = useSelector(cartItemsTotalSelector) || 0;
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const estimatedSubtotal = useSelector(cartSubtotalSelector);
    const cartObject = useSelector(cartSelector);
    const cartIsBusy = useSelector(cartIsBusySelector);
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const isBirdiCash = useSelector(accountAcCodeSelector);
    const isMembership = useSelector(accountIsMembershipSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const profileObject = useSelector(accountProfileSelector);
    const cardOrderShippingAddress = useSelector(cartOrderShippingAddressSelector);

    const isLoadingPayments = useSelector(isLoadingPaymentsCartSelector);
    const isLoadingAddressShipping = useSelector(isLoadingAddressShippingCartSelector);
    const isLoadingCreditCardsAccount = useSelector(accountIsLoadingPaymentMethodsSelector);
    const prescriptionsIsBusySelector = useSelector(medicineCabinetIsBusySelector);
    const healthConditionsByPatientNumber = useSelector(healthConditionsByPatientNumberSelector);

    const isLoadingHealthConditions = Object.values(healthConditionsByPatientNumber).some(
        (healthConditions) =>
            healthConditions.allergiesApiStatus === ApiStatus.LOADING ||
            healthConditions.medicalConditionsApiStatus === ApiStatus.LOADING
    );
    const isCartLoading =
        isLoadingAddressShipping ||
        isLoadingHealthConditions ||
        prescriptionsIsBusySelector ||
        isLoadingPayments ||
        isLoadingCreditCardsAccount ||
        cartIsBusy;

    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload[]>();

    const { width } = useWindowDimensions();

    useEffect(() => {
        // Since the drug prices API is called separately, we are currently validating against the prescriptions
        // already present in the cart. This ensures consistency in our approach by only fetching prescriptions
        // for the current user and refreshing the state when each tab is called. Please note:
        // This validation serves as a temporary solution, providing a safeguard against potential side effects
        // that may impact pricing results it should be reviewed for future implementations.
        async function prescriptionsInCart(cart: CartPayload[]) {
            const prescriptionsInCart: RxDetails[] = [];

            cart.forEach((orderPayload: CartPayload) => {
                if (orderPayload.Order.refillRxs && orderPayload.Order.refillRxs.length > 0) {
                    orderPayload.Order.refillRxs.forEach((refillRx: RefillRxs) => {
                        if (refillRx.prescriptionDetail) {
                            prescriptionsInCart.push(refillRx.prescriptionDetail);
                        }
                    });
                }
            });

            return prescriptionsInCart;
        }

        async function getCartOrder() {
            if (cartObject) {
                const extendedCart = processCart(
                    cartObject,
                    accountHasInsurance,
                    profileObject?.isCaregiver ? await prescriptionsInCart(cartObject) : prescriptionsObject,
                    drugDiscountPrices,
                    accountPlans,
                    isMembership,
                    cardOrderShippingAddress?.zipcode || ''
                );
                setExtendedCartObject(extendedCart);
            }
        }
        getCartOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartObject, drugDiscountPrices, accountPlans, accountHasInsurance, isMembership, profileObject]);

    return (
        <SidebarColumn
            className={`medicine-cabinet-v2-cart ${width <= BREAKPOINTS.sm ? 'medicine-cabinet-v2-cart--mobile' : ''}`}
        >
            {isCartLoading && cartItemsTotal > 0 && (
                <div className="medicine-cabinet-v2-cart__loading__blocker">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" focusOnShow />
                </div>
            )}

            <div className="medicine-cabinet-v2-cart__header">
                <CartIcon className={'header-icon-container'} />
                <h3>{t('components.medicineCabinetCart.title')}</h3>
            </div>

            {cartItemsTotal === 0 && isCartLoading ? (
                <div className="medicine-cabinet-v2-cart__loading">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" />
                </div>
            ) : (
                <>
                    {cartItemsTotal > 0 ? (
                        <>
                            <ShippingAddress />
                            <ShippingMethod />
                            <PaymentMethod
                                addNewPaymentButtonLabel={t(
                                    'components.medicineCabinetCart.paymentMethod.addPaymentMethod'
                                )}
                                buttonVariant="text-blue"
                                selectedPaymentMethod={selectedPaymentMethod}
                                paymentData={allPaymentData || []}
                                showSelectCardRadioInput
                                showSetDefaultLink
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                orderBillShip={orderBillShip!}
                            />
                            <HealthProfile />
                            <PrescriptionInformation
                                cartObject={extendedCartObject}
                                isInsuranceAccount={accountHasInsurance}
                                prescriptions={prescriptionsObject}
                                onPrescriptionRemoved={() => {
                                    if (cartItemsTotal <= 1) window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}
                            />
                            <CartTotal
                                cartObject={extendedCartObject}
                                prescriptionsObject={prescriptionsObject}
                                isUnknownPrice={false}
                                initialOrderPrice={String(estimatedSubtotal)}
                                currentShippingPrice={
                                    shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0
                                }
                                isInsuranceAccount={accountHasInsurance}
                                isBirdiCash={['BRD01', 'BRD02'].includes(isBirdiCash)}
                            />
                        </>
                    ) : (
                        <EmptyCart
                            title={t('components.medicineCabinetCart.empty.title')}
                            body={t('components.medicineCabinetCart.empty.body')}
                        />
                    )}
                </>
            )}
        </SidebarColumn>
    );
};

export default MedicineCabinetCart;
