import { ReactElement } from 'react';
import classNames from 'classnames';

// Interface and types
import { FamilyAccountCardListProps } from 'types/family-management';

// Components
import FamilyAccountCard from '../family-account-card';

// Styles
import './family-account-card-list.style.scss';

// Main component
const FamilyAccountCardList = ({ payload, className }: FamilyAccountCardListProps): ReactElement => {
    const containerClasses = classNames('family-account-card-list', { [`${className}`]: className });

    return (
        <div className={containerClasses}>
            <div className="family-account-card-list__container-list">
                {payload &&
                    payload.map((familyMember, index) => (
                        <div key={`family-account-card-list-item-${index}`}>
                            <FamilyAccountCard {...familyMember} />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default FamilyAccountCardList;
