import classNames from 'classnames';
import { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import BackgroundImage from 'ui-kit/background-image/background-image';

import { InternalHeaderProps } from './internal-header.props';
import './internal-header.style.scss';

const InternalHeader = ({
    eyebrowText,
    title,
    body,
    backgroundImage,
    backgroundStyle,
    sectionIndex,
    showSpacer = false,
    variant,
    icon,
    columnWidthOnDesktop = 9
}: InternalHeaderProps): ReactElement => {
    const internalHeaderContainerClasses = classNames('internal-header', { [`${variant}`]: variant });

    return (
        <div className={internalHeaderContainerClasses}>
            {backgroundImage && (
                <BackgroundImage image={backgroundImage} style={backgroundStyle} className="header-background" />
            )}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={columnWidthOnDesktop}>
                        <div className="internal-header-text">
                            {icon}
                            {eyebrowText && <div className="h5 internal-header-eyebrow">{eyebrowText}</div>}
                            {sectionIndex === 0 && <h1 className="internal-header-title">{title}</h1>}
                            {sectionIndex > 0 && <h2 className="h1 internal-header-title">{title}</h2>}
                            {(showSpacer || body) && <div className="spacer" />}
                            {body && (
                                <div className="col-lg-10 col-md-10 col-12 internal-header-subtitle">
                                    <div className="internal-header-body">{body}</div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InternalHeader;
