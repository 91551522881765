import { RefObject, useEffect, useMemo, useState } from 'react';

/**
 * Detects if an element is visible.
 *
 * @param {RefObject<HTMLElement>} ref - element to be observed
 * @returns {boolean} - visibility of the element
 */
export default function useElementOnScreen(ref: RefObject<HTMLElement>) {
    const [isVisible, setVisibility] = useState(true);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setVisibility(entry.isIntersecting)), [ref]);

    useEffect(() => {
        if (ref.current === null) return;

        observer.observe(ref.current as HTMLElement);

        return () => {
            observer.disconnect();
        };
    }, [ref.current]);

    return isVisible;
}
