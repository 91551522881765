import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'ui-kit/button/button';

import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import CompleteVisitModalContent from './complete-visit-content';

const CompleteVisitModal = () => {
    const dispatch = useDispatch();

    const handleCloseModal = () => dispatch(closeModalComponent());

    const handleOpenModal = () => {
        dispatch(
            openModalComponent({
                title: 'Visit for COVID: SARS-CoV-2 & UTI', // evrything after "Visit For" should be populated by BE
                subtitle: 'Here is your after visit summary.',
                hasCustomContent: true,
                hasDefaultFooter: false,
                variation: 'large',
                isCentered: true,
                content: <CompleteVisitModalContent />,
                onClose: handleCloseModal
            })
        );
    };

    useEffect(() => {
        handleOpenModal();
    }, []);

    return (
        <Button
            className="complete-visit-modal__button"
            label="Open modal"
            type="button"
            variant="text-blue"
            onClick={handleOpenModal}
        />
    );
};

export default CompleteVisitModal;
