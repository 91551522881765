import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import {
    PaymentHistoryRequestPayload,
    PayPatientBalanceFamilyResponse,
    PostPatientBalanceFamilyPayload
} from 'types/payment-history';
import { BaseService } from 'types/service';

import axiosClient from 'util/axiosClient';

export default class PaymentsService {
    static postPayPatientBalanceFamily(): BaseService<PostPatientBalanceFamilyPayload> {
        return {
            post: async (payload: PostPatientBalanceFamilyPayload): Promise<Array<PayPatientBalanceFamilyResponse>> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Ar/v2/PayPatientBalanceFamily`,
                    payload
                );
                return response.data;
            }
        };
    }

    /**
     * Retrieves the endpoints for accessing payment history data.
     * Provides different versions for specific use cases.
     */
    static getPaymentHistory() {
        return {
            /**
             * Retrieves the payment history for a caregiver, including their patients and dependents.
             *
             * @function v2
             * @param {PaymentHistoryRequestPayload} payload - The request payload containing filters and pagination details.
             * @param {number} payload.page - The page number of the results to retrieve.
             * @param {number} payload.pageSize - The number of results per page.
             * @param {boolean} payload.includeAging - Flag to include aging data.
             * @returns {Promise<PaymentHistoryV2Response>} A promise that resolves to a payment history response for caregivers.
             */
            v2: async (payload: PaymentHistoryRequestPayload) => {
                const epost = payload?.epostPatientNum;

                const epostParam = epost ? `${epost}` : '';

                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/Ar/v2/PaymentHistory/${payload.page}/${payload.pageSize}/${payload.includeAging}/${epostParam}`
                );
                return response.data;
            }
        };
    }
}
