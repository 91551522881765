import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI-kit
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// State
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugSelector } from 'state/drug/drug.selectors';
import { easyRefillGetCartRoutine, easyRefillSubmitOrderRoutine } from 'state/easy-refill/easy-refill.routines';
import {
    easyRefillCartItemsSelector,
    easyRefillOrderAddressSelector,
    easyRefillOrderPaymentCardSelector,
    easyRefillPlansSelector
} from 'state/easy-refill/easy-refill.selectors';

// Schema
import { formatPrice } from 'schema/price.schema';

// Types
import { EasyRefillCartPayload, ExtendedEasyRefillCartObjectPayload } from 'types/easy-refill';
import { RxDetails } from 'types/prescription';

// Utils
import { processEasyRefillCart } from 'util/easy-refill';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

// Hooks
import { useHealthConditionsByPatientNum } from 'hooks/useHealthConditionsByPatientNum';
import { usePatientsInCart } from 'hooks/usePatientsInCart';

// Styles
import './easy-refill-cart-total.style.scss';

interface ReviewNewCartTotalProps {
    isUnknownPrice: boolean;
    initialOrderPrice?: string;
    currentShippingPrice: number;
    isInsuranceAccount: boolean;
    cartObject?: EasyRefillCartPayload[];
    prescriptionsObject: RxDetails[];
}

export const EasyRefillCartTotal = ({
    isUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    isInsuranceAccount,
    cartObject,
    prescriptionsObject
}: ReviewNewCartTotalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedEasyRefillCartObjectPayload[]>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const userAddress = useSelector(easyRefillOrderAddressSelector);
    const userPaymentCard = useSelector(easyRefillOrderPaymentCardSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);

    const [patientsInCart] = usePatientsInCart();

    const { isMissingHealthConditions } = useHealthConditionsByPatientNum(
        patientsInCart.map((patient) => patient.epostPatientNum)
    );

    const { drugDiscountPrices } = useSelector(drugSelector);

    const isOneClickOrderAvailable = !isMissingHealthConditions && !!userPaymentCard && !!userAddress;

    const showPaymentError = () => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.paymentMethods.paymentError.title')} icon="alert" />
                ),
                bodyContent: (
                    <BirdiModalErrorContent
                        description={t('modals.paymentMethods.paymentError.description')}
                        ctaText={t('modals.paymentMethods.paymentError.cta')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleSubmitOrderClick = () => {
        setIsBusy(true);
        dispatch(
            easyRefillSubmitOrderRoutine({
                currentShippingPrice: Number(currentShippingPrice),
                lineItems: extendedCartObject?.flatMap((rx) => rx.extendedRefillRxs).filter((item) => !!item) || [],
                onSuccess: () => {
                    setIsBusy(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: extendedCartObject,
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: String(currentShippingPrice),
                        accountHasInsurance: isInsuranceAccount
                    });
                    navigate('/easy-refill/order-confirmation');
                },
                onFailure: (data: any) => {
                    setIsBusy(false);

                    if (
                        data[0]?.order?.messageErrorText === 'PRE-AUTH-DENIED' ||
                        data[0]?.messageErrorText === 'PRE-AUTH-DENIED'
                    ) {
                        showPaymentError();
                        dispatch(easyRefillGetCartRoutine.trigger());
                        return;
                    }

                    dispatch(
                        openModal({
                            showClose: true,
                            size: 'lg',
                            type: 'danger',
                            headerContent: (
                                <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                            ),
                            bodyContent: (
                                <BirdiModalContent
                                    body={t(`pages.easyRefill.review.errors.orderError`, {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.easyRefillFailure.buttonText'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'EasyRefillOrderFailed'
                                }
                            ]
                        })
                    );
                    dispatch(easyRefillGetCartRoutine.trigger());
                }
            })
        );
    };

    useEffect(() => {
        async function getCartOrder() {
            if (cartObject && accountPlans && easyRefillCartItems) {
                const extendedCart = processEasyRefillCart(
                    cartObject,
                    isInsuranceAccount,
                    easyRefillCartItems,
                    drugDiscountPrices,
                    accountPlans
                );
                setExtendedCartObject(extendedCart);
            }
        }
        getCartOrder();
    }, [easyRefillCartItems, isInsuranceAccount, drugDiscountPrices, accountPlans, cartObject]);

    return (
        <div className="easy-refill-cart-total">
            <div className="easy-refill-cart-total__calculations">
                <div className="easy-refill-cart-total__calculations__subtotal">
                    <div>
                        {t('pages.cart.subTotal')}
                        {isUnknownPrice ? <sup>*</sup> : ''}:
                    </div>
                    <div>{formatPrice(initialOrderPrice)}</div>
                </div>
                <div className="easy-refill-cart-total__calculations__shipping">
                    <div>{t('pages.cart.shipping')}</div>
                    <div>{formatPrice(currentShippingPrice)}</div>
                </div>
            </div>
            <div className="easy-refill-cart-total__total">
                <div className="easy-refill-cart-total__total__title">
                    {extendedCartObject?.some((cart) => cart.showEstimatedTotal)
                        ? t('components.medicineCabinetCart.newCartTotal.estTotal')
                        : t('components.medicineCabinetCart.newCartTotal.total')}
                </div>
                <div className="easy-refill-cart-total__total__price">
                    {formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}
                </div>
            </div>
            {isUnknownPrice && isInsuranceAccount && (
                <ToastBox icon="default" variant="info">
                    {t('pages.cart.totalDisclaimer')}
                </ToastBox>
            )}
            <div className="easy-refill-cart-total__ctas">
                <Button
                    async
                    isBusy={isBusy}
                    disabled={!isOneClickOrderAvailable || isBusy}
                    type="submit"
                    variant="primary"
                    label={t('components.medicineCabinetCart.newCartTotal.submit')}
                    onClick={handleSubmitOrderClick}
                />
                <Button
                    customFontSize="18"
                    type="button"
                    variant="text"
                    className="btn btn-text text-cerulean no-min-width"
                    label={t('components.medicineCabinetCart.newCartTotal.review')}
                    onClick={() => navigate('/easy-refill/review')}
                    disabled={isBusy}
                />
                <div className="easy-refill-cart-total__ctas__disclaimer">
                    {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                </div>
            </div>
            <br />
        </div>
    );
};
