import { USPSPartialAddress } from 'types/discount-card';
import { Drug, PharmacyResults } from 'types/discount-card';

import { convertToTitleCase, lowercaseAndCapitalize } from 'util/string';

/* eslint-disable no-unused-vars */
export enum DiscountCardErrors {
    // eslint-disable-next-line no-unused-vars
    locationNotFound = 'LOCATION_NOT_FOUND',
    // eslint-disable-next-line no-unused-vars
    drugNotFound = 'DRUG_NOT_FOUND',
    // eslint-disable-next-line no-unused-vars
    somethingWentWrong = 'SOMETHING_WENT_WRONG',
    // eslint-disable-next-line no-unused-vars
    pharmaciesNotFound = 'PHARMACIES_NOT_FOUND',
    // eslint-disable-next-line no-unused-vars
    prescriptionNotFound = 'PRESCRIPTION_NOT_FOUND'
}

const errorNavigationMap: { [key: string]: string } = {
    [DiscountCardErrors.somethingWentWrong]: '/discount-card/error'
};
/**
 * Returns the appropriate navigation path based on the provided error message.
 *
 * @param {Object} errors - Object containing the error message and optionally the drug name.
 * @param {string} errors.messageText - The received error message.
 * @param {string} [errors.drugName] - (Optional) The name of the drug.
 * @returns {string | undefined} The corresponding navigation path or undefined if no match is found.
 */
export const getErrorNavigationPath = (errors: { messageText: string; drugName?: string }): string | undefined => {
    const errorMessages = Object.keys(errorNavigationMap);
    for (const errorMessage of errorMessages) {
        if (errors?.messageText?.includes(errorMessage)) {
            if (errors?.drugName) return `${errorNavigationMap[errorMessage]}?drugName=${errors?.drugName}`;
            return errorNavigationMap[errorMessage];
        }
    }
    return undefined;
};

/**
 * Converts a drug name to title case.
 *
 * @param {string} drugName - The drug name to be converted.
 * @returns {string | undefined} The drug name in title case or undefined if the input is falsy.
 */
export const titleCaseDrugName = (drugName: string) => {
    if (!drugName) {
        return;
    }

    const parsedDrugName = drugName.split(' ').map((d) => {
        return d[0].toUpperCase() + d.substring(1).toLowerCase();
    });
    return parsedDrugName.join(' ');
};

/**
 * Formats a location address into a string.
 *
 * @param {USPSPartialAddress} location - The location address to be formatted.
 * @param {boolean} [hasZipOptional=false] - A flag to indicate if the zip code should be optional.
 * @returns {string | undefined} The formatted location address or undefined if the location is falsy.
 */
export const formatLocationAddress = (location?: USPSPartialAddress, hasZipOptional?: boolean) => {
    if (!location) return undefined;

    const city = convertToTitleCase(location?.city || '');
    const state = location.state || '';
    const zip = location.zip || '';

    const formatZip = hasZipOptional ? `(${zip})` : zip;

    return `${city}, ${state} ${formatZip}`;
};

/**
 * Function to either convert a drug name into a URL-friendly format (spaces replaced with dash),
 * or to convert a URL-friendly name (with dash) back to a normal format (with spaces).
 * Dash was used because some drugNames may contain hyphen and this would lead to failures when querying the API
 *
 * @param {string} drugName - The name of the drug to be formatted.
 * @param {boolean} toUrlFormat - Optional flag. If true, converts spaces to dash. If false, converts dash back to spaces. Default is true.
 *
 * @returns {string} - The formatted string with spaces or dash based on the flag.
 */
export const formatDrugNameForURL = (drugName: string | undefined, toUrlFormat = true): string | undefined => {
    if (!drugName) return undefined;

    if (toUrlFormat) {
        // Encode
        return encodeURIComponent(drugName);
    } else {
        // Decode
        return decodeURIComponent(drugName);
    }
};

/**
 * Adapt a list of drugs into an array of PharmacyResults objects.
 *
 * @param {Drug[]} drugs - Array of Drug objects to be adapted.
 * @returns {PharmacyResults[]} Array of PharmacyResults objects, each representing a unique pharmacy with aggregated drug information.
 *
 */
export const adaptDrugResponse = (drugs: Drug[]): PharmacyResults[] => {
    const groupedByPharmacy = drugs.reduce((acc, drug) => {
        if (!acc[drug.PharmacyName]) {
            acc[drug.PharmacyName] = [];
        }
        acc[drug.PharmacyName].push(drug);
        return acc;
    }, {} as Record<string, Drug[]>);

    // Convert each group into a PharmacyResults object
    return Object.entries(groupedByPharmacy).map(([pharmacyName, drugs]) => {
        // Find the lowest price for the current pharmacy
        const minPrice = Math.min(...drugs.map((drug) => (drug.Price !== 9999999 ? drug.Price : Infinity)));

        // Construct the PharmacyResults object
        return {
            name: lowercaseAndCapitalize(pharmacyName),
            price: `$${minPrice === Infinity ? 'N/A*' : minPrice.toFixed(2)}`,
            address: drugs[0].Address,
            resultsNumber: drugs.length,
            isLowestPrice: drugs.some((drug) => drug.Price === minPrice),
            locations: drugs.map((drug) => ({
                ...drug,
                name: lowercaseAndCapitalize(pharmacyName),
                address: `${lowercaseAndCapitalize(drug.Address)}, ${drug.City}, ${drug.State} ${drug.Zip}`,
                schedules: getTodayHours(drug.HoursOfOperation),
                //DRX-3650: The api is returning a value of 9999999 when it got no price for the medicine, so we treat in our side
                price: `$${drug.Price !== 9999999 ? drug.Price.toFixed(2) : 'N/A*'}`,
                phoneNumber: drug.Phone
            }))
        };
    });
};

/**
 * Checks if a search string is present in an array of drugs.
 *
 * @param drugList - An array of drugs in which the search will be performed.
 * @param searchString - The string to search for within the array.
 * @returns `true` if `searchString` is found in `drugList`; otherwise, `false`.
 */
export const includesSearchDrugList = (drugList: string[], searchString: string): boolean => {
    return drugList.includes(searchString);
};

/**
 * Parses the open hours string and returns the hours for the current day.
 *
 * @param openHours - The open hours string from the API (e.g., "S(Clsd) M(8:30a-5p) T(8:30a-5p) W(8:30a-5p) T(8:30a-5p) F(8:30a-5p) S(Clsd)").
 * @returns The open hours for the current day in the format "Day: Hours" or a message indicating the location is closed or the hours are unknown.
 *
 * @example
 * // Assume today is Monday
 * getTodayHours("S(Clsd) M(8:30a-5p) T(8:30a-5p) W(8:30a-5p) T(8:30a-5p) F(8:30a-5p) S(Clsd)")
 * Returns: "8:30AM to 5PM today"
 *
 * @example
 * // Assume today is Sunday
 * getTodayHours("S(Clsd) M(8:30a-5p) T(8:30a-5p) W(8:30a-5p) T(8:30a-5p) F(8:30a-5p) S(Clsd)")
 * // Returns: "Sunday: Closed"
 *
 * @example
 * // Assume today is Tuesday
 * getTodayHours("S(Clsd) M(Unknown) T(Unknown) W(8:30a-5p) T(8:30a-5p) F(8:30a-5p) S(Clsd)")
 * // Returns: "Tuesday: Unknown"
 */
function getTodayHours(openHours: string): string {
    let hoursArray = openHours.split(' ');

    hoursArray = hoursArray.map((entry) => entry.replace('-', ' to ').replace('a', 'AM').replace('p', 'PM'));

    // Get the current day of the week as an index (0 = Sunday, 6 = Saturday)
    const todayIndex = new Date().getDay();

    const todayHours = hoursArray[todayIndex];

    // Check for "Clsd" or "Unknown"
    if (todayHours?.includes('Clsd')) {
        return 'Closed';
    }
    if (todayHours?.includes('Unknown')) {
        return 'Unknown';
    }

    // Extract the hours part from the string, removing the day abbreviation
    const hoursMatch = todayHours?.match(/\((.*?)\)/);

    // If hours are found, return them in "Day: Hours" format; otherwise, return "Day: Unknown"
    return hoursMatch
        ? `${hoursMatch[1]} today`
        : `pages.discountCard.pharmacyResults.pharmacyList.noSchedulesDisclaimer`;
}
