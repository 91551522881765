import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import InfoBox from 'ui-kit/info-box/info-box';

import LearnMoreContentModal from 'display-components/auto-refill-modal-contents/learn-more';

import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import './auto-refill-banner.style.scss';

export interface AutoRefillBannerProps {
    currentPage?: 'prescriptions' | 'order-confirmation';
}

const AutoRefillBanner = ({ currentPage = 'prescriptions' }: AutoRefillBannerProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
    };

    const handleOpenModal = () => {
        dispatch(
            openModalComponent({
                title: t('pages.profile.autoRefill.learnMore.label'),
                isCloseable: true,
                customDialogClassName: 'learn-more-modal-header',
                hasDefaultFooter: false,
                hasModalHeader: true,
                variation: 'small',
                isCentered: true,
                hasCustomContent: false,
                content: <LearnMoreContentModal />,
                onClose: handleCloseModal
            })
        );
    };

    return (
        <div className="auto-refill-banner">
            <InfoBox
                button={
                    <Button
                        onClick={handleOpenModal}
                        variant="text-blue"
                        label={t('pages.medicineCabinet.autoRefillLearnMoreLink')}
                        type="button"
                    />
                }
            >
                <>
                    {currentPage === 'prescriptions' ? (
                        <>
                            <h4 className="title">{t('pages.medicineCabinet.autoRefillLearnTitle')}</h4>
                            <p className="description">{t('pages.medicineCabinet.autoRefillLearnBody')}</p>
                        </>
                    ) : (
                        <p
                            className="order-confirmation"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(t('pages.easyRefill.orderConfirmation.autoRefillLearnMoreText'), {
                                    allowedTags: ['strong']
                                })
                            }}
                        />
                    )}
                </>
            </InfoBox>
        </div>
    );
};

export default AutoRefillBanner;
