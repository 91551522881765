import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { TokenizePaymentCardPayload } from 'state/account/account.services';
import {
    adaptCaregiverAddresessResponse,
    adaptCaregiverPaymentsResponse,
    getEasyRefillMainCart,
    setIsExpiredFlagToCard
} from 'state/easy-refill/easy-refill.helpers';

import { AddressPayload } from 'types/account';
import { AutoRefillResponse, ToggleAutoRefillRequest } from 'types/auto-refill';
import { CancelOrderLinePayload } from 'types/cart';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    CreateOrUpdateAddressRequest,
    CreateOrUpdateAddressResponse,
    CreateOrUpdatePaymentRequest,
    EasyRefillCartPayload,
    EasyRefillPaymetricDetailsPayload,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPatientFamilyDataResponse,
    GetEasyRefillPaymentCardDataResponse,
    GetEasyRefillTokenizedCardNumberResponse,
    PaymentCard,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/easy-refill';
import { Allergies, MedicalConditions } from 'types/easy-refill-health-profile';
import { OrderBillShip, RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';

import { rxAutoRefillEligible, rxAutoRefillEnabled } from 'util/autorefill';
import { DEFAULT_SHIPPING_ID } from 'util/cart';
import { TrackError, TrackFlowComplete, TrackFormSuccess } from 'util/google_optimize/optimize_helper';
import { getRxDisplayStatus, getRxStatus } from 'util/payload-to-props';
import { baseEffectHandler } from 'util/sagas/sagas';
import storageHelper from 'util/storageHelper';

import { setEasyRefillCartLoading } from './easy-refill.actions';
import {
    easyRefillAddAddressesRoutine,
    easyRefillAddPaymentCardsRoutine,
    easyRefillAddRxToRefillRoutine,
    easyRefillAuthenticateUserRoutine,
    easyRefillCancelOrderLineRoutine,
    easyRefillFetchHealthConditionsRoutine,
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillGetPatientPlansRoutine,
    easyRefillGetSecretKeyHashRoutine,
    easyRefillPaymetricDetailsRoutine,
    easyRefillRemoveAllRxToRefillRoutine,
    easyRefillRemoveRxToRefillRoutine,
    easyRefillSetPrimaryAddressRoutine,
    easyRefillStartOrderRoutine,
    easyRefillSubmitOrderRoutine,
    easyRefillToggleAutoRefillRxStatusRoutine,
    easyRefillTokenizedCardNumberRoutine,
    easyRefillUpdateAddressesRoutine,
    easyRefillUpdateAllergiesRoutine,
    easyRefillUpdateCartPaymentRoutine,
    easyRefillUpdateMedicalConditionsRoutine,
    easyRefillUpdatePaymentCardsRoutine,
    easyRefillUpdateShippingMethodRoutine,
    easyRefillUpdateShippingRoutine,
    easyRefillVerifyUserRoutine
} from './easy-refill.routines';
import {
    cartSelector,
    easyRefillAddressesSelector,
    easyRefillCartSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillFamilyDependentsPrescriptionsSelector,
    easyRefillIsCaregiverSelector,
    easyRefillOrderAddressSelector,
    easyRefillOrderBillShipMethodSelector,
    easyRefillOrderBillShipSelector,
    easyRefillOrderPaymentCardSelector,
    easyRefillPatientAddressSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPaymetricDetailsSelector,
    easyRefillRxsSelector,
    easyRefillRxsToRefillSelector,
    easyRefillSecretKeyHashSelector,
    easyRefillSecurityTokenSelector,
    easyRefillStateAddOrUpdateAddressSelector,
    easyRefillUserBearerTokenSelector
} from './easy-refill.selectors';
import {
    APIAllergiesPayload,
    APIHealthConditionsPayload,
    APIMedicalConditionsPayload,
    CancelOrderLineResponse,
    EasyRefillService
} from './easy-refill.services';

const FORM_NAME = {
    AddEasyRefillAddress: 'AddEasyRefillAddress',
    UpdateEasyRefillAddress: 'UpdateEasyRefillAddress',
    AddEasyRefillCreditCard: 'AddEasyRefillCreditCard',
    UpdateEasyRefillCreditCard: 'UpdateEasyRefillCreditCard'
};

function* generateSecretKeySaga(
    action: PayloadAction<{
        onSuccess?: () => void;
        onFailure?: (response: string) => void;
    }>
) {
    try {
        const securityToken: string = yield select(easyRefillSecurityTokenSelector);

        yield baseEffectHandler({
            service: EasyRefillService.getSecretKeyHash().get,
            isAuthenticatedService: false,
            data: securityToken,
            *onResponse(response: AuthenticateUserResponse) {
                yield put(easyRefillGetSecretKeyHashRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError() {
                yield put(easyRefillGetSecretKeyHashRoutine.failure('The security token is invalid'));
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure('The security token is invalid');
                }
            }
        });
    } catch (error) {
        yield put(easyRefillGetSecretKeyHashRoutine.failure(error));
    }
}

function* authenticateUserSaga() {
    try {
        const securityToken: string = yield select(easyRefillSecurityTokenSelector);
        const secretKeyHash: string = yield select(easyRefillSecretKeyHashSelector);
        // if successful verification - authenticate user
        const authenticateData: AuthenticateUserRequest = {
            securityToken,
            secretKeyHash,
            authenticationType: 'EasyRefill'
        };

        yield baseEffectHandler({
            service: EasyRefillService.authenticateUser().post,
            isAuthenticatedService: false,
            data: authenticateData,
            *onResponse(response: AuthenticateUserResponse) {
                if (response && response.messageText !== 'Authenticated') {
                    yield put(easyRefillAuthenticateUserRoutine.failure(response));
                    TrackError('easy-refill.sagas.ts', 'authenticateUserSaga', response.messageText);
                } else {
                    yield put(easyRefillAuthenticateUserRoutine.success(response));
                }
            },
            *onError(error) {
                yield put(easyRefillAuthenticateUserRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
    } catch (error) {
        //empty
    }
}

function* verifyUserSaga(
    action: PayloadAction<{
        dateOfBirth: string;
        zipcode: string;
        onSuccess?: () => void;
        onFailure?: (response?: VerifyUserResponse) => void;
        onMaxNumAttemptsReached?: (response: VerifyUserResponse) => void;
    }>
) {
    try {
        const securityToken: string = yield select(easyRefillSecurityTokenSelector);
        const { dateOfBirth, zipcode, onSuccess, onFailure, onMaxNumAttemptsReached } = action.payload;
        const data: VerifyUserRequest = {
            dateOfBirth,
            zipcode,
            securityToken,
            authenticationType: 'EasyRefill'
        };

        yield baseEffectHandler({
            service: EasyRefillService.verifyUser().post,
            isAuthenticatedService: false,
            data: data,
            *onResponse(response: VerifyUserResponse) {
                if (response && response.verificationStatus === 'MaxAttemptsReached') {
                    yield put(easyRefillVerifyUserRoutine.failure(response));
                    if (onMaxNumAttemptsReached) onMaxNumAttemptsReached(response);
                    if (onFailure) onFailure(response);
                } else if (
                    response &&
                    response.verificationStatus !== 'UserVerified' &&
                    response.verificationStatus !== 'TokenValid'
                ) {
                    yield put(easyRefillVerifyUserRoutine.failure(response));
                    TrackError('easy-refill.sagas.ts', 'verifyUserSaga', response.messageText);
                    if (onFailure) onFailure(response);
                } else {
                    yield put(easyRefillVerifyUserRoutine.success(response));
                    if (response.verificationStatus !== 'TokenValid') {
                        yield put(easyRefillAuthenticateUserRoutine.trigger());
                    }
                    if (onSuccess) onSuccess();
                }
            },
            *onError(error) {
                yield put(easyRefillVerifyUserRoutine.failure({ messageText: 'An error has occured' }));
                if (onFailure) onFailure(error);
            }
        });
    } catch (error) {
        const { onFailure } = action.payload;
        if (onFailure) onFailure();

        console.warn(error);
    }
}

function* getPatientDataSaga(action: PayloadAction<{ onFailure?: () => void; withoutLoading?: boolean }>) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const isCaregiver: boolean = yield select(easyRefillIsCaregiverSelector);

        yield baseEffectHandler({
            service: isCaregiver ? EasyRefillService.getFamilyData().get : EasyRefillService.getPatientData().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: GetEasyRefillPatientDataResponse & GetEasyRefillPatientFamilyDataResponse) {
                const data: GetEasyRefillPatientDataResponse[] = isCaregiver ? response?.data : [response];

                yield put(
                    easyRefillGetPatientDataRoutine.success({
                        data: data.map((patient: GetEasyRefillPatientDataResponse) => ({
                            ...patient,
                            ...{
                                epostPatientNum: patient.epostPatientNum
                                    ? patient.epostPatientNum.toString()
                                    : patient.rxResults[0]?.epostPatientNum.toString()
                            },
                            ...{
                                rxResults: patient.rxResults.map((prescription) => ({
                                    ...prescription,
                                    // realRxCardStatus contains the "real" status, ignoring if the
                                    // prescription is in the cart.
                                    realRxCardStatus: getRxStatus(prescription),
                                    // rxCardStatus contains the status to display in
                                    // the rx card, includes in cart status.
                                    rxCardStatus: getRxDisplayStatus(prescription),
                                    // Ensures that every rx will contain a autoRefillEligible flag
                                    // according to what autoRefillFlags returns.
                                    autoRefillEligible: rxAutoRefillEligible(
                                        patient.autoRefillFlags,
                                        prescription.rxNumber
                                    ),
                                    // Ensures that every rx will contain a autoRefillEnabled flag
                                    // according to what autoRefillFlags returns.
                                    autoRefillEnabled: rxAutoRefillEnabled(
                                        patient.autoRefillFlags,
                                        prescription.rxNumber
                                    ),
                                    inFormulary: prescription.inCashFormulary,
                                    epostPatientNum: patient.epostPatientNum
                                }))
                            }
                        })),
                        withoutLoading: action.payload.withoutLoading
                    })
                );
            },
            *onError() {
                yield put(easyRefillGetPatientDataRoutine.failure({ messageText: 'An error has occured' }));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        yield put(easyRefillGetPatientDataRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

// Addresses

function* getPatientAddressesSaga() {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const isCaregiver: boolean = yield select(easyRefillIsCaregiverSelector);

        yield baseEffectHandler({
            service: isCaregiver
                ? EasyRefillService.getAddresses().familyAddresses
                : EasyRefillService.getAddresses().adresses,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response) {
                if (isCaregiver) {
                    const caregiverAddresses = adaptCaregiverAddresessResponse(response);

                    yield put(easyRefillGetPatientAddressesRoutine.success(caregiverAddresses));
                    return;
                } else {
                    yield put(easyRefillGetPatientAddressesRoutine.success(response));
                }
            },
            *onError(error) {
                yield put(easyRefillGetPatientAddressesRoutine.failure({ messageText: error.messageText }));
            }
        });
    } catch (error) {
        //empty
    }
}

function* addPatientAddressesSaga(
    action: PayloadAction<{
        onSuccess?: (response: CreateOrUpdateAddressResponse) => void;
        onFailure?: () => void;
    }>
) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const selectedAddress: CreateOrUpdateAddressRequest = yield select(easyRefillStateAddOrUpdateAddressSelector);

        const { onFailure, onSuccess } = action.payload;

        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        yield baseEffectHandler({
            service: EasyRefillService.addUpdateAddress().post,
            isAuthenticatedService: false,
            data: { ...selectedAddress, bearerToken: bearerToken },
            *onResponse(response: CreateOrUpdateAddressResponse) {
                yield put(easyRefillAddAddressesRoutine.success(response));
                TrackFormSuccess(FORM_NAME.AddEasyRefillAddress);
                TrackFlowComplete(FORM_NAME.AddEasyRefillAddress);
                if (onSuccess) onSuccess(response);
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            },
            *onError(error) {
                yield put(easyRefillAddAddressesRoutine.failure({ messageText: error.messageText }));
                if (onFailure) onFailure();
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            }
        });
    } catch (error) {
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

function* updatePatientAddressSaga(
    action: PayloadAction<{
        index: number;
        address: AddressPayload;
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    const { onFailure, onSuccess } = action.payload;
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const selectedAddress: CreateOrUpdateAddressRequest = yield select(easyRefillStateAddOrUpdateAddressSelector);

        yield baseEffectHandler({
            service: EasyRefillService.addUpdateAddress().post,
            isAuthenticatedService: false,
            data: { ...selectedAddress, bearerToken: bearerToken },
            *onResponse(response: CreateOrUpdateAddressResponse) {
                yield put(easyRefillAddAddressesRoutine.success(response));
                TrackFormSuccess(FORM_NAME.AddEasyRefillAddress);
                TrackFlowComplete(FORM_NAME.AddEasyRefillAddress);
                if (onSuccess) onSuccess();
            },
            *onError(error) {
                yield put(easyRefillAddAddressesRoutine.failure({ messageText: error.messageText }));
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        //empty
    }
}

function* setPrimaryAddressSaga(
    action: PayloadAction<{
        address: AddressPayload;
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

        const { address, onSuccess, onFailure } = action.payload;

        yield baseEffectHandler({
            service: EasyRefillService.addUpdateAddress().post,
            isAuthenticatedService: false,
            data: { ...address, bearerToken },
            *onResponse(response: CreateOrUpdateAddressResponse) {
                yield put(easyRefillAddAddressesRoutine.success(response));
                if (onSuccess) {
                    onSuccess();
                }
            },
            *onError(error) {
                yield put(easyRefillAddAddressesRoutine.failure({ messageText: error.messageText }));
                if (onFailure) {
                    onFailure();
                }
            }
        });
    } catch (error) {
        //empty
    }
}

// Payment Methods

function* getPatientPaymentCardsSaga() {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const isCaregiver: boolean = yield select(easyRefillIsCaregiverSelector);

        yield baseEffectHandler({
            service: isCaregiver
                ? EasyRefillService.getPaymentCards().familyCards
                : EasyRefillService.getPaymentCards().cards,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response) {
                if (isCaregiver) {
                    const caregiverCards = adaptCaregiverPaymentsResponse(response);

                    // setIsExpiredFlagToCard should be used while the cardIsExpired flag isn't available.
                    const mappedPaymentCards = setIsExpiredFlagToCard(caregiverCards);

                    yield put(easyRefillGetPatientPaymentCardsRoutine.success(mappedPaymentCards));
                    return;
                } else {
                    // setIsExpiredFlagToCard should be used while the cardIsExpired flag isn't available.
                    const mappedPaymentCards = setIsExpiredFlagToCard(response);
                    yield put(easyRefillGetPatientPaymentCardsRoutine.success(mappedPaymentCards));
                }
            },
            *onError(error) {
                yield put(easyRefillGetPatientPaymentCardsRoutine.failure({ messageText: error.messageText }));
            }
        });
    } catch (error) {
        //empty
    }
}

function* addPaymentMethodSaga(
    action: PayloadAction<{
        creditCard: CreateOrUpdatePaymentRequest;
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const creditCards: PaymentCard[] = yield select(easyRefillPaymentCardsSelector);

        const { onSuccess, onFailure, creditCard } = action.payload;

        if (!creditCards || creditCards.length === 0) {
            creditCard.defaultCard = true;
        }

        yield baseEffectHandler({
            service: EasyRefillService.addOrUpdatePaymentCard().post,
            isAuthenticatedService: false,
            data: { ...creditCard, bearerToken },
            *onResponse(response: GetEasyRefillPaymentCardDataResponse) {
                const updatedCreditCardPayload: any = creditCards
                    ? [...creditCards, response.paymentCards]
                    : [response.paymentCards];
                yield put(easyRefillAddPaymentCardsRoutine.success(updatedCreditCardPayload));
                TrackFormSuccess(FORM_NAME.AddEasyRefillCreditCard);
                TrackFlowComplete(FORM_NAME.AddEasyRefillCreditCard);
                yield put(easyRefillGetPatientPaymentCardsRoutine.trigger());

                if (onSuccess) onSuccess();

                yield put(easyRefillGetPatientPaymentCardsRoutine.success(response));
            },
            *onError(error) {
                yield put(easyRefillGetPatientPaymentCardsRoutine.failure({ messageText: error.messageText }));
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        //empty
    }
}

function* updatePaymentMethodSaga(
    action: PayloadAction<{
        cardToUpdate: CreateOrUpdatePaymentRequest;
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const creditCards: PaymentCard[] = yield select(easyRefillPaymentCardsSelector);

        const { cardToUpdate, onSuccess, onFailure } = action.payload;

        const paymentToUpdate = {
            ...creditCards.find(
                (card) =>
                    card.cardSeqNum === cardToUpdate.cardSeqNum &&
                    card.secureCardNumber === cardToUpdate.secureCardNumber &&
                    card.cardMonthDesc === cardToUpdate.cardMonthDesc &&
                    card.cardYear === cardToUpdate.cardYear
            )
        };

        const data = {
            ...paymentToUpdate,
            defaultCard: true
        };

        yield baseEffectHandler({
            service: EasyRefillService.addOrUpdatePaymentCard().post,
            isAuthenticatedService: false,
            data: { ...data, bearerToken },
            *onResponse(response: GetEasyRefillPaymentCardDataResponse) {
                yield put(easyRefillGetPatientPaymentCardsRoutine.trigger());
                yield put(easyRefillGetCartRoutine.trigger());
                yield put(easyRefillUpdatePaymentCardsRoutine.success(response));
                if (onSuccess) {
                    onSuccess();
                }
            },
            *onError(error) {
                yield put(easyRefillUpdatePaymentCardsRoutine.failure({ messageText: error.messageText }));
                if (onFailure) {
                    onFailure();
                }
            }
        });
    } catch (error) {
        //empty
    }
}

function* getPaymetricCredentials() {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        yield baseEffectHandler({
            service: EasyRefillService.getPaymetricCredentials().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(data) {
                yield put(easyRefillPaymetricDetailsRoutine.success(data));
            },
            *onError(error) {
                yield put(easyRefillPaymetricDetailsRoutine.failure({ messageText: error.messageText }));
            }
        });
    } catch (error) {
        //empty
    }
}

function* getTokenizedCardNumber(action: PayloadAction<TokenizePaymentCardPayload>) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const paymetricDetails: EasyRefillPaymetricDetailsPayload = yield select(easyRefillPaymetricDetailsSelector);
        const accessToken = paymetricDetails?.accessToken;

        yield baseEffectHandler({
            service: EasyRefillService.getTokenizedCardNumber().get,
            isAuthenticatedService: false,
            data: { accessToken, bearerToken },
            *onResponse(data: GetEasyRefillTokenizedCardNumberResponse) {
                if (data.body.length !== 0 && data.body !== null) {
                    // Done this way because the API will return a "success" response even if the request is a failure
                    yield put(easyRefillTokenizedCardNumberRoutine.success(data));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess(data.body);
                } else {
                    yield put(easyRefillTokenizedCardNumberRoutine.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(data);
                }

                yield put(easyRefillTokenizedCardNumberRoutine.success(data));
            },
            *onError(error) {
                yield put(easyRefillTokenizedCardNumberRoutine.failure({ messageText: error.messageText }));
            }
        });
    } catch (error) {
        //empty
    }
}

// Order

function* removeRxsToRefillSaga(action: PayloadAction<{ rxNumber: string; onSuccess?: () => void }>) {
    try {
        const { rxNumber, onSuccess } = action.payload;
        const rxsToRefill: string[] = yield select(easyRefillRxsToRefillSelector);
        const newRxList = rxsToRefill.filter((rx) => rx !== rxNumber);
        yield put(easyRefillRemoveRxToRefillRoutine.success(newRxList));
        if (onSuccess) onSuccess();
    } catch (error) {
        //empty
    }
}

function* removeAllRxsToRefillSaga(action: PayloadAction<{ onSuccess?: () => void }>) {
    const { onSuccess } = action.payload;
    yield put(easyRefillRemoveRxToRefillRoutine.success([]));
    if (onSuccess) onSuccess();
}

function* addRxsToRefillSaga(action: PayloadAction<{ rxNumber: string; onSuccess?: () => void }>) {
    try {
        const { rxNumber, onSuccess } = action.payload;

        const rxsToRefill: string[] = yield select(easyRefillRxsToRefillSelector);
        let newListOfRxs: string[] = [];

        if (rxsToRefill.indexOf(rxNumber) === -1) {
            newListOfRxs = [...rxsToRefill, rxNumber];
        } else {
            newListOfRxs = [...rxsToRefill];
        }
        yield put(easyRefillAddRxToRefillRoutine.success(newListOfRxs));
        if (onSuccess) onSuccess();
    } catch (error) {
        //empty
    }
}

function* toggleAutoRefillForRxSaga(
    action: PayloadAction<{
        rxsToToggle: { rxSeqNum: string; newStatus: boolean; rxNumber: string; isRenew: boolean };
        onFailure?: () => void;
        onSuccess?: () => void;
    }>
) {
    try {
        const { rxsToToggle, onSuccess } = action.payload;
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const data: ToggleAutoRefillRequest = {
            rxNum: rxsToToggle.rxNumber,
            newStatus: rxsToToggle.newStatus
        };

        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        yield baseEffectHandler({
            service: EasyRefillService.toggleAutoRefillForRx().get,
            data: { ...data, bearerToken: bearerToken },
            isAuthenticatedService: false,
            *onResponse(response: AutoRefillResponse) {
                if (response.messageStatus) {
                    yield put(
                        easyRefillToggleAutoRefillRxStatusRoutine.success({
                            rxNumber: rxsToToggle.rxNumber,
                            autoRefillEnabled: rxsToToggle.newStatus,
                            isRenew: rxsToToggle.isRenew
                        })
                    );
                } else {
                    yield put(
                        easyRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occured' })
                    );
                }
            },
            *onError() {
                yield put(easyRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
        if (onSuccess) onSuccess();
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    } catch (error) {
        yield put(easyRefillToggleAutoRefillRxStatusRoutine.failure({ messageText: 'An error has occurred' }));
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

function* updateMedicalConditions(action: PayloadAction<{ onSuccess?: () => void; TemporaryOther: string }>) {
    try {
        const { onSuccess, TemporaryOther } = action.payload;
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        yield baseEffectHandler({
            service: EasyRefillService.medicalConditions().post,
            isAuthenticatedService: false,
            data: { ...action.payload, bearerToken: bearerToken },
            *onResponse() {
                yield put(easyRefillFetchHealthConditionsRoutine.trigger());
                yield put(easyRefillUpdateMedicalConditionsRoutine.success(action.payload));

                /*
                 *  DRX-4349: Short-term solution to allow the user to place order if added "other" health condition and allergy
                 */
                if (TemporaryOther) {
                    storageHelper.cookies.setHealthConditionsTemOther(TemporaryOther);
                }

                TrackFormSuccess('HealthConditions');
                TrackFlowComplete('HealthConditions');
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            },
            *onError() {
                yield put(easyRefillUpdateMedicalConditionsRoutine.failure());
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            }
        });
        if (onSuccess) onSuccess();
    } catch (error) {
        yield put(easyRefillUpdateMedicalConditionsRoutine.failure({ messageText: 'An error has occurred' }));
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

function* updateAllergies(action: PayloadAction<{ onSuccess?: () => void; TemporaryOther: string }>) {
    try {
        const { onSuccess, TemporaryOther } = action.payload;
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        yield baseEffectHandler({
            service: EasyRefillService.allergies().post,
            isAuthenticatedService: false,
            data: { ...action.payload, bearerToken: bearerToken },
            *onResponse() {
                yield put(easyRefillFetchHealthConditionsRoutine.trigger());
                yield put(easyRefillUpdateAllergiesRoutine.success(action.payload));

                /*
                 *  DRX-4349: Short-term solution to allow the user to place order if added "other" health condition and allergy
                 */
                if (TemporaryOther) {
                    storageHelper.cookies.setAllergiesTemOther(TemporaryOther);
                }

                TrackFormSuccess('Allergies');
                TrackFlowComplete('Allergies');
            },
            *onError() {
                yield put(easyRefillUpdateAllergiesRoutine.failure());
            }
        });
        if (onSuccess) onSuccess();
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    } catch (error) {
        yield put(easyRefillUpdateAllergiesRoutine.failure({ messageText: 'An error has occurred' }));
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

const reduceHealthProfileResponse = <P extends APIHealthConditionsPayload, T>(response: P): T => {
    const { messageStatus, messageText, ...reduced } = response;
    return { ...reduced } as T;
};

function* getHealthConditions(): any {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const epostPatientNum = yield select(easyRefillEpostPatientNumSelector);

        const conditionsResponse = yield call(EasyRefillService.medicalConditions().getAll, {
            bearerToken: bearerToken,
            epostPatientNum: epostPatientNum
        });
        const allergiesResponse = yield call(EasyRefillService.allergies().getAll, {
            bearerToken: bearerToken,
            epostPatientNum: epostPatientNum
        });

        if (conditionsResponse.error || allergiesResponse.error) {
            throw conditionsResponse.error ?? allergiesResponse.error;
        } else {
            /*
             *  DRX-4349: Short-term solution to allow the user to place order if added "other" health condition and allergy
             */
            const temporaryOtherHealthConditions = storageHelper.cookies.getHealthConditionsTemOther()
                ? { TemporaryOther: storageHelper.cookies.getHealthConditionsTemOther() }
                : { TemporaryOther: undefined };
            const temporaryOtherAllergies = storageHelper.cookies.getAllergiesTemOther()
                ? { TemporaryOther: storageHelper.cookies.getAllergiesTemOther() }
                : { TemporaryOther: undefined };

            const reducedConditions = reduceHealthProfileResponse<APIMedicalConditionsPayload, MedicalConditions>(
                conditionsResponse.patientCondition
            );
            const reducedAllergies = reduceHealthProfileResponse<APIAllergiesPayload, Allergies>(
                allergiesResponse.patientAllergies
            );
            yield put(
                easyRefillFetchHealthConditionsRoutine.success({
                    medicalConditions: { ...reducedConditions, ...temporaryOtherHealthConditions },
                    allergies: { ...reducedAllergies, ...temporaryOtherAllergies }
                })
            );
        }
    } catch {
        yield put(easyRefillFetchHealthConditionsRoutine.failure());
    }
}

function* getCart(
    action: PayloadAction<{
        onSuccess?: (data: any) => void;
        onFailure?: (data: any) => void;
    }>
) {
    try {
        const { onFailure, onSuccess } = action.payload || {};
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const epostPatientNum: string = yield select(easyRefillEpostPatientNumSelector);
        const isCaregiver: boolean = yield select(easyRefillIsCaregiverSelector);

        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        yield baseEffectHandler({
            service: isCaregiver
                ? EasyRefillService.startOrder().getCaregiverCart
                : EasyRefillService.startOrder().getCart,
            isAuthenticatedService: false,
            data: {
                bearerToken,
                epostPatientNum
            },
            *onResponse(data: EasyRefillCartPayload[]) {
                const result = {
                    cart: data,
                    isCaregiver,
                    epostPatientNum
                };
                if (onSuccess) onSuccess(result);
                yield put(easyRefillGetCartRoutine.success(result));
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            },
            *onError(error) {
                if (onFailure) onFailure(error);
                yield put(easyRefillGetCartRoutine.failure(error));
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            }
        });
    } catch (error) {
        const { onFailure } = action.payload || {};
        if (onFailure) onFailure(error);
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

function* startEasyRefillOrderCart(
    action: PayloadAction<{
        rxNumber: string;
        epostPatientNum: string;
        onSuccess: (data: any) => void;
        onFailure: (data: any) => void;
    }>
) {
    const { rxNumber, onFailure, onSuccess } = action.payload;

    try {
        const easyRefillCartObjects: EasyRefillCartPayload[] = yield select(easyRefillCartSelector);
        const prescriptionsObject: RxDetails[] = yield select(easyRefillRxsSelector);
        const prescriptionsCaregiversObject: RxDetails[] = yield select(
            easyRefillFamilyDependentsPrescriptionsSelector
        );
        const isCaregiver: boolean = yield select(easyRefillIsCaregiverSelector);
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const accountEpostPatientNum: string = yield select(easyRefillEpostPatientNumSelector);
        const selectedAddress: AddressPayload = yield select(easyRefillOrderAddressSelector);
        const selectedPaymentCard: PaymentCard = yield select(easyRefillOrderPaymentCardSelector);
        const selectedOrderBillShip: OrderBillShip = yield select(easyRefillOrderBillShipSelector);
        const userCards: PaymentCard[] = yield select(easyRefillPaymentCardsSelector);
        const userAddresses: AddressPayload[] = yield select(easyRefillAddressesSelector);
        const orderDate = new Date().toISOString();
        // DRX-2008: The epostPatientNum that is inside the RxObject is a number, so we need to convert to validate into the helper.

        const cart = getEasyRefillMainCart(easyRefillCartObjects, String(accountEpostPatientNum));

        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        let currentPrescription: RxDetails | undefined = (
            isCaregiver ? prescriptionsCaregiversObject : prescriptionsObject
        )?.find((obj) => {
            return obj.rxNumber === rxNumber;
        })!;

        const orderBillShipMethodId = selectedOrderBillShip?.shipMethodId || DEFAULT_SHIPPING_ID;

        currentPrescription = { ...currentPrescription, webEligibilityStatus: RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE };
        const isFirstRxInCart = cart?.order.refillRxs.length === 0;
        const defaultOrFirstCard = userCards.find((card) => card.defaultCard) || userCards[0];

        const rxsToOrder: RefillRxs[] = [];
        const rxObject = {
            rxNumber: rxNumber,
            lastRefillScriptId: String(currentPrescription?.prevScriptId),
            originationNum: '6',
            messageText: '',
            messageStatus: true,
            /**
             * DRX-2008
             * @rxLineNumber This property must be initialize as 0.
             */
            rxLineError: 0,
            lineTotal: null,
            fillNote: '',
            epostRxScriptId: '',
            orderNum: '',
            patientCopay: null,
            planNum: '',
            insurancePayment: null,
            messageErrorText: '',
            prescriptionDetail: {}
        };
        rxsToOrder.push(rxObject);

        cart?.order.refillRxs.map((refill) => rxsToOrder.push(refill));

        const orderBillShip = {
            ordShipDate: orderDate,
            orderPaymentOwner: String(accountEpostPatientNum),
            paymentMethodId: '2',
            shipMethodId: isFirstRxInCart ? DEFAULT_SHIPPING_ID : orderBillShipMethodId,
            orderNum: null,
            patientBillAddressSeq: isFirstRxInCart
                ? userAddresses.find((address) => address.defaultBill)?.addressSeqNum
                : selectedAddress.addressSeqNum,
            patientShipAddressSeq: isFirstRxInCart
                ? userAddresses.find((address) => address.defaultBill)?.addressSeqNum
                : selectedAddress.addressSeqNum,
            paymentCardSeqNum: isFirstRxInCart
                ? defaultOrFirstCard.cardSeqNum //DRX-4861
                : selectedPaymentCard.cardSeqNum
        };

        // When updating, consider the medicine cabinet (src/state/cart/cart.sagas.ts:150)
        const updatedCartObject = produce(cart, (draftState) => {
            if (!draftState) return;

            draftState.order.orderBillShip = { ...orderBillShip };
            draftState.order.doNotSubmitToWorkflow = true;
            draftState.order.orderHeader.forceReview = false;
            draftState.order.orderHeader.locationId = cart?.order.orderHeader.locationId;
            draftState.order.orderHeader.orderDate = orderDate;
            draftState.order.orderHeader.orderHighPriority = false;
            draftState.order.orderHeader.orderStatusNum = cart?.order.orderHeader.orderStatusNum || null;
            draftState.order.orderHeader.originationNum = '6';
            draftState.order.orderHeader.workflowTypeNum = '3';
            draftState.order.orderHeader.orderInvoiceNumber = cart?.order.orderHeader.orderInvoiceNumber || null;
            draftState.order.orderHeader.orderNum = cart?.order.orderHeader.orderNum || null;
            draftState.order.originationNum = '6';
            draftState.order.refillRxs = [...rxsToOrder];

            draftState.order.cartId = draftState.order?.cartId || null;

            // DRX-3081; Reset the message* fields on the order, this is needed when attempting to add multiple RXs to the order
            draftState.order.messageErrorText = '';
            draftState.order.messageStatus = true;
            draftState.order.messageText = '';
        });

        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        const payload = { epostPatientNum: accountEpostPatientNum, bearerToken, updatedCartObject };

        yield baseEffectHandler({
            service: isCaregiver
                ? EasyRefillService.startOrder().startCaregiverOrder
                : EasyRefillService.startOrder().startOrder,
            isAuthenticatedService: false,
            data: payload,
            *onResponse(response) {
                const index = easyRefillCartObjects.findIndex(
                    (cart) => cart.epostPatientNum === String(accountEpostPatientNum)
                );
                const updatedCart = easyRefillCartObjects.map((cart) => ({
                    ...cart,
                    order: { ...updatedCartObject?.order, orderBillShip: { ...updatedCartObject?.order.orderBillShip } }
                }));

                if (index !== -1) {
                    updatedCart[index] = {
                        ...response,
                        order: {
                            ...response.order,
                            orderBillShip: { ...updatedCartObject?.order?.orderBillShip },
                            refillRxs: [
                                ...rxsToOrder.map((rx) => {
                                    return rx.rxNumber === rxNumber
                                        ? {
                                              ...rx,
                                              rxLineError: '',
                                              messageStatus: true,
                                              prescriptionDetail: { ...currentPrescription }
                                          }
                                        : rx;
                                })
                            ]
                        }
                    };
                }
                if (onSuccess) onSuccess(updatedCart);
                yield put(easyRefillStartOrderRoutine.success(updatedCart));
            },
            *onError(response) {
                if (onFailure) onFailure(response);
                yield put(easyRefillStartOrderRoutine.failure());
                TrackError('easy-refill.sagas.ts', 'startCartRoutine', response);
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            }
        });
    } catch (e) {
        if (onFailure) onFailure(e);
        yield put(easyRefillStartOrderRoutine.failure());
        TrackError('easy-refill.sagas.ts', 'startCartRoutine', e);
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

/**
 * Order Update Actions
 */

function* updateShippingAddress(
    action: PayloadAction<{ addressSeqNum: string; onSuccess?: () => void; onFailure?: () => void }>
) {
    const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
    const epostPatientNum: string = yield select(easyRefillEpostPatientNumSelector);
    const cartsObject: EasyRefillCartPayload[] = yield select(cartSelector);
    const cartObject = cartsObject?.find(
        (cart) =>
            cart?.order.orderBillShip.patientBillAddressSeq !== null ||
            cart?.order?.orderBillShip?.dependentAddressSeqNum
    )
        ? cartsObject?.find((cart) => cart?.order.orderBillShip.patientBillAddressSeq !== null)
        : getEasyRefillMainCart(cartsObject, epostPatientNum);

    const selectedShippingMethodId =
        cartObject?.order.orderBillShip.shipMethodId ||
        cartsObject?.find((cart) => cart?.order.orderBillShip.shipMethodId !== null)?.order.orderBillShip
            .shipMethodId ||
        DEFAULT_SHIPPING_ID;

    const cartObjectPayload = produce(cartObject, (draftCartObject) => {
        if (draftCartObject) {
            draftCartObject.order.orderBillShip.patientBillAddressSeq = action.payload.addressSeqNum;
            draftCartObject.order.orderBillShip.patientShipAddressSeq = action.payload.addressSeqNum;
            draftCartObject.order.orderBillShip.dependentAddressSeqNum = action.payload.addressSeqNum;
            draftCartObject.order.orderBillShip.shipMethodId = selectedShippingMethodId;
        }
    });

    yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
    yield baseEffectHandler({
        service: EasyRefillService.updateCart().put,
        isAuthenticatedService: false,
        data: {
            bearerToken,
            epostPatientNum,
            updatedCartObject: cartObjectPayload
        },
        *onResponse() {
            const { onSuccess } = action.payload;
            if (onSuccess) onSuccess();
            yield put(easyRefillUpdateShippingRoutine.success());
        },
        *onError(data) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(easyRefillUpdateShippingRoutine.failure());
            yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
        }
    });
}

function* updatePaymentMethods(
    action: PayloadAction<{ paymentCardSeqNum: string; onSuccess?: () => void; onFailure?: () => void }>
) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const epostPatientNum: string = yield select(easyRefillEpostPatientNumSelector);
        const cartsObject: EasyRefillCartPayload[] = yield select(cartSelector);
        const cartObject = cartsObject?.find((cart) => cart?.order.orderBillShip.paymentCardSeqNum !== null)
            ? cartsObject?.find(
                  (cart) =>
                      cart?.order.orderBillShip.paymentCardSeqNum || cart?.order?.orderBillShip?.dependentPaymentSeqNum
              )
            : getEasyRefillMainCart(cartsObject, epostPatientNum);
        const addresses: AddressPayload[] = yield select(easyRefillAddressesSelector);

        const shipMethodId: string = yield select(easyRefillOrderBillShipMethodSelector);
        const orderBillShipAddress: AddressPayload = yield select(easyRefillOrderAddressSelector);

        const selectedAddressSeqNum =
            orderBillShipAddress?.addressSeqNum ||
            addresses.find((address) => address.defaultAddress)?.addressSeqNum ||
            addresses[0]?.addressSeqNum;

        const selectedShippingMethod = shipMethodId || DEFAULT_SHIPPING_ID;

        const cartObjectPayload = produce(cartObject, (draftCartObject) => {
            if (draftCartObject) {
                draftCartObject.order.orderBillShip.paymentCardSeqNum = action.payload.paymentCardSeqNum;
                draftCartObject.order.orderBillShip.dependentPaymentSeqNum = action.payload.paymentCardSeqNum;
                draftCartObject.order.orderBillShip.shipMethodId = selectedShippingMethod;
                draftCartObject.order.orderBillShip.patientBillAddressSeq = selectedAddressSeqNum;
                draftCartObject.order.orderBillShip.patientShipAddressSeq = selectedAddressSeqNum;
            }
        });

        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        yield baseEffectHandler({
            service: EasyRefillService.updateCart().put,
            isAuthenticatedService: false,
            data: {
                bearerToken,
                epostPatientNum,
                updatedCartObject: cartObjectPayload
            },
            *onResponse() {
                const { onSuccess } = action.payload;
                yield put(easyRefillGetCartRoutine.trigger());
                yield take(easyRefillGetCartRoutine.SUCCESS);
                yield put(easyRefillUpdateCartPaymentRoutine.success());
                if (onSuccess) onSuccess();
            },
            *onError(data) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
                yield put(easyRefillUpdateCartPaymentRoutine.failure());
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            }
        });
    } catch (e) {
        const { onFailure } = action.payload;
        if (onFailure) onFailure();
        yield put(easyRefillUpdateCartPaymentRoutine.failure());
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

function* updateShippingMethod(
    action: PayloadAction<{
        orderHighPriority: boolean;
        shipMethodId: string;
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    const { orderHighPriority, shipMethodId, onSuccess, onFailure } = action.payload;

    const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
    const epostPatientNum: string = yield select(easyRefillEpostPatientNumSelector);
    const cartsObject: any[] = yield select(cartSelector);
    const cartObject = cartsObject?.find((cart) => cart?.order.orderBillShip.shipMethodId !== null)
        ? cartsObject?.find((cart) => cart?.order.orderBillShip.shipMethodId !== null)
        : getEasyRefillMainCart(cartsObject, epostPatientNum);

    const cartObjectPayload = produce(cartObject, (draftCartObject) => {
        if (draftCartObject) {
            draftCartObject.order.orderBillShip.shipMethodId = shipMethodId;
            draftCartObject.order.orderHeader.orderHighPriority = orderHighPriority;
        }
    });
    yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
    yield baseEffectHandler({
        service: EasyRefillService.updateCart().put,
        isAuthenticatedService: false,
        data: {
            bearerToken,
            epostPatientNum,
            updatedCartObject: cartObjectPayload
        },
        *onResponse() {
            if (onSuccess) onSuccess();
            yield put(easyRefillUpdateShippingMethodRoutine.success());
        },
        *onError(data) {
            if (onFailure) onFailure();
            yield put(easyRefillUpdateShippingMethodRoutine.failure());
            yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
        }
    });
}

function* getPatientPlans(
    action: PayloadAction<{
        familyMembers: any[];
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    const isCaregiver: boolean = yield select(easyRefillIsCaregiverSelector);

    const { familyMembers, onSuccess, onFailure } = action.payload;

    // DRX-2425: Due to differences in the response objects for patient data, we must handle
    // the passing of epostPatientNum in a way that maintains legibility and simplifies the service.
    let members: string[];

    if (isCaregiver) {
        // Returns the ePostPatientNum for every patient y family account (Caregiver)
        members = familyMembers.map((member) => member.value);
    } else {
        // Return the ePostPatientNum of the user that is in the unauth flow (Normal Account)
        members = familyMembers;
    }

    const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

    yield baseEffectHandler({
        service: EasyRefillService.getPatientPlans().get,
        isAuthenticatedService: false,
        data: {
            bearerToken,
            members
        },
        *onResponse(data) {
            if (onSuccess) onSuccess();
            yield put(easyRefillGetPatientPlansRoutine.success(data));
        },
        *onError(data) {
            if (onFailure) onFailure();
            yield put(easyRefillGetPatientPlansRoutine.failure(data));
        }
    });
}

function* cancelOrderLineSaga(action: PayloadAction<CancelOrderLinePayload>) {
    try {
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);
        const epostNumPatient = action.payload.epostNumPatient;
        const rxNumber: string = action.payload.rxNumber;
        const lineId: string = action.payload.lineId;
        const orderNum: string = action.payload.orderNum;

        yield put(setEasyRefillCartLoading({ isLoadingCart: true }));
        yield baseEffectHandler({
            service: EasyRefillService.cancelOrderLine().post,
            isAuthenticatedService: false,
            data: {
                bearerToken,
                rxNumber,
                lineId,
                orderNum,
                epostNumPatient
            },
            *onResponse(data: CancelOrderLineResponse) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(easyRefillCancelOrderLineRoutine.success({ data, rxNumber }));
                yield put(easyRefillGetPatientDataRoutine.trigger({ withoutLoading: true }));
            },
            *onError() {
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
                yield put(easyRefillCancelOrderLineRoutine.failure());
                yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
            }
        });
    } catch (e: unknown) {
        const { onFailure } = action.payload;
        if (onFailure) onFailure();
        yield put(easyRefillUpdateShippingRoutine.failure());
        TrackError('easy-refill.sagas.ts', 'updateShippingAddress', e as string | object);
        yield put(setEasyRefillCartLoading({ isLoadingCart: false }));
    }
}

function* submitOrder(action: PayloadAction<any>) {
    const {
        payload: { onSuccess, onFailure, lineItems }
    } = action;

    try {
        const cartsObject: EasyRefillCartPayload[] = yield select(cartSelector);
        const creditCards: PaymentCard[] = yield select(easyRefillPaymentCardsSelector);
        const epostPatientNum: string = yield select(easyRefillEpostPatientNumSelector);
        const isCaregiver: boolean = yield select(easyRefillIsCaregiverSelector);
        const defaultAddress: AddressPayload = yield select(easyRefillPatientAddressSelector);
        const bearerToken: string = yield select(easyRefillUserBearerTokenSelector);

        const defaultCreditCard =
            creditCards !== undefined && creditCards.length > 0
                ? creditCards.find((obj: PaymentCard) => obj.defaultCard)?.cardSeqNum
                : null;

        const updatedCartObject = produce(cartsObject, (draftState) => {
            if (draftState) {
                draftState = draftState.map((orderCart: EasyRefillCartPayload) => {
                    const cartItem: EasyRefillCartPayload = { ...orderCart };

                    // Use default address there is no address selected
                    if (defaultAddress !== undefined) {
                        cartItem.order.orderBillShip.patientBillAddressSeq =
                            orderCart.order.orderBillShip.patientBillAddressSeq ?? defaultAddress.addressSeqNum;
                        cartItem.order.orderBillShip.patientShipAddressSeq =
                            orderCart.order.orderBillShip.patientShipAddressSeq ?? defaultAddress.addressSeqNum;
                    }

                    // Use default credit card there is no credit card selected
                    if (defaultCreditCard !== undefined) {
                        cartItem.order.orderBillShip.paymentCardSeqNum =
                            orderCart.order.orderBillShip.paymentCardSeqNum ?? defaultCreditCard?.cardSeqNum;
                    }

                    cartItem.order.orderBillShip.paymentMethodId =
                        orderCart.order.orderBillShip.paymentMethodId === '6' &&
                        orderCart.order.orderBillShip.paymentCardSeqNum
                            ? '2'
                            : orderCart.order.orderBillShip.paymentMethodId;

                    if (lineItems !== undefined) {
                        const cleanRefillRxs: RefillRxs[] = [];

                        orderCart.order.refillRxs.forEach((rx: RefillRxs) => {
                            const currentPrescription = lineItems.find((lineItem: RefillRxs) => {
                                return rx.rxNumber === lineItem.rxNumber;
                            });
                            if (currentPrescription) {
                                rx.prescriptionDetail = {};

                                // Exclude extra attributes included in the ExtendedRefillRxs object
                                const cleanRefillRx: RefillRxs & { [key: string]: any } = rx;

                                Object.keys(rx).forEach(function (key) {
                                    cleanRefillRx[key] = currentPrescription[key];
                                    cleanRefillRx.prescriptionDetail = {};
                                });
                                cleanRefillRxs.push(cleanRefillRx);
                            } else {
                                rx.prescriptionDetail = {};
                                cleanRefillRxs.push(rx);
                            }
                        });
                        cartItem.order.refillRxs = cleanRefillRxs;
                    }
                    return cartItem;
                });
            }
        });

        const payload = { epostPatientNum, bearerToken, updatedCartObject };
        yield baseEffectHandler({
            service: isCaregiver
                ? EasyRefillService.completeOrder().postCaregiver
                : EasyRefillService.completeOrder().post,
            isAuthenticatedService: false,
            data: payload,
            *onResponse(data) {
                if (
                    data[0].order.messageErrorText === 'PRE-AUTH-DENIED' ||
                    data[0].messageErrorText === 'PRE-AUTH-DENIED'
                ) {
                    yield put(easyRefillSubmitOrderRoutine.failure({ cart: data, orderPlaced: false, isCaregiver }));
                    if (onFailure) onFailure(data);
                    return;
                }
                yield put(easyRefillSubmitOrderRoutine.success({ cart: data, orderPlaced: true, isCaregiver }));
                if (onSuccess) onSuccess();
            },
            *onError(data) {
                yield put(easyRefillSubmitOrderRoutine.failure({ cart: data, orderPlaced: false, isCaregiver }));
                if (onFailure) onFailure(data);
            }
        });
    } catch (e: Error | unknown) {
        yield put(easyRefillSubmitOrderRoutine.failure());
        if (onFailure) onFailure();
        TrackError('cart.sagas.ts', 'cartCompleteOrderRoutine', e as Error);
    }
}

function* easyRefillSaga() {
    yield takeLatest(easyRefillVerifyUserRoutine.TRIGGER, verifyUserSaga);
    yield takeLatest(easyRefillAuthenticateUserRoutine.TRIGGER, authenticateUserSaga);
    yield takeLatest(easyRefillGetSecretKeyHashRoutine.TRIGGER, generateSecretKeySaga);
    yield takeLatest(easyRefillGetPatientDataRoutine.TRIGGER, getPatientDataSaga);
    yield takeLatest(easyRefillGetPatientAddressesRoutine.TRIGGER, getPatientAddressesSaga);
    yield takeLatest(easyRefillAddAddressesRoutine.TRIGGER, addPatientAddressesSaga);
    yield takeLatest(easyRefillUpdateAddressesRoutine.TRIGGER, updatePatientAddressSaga);
    yield takeLatest(easyRefillSetPrimaryAddressRoutine.TRIGGER, setPrimaryAddressSaga);
    yield takeLatest(easyRefillGetPatientPaymentCardsRoutine.TRIGGER, getPatientPaymentCardsSaga);
    yield takeLatest(easyRefillAddPaymentCardsRoutine.TRIGGER, addPaymentMethodSaga);
    yield takeLatest(easyRefillUpdatePaymentCardsRoutine.TRIGGER, updatePaymentMethodSaga);
    yield takeLatest(easyRefillPaymetricDetailsRoutine.TRIGGER, getPaymetricCredentials);
    yield takeLatest(easyRefillTokenizedCardNumberRoutine.TRIGGER, getTokenizedCardNumber);
    yield takeLatest(easyRefillRemoveRxToRefillRoutine.TRIGGER, removeRxsToRefillSaga);
    yield takeLatest(easyRefillAddRxToRefillRoutine.TRIGGER, addRxsToRefillSaga);
    yield takeLatest(easyRefillRemoveAllRxToRefillRoutine.TRIGGER, removeAllRxsToRefillSaga);
    yield takeLatest(easyRefillToggleAutoRefillRxStatusRoutine.TRIGGER, toggleAutoRefillForRxSaga);
    yield takeLatest(easyRefillUpdateMedicalConditionsRoutine.TRIGGER, updateMedicalConditions);
    yield takeLatest(easyRefillUpdateAllergiesRoutine.TRIGGER, updateAllergies);
    yield takeLatest(easyRefillFetchHealthConditionsRoutine.TRIGGER, getHealthConditions);
    yield takeLatest(easyRefillGetCartRoutine.TRIGGER, getCart);
    yield takeLatest(easyRefillStartOrderRoutine.TRIGGER, startEasyRefillOrderCart);
    yield takeLatest(easyRefillUpdateShippingRoutine.TRIGGER, updateShippingAddress);
    yield takeLatest(easyRefillUpdateCartPaymentRoutine.TRIGGER, updatePaymentMethods);
    yield takeLatest(easyRefillUpdateShippingMethodRoutine.TRIGGER, updateShippingMethod);
    yield takeLatest(easyRefillGetPatientPlansRoutine.TRIGGER, getPatientPlans);
    yield takeLatest(easyRefillCancelOrderLineRoutine.TRIGGER, cancelOrderLineSaga);
    yield takeLatest(easyRefillSubmitOrderRoutine.TRIGGER, submitOrder);
}

export default easyRefillSaga;
