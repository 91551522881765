import PhoneAppsWork from 'assets/images/phone-apps-work.svg';
import { graphql, navigate } from 'gatsby';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';

import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { accountProfileSelector } from 'state/account/account.selectors';

import { maskPhoneNumber, removeChars } from 'util/string';

import { useOnboardingRedirection } from 'hooks/useOnboardingRedirection';

import { OnboardingFooterInformation } from '../../../../components/onboarding-footer-information/onboarding-footer-information';
import './mobile-phone-confirm-your-number.styles.scss';

const MobilePhoneSetupConfirmYourNumber = () => {
    const { t } = useTranslation();
    const { shouldSetHealthProfile } = useOnboardingRedirection(false);
    const profile = useSelector(accountProfileSelector);
    const mainPhone = profile?.phones?.[0];
    const completeNumber = mainPhone ? removeChars(mainPhone?.uiPhone) : '';

    useEffect(() => {
        if (shouldSetHealthProfile === undefined) return;

        if (shouldSetHealthProfile) navigate('/secure/onboarding/health-profile');
    }, [shouldSetHealthProfile]);

    return (
        <WorkflowLayout
            className="mobile-phone-setup-page-confirm-your-number"
            metaData={{ nodeTitle: 'Mobile Phone - Confirm your number' }}
            isUnauthenticatedFlow={false}
        >
            <LayoutWrapperComponent
                workflowNavigation={<WorkflowNavigationSection limit={5} usage={4} customColor="picton-blue" />}
            >
                <div className="mobile-phone-setup-page-confirm-your-number__container">
                    <div className="mobile-phone-setup-page-confirm-your-number__image">
                        <img src={PhoneAppsWork} alt="Phone Apps Work" />
                    </div>

                    <div className="mobile-phone-setup-page-confirm-your-number__content">
                        <h2>{t('pages.onboarding.mobilePhoneConfirmationYourNumber.title')}</h2>
                        <Trans>
                            <p>
                                {t('pages.onboarding.mobilePhoneConfirmationYourNumber.subtitle', {
                                    phoneNumber: maskPhoneNumber(completeNumber)
                                })}
                            </p>
                        </Trans>
                    </div>

                    <div className="mobile-phone-setup-page-confirm-your-number__actions">
                        <ButtonComponent
                            label={t('pages.onboarding.mobilePhoneConfirmationYourNumber.buttons.continue')}
                            full
                            onClick={() =>
                                navigate(
                                    `/secure/onboarding/mobile-phone-validation-setup?phoneNumber=${completeNumber}`
                                )
                            }
                        />
                        <ButtonComponent
                            label={t('pages.onboarding.mobilePhoneConfirmationYourNumber.buttons.changePhoneNumber')}
                            variant="ghost"
                            full
                            onClick={() => navigate('/secure/onboarding/mobile-phone-setup')}
                        />
                    </div>

                    <OnboardingFooterInformation />
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default MobilePhoneSetupConfirmYourNumber;

export const query = graphql`
    query MobilePhoneSetupConfirmYourNumberPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
