import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ToastBox from 'ui-kit/toast-box/toast-box';

// Components & UI
import { CartShipping } from 'components/cart/cart-shipping.component';
import { CartShippingProps } from 'components/cart/types';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartUpdateExpeditedShippingRoutine, getCartRoutine } from 'state/cart/cart.routines';
import { cartOrderBillShipMethodSelector } from 'state/cart/cart.selectors';

// Cart
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_ID } from 'util/cart';

import './shipping-method.style.scss';

const ShippingMethod: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleDefaultShippingClick = useCallback<NonNullable<CartShippingProps['handleDefaultShippingClick']>>(() => {
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: false,
                shipMethodId: DEFAULT_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    columnSectionRef.current?.handleSaveChangesButtonClick();
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(getCartRoutine.trigger());
                    return false;
                }
            })
        );
    }, [dispatch, showShippingError]);

    const handleExpeditedShippingClick = useCallback<
        NonNullable<CartShippingProps['handleExpeditedShippingClick']>
    >(() => {
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: true,
                shipMethodId: EXPEDITED_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    columnSectionRef.current?.handleSaveChangesButtonClick();
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(getCartRoutine.trigger());
                    return false;
                }
            })
        );
    }, [dispatch, showShippingError]);

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="shipping-method__content"
            className="shipping-method"
            editModeContent={
                <CartShipping
                    handleDefaultShippingClick={handleDefaultShippingClick}
                    handleExpeditedShippingClick={handleExpeditedShippingClick}
                    shipMethodId={shipMethodId}
                    t={t}
                />
            }
            headerClassName="shipping-method__header"
            title={t('components.medicineCabinetCart.shippingMethod.title')}
        >
            <>
                <p className="shipping-method__selected">
                    {t(
                        `components.medicineCabinetCart.shippingMethod.${
                            shipMethodId === EXPEDITED_SHIPPING_ID ? 'expeditedShipping' : 'freeShipping'
                        }`
                    )}
                </p>
                <ToastBox icon="default" variant="info">
                    {t('components.medicineCabinetCart.shippingMethod.footer')}
                </ToastBox>
            </>
        </ColumnSectionEditModeToggle>
    );
};

export default ShippingMethod;
