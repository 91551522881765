// General
import { graphql, navigate } from 'gatsby';
// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Ui-kit
import PageSection from 'ui-kit/page-section/page-section';

import { MembershipAvailablePlanCardProps } from 'display-components/membership/available-plan/card';
import MembershipAvailablePlanCardList from 'display-components/membership/available-plan/card-list';
import MembershipCartTotals from 'display-components/membership/membership-cart-totals';
import PageSectionTitle from 'display-components/page-section-title';

import Footer from 'components/footer/footer.component';
import InternalHeader from 'components/internal-header/internal-header.component';
// Components
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import { BadgePath, NavPath } from 'components/navigation/navigation.props';
import PaymentCard from 'components/payment-methods/payment-card/payment-card.component';
import QuickLink from 'components/quick-link/quick-link.component';

import { accountCreditCardsSelector } from 'state/account/account.selectors';
import { membershipRegistrationSelectPlanListSelector } from 'state/membership-registration/membership-registration.selectors';
import {
    membershipIsCarePlanSelector,
    membershipIsCarePlusPlanSelector,
    membershipIsValidPromoCodeSelector,
    membershipSelectedPlanToUpgradeSelector,
    membershipUpgradedPlanSelector,
    membershipUpgradePlanPaymentModelSelector,
    membershipUpgradePlanPricingDetailsSelector
} from 'state/membership/membership.selector';

import { BREAKPOINTS } from 'const/breakpoints';

import { formatPrice } from 'schema/price.schema';

import { CreditCardPayload } from 'types/card';

import { DEFAULT_MEMBERSHIP_JOIN_FEE } from 'util/membership';
import { convertToTitleCase } from 'util/string';

import useWindowDimensions from 'hooks/useWindowDimensions';

// Styles
import './membership-upgrade-your-plan-page-success.style.scss';

// Main component
const MembershipUpgradePlanSuccessPage = ({ data }: { data: GatsbyTypes.MembershipUpgradePlanPageDataQuery }) => {
    // Hooks
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    // General
    const { whiteFeathersBackground } = data;

    const upgradedPlan = useSelector(membershipUpgradedPlanSelector);
    const selectedPlanToUpgrade = useSelector(membershipSelectedPlanToUpgradeSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const planDetails = useSelector(membershipRegistrationSelectPlanListSelector);
    const pricingDetails = useSelector(membershipUpgradePlanPricingDetailsSelector);
    const isCarePlusPlan = useSelector(membershipIsCarePlusPlanSelector);
    const isCarePlan = useSelector(membershipIsCarePlanSelector);
    const paymentModel = useSelector(membershipUpgradePlanPaymentModelSelector);
    const isValidPromoCode = useSelector(membershipIsValidPromoCodeSelector);

    if (!upgradedPlan || !selectedPlanToUpgrade || !allPaymentData || !planDetails) {
        navigate('/secure/profile/membership');
    }

    const selectedPlanPrice = pricingDetails.find((plan) => plan.PlanId === selectedPlanToUpgrade.membershipPlanId);
    const discountChargeAmount = selectedPlanPrice?.DiscountChargeAmount || 0;
    const planDiscountAmount =
        paymentModel === 'Annually' ? selectedPlanPrice?.AnnualDiscount || 0 : selectedPlanPrice?.MonthlyDiscount || 0;
    const appliedDiscountValue = discountChargeAmount || planDiscountAmount || undefined;
    const selectedPlanDetails = planDetails?.find(
        (detailedPlan) => detailedPlan.membershipPlanId === upgradedPlan.planIdNew
    );
    const membershipAmountDue = upgradedPlan?.differencePay as number;

    const selectedPaymentMethod = useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0
            ? allPaymentData.find((card) => card.cardSeqNum === selectedPlanToUpgrade.cardSeqNum)
            : allPaymentData?.find((card) => card.defaultCard);
    }, [allPaymentData]);

    const newPlan = useMemo(() => {
        let planPricing = 0;
        if (paymentModel === 'Annually') {
            planPricing = selectedPlanDetails?.membershipPlanAttributes[0].pricingAnnual
                ? selectedPlanDetails?.membershipPlanAttributes[0].pricingAnnual / 12
                : 0;
        } else {
            planPricing = selectedPlanDetails?.membershipPlanAttributes[0].pricingMonthly || 0;
        }

        const response: MembershipAvailablePlanCardProps[] = [
            {
                membershipPlanId: selectedPlanDetails?.membershipPlanId as number,
                planName: selectedPlanDetails?.membershipPlanDescription as string,
                availableMembers: selectedPlanDetails?.membershipPlanAttributes[0].membershipMembers as number,
                price: formatPrice(planPricing),
                joinFee: formatPrice(selectedPlanDetails?.membershipPlanAttributes[0].joinFeeAmount),
                savePercentage: paymentModel === 'Annually' ? 15 : undefined,
                priceAccumulated:
                    paymentModel === 'Annually'
                        ? {
                              total: selectedPlanDetails?.membershipPlanAttributes[0].pricingAnnual || 0,
                              monthlyFrequency: 12,
                              showBilled: false
                          }
                        : undefined,
                features: [
                    {
                        name: 'prescriptions',
                        total: selectedPlanDetails?.membershipPlanAttributes[0].membershipIncludedrx,
                        monthlyFrequency: 12
                    },
                    {
                        name: 'telemedicineVisits',
                        total: selectedPlanDetails?.membershipPlanAttributes[0].membershipIncludedvisits,
                        monthlyFrequency: 12
                    }
                ]
            }
        ];
        return response;
    }, [planDetails, upgradedPlan]);

    const paymentCard = {
        cardHolder: convertToTitleCase(selectedPaymentMethod?.cardName as string),
        cardType: selectedPaymentMethod?.cardType as string,
        endingDigits: selectedPaymentMethod?.secureCardNumber.slice(-4) as string,
        expiryMonth: selectedPaymentMethod?.cardMonthNum as string,
        expiryYear: selectedPaymentMethod?.cardYear.slice(-2) as string,
        key: 'payment-card',
        isDefaultCard: selectedPaymentMethod?.defaultCard as boolean,
        showLabel: true
    };

    return (
        <>
            <WorkflowLayout
                backgroundImage={whiteFeathersBackground}
                metaData={{ nodeTitle: t('pages.membershipUpgradeYourPlan.metadata') }}
                suppressApplicationPage={false}
                showMainMenu
            >
                <PageSection className="page-layout">
                    <Container fluid className="membership-upgrade-your-plan-page-success">
                        <InternalHeader
                            title={t('pages.membershipUpgradeYourPlanSuccess.header.title')}
                            eyebrowText={t('pages.membershipUpgradeYourPlanSuccess.header.eyebrowText')}
                            sectionIndex={0}
                            body={<p>{t('pages.membershipUpgradeYourPlanSuccess.instructions.textAboutActivation')}</p>}
                            columnWidthOnDesktop={12}
                            variant="blue-background"
                            showSpacer
                        />
                        <Row className="membership-upgrade-your-plan-page-success__content-container">
                            <Col xs={12} md={12} lg={8}>
                                <div className="membership-upgrade-your-plan-page-success__available-plans-container">
                                    <PageSectionTitle
                                        title={t('pages.membershipUpgradeYourPlanSuccess.pageSections.yourPlan')}
                                    />
                                    <MembershipAvailablePlanCardList
                                        planList={newPlan}
                                        className="membership-success-plan-card-display-only"
                                    />
                                </div>

                                <div className="membership-upgrade-your-plan-page-success__available-plans-container">
                                    <PageSectionTitle
                                        title={t('pages.membershipUpgradeYourPlanSuccess.pageSections.paymentInfo')}
                                    />
                                    <div className="membership-upgrade-your-plan-page-success__payment-card">
                                        <PaymentCard
                                            {...paymentCard}
                                            card={selectedPaymentMethod as CreditCardPayload}
                                            isBorderlessCard={width >= BREAKPOINTS.lg}
                                        />
                                    </div>
                                </div>
                                <div className="membership-upgrade-your-plan-page-success__cart-total">
                                    <MembershipCartTotals
                                        t={t}
                                        appliedDiscount={appliedDiscountValue}
                                        defaultFee={DEFAULT_MEMBERSHIP_JOIN_FEE}
                                        feeTotal={selectedPlanPrice ? selectedPlanPrice.JoinFee : 0}
                                        initialChargeAmount={
                                            appliedDiscountValue
                                                ? membershipAmountDue + appliedDiscountValue
                                                : membershipAmountDue
                                        }
                                        wasCarePlan={isCarePlusPlan}
                                        wasOnDemandPlan={isCarePlan}
                                        isSuccessPage
                                        membershipTotal={membershipAmountDue}
                                        paymentModel={paymentModel}
                                        promoCode={isValidPromoCode ? selectedPlanToUpgrade.promoCode : undefined}
                                    />
                                </div>
                                <div className="membership-upgrade-your-plan-page-success__links">
                                    <PageSectionTitle
                                        title={t('pages.membershipUpgradeYourPlanSuccess.pageSections.quickLinks')}
                                    />
                                    <div className="membership-upgrade-your-plan-page-success__links__list">
                                        <QuickLink
                                            type="link"
                                            icon="visits-fullsize-blue"
                                            iconVariant="dark"
                                            title={t(
                                                'pages.membershipUpgradeYourPlanSuccess.quickLinks.requestTelemedicineVisit'
                                            )}
                                            to="/secure/membership/request-visit"
                                            dataGALocation="Request a Telemedicine visit"
                                            hideChevron
                                            alignCenter
                                            className="quick-links-success-page"
                                        />
                                        <QuickLink
                                            type="link"
                                            icon="plane-blue"
                                            iconVariant="dark"
                                            title={t(
                                                'pages.membershipUpgradeYourPlanSuccess.quickLinks.manageMembership'
                                            )}
                                            to="/secure/profile/membership"
                                            dataGALocation="Manage your membership"
                                            hideChevron
                                            alignCenter
                                            className="quick-links-success-page"
                                        />
                                    </div>
                                    {/* Quick links component */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </PageSection>
            </WorkflowLayout>

            <Footer
                copyrightYear={new Date().getFullYear().toString()}
                aboutUsPaths={data.allMenuLinkContentFooter.nodes as NavPath[]}
                socialMediaPaths={data.allMenuLinkContentFooterSocial.nodes as NavPath[]}
                howCanWeHelpPaths={data.allMenuLinkContentFooterSupport.nodes as NavPath[]}
                legalPaths={data.allMenuLinkContentFooterLegal.nodes as NavPath[]}
                solutionsPaths={data.allMenuLinkContentFooterCompany.nodes as NavPath[]}
                getAppPaths={data.allMenuLinkContentFooterGetTheApp.nodes as BadgePath[]}
                contactPaths={data.allMenuLinkContentFooterContact.nodes as NavPath[]}
                groupsPaths={data.allMenuLinkContentFooterGroupsBusiness.nodes as NavPath[]}
                physicalAddress={data.allBlockContentAddresses.nodes[0]?.field_physical_address}
                mailingAddress={data.allBlockContentAddresses.nodes[0]?.field_mailing_address}
                specialtyMailingAddress={data.allBlockContentAddresses.nodes[0]?.field_specialty_mailing_address}
                specialtyPhysicalAddress={data.allBlockContentAddresses.nodes[0]?.field_specialty_physical_address}
            />
        </>
    );
};

export default MembershipUpgradePlanSuccessPage;

export const query = graphql`
    query MembershipUpgradePlanSuccessPage($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        whiteFeathersBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentAlertBanner(
            sort: { fields: changed, order: DESC }
            filter: { field_alert_active: { eq: true } }
        ) {
            nodes {
                field_alert_text
                field_alert_priority_level
                field_alert_permanent
                field_alert_link {
                    title
                    uri: url
                }
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
                field_specialty_mailing_address
                field_specialty_physical_address
            }
        }
        allMenuLinkContentMain(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                isButton: field_menu_cta
                link {
                    url
                }
                label: title
                requiresAccountAuth: field_requires_account_auth
                requiresMembership: field_requires_membership
                isMobile: field_menu_only_mobile
                buttonVariant: field_menu_button_variant
                langcode
            }
        }
        allMenuLinkContentFooter(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterSocial(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterSupport(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                field_menu_body {
                    processed
                }
                langcode
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterCompany(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterGetTheApp(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                badgeType: field_badge_type
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allMenuLinkContentFooterContact(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                field_menu_body {
                    processed
                }
                langcode
            }
        }
        allMenuLinkContentFooterGroupsBusiness(
            sort: { order: ASC, fields: weight }
            filter: { enabled: { eq: true } }
        ) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allNodeApplicationPage {
            nodes {
                title
                id
                drupal_internal__nid
                relationships {
                    field_application_page_content {
                        ...supportedParagraphs
                    }
                }
                path {
                    langcode
                    alias
                }
                field_ap_show_on_subpages
                field_meta_tags {
                    title
                    description
                    abstract
                    keywords
                }
            }
        }
    }
`;
