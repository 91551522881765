import { createSelector } from '@reduxjs/toolkit';

import { drugSelector } from 'state/drug/drug.selectors';
import { MembershipPlanId } from 'state/membership/membership.selector';
import { RootState } from 'state/store';

import { AddressesResponse, PaymentCardsResponse } from 'types/auto-refill';

import { BIRDI_PLANS } from 'enums/plans';

import { mapEasyRefillFamilyPricingData } from 'util/easy-refill';
import { mapRxPrescriptionCards } from 'util/prescription';

export const autoRefillSelector = (state: RootState) => state.autoRefillReducer;

export const autoRefillUserVerificationFailedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.userVerified !== 'UserVerified';
});

export const autoRefillUserVerifiedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.userVerified === 'UserVerified';
});

export const autoRefillSecurityTokenSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.securityToken;
});

export const isLoadingPatientDataSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isLoadingPatientData;
});

export const autoRefillSecretKeyHashSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.secretKeyHash;
});

export const autoRefillErrorMessageSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.error.messageText ?? '';
});

export const autoRefillUserBearerTokenSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.bearerToken;
});

export const autoRefillFirstNameSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.firstName;
});

export const autoRefillLoadingRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.loadingPrescriptions;
});

export const autoRefillRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.rxResults;
});

export const autoRefillRxsLoadedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.prescriptionsLoaded;
});

export const autoRefillOrderInvoiceNumberSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.orderInvoiceNumber;
});

export const autoRefillFlagsSelector = createSelector(autoRefillSelector, (autoRefill) => autoRefill.autoRefillFlags);

export const autoRefillFamilyDataSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.familyDependents;
});

export const autoRefillEligibleFamilyDataSelector = createSelector(autoRefillFamilyDataSelector, (familyDependents) => {
    return familyDependents.map((patient) => ({
        ...patient,
        rxResults: [...patient.rxResults.filter((rx) => rx.autoRefillEligible)]
    }));
});

export const autoRefillAdressesSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.addresses;
});

export const autoRefillDefaultAddressSelector = createSelector(autoRefillAdressesSelector, (addresses) => {
    return addresses.find((address) => address.defaultShip);
});

export const autoRefillZipCodeSelector = createSelector([autoRefillDefaultAddressSelector], (defaultAddress) => {
    return defaultAddress?.zipcode ?? null;
});

export const autoRefillAcCodeSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.planAlias;
});

export const autoRefillePostPatientNumberSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.epostPatientNum;
});
export const autoRefillFamilyMembersPricingDataSelector = createSelector(
    [
        autoRefillFamilyDataSelector,
        autoRefillAcCodeSelector,
        autoRefillZipCodeSelector,
        autoRefillePostPatientNumberSelector
    ],
    (autoRefillFamilyDependents, mainUserPlanAlias, mainUserZipCode, epostPatientNum) =>
        mapEasyRefillFamilyPricingData(
            autoRefillFamilyDependents,
            mainUserPlanAlias as BIRDI_PLANS,
            mainUserZipCode || '',
            epostPatientNum,
            // TODO: API is not giving us the value, ensure that this is replaced with the right property
            MembershipPlanId.NONE
        )
);
export const autoRefillActiveTabSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.activeTab;
});

export const autoRefillPrescriptionsCardsSelector = createSelector(
    [
        autoRefillEligibleFamilyDataSelector,
        autoRefillFamilyMembersPricingDataSelector,
        autoRefillZipCodeSelector,
        autoRefillAcCodeSelector,
        autoRefillActiveTabSelector,
        drugSelector
    ],
    (familyData, familyPricingData, mainUserZipCode, accountPlanAlias, activeTab, { drugDiscountPrices }) => {
        if (familyData.length <= 0) return [];

        const activeTabData = familyData.find((dependent) => dependent.epostPatientNum === activeTab);

        if (!activeTabData || activeTabData.rxResults.length <= 0) return [];

        return mapRxPrescriptionCards(
            activeTabData?.rxResults,
            familyPricingData,
            drugDiscountPrices,
            [],
            null,
            mainUserZipCode,
            null
        );
    }
);

export const selectedMemberDataSelector = createSelector(
    [autoRefillEligibleFamilyDataSelector, autoRefillActiveTabSelector],
    (familyMembers, activeTab) => familyMembers.find((patient) => patient.epostPatientNum === activeTab) || null
);
export const autoRefillToggleRxBusySelector = createSelector(autoRefillSelector, (autoRefill) => autoRefill.isBusy);

export const autoRefillAccountHasInsuranceSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.planAlias !== 'BRD01' && autoRefill.planAlias !== 'BRD02';
});

export const autoRefillAccountIsCaliforniaUserSelector = createSelector(
    autoRefillAdressesSelector,
    (autoRefillAddresses: AddressesResponse[]) => {
        if (!Array.isArray(autoRefillAddresses) && autoRefillAddresses > 0) {
            return false;
        }
        return !!autoRefillAddresses.find((address) => address.state === 'CA' && address.defaultShip);
    }
);

export const autoRefillPaymentCardsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.paymentCards;
});

export const autoRefillIsCaregiverSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isCaregiver;
});

export const autoRefillDefaultPaymentSelector = createSelector(
    autoRefillPaymentCardsSelector,
    (paymentCards: PaymentCardsResponse[]) => {
        return paymentCards.find((card) => card.defaultCard);
    }
);

export const autoRefillFamilyRxSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isFamilyMembersWithRx;
});

export const autoRefillFamilyPlansMapSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.familyDependents.reduce((acc, item) => {
        return {
            ...acc,
            [item.epostPatientNum]: item.planAlias
        };
    }, {});
});

export const autoRefillPatientPlanSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.patientAutoRefill;
});

export const autoRefillAccountStatusSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isPlanAutoRefillOn;
});
