import classNames from 'classnames';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import CheckboxComponent from 'ui-kit-v2/checkbox/checkbox';
import Icon from 'ui-kit-v2/icon/icon';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { accountUpdateAutoRefill } from 'state/account/account.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';

import { useOnboardingRedirection } from 'hooks/useOnboardingRedirection';

import './auto-refill-page.styles.scss';

const NavigationComponent = (img: ImageDataLike) => {
    return (
        <div className="autorefill-page-banner">
            <div className="autorefill-page-banner__image">
                <GatsbyImage image={getImage(img) as IGatsbyImageData} alt="Auto refill onboarding" />
            </div>
        </div>
    );
};

const AutoRefillPage = ({ data }: GatsbyTypes.AutoRefillPageDataQuery) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { shouldSetupAutoRefill, shouldSetHealthProfile } = useOnboardingRedirection(false);

    const profileObject = useSelector(accountProfileSelector);

    const [learnMore, setLearnMore] = useState<boolean>(true);
    const [hasAcceptTerms, setHasAcceptTerms] = useState<boolean>(false);
    const [tcErrors, setTcErrors] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const handleLearnMore = () => setLearnMore(!learnMore);

    const { imageData } = data;

    const handleTerms = () => {
        setTcErrors(false);
        setHasAcceptTerms(!hasAcceptTerms);
    };

    const handleAutoRefillPage = (turnAutoRefill: boolean) => {
        if (turnAutoRefill && !hasAcceptTerms) {
            setTcErrors(true);
            return;
        }

        if (!turnAutoRefill) {
            navigate(`/secure/onboarding/mobile-phone-confirm-your-number`);
            return;
        }

        setIsBusy(true);
        dispatch(
            accountUpdateAutoRefill.trigger({
                epostPatientNumber: profileObject?.epostPatientNum,
                autoRefillFlag: !profileObject?.autoRefill,
                onSuccess: () => {
                    // Add to local storage so that the modal doesn't open again on other screens
                    storageHelper.local.setAutoRefillToggleFlag();
                    setIsBusy(false);
                    // navigate to the phone confirmation
                    navigate(`/secure/onboarding/mobile-phone-confirm-your-number`);
                },
                onFailure: () => {
                    setIsBusy(false);
                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            onClose: () => dispatch(closeModal({})),
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('components.membershipModals.errorTitle')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt
                                    subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                                    note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const RenderLearnMoreButton = useCallback(() => {
        const learnMoreBtnClasses = classNames('auto-refill-page__toggle-btn', {
            'rotate-chevron': learnMore
        });

        return (
            <ButtonComponent
                label={t('pages.profile.autoRefill.learnMore.label')}
                variant="ghost"
                iconRight={<Icon icon={'chevron-down'} />}
                color="neutral"
                size="sm"
                onClick={handleLearnMore}
                className={learnMoreBtnClasses}
            />
        );
    }, [learnMore]);

    useEffect(() => {
        // Ensuring that if the user does not have the health profile configured, they need to be redirected
        if (shouldSetHealthProfile === undefined) return;

        if (shouldSetHealthProfile) {
            navigate('/secure/onboarding/health-profile');
            return;
        }

        if (shouldSetupAutoRefill === undefined) return;

        if (!shouldSetupAutoRefill) navigate('/secure/onboarding/mobile-phone-confirm-your-number');
    }, [shouldSetHealthProfile, shouldSetupAutoRefill]);

    return (
        <WorkflowLayout metaData={{ nodeTitle: t('pages.profile.autoRefill.urlTitle') }} isUnauthenticatedFlow={false}>
            <LayoutWrapperComponent
                headerAlign="left"
                className="auto-refill-page-override"
                workflowNavigation={NavigationComponent(imageData)}
            >
                <div className="auto-refill-page">
                    <h1 className="auto-refill-page__title">{t('pages.autoRefill.userVerification.title')}</h1>
                    <p className="auto-refill-page__description">
                        <Trans i18nKey="pages.profile.autoRefill.descriptionWithBreak" />
                    </p>

                    {RenderLearnMoreButton()}

                    {learnMore && (
                        <div className="auto-refill-page__list-container--with-border">
                            <p className="auto-refill-page__list-container__title">
                                {t('pages.profile.autoRefill.learnMore.description')}
                            </p>
                            <ul className="auto-refill-page__items-list--regular">
                                <li>{t('pages.profile.autoRefill.learnMore.pointOne')}</li>
                                <li>{t('pages.profile.autoRefill.learnMore.pointTwo')}</li>
                                <li>{t('pages.profile.autoRefill.learnMore.pointThree')}</li>
                                <li>{t('pages.profile.autoRefill.learnMore.pointFour')}</li>
                                <li>{t('pages.profile.autoRefill.learnMore.pointFive')}</li>
                            </ul>
                            <p className="auto-refill-page__list-container__title">
                                {t('pages.profile.autoRefill.learnMore.pleaseNote')}
                            </p>
                            <ul className="auto-refill-page__items-list--regular">
                                <li>{t('pages.profile.autoRefill.learnMore.note')}</li>
                            </ul>
                        </div>
                    )}

                    <div className="auto-refill-page__divider" />

                    <div className="auto-refill-page__list-container">
                        <p className="auto-refill-page__list-container__title">
                            {t('pages.profile.autoRefill.autoRefillTC.title')}:
                        </p>
                        <ul className="auto-refill-page__items-list--numeric">
                            <li>{t('pages.profile.autoRefill.autoRefillTC.termsOne')}</li>
                            <li>{t('pages.profile.autoRefill.autoRefillTC.termsTwo')}</li>
                            <li>{t('pages.profile.autoRefill.autoRefillTC.termsThree')}</li>
                            <li>{t('pages.profile.autoRefill.autoRefillTC.termsFour')}</li>
                            <li>{t('pages.profile.autoRefill.autoRefillTC.termsFive')}</li>
                            <li>
                                <Trans
                                    i18nKey={'pages.profile.autoRefill.autoRefillTC.termsSix'}
                                    values={{
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    }}
                                />
                            </li>
                        </ul>
                        <ToastBox icon="info" variant="info">
                            <p className="auto-refill-page__info-box-text">{t('pages.profile.autoRefill.alert')}</p>
                        </ToastBox>
                    </div>

                    <div className="auto-refill-page__checkbox">
                        <CheckboxComponent
                            onChange={handleTerms}
                            isSelected={hasAcceptTerms}
                            hasError={tcErrors}
                            label={t('pages.profile.autoRefill.autoRefillTC.checkboxLabel')}
                        />
                    </div>

                    <div className="auto-refill-page__action-container">
                        <ButtonComponent
                            label={t('pages.profile.autoRefill.button.offAlt')}
                            variant="solid"
                            color="neutral"
                            size="sm"
                            type="button"
                            onClick={() => handleAutoRefillPage(false)}
                        />
                        <ButtonComponent
                            label={t('pages.profile.autoRefill.button.on')}
                            variant="solid"
                            color="primary"
                            size="sm"
                            type="button"
                            disabled={tcErrors}
                            onClick={() => handleAutoRefillPage(true)}
                            isLoading={isBusy}
                        />
                    </div>
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default AutoRefillPage;

export const query = graphql`
    query AutoRefillPage($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        imageData: file(relativePath: { eq: "assets/images/auto-refill-onboarding.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [PNG])
            }
        }
    }
`;
