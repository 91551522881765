import * as yup from 'yup';

import { Login } from 'types/login';

export const LOGIN_SCHEMA = yup.object().shape({
    email: yup.string().trim().required(),
    password: yup.string().required()
});

export const EMAIL_UPDATE_SCHEMA = yup.object().shape({
    email: yup.string().trim().required()
});

export function isLogin(data: unknown): data is Login {
    return !!data && typeof data === 'object' && LOGIN_SCHEMA.isValidSync(data);
}
