import { createRoutine } from 'redux-saga-routines';

export const accountAddAddressToProfileRoutine = createRoutine('ACCOUNT/ADD_ADDRESS');
export const accountAddPaymentRoutine = createRoutine('ACCOUNT/ADD_PAYMENT');
export const accountAddRepresentativesRoutine = createRoutine('ACCOUNT/ADD_REPRESENTATIVES');
export const accountFetchHealthConditionsRoutine = createRoutine('ACCOUNT/FETCH_HEALTH_CONDITIONS');
export const accountFetchOrderLinesRoutine = createRoutine('ACCOUNT/FETCH_ORDER_LINES');
export const accountFetchOrderHistoryRoutine = createRoutine('ACCOUNT/FETCH_ORDER_HISTORY');
export const accountFetchOrderGetInvoiceRoutine = createRoutine('ACCOUNT/FETCH_ORDER_GET_INVOICE');
export const accountClearOrderInvoiceRoutine = createRoutine('ACCOUNT/CLEAR_ORDER_INVOICE');
export const accountFetchProfileRoutine = createRoutine('ACCOUNT/FETCH_PROFILE');
export const accountFetchPlansRoutine = createRoutine('ACCOUNT/FETCH_PLANS');
export const accountFetchSecurityQuestionsRoutine = createRoutine('ACCOUNT/FETCH_SECURITY_QUESTIONS');
export const accountFetchWebProfileRoutine = createRoutine('ACCOUNT/FETCH_WEB_PROFILE');
export const accountForgotUsernameRoutine = createRoutine('ACCOUNT/FORGOT_USERNAME');
export const accountGetAllCreditCardsRoutine = createRoutine('ACCOUNT/CREDIT_CARDS');
export const accountGetAllRepresentativesRoutine = createRoutine('ACCOUNT/REPRESENTATIVES');
export const accountGetContactUsMessageRoutine = createRoutine('ACCOUNT/GET_CONTACT_US');
export const accountGetPaymetricDetailsRoutine = createRoutine('ACCOUNT/PAYMETRIC_ACCESS_TOKEN');
export const accountGetTokenizedCardNumberRoutine = createRoutine('ACCOUNT/TOKENIZE_CARD');
export const accountLoginRoutine = createRoutine('ACCOUNT/LOGIN');
export const accountRegisterProfileRoutine = createRoutine('ACCOUNT/REGISTER_PROFILE');
export const accountRegisterRoutine = createRoutine('ACCOUNT/REGISTER');
export const accountRegisterSecurityQuestionsRoutine = createRoutine('ACCOUNT/REGISTER_SEC_QUESTIONS');
export const accountRemoveAddressFromProfileRoutine = createRoutine('ACCOUNT/REMOVE_ADDRESS');
export const accountSendChangePasswordRequestRoutine = createRoutine('ACCOUNT/SEND_CHANGE_PASSWORD_REQUEST');
export const accountRemoveCreditCardRoutine = createRoutine('ACCOUNT/REMOVE_CREDIT_CARDS');
export const accountRemoveRepresentativesRoutine = createRoutine('ACCOUNT/REMOVE_REPRESENTATIVES');
export const accountResetPasswordRoutine = createRoutine('ACCOUNT/RESET_PASSWORD');
export const accountSendContactUsMessageRoutine = createRoutine('ACCOUNT/SEND_CONTACT_US');
export const accountSetPrimaryAddressRoutine = createRoutine('ACCOUNT/SET_SET_PRIMARY_ADDRESS');
export const accountSubmitSecurityQuestionAnswerRoutine = createRoutine('ACCOUNT/SUBMIT_SECURITY_QUESTION_ANSWER');
export const accountUpdateAddressToProfileRoutine = createRoutine('ACCOUNT/UPDATE_ADDRESS');
export const accountUpdateAllergiesRoutine = createRoutine('ACCOUNT/ALLERGIES_UPDATE');
export const accountUpdateCreditCardRoutine = createRoutine('ACCOUNT/UPDATE_CREDIT_CARDS');
export const accountUpdateMedicalConditionsRoutine = createRoutine('ACCOUNT/MEDICAL_CONDITIONS_UPDATE');
export const accountUpdateProfileRoutine = createRoutine('ACCOUNT/UPDATE_PROFILE');
export const accountUpdateSecurePasswordRoutine = createRoutine('ACCOUNT/SECURE_PASSWORD_UPDATE');
export const accountFetchNotificationsRoutine = createRoutine('ACCOUNT/FETCH_NOTIFICATIONS');
export const accountUpdateNotificationsRoutine = createRoutine('ACCOUNT/UPDATE_NOTIFICATIONS');
export const accountGetAcknowledgementRoutine = createRoutine('ACCOUNT/GET_ACKNOWLEDGEMENT');
export const accountSetAcknowledgementRoutine = createRoutine('ACCOUNT/SET_ACKNOWLEDGEMENT');
export const accountUpdateAcknowledgementRoutine = createRoutine('ACCOUNT/UPDATE_ACKNOWLEDGEMENT');
export const accountVerifyPatient = createRoutine('ACCOUNT/UPDATE_VERIFY_PATIENT');
export const accountGetAwsCredsRoutine = createRoutine('ACCOUNT/GET_AWS_CREDS');
export const accountOptOutRoutine = createRoutine('ACCOUNT/OPTOUT');
export const accountUpdateAutoRefill = createRoutine('ACCOUNT/UPDATE_AUTO_REFILL');
export const accountResendVerificationEmailRoutine = createRoutine('ACCOUNT/RESEND_VERIFICATION_EMAIL');
export const accountUninsuredRegister = createRoutine('ACCOUNT/UNINSURED_REGISTER');
export const accountResetReducersOnLogout = createRoutine('ACCOUNT/LOGOUT');

export const deleteCreditCardAndSelectANewOneIfExistsAsDefaultRoutine = createRoutine(
    'ACCOUNT/DELETE_CREDIT_CARD_DEFAULT_AND_SELECT_A_NEW_ONE_IF_EXISTS'
);

// Messages Routines
export const accountFetchContactUsReplyMessageRoutine = createRoutine('ACCOUNT/FETCH_CONTACT_US_REPLY_MESSAGE');
export const accountGetContactUsMessageThreadRoutine = createRoutine('ACCOUNT/GET_CONTACT_US_MESSAGE_THREAD');

export const accountFetchMessagesRoutine = createRoutine('ACCOUNT/FETCH_MESSAGE');
export const accountFetchComposeMessageRoutine = createRoutine('ACCOUNT/FETCH_COMPOSE_MESSAGE');
export const accountPostComposeMessageRoutine = createRoutine('ACCOUNT/POST_COMPOSE_MESSAGE');
export const accountFetchReplyMessageRoutine = createRoutine('ACCOUNT/FETCH_REPLY_MESSAGE');
export const accountPostReplyMessageRoutine = createRoutine('ACCOUNT/POST_REPLY_MESSAGE');
export const accountGetMessageThreadRoutine = createRoutine('ACCOUNT/GET_MESSAGE_THREAD');
export const accountPostCloseMessageRoutine = createRoutine('ACCOUNT/DELETE_CLOSE_MESSAGE');
export const accountGetMessageFileListRoutine = createRoutine('ACCOUNT/GET_MESSAGE_FILE_LIST');
export const accountPostMessageFileUploadRoutine = createRoutine('ACCOUNT/POST_MESSAGE_FILE_UPLOAD');
export const accountGetMessageFileDownloadRoutine = createRoutine('ACCOUNT/GET_MESSAGE_FILE_DOWNLOAD');
export const accountGetMessageGetFileDownloadPathRoutine = createRoutine('ACCOUNT/GET_MESSAGE_FILE_DOWNLOAD_PATH');
export const accountClearMessageThreadRoutine = createRoutine('ACCOUNT/CLEAR_MESSAGE_THREAD');

// SMS Confirmation Routines
export const accountSendSmsConfirmationRoutine = createRoutine('ACCOUNT/SEND_SMS_CONFIRMATION');
export const accountCheckPendingSmsRequestsRoutine = createRoutine('ACCOUNT/CHECK_PENDING_SMS_REQUESTS');
export const accountCheckSmsRequestStatusRoutine = createRoutine('ACCOUNT/CHECK_SMS_REQUEST_STATUS');
export const accountCheckSmsRequestsStatusRoutine = createRoutine('ACCOUNT/CHECK_SMS_REQUESTS_STATUS');
export const accountCancelSmsRequestRoutine = createRoutine('ACCOUNT/CANCEL_SMS_REQUEST');
export const accountSendSmsConfirmationsRoutine = createRoutine('ACCOUNT/SEND_SMS_CONFIRMATIONS');

// retrieve user's IP address
export const accountGetUserIpAddressRoutine = createRoutine('ACCOUNT/GET_USER_IP_ADDRESS');

export const accountValidatePhoneNumberRoutine = createRoutine('ACCOUNT/VALIDATE_PHONE_NUMBER');

export const accountFetchPaymentHistoryV2Routine = createRoutine('ACCOUNT/FETCH_PAYMENT_HISTORY_V2');

export type AccountRoutines = typeof accountRegisterRoutine | typeof accountLoginRoutine;

// Onboarding routines
export const accountExistsHealthProfileRoutine = createRoutine('ACCOUNT/EXISTS_HEALTH_PROFILE');
