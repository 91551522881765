import { ALLOW_INSURED_BIRDI_PRICE, OVERRIDE_ADJUDICATION } from 'gatsby-env-variables';

import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { MembershipPlanId } from 'state/membership/membership.selector';

import { GetEasyAutoRefillPatientDataResponse } from 'types/auto-refill';
import {
    EasyRefillCartPayload,
    ExtendedEasyRefillCartObjectPayload,
    ExtendedEasyRefillRxs,
    GetEasyRefillPatientDataResponse
} from 'types/easy-refill';
import { DependentsPricingData } from 'types/family-account';
import { RefillRxs } from 'types/order-prescription';
import { PlansObjectPayload } from 'types/plans';
import { RxDetails } from 'types/prescription';

import { RX_LINE_ERROR } from 'enums/cart';
import { EASY_REFILL_MEMBERSHIP_PLAN_TYPES } from 'enums/easy-refill';
import { BIRDI_PLANS } from 'enums/plans';
import { RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';

import { isOnDemandPlan } from 'util/plans';

import { birdiPriceRxLineErrorCodes, getBirdiPricePlanNum, isInBirdiFormulary } from './cart';
import { safeParseFloat } from './number';
import { lowercaseAndCapitalize } from './string';

// DRX-2425: In future, we should merge the logic for both carts, mantaining the same parameters.

/**
 * Processes an array of carts returned from the API based on specific rules.
 *
 * @param carts Array of carts retrieved from the API.
 * @param accountHasInsurance Indicates whether the account has insurance.
 * @param prescriptionsObject Full prescription object with prescription details.
 * @param drugDiscountPrices Discounts obtained from the DrugPriceApi.
 * @param accountPlans Validated account plans for each cart.
 * @returns An array of processed carts based on specified rules.
 */

export function processEasyRefillCart(
    carts: EasyRefillCartPayload[],
    accountHasInsurance: boolean,
    prescriptionsObject: RefillRxs[],
    drugDiscountPrices: DrugWithDiscountPrice[],
    accountPlans: PlansObjectPayload[],
    zipCode = ''
): ExtendedEasyRefillCartObjectPayload[] {
    const result: ExtendedEasyRefillCartObjectPayload[] = carts?.map((cart) => {
        const extendedCart: ExtendedEasyRefillCartObjectPayload = {
            ...cart.order
        } as ExtendedEasyRefillCartObjectPayload;
        let itemHasUnknownPrice = false;

        const cartItemsObject: ExtendedEasyRefillRxs[] = (cart.order?.refillRxs ?? []).map((item: RefillRxs) => {
            const extendedItems: ExtendedEasyRefillRxs = {
                ...item
            } as ExtendedEasyRefillRxs;

            return extendedItems;
        });

        /** Filter thr `accountPlans` for indivdual price validation*/
        const plans = accountPlans?.filter((plan) => {
            if (!plan?.epostPatientNum) return false;
            return plan?.epostPatientNum === cart.epostPatientNum;
        });

        const birdiPricePlanNum: string | undefined = getBirdiPricePlanNum(plans);
        const isBirdiCash = plans?.find((f) => ['BRD01', 'BRD02'].includes(f.planAlias))?.planAlias ? true : false;

        if (cartItemsObject?.length > 0) {
            const updatedRefillRxs: ExtendedEasyRefillRxs[] = [];
            let calculatedOrderTotal = 0;

            cartItemsObject.map((item: ExtendedEasyRefillRxs) => {
                // Clone the item to avoid modifying the original object
                const updatedLineItem: ExtendedEasyRefillRxs = { ...item };

                // Set default values for additional properties
                updatedLineItem.birdiPlanNum = birdiPricePlanNum;
                updatedLineItem.hasKnownPrice = false;

                // Find the details for the current prescription that is in the cart.
                const currentFullPrescription = prescriptionsObject?.find(
                    (obj: RefillRxs) => obj.rxNumber === item.rxNumber
                );

                // Check if the prescription is in the Birdi formulary and update prices accordingly
                const prescriptionInBirdiFormulary = isInBirdiFormulary(item.rxNumber, zipCode, drugDiscountPrices);
                if (prescriptionInBirdiFormulary) {
                    updatedLineItem.awpPrice = prescriptionInBirdiFormulary.awpPrice;
                    updatedLineItem.birdiPrice = prescriptionInBirdiFormulary.price;
                    updatedLineItem.showStrikeThruPricing = showStrikeThruPricingEasyRefill(
                        updatedLineItem,
                        accountHasInsurance
                    );
                    updatedLineItem.showBirdiPricing = showBirdiPricingEasyRefill(updatedLineItem, accountHasInsurance);
                }

                // Check if Birdi pricing is being used for the item
                updatedLineItem.isUsingBirdiPrice = itemIsUsingBirdiPriceEasyRefill(
                    updatedLineItem,
                    accountHasInsurance,
                    isBirdiCash
                );

                // Check if the item's price is known based on the current prescription
                updatedLineItem.hasKnownPrice = currentFullPrescription
                    ? isItemPriceKnownEasyRefill(
                          updatedLineItem,
                          currentFullPrescription.prescriptionDetail as RxDetails,
                          accountHasInsurance
                      )
                    : false;

                // Update itemHasUnknownPrice flag
                if (!updatedLineItem.hasKnownPrice) {
                    itemHasUnknownPrice = true;
                } else {
                    // Update calculatedOrderTotal based on pricing information
                    if (updatedLineItem.showStrikeThruPricing || updatedLineItem.showBirdiPricing) {
                        updatedLineItem.planNum = birdiPricePlanNum!;
                        if (updatedLineItem.birdiPrice) {
                            calculatedOrderTotal += Number(updatedLineItem.birdiPrice);
                        }
                    } else {
                        if (updatedLineItem.patientCopay) calculatedOrderTotal += updatedLineItem.patientCopay;
                    }
                }

                // Handle birdiPriceNumeric and set finalPrice based on isUsingBirdiPrice
                let birdiPriceNumeric = Number(updatedLineItem.birdiPrice);
                birdiPriceNumeric = isNaN(birdiPriceNumeric) ? 0 : birdiPriceNumeric;
                updatedLineItem.finalPrice = updatedLineItem.isUsingBirdiPrice
                    ? birdiPriceNumeric
                    : updatedLineItem.patientCopay;

                const { disclaimerTranslationKey, showDisclaimer } = determineRxDisclaimerEasyRefill(
                    updatedLineItem,
                    currentFullPrescription as unknown as RxDetails
                );
                updatedLineItem.disclaimerTranslationKey = disclaimerTranslationKey;
                updatedLineItem.showDisclaimer = showDisclaimer;
                // Add the updated item to the array
                updatedRefillRxs.push(updatedLineItem);
            });

            extendedCart.itemHasUnknownPrice = itemHasUnknownPrice;
            extendedCart.extendedRefillRxs = updatedRefillRxs;
            extendedCart.orderTotal = calculatedOrderTotal;
            extendedCart.firstName = cart.firstName;
            extendedCart.lastName = cart.lastName;

            // Categorize carts to show estimated total or total
            extendedCart.showEstimatedTotal = showEstimatedTotalEasyRefill(extendedCart, isBirdiCash);
        }
        return extendedCart;
    });
    return result;
}

// As the price validation for the easyrefill flow is different we need to replicate
// this code to avoid if stament and make function to complex to manage.
export function hasAdjudicatedPriceInEasyRefill(
    item: ExtendedEasyRefillRxs,
    prescriptionDetails?: Partial<RxDetails> | undefined
): boolean {
    if (item) {
        // TO TEST THE CART PAGES AS IF ADJUDICATION IS ON, UPDATE THE ENVIRONMENT VARIABLE
        if (OVERRIDE_ADJUDICATION) {
            return true;
        }

        if (item.rxLineError === RX_LINE_ERROR.NONE) {
            return true;
        } else {
            if (prescriptionDetails) {
                if (
                    item.messageStatus &&
                    prescriptionDetails.webEligibilityStatus !== RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
                    prescriptionDetails.webEligibilityStatus !== RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return item.messageStatus;
            }
        }
    }
    return false;
}

export function isItemPriceKnownEasyRefill(
    item: ExtendedEasyRefillRxs,
    prescriptionDetails: RxDetails | undefined,
    accountHasInsurance: boolean
): boolean {
    if (
        hasAdjudicatedPriceInEasyRefill(item, prescriptionDetails) ||
        (!hasAdjudicatedPriceInEasyRefill(item, prescriptionDetails) &&
            accountHasInsurance &&
            ALLOW_INSURED_BIRDI_PRICE &&
            (showStrikeThruPricingEasyRefill(item, accountHasInsurance) ||
                showBirdiPricingEasyRefill(item, accountHasInsurance)))
    ) {
        return true;
    } else {
        return false;
    }
}

export function showStrikeThruPricingEasyRefill(item: ExtendedEasyRefillRxs, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        isRxLineError(item) &&
        item?.awpPrice !== 'NA' &&
        parseFloat(item?.awpPrice) !== 0 &&
        item.birdiPrice !== 'NA' &&
        parseFloat(item?.awpPrice) > parseFloat(item.birdiPrice)
    ) {
        return true;
    } else {
        return false;
    }
}

export function showBirdiPricingEasyRefill(item: ExtendedEasyRefillRxs, accountHasInsurance: boolean): boolean {
    if (
        accountHasInsurance &&
        ALLOW_INSURED_BIRDI_PRICE &&
        isRxLineError(item) &&
        (item?.awpPrice === 'NA' || safeParseFloat(item?.awpPrice) <= safeParseFloat(item.birdiPrice)) &&
        item.birdiPrice !== 'NA'
    ) {
        return true;
    } else {
        return false;
    }
}

export function itemIsUsingBirdiPriceEasyRefill(
    item: ExtendedEasyRefillRxs,
    accountHasInsurance: boolean,
    isBirdiCash?: boolean
) {
    // for BRD01 and BRD02
    if (isBirdiCash && !accountHasInsurance && Number(item.birdiPrice) > 0) {
        return true;
    }

    // for insuredAccounts
    if (
        accountHasInsurance &&
        ((item.birdiPlanNum && item.planNum === item.birdiPlanNum) ||
            item.showStrikeThruPricing ||
            item.showBirdiPricing)
    ) {
        return true;
    } else {
        return false;
    }
}

export function isRxLineError(item: ExtendedEasyRefillRxs) {
    return birdiPriceRxLineErrorCodes.includes(item.rxLineError);
}

/********************************************************************************************/

const mapFamilyMembers = (familyMembers: GetEasyRefillPatientDataResponse[], willUseEposPatienNum: boolean) => {
    return familyMembers.map(({ epostPatientNum, firstName, lastName }) => {
        const patientName = `${firstName} + ${lastName}`;

        return {
            key: patientName,
            label: lowercaseAndCapitalize(patientName),
            value: willUseEposPatienNum
                ? epostPatientNum
                : [...lowercaseAndCapitalize(patientName).split(' '), epostPatientNum]
        };
    });
};

export const mapEasyRefillFamilyMembers = (
    familyMembers: GetEasyRefillPatientDataResponse[],
    willUseEposPatienNum: boolean
) => {
    const dependents = familyMembers.length > 0 ? mapFamilyMembers(familyMembers, willUseEposPatienNum) : [];

    return [...dependents];
};

// DRX-4102: On future, we should merge the logic for both carts, mantaining the same parameters.
// TODO: Delete this function when we merge the logic for both carts.

/**
 * Function that determines the disclaimer to be displayed based on the item and prescription
 * @param updatedLineItem - The item to be displayed
 * @param currentFullPrescription - The prescription details
 * @returns An object with the disclaimer translation key and a boolean indicating if the disclaimer should be displayed
 */
export function determineRxDisclaimerEasyRefill(
    updatedLineItem: ExtendedEasyRefillRxs,
    currentFullPrescription: RxDetails
) {
    const { rxLineError, hasKnownPrice, showStrikeThruPricing, showBirdiPricing } = updatedLineItem;

    // Display the adjudicate Rx error
    switch (rxLineError) {
        case RX_LINE_ERROR.ADJUDICATE_RX_ERROR:
            return {
                disclaimerTranslationKey: 'pages.cart.rxItemErrorMessageDiscount',
                showDisclaimer: true
            };
    }

    if (
        !hasAdjudicatedPriceInEasyRefill(updatedLineItem, currentFullPrescription) &&
        hasKnownPrice &&
        (showStrikeThruPricing || showBirdiPricing)
    ) {
        let translationKey: string | undefined;

        switch (rxLineError) {
            case RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY:
                translationKey = 'pages.cart.rxItemNoPharmacyContractErrorMessage';
                break;
            case RX_LINE_ERROR.NO_LONGER_COVERED:
                translationKey = 'pages.cart.rxItemNoLongerCoveredErrorMessage';
                break;
            case RX_LINE_ERROR.NOT_COVERED:
                translationKey = 'pages.cart.rxItemNotCoveredErrorMessage';
                break;
            default:
                translationKey = undefined;
                break;
        }

        return {
            disclaimerTranslationKey: translationKey,
            showDisclaimer: true
        };
    }

    // Default case when no conditions are met
    return {
        disclaimerTranslationKey: undefined,
        showDisclaimer: false
    };
}

// DRX-4102: On future, we should merge the logic for both carts, mantaining the same parameters.
// TODO: Delete this function when we merge the logic for both carts.
/**
 * Function that determines if the estimated total should be displayed based on the cart and accountHasInsurance
 * @param extendedCart - The cart object
 * @param isBirdiCash - Indicates whether the account has a cash plan
 * @returns A boolean indicating if the estimated total should be displayed
 */
function showEstimatedTotalEasyRefill(extendedCart: ExtendedEasyRefillCartObjectPayload, isBirdiCash: boolean) {
    if (extendedCart?.extendedRefillRxs) {
        const hasBirdiPricing = extendedCart.extendedRefillRxs.some((item) => item.showBirdiPricing);
        if (hasBirdiPricing && isBirdiCash) {
            return true;
        }
        return false;
    }
    return false;
}
export const mapEasyRefillFamilyMemberPlanType = (
    planType: EASY_REFILL_MEMBERSHIP_PLAN_TYPES | undefined
): MembershipPlanId => {
    if (!planType) return MembershipPlanId.NONE;

    const enumKey = Object.keys(EASY_REFILL_MEMBERSHIP_PLAN_TYPES).find(
        (key: string) => (EASY_REFILL_MEMBERSHIP_PLAN_TYPES as any)[key] === planType
    );
    return enumKey ? (MembershipPlanId as any)[enumKey] : MembershipPlanId.NONE;
};

export const mapEasyRefillFamilyPricingData = (
    members: GetEasyAutoRefillPatientDataResponse[] | GetEasyRefillPatientDataResponse[],
    mainUserPlanAlias: BIRDI_PLANS,
    mainUserZipCode: string,
    mainUserEpostPatientNum: string,
    mainUserPlanType: MembershipPlanId
): DependentsPricingData[] => {
    if (members.length > 0) {
        return members.map((member: GetEasyRefillPatientDataResponse) => {
            const { planAlias, zipCode, planType, epostPatientNum } = member;
            const planTypeMapped = mapEasyRefillFamilyMemberPlanType(planType);
            return {
                planAlias: planAlias as BIRDI_PLANS,
                zipCode,
                planType: planTypeMapped,
                epostPatientNum,
                isOnDemandPlan: isOnDemandPlan(planTypeMapped)
            };
        });
    } else {
        return [
            {
                planAlias: mainUserPlanAlias,
                zipCode: mainUserZipCode,
                planType: mainUserPlanType,
                epostPatientNum: mainUserEpostPatientNum,
                isOnDemandPlan: isOnDemandPlan(mainUserPlanType)
            }
        ] as DependentsPricingData[];
    }
};
