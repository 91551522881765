import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { validatePhoneNumber } from 'util/form.helper';
import { removeChars } from 'util/string';

export const MOBILE_PHONE_SETUP_VALIDATION = (t: TFunction) =>
    Yup.object().shape({
        phoneNumber: Yup.string()
            .required(t('forms.errorMessages.invalidPhoneNumber'))
            .min(14, t('forms.errorMessages.invalidPhoneNumber'))
            .test('is-valid-phone', t('forms.errorMessages.invalidUSNumber'), async function (value) {
                if (!value || (value && removeChars(value).length < 10)) {
                    return true;
                }
                const { path, createError } = this;
                const isValid = await validatePhoneNumber(value);
                if (!isValid) {
                    return createError({ path, message: t('forms.errorMessages.invalidUSNumber') });
                }
                return true;
            })
    });
