import { useFormik } from 'formik';
import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';
import * as Yup from 'yup';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
// UI Kit & Components
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { MembershipEmailZipcodePayload } from 'state/membership-registration/membership-registration.services';
import { setRegistrationFormValues } from 'state/registration/registration.reducer';
import { registrationFormValuesSelector } from 'state/registration/registration.selector';

import { BREAKPOINTS } from 'const/breakpoints';

import { BIRDI_REGISTRATION_FLOW_PLAN } from 'enums/plans';

import { getPhoneNumber } from 'util/globalVariables';
import { TrackEvent } from 'util/google_optimize/optimize_helper';
import { TRACKING_EVENTS } from 'util/registration.util';
import { ZipValidateResponse } from 'util/usps';

import { useAddressVerification } from 'hooks/useAddressVerification';
import useCustomerIo from 'hooks/useCustomerIo';
import { useEmailZipcodeVerification } from 'hooks/useEmailZipcodeVerification';
import usePageRedirection from 'hooks/usePageRedirection';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './membership.style.scss';

const StartHerePage = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.StartHerePageDataQuery;
}): ReactElement => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { verifyZip } = useAddressVerification();
    const { verifyEmailZipcode } = useEmailZipcodeVerification();
    const { width } = useWindowDimensions();
    const { identifyCustomerIo, trackPageCustomerIo, trackEventCustomerIo } = useCustomerIo();

    // Selectors
    const formValues = useSelector(registrationFormValuesSelector);

    // Constants
    const planAlias = BIRDI_REGISTRATION_FLOW_PLAN.MEMBERSHIP;

    // Footer links selector
    const workflowFooterLinks =
        data?.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    // can be integrated with Drupal to get custom content for each kind of page
    const asideContent = {
        title: t('membership.careStartHere.sideContent.title'),
        copyText: t('membership.careStartHere.sideContent.copy'),
        bottomImage: data?.asideBottomImage
    };

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const initialValues = {
        email: formValues?.email ?? '',
        zipCode: formValues?.zipCode ?? ''
    };

    const getStartedValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t(`membership.getStarted.form.errors.email.invalid`))
            .required(t(`membership.getStarted.form.errors.email.required`)),
        zipCode: Yup.string()
            .min(5, t(`membership.getStarted.form.errors.zipCode.invalid`))
            .matches(/^\d+$/, t(`membership.getStarted.form.errors.zipCode.invalid`))
            .required(t(`membership.getStarted.form.errors.zipCode.required`))
    });

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validationSchema: getStartedValidationSchema,
        onSubmit: async (values) => {
            await getEmailZipcode(values);
        }
    });

    const handleCloseModal = () => {
        dispatch(closeModal({}));
    };

    const handleErrorModal = (validationResponse: ZipValidateResponse) => {
        if (validationResponse.responseCode === 'invalid') {
            formik.setFieldError('zipCode', t(`membership.getStarted.form.errors.zipCode.invalid`));
        } else {
            dispatch(
                openModal({
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('registration.errors.modals.default.body')}
                            note={t('registration.errors.modals.default.note', {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })}
                        />
                    ),
                    ctas: [
                        {
                            dataGALocation: 'ProfileSetupError',
                            label: t('registration.errors.modals.default.button'),
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            variant: 'primary'
                        }
                    ],
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('registration.errors.modals.default.title')}
                            icon="alert"
                        />
                    ),
                    showClose: true,
                    type: 'danger',
                    size: 'lg'
                })
            );
        }
    };

    const handleEmailZipCodeErrorModal = () => {
        dispatch(
            openModal({
                showClose: false,
                onClose: handleCloseModal,
                size: 'lg',
                type: 'danger',
                contentClassName: 'modal-content-padding',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('registration.errors.modals.emailAlreadyOnFile.title')}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('registration.errors.modals.emailAlreadyOnFile.body')}
                        note={t('registration.errors.modals.emailAlreadyOnFile.note', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        label: t('registration.errors.modals.emailAlreadyOnFile.button'),
                        variant: 'primary',
                        onClick: handleCloseModal
                    }
                ]
            })
        );
    };

    const getEmailZipcode = async (values: { email: string; zipCode: string }) => {
        setIsSubmitting(true);

        verifyZip({
            zip: values.zipCode,
            onSuccess: (validationResponse: ZipValidateResponse) => {
                verifyEmailZipcode({
                    email: values.email ?? '',
                    zipcode: values.zipCode ?? '',
                    onSuccess: () => {
                        dispatch(
                            setRegistrationFormValues({
                                email: values.email ?? '',
                                zipCode: values.zipCode ?? '',
                                city: validationResponse?.address?.city,
                                state: validationResponse?.address?.state
                            })
                        );

                        setIsSubmitting(false);

                        // Just track the events when the email is not registered yed and also the zipcode is valid.
                        trackEvents(values);

                        navigate('/get-started-v2/setup-your-account');
                    },
                    onFailure: (validationResponse: MembershipEmailZipcodePayload) => {
                        setIsSubmitting(false);
                        switch (validationResponse?.validate) {
                            case 'registered':
                                handleEmailZipCodeErrorModal();
                                break;
                            case 'invalid':
                                formik.setFieldError('email', t(`membership.getStarted.form.errors.email.invalid`));
                                break;
                            default:
                                handleErrorModal({
                                    ...validationResponse,
                                    responseCode: undefined
                                });
                                break;
                        }
                    }
                });
            },
            onFailure(validationResponse: ZipValidateResponse) {
                setIsSubmitting(false);
                if (validationResponse.responseCode === 'invalid') {
                    formik.setFieldError('zipCode', t(`membership.getStarted.form.errors.zipCode.invalid`));
                } else {
                    handleErrorModal(validationResponse);
                }
            }
        });
    };

    /**
     * This function unify all the tracking events
     * @param values current email and zipCode of the current user
     */
    const trackEvents = (values: { email: string; zipCode: string }) => {
        /** Costumer.io events */
        // Identify the current user :: push attribute(s) to customer.io
        identifyCustomerIo({
            email: values.email,
            plan: planAlias
        });

        // Track current person zipcode
        trackEventCustomerIo({
            label: 'used_zipcode',
            plan: planAlias,
            value: {
                zipCode: values.zipCode
            }
        });

        // Track the current page
        trackPageCustomerIo({ path: window.location.href, plan: planAlias });

        // Set true as default value for has_gov_insurance event
        trackEventCustomerIo({
            label: 'has_gov_insurance',
            value: { insured: 1 },
            plan: planAlias
        });

        /** Google Analytics events */
        TrackEvent('membership_registration_step1_click', TRACKING_EVENTS.CONTINUE_PAGE_ONE);
    };

    // membership redirection
    const hasMembershipRedirection = usePageRedirection({
        featureFlag: ENABLE_MEMBERSHIP
    });

    if (hasMembershipRedirection) {
        return <></>;
    }

    return (
        <WorkflowLayout
            className="your-care-starts-here-page"
            metaData={{ nodeTitle: 'Membership registration' }}
            isUnauthenticatedFlow={true}
        >
            <LayoutWrapperComponent
                title={t('membership.careStartHere.mainContent.title')}
                copyText={t('membership.careStartHere.mainContent.copy')}
                asideContent={asideContent}
                footerLinks={<WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="membership" />}
                birdiAddonImage={data?.addonBird}
                workflowLogo={data?.workflowLogo}
            >
                <form className="your-care-starts-here-page__form" noValidate onSubmit={formik.handleSubmit}>
                    <div className="form__inputs">
                        <TextInputComponent
                            {...formik.getFieldProps('email')}
                            type="email"
                            name="email"
                            label={t('membership.careStartHere.mainContent.form.email')}
                            variant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                            error={formik.touched.email && formik.errors.email}
                            autoComplete="email"
                        />

                        <TextInputComponent
                            {...formik.getFieldProps('zipCode')}
                            autoComplete="billing postal-code"
                            name="zipCode"
                            label={t('membership.careStartHere.mainContent.form.zipCode')}
                            variant={width > BREAKPOINTS.md ? 'alt' : 'default'}
                            error={formik.touched.zipCode && formik.errors.zipCode}
                            maxLength={5}
                            onlyNumbers
                        />
                    </div>

                    <ButtonComponent
                        full
                        variant="solid"
                        color="primary"
                        label={t('membership.careStartHere.mainContent.form.cta')}
                        type="submit"
                        className="your-care-starts-here-page__submit_button"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                    />
                </form>

                <div className="your-care-starts-here-page__terms-and-privacy">
                    <p>
                        {t('membership.getStarted.uninsured.terms.phrase')}{' '}
                        <Alert.Link href="/website-terms-of-use">
                            {t('membership.getStarted.uninsured.terms.termsOfUse')}
                        </Alert.Link>{' '}
                        {t('membership.getStarted.uninsured.terms.and')}{' '}
                        <Alert.Link href="/website-privacy-policy">
                            {t('membership.getStarted.uninsured.terms.privacyPolicy')}
                        </Alert.Link>{' '}
                    </p>
                </div>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default StartHerePage;

export const query = graphql`
    query StartHerePageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        asideBottomImage: file(relativePath: { eq: "assets/images/setup-your-account-image.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG, layout: CONSTRAINED, width: 320)
            }
        }
        workflowLogo: file(relativePath: { eq: "assets/images/birdi-logo-care.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: CONSTRAINED, quality: 100)
            }
        }
        addonBird: file(relativePath: { eq: "assets/images/get-started-flow-bird.png" }) {
            id
            childImageSharp {
                gatsbyImageData(
                    formats: PNG
                    layout: CONSTRAINED
                    transformOptions: { fit: COVER }
                    height: 56
                    width: 56
                )
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
