import CloseBlueIcon from 'assets/icons/close-blue-2.svg';
import { Link } from 'gatsby';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeMembership from 'ui-kit/bagde/badge-membership';
//Ui-kit
import BorderedContainer from 'ui-kit/bordered-container/bordered-container';
import Button from 'ui-kit/button/button';
import IconBackgroundColor from 'ui-kit/icon-background-color';
// Ui-kit: icons
import ClockCircleIcon from 'ui-kit/icons/clock-circle';

// Interfaces and types
import { PlanInviteCardProps } from 'types/membership';

import storageHelper from 'util/storageHelper';
import { lowercaseAndCapitalize } from 'util/string';

// Sub-components
import FamilyMember from '../../family-management/family-member';
// Styles
import './plan-invite-card.styles.scss';

// Main component
const PlanInviteCard = ({
    planName,
    invitationStatus,
    requestedByDate,
    requestedByName,
    isBusy,
    onAccept,
    onDecline,
    isMembership,
    expirationDays,
    invitationId
}: PlanInviteCardProps): ReactElement => {
    const { t } = useTranslation();
    const [isExpiredInviteClosed, setExpiredInviteClosed] = useState(false);

    const isExpired = invitationStatus && invitationStatus === 'expired';
    const formattedPlanName = planName && planName.length > 0 ? lowercaseAndCapitalize(planName) : '';

    const isThisInviteCardAlreadyClosed = () => {
        const membershipInviteClosedFlag = storageHelper.local.getExpiredInviteClosedFlag();

        return (
            Object.keys(membershipInviteClosedFlag).length > 0 &&
            membershipInviteClosedFlag.invitationId === invitationId &&
            invitationStatus === 'expired'
        );
    };

    const setInviteAsClosed = (invitationId: number) => {
        try {
            storageHelper.local.setExpiredInviteClosedFlag(invitationId as number);
            setExpiredInviteClosed(true);
        } catch (error) {
            console.log(error);
        }
    };

    return isExpiredInviteClosed || isThisInviteCardAlreadyClosed() ? (
        <></>
    ) : (
        <BorderedContainer className="plan-invite-card">
            <div className="plan-invite-card__body">
                <div className="plan-invite-card__content">
                    {!isMembership && (
                        <div className="plan-invite-card__content__icon-container">
                            <IconBackgroundColor variant="primary" className="plan-invite-card__content__icon">
                                <ClockCircleIcon />
                            </IconBackgroundColor>
                        </div>
                    )}

                    <div className="plan-invite-card__content__plan-info">
                        {isMembership ? (
                            <>
                                <BadgeMembership variant={'care +'} />
                                <p className="plan-invite-card__content__details">
                                    {t('components.membership.membershipPlanInviteCard.inviteDetails', {
                                        requestedName: lowercaseAndCapitalize(requestedByName),
                                        planName: formattedPlanName.replace('Care +', 'Care+')
                                    })}
                                </p>
                                {isExpired ? (
                                    <p className="plan-invite-card__content__expired">
                                        {t('components.membership.membershipPlanInviteCard.expired')}
                                    </p>
                                ) : (
                                    <p className="plan-invite-card__content__requestedBy">
                                        {t('components.membership.membershipPlanInviteCard.requestedOn', {
                                            requestedDate: requestedByDate,
                                            expirationDays: expirationDays
                                        })}
                                    </p>
                                )}
                            </>
                        ) : (
                            <>
                                <p className="plan-invite-card__content__label">
                                    {t('components.membership.membershipPlanInviteCard.inviteLabel')}
                                </p>
                                <FamilyMember name={planName} invitationStatus={invitationStatus} />
                                <p className="plan-invite-card__content__requestedBy">
                                    {t('components.membership.membershipPlanInviteCard.requestedBy', {
                                        requestedName: requestedByName,
                                        requestedDate: requestedByDate
                                    })}
                                </p>
                            </>
                        )}
                    </div>
                    {isExpired ? (
                        <div className="plan-invite-card__content__expired__icon">
                            <Link
                                onClick={() => {
                                    setInviteAsClosed(invitationId as number);
                                }}
                                to="/secure/profile/membership"
                            >
                                <img src={CloseBlueIcon} height={16} width={16} alt={'Close'} />
                            </Link>
                        </div>
                    ) : (
                        <div className="plan-invite-card__content__buttons">
                            <Button
                                label={t('components.membership.membershipPlanInviteCard.declineAction')}
                                type="button"
                                variant="text"
                                className="btn-text-blue btn-underline"
                                onClick={() => onDecline()}
                                disabled={isBusy}
                            />
                            <Button
                                label={t('components.membership.membershipPlanInviteCard.acceptAction')}
                                type="button"
                                onClick={() => onAccept()}
                                isBusy={isBusy}
                                disabled={isBusy}
                                async
                            />
                        </div>
                    )}
                </div>
            </div>
        </BorderedContainer>
    );
};

export default PlanInviteCard;
