import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { renderHealthConditions } from 'components/health-profile/health-profile.component';

import {
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine
} from 'state/medical-conditions/medical-conditions.routines';

import { useHealthConditionsByPatientNum } from 'hooks/useHealthConditionsByPatientNum';

import { HealthConditionsModalContentProps } from './health-profile.props';

export const HealthConditionsModalContentV2 = ({
    icon,
    title,
    subTitle,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions,
    ePostPatientNum
}: HealthConditionsModalContentProps) => {
    const healthConditions = useHealthConditionsByPatientNum(ePostPatientNum);

    const { conditionChoices, existingConditions, existingFreeformConditions } = healthConditions;

    return (
        <BirdiModalContent
            icon={icon ?? 'none'}
            title={title}
            subTitle={subTitle}
            body={renderHealthConditions({
                isInModal: true,
                formName: 'healthConditionsModalContent',
                action: medicalConditionsAddOrUpdateRoutine,
                conditionChoices,
                conditionType: 'condition',
                ePostPatientNum,
                existingConditions,
                existingFreeformConditions,
                freeformConditionsLabel,
                onUpdateHealthConditions,
                submitLabel
            })}
        />
    );
};

export const AllergiesModalContentV2 = ({
    icon,
    title,
    subTitle,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions,
    ePostPatientNum
}: HealthConditionsModalContentProps) => {
    const healthConditions = useHealthConditionsByPatientNum(ePostPatientNum);

    const { allergyChoices, existingAllergies, existingFreeformAllergies } = healthConditions;

    return (
        <BirdiModalContent
            icon={icon ?? 'none'}
            title={title}
            subTitle={subTitle}
            body={renderHealthConditions({
                isInModal: true,
                formName: 'allergiesModalContent',
                action: medicalConditionsAllergiesAddOrUpdateRoutine,
                conditionChoices: allergyChoices,
                conditionType: 'allergy',
                ePostPatientNum,
                existingConditions: existingAllergies,
                existingFreeformConditions: existingFreeformAllergies,
                freeformConditionsLabel: freeformConditionsLabel,
                onUpdateHealthConditions,
                submitLabel
            })}
        />
    );
};
