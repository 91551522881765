import { BIRDI_PLANS, PLANS_ALLOWING_PRICE } from 'enums/plans';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';

//
// --- Functions ---

// Validates if the plan allows pricing to be displayed
export const doesPlanAllowsPricing = (planAlias?: string | BIRDI_PLANS): boolean => {
    if (!planAlias) return false;
    return PLANS_ALLOWING_PRICE.includes(planAlias as BIRDI_PLANS);
};

// Validate if we should show pricing based on the plan
// and combined with the ALLOW_INSURED_BIRDI_PRICE feature flag
export const shouldPlanShowPrice = (planAlias?: BIRDI_PLANS) => {
    const planAllowsPricing = doesPlanAllowsPricing(planAlias);
    // If doesn't allow pricing is an insurance plan
    return planAllowsPricing || (!planAllowsPricing && ALLOW_INSURED_BIRDI_PRICE);
};
