import { DRUPAL_BASE_URL } from 'gatsby-env-variables';

import { MaintenanceType } from 'types/drupal';

import axiosClient from 'util/axiosClient';

/**
 * Anything related to Drupal in the system.
 */
export default class DrupalService {
    static fetchCSRF(): any {
        return {
            get: () => {
                return axiosClient.get(`${DRUPAL_BASE_URL}session/token`).then((response) => response.data);
            }
        };
    }
    static maintenance() {
        return {
            get: (): Promise<MaintenanceType> => {
                return axiosClient
                    .get(`${DRUPAL_BASE_URL}api/maintenance`, {
                        headers: {
                            Authorization: ''
                        }
                    })
                    .then((response) => response.data);
            }
        };
    }
}
