import { navigate } from 'gatsby';
import { BIRDI_IP_ADDRESSES } from 'gatsby-env-variables';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { accountGetUserIpAddressRoutine } from 'state/account/account.routines';
import { accountUserIpAddressSelector } from 'state/account/account.selectors';
import { drupalMaintenanceRoutine } from 'state/drupal/drupal.routines';
import { drupalIsMaintenanceCheckedSelector, drupalIsMaintenanceModeSelector } from 'state/drupal/drupal.selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withMaintenanceMode = (Component: any) => (props: any) => {
    // Prevent errors on building
    if (typeof window === 'undefined') {
        return null;
    }

    const dispatch = useDispatch();
    const isMaintenanceModeActive = useSelector(drupalIsMaintenanceModeSelector);
    const isMaintenanceModeChecked = useSelector(drupalIsMaintenanceCheckedSelector);
    const accountUserIpAddress = useSelector(accountUserIpAddressSelector);

    useEffect(() => {
        if (!accountUserIpAddress) {
            dispatch(accountGetUserIpAddressRoutine.trigger());
        }
        if (!isMaintenanceModeChecked) {
            dispatch(drupalMaintenanceRoutine.trigger());
        }
    }, [dispatch, isMaintenanceModeChecked, accountUserIpAddress]);

    const shouldRedirectToMaintenancePage = useMemo(() => {
        return (
            isMaintenanceModeActive &&
            window.location.pathname !== '/maintenance' &&
            typeof BIRDI_IP_ADDRESSES === 'object' &&
            !!accountUserIpAddress &&
            !BIRDI_IP_ADDRESSES.includes(accountUserIpAddress)
        );
    }, [accountUserIpAddress, isMaintenanceModeActive]);

    if (shouldRedirectToMaintenancePage) {
        navigate('/maintenance');
        return null;
    } else {
        return <Component {...props} />;
    }
};

export default withMaintenanceMode;
