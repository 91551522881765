import AlertIcon from 'ui-kit/icons/alert/alert-icon';

import './input-error.styles.scss';

type InputErrorProps = { error: string; description?: string } | { error?: string; description: string };

export const InputError = ({ error, description }: InputErrorProps) => {
    return (
        <div className="input-error">
            <AlertIcon variant="error-v2" />
            <div className="input-error__message">
                {error && (
                    <p className="input-error__message__text">
                        <strong>{error}</strong>
                    </p>
                )}
                {description && <p className="input-error__message__text">{description}</p>}
            </div>
        </div>
    );
};
