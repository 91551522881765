import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { accountAcCodeSelector, accountDefaultAddressZipCodeSelector } from 'state/account/account.selectors';
import {
    autoRefillAcCodeSelector,
    autoRefillActiveTabSelector,
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyMembersPricingDataSelector,
    autoRefillPrescriptionsCardsSelector,
    autoRefillSelector,
    autoRefillZipCodeSelector
} from 'state/auto-refill/auto-refill.selectors';
import { cartItemsSelector } from 'state/cart/cart.selectors';
import {
    easyRefillActiveTabSelector,
    easyRefillCartItemsSelector,
    easyRefillCartZipCodeSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillFamilyMembersPricingDataSelector,
    easyRefillPatientShipAddressZipCodeSelector,
    easyRefillPlanAliasSelector,
    easyRefillPrescriptionsCardsSelector
} from 'state/easy-refill/easy-refill.selectors';
import { familyMembersPricingDataSelector } from 'state/family-profile/family-profile.selectors';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetCartZipCodeSelector,
    medicineCabinetPrescriptionsCardsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';

import { DependentsPricingData } from 'types/family-account';
import { RefillRxs } from 'types/order-prescription';
import { PrescriptionCardProps } from 'types/prescription';
import { Selector } from 'types/saga-handler';

import { RX_AVAILABLE_FLOWS } from 'enums/prescription';

export interface FlowSelectorsMapping {
    cartZipCode: Selector<string | null | undefined>;
    ownerPlan: Selector<string>;
    familyPricingData: Selector<DependentsPricingData[]>;
    prescriptionCards: Selector<PrescriptionCardProps[]>;
    activeTab: Selector<string | null>;
    cartItems: Selector<RefillRxs[] | undefined>;
    mainUserZipCode: Selector<string | null | undefined>;
}

/**
 * This Hook allows us to unify the sagas selectors used across
 * the flows that render prescription information ensuring data consistency
 */
const usePrescriptionFlow = () => {
    // Easy Refill data
    const easyRefillEpostNum = useSelector(easyRefillEpostPatientNumSelector);

    // Auto refill flow
    const autoRefillEpostPatientNum = useSelector(autoRefillePostPatientNumberSelector);

    // Medicine cabinet Data

    const selectors: Record<RX_AVAILABLE_FLOWS, FlowSelectorsMapping> = {
        [RX_AVAILABLE_FLOWS.MEDICINE_CABINET]: {
            cartZipCode: medicineCabinetCartZipCodeSelector,
            ownerPlan: accountAcCodeSelector,
            familyPricingData: familyMembersPricingDataSelector,
            prescriptionCards: medicineCabinetPrescriptionsCardsSelector,
            activeTab: medicineCabinetActiveTabSelector,
            cartItems: cartItemsSelector,
            mainUserZipCode: accountDefaultAddressZipCodeSelector
        },
        [RX_AVAILABLE_FLOWS.EASY_REFILL]: {
            cartZipCode: easyRefillCartZipCodeSelector,
            ownerPlan: easyRefillPlanAliasSelector,
            familyPricingData: easyRefillFamilyMembersPricingDataSelector,
            prescriptionCards: easyRefillPrescriptionsCardsSelector,
            activeTab: easyRefillActiveTabSelector,
            cartItems: easyRefillCartItemsSelector,
            mainUserZipCode: easyRefillPatientShipAddressZipCodeSelector
        },
        [RX_AVAILABLE_FLOWS.AUTO_REFILL]: {
            cartZipCode: autoRefillZipCodeSelector,
            ownerPlan: autoRefillAcCodeSelector,
            familyPricingData: autoRefillFamilyMembersPricingDataSelector,
            prescriptionCards: autoRefillPrescriptionsCardsSelector,
            activeTab: autoRefillActiveTabSelector,
            // The cart doesn't exist in easy refill so we mock the
            // selector to return an empty array.
            cartItems: createSelector(autoRefillSelector, () => {
                return [] as RefillRxs[];
            }),
            mainUserZipCode: autoRefillZipCodeSelector
        }
    };

    // Load memoized data
    // TODO: Re-structure this data to be managed from the sagas, so we won't need
    // to manually switch the type of data. Ideally, BE will return consistent values too.
    // Also, this will need to be adapted to medicine cabinet
    const currentFlow: RX_AVAILABLE_FLOWS = useMemo(
        () =>
            easyRefillEpostNum
                ? RX_AVAILABLE_FLOWS.EASY_REFILL
                : autoRefillEpostPatientNum
                ? RX_AVAILABLE_FLOWS.AUTO_REFILL
                : RX_AVAILABLE_FLOWS.MEDICINE_CABINET,
        [easyRefillEpostNum, autoRefillEpostPatientNum]
    );

    // Mapping the selectors based on each flow
    const cartZipCode: string | undefined | null = useSelector(selectors[currentFlow].cartZipCode);
    const ownerPlan: string = useSelector(selectors[currentFlow].ownerPlan);
    const familyPricingData: DependentsPricingData[] = useSelector(selectors[currentFlow].familyPricingData);
    const prescriptionCards: PrescriptionCardProps[] = useSelector(selectors[currentFlow].prescriptionCards);
    const activeTab: string | null = useSelector(selectors[currentFlow].activeTab);
    const cartItems: RefillRxs[] | undefined = useSelector(selectors[currentFlow].cartItems);
    const mainUserZipCode: string | undefined | null = useSelector(selectors[currentFlow].mainUserZipCode);

    return {
        currentFlow,
        cartZipCode,
        ownerPlan,
        familyPricingData,
        prescriptionCards,
        activeTab,
        cartItems,
        mainUserZipCode
    };
};

export default usePrescriptionFlow;
