import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMessage from 'ui-kit/loading-message/loading-message';

import DashboardCtaBlock from 'components/dashboard-cta-block/DashboardCtaBlock';
import { DrugFormWrapper } from 'components/drug-form-wrapper/drug-form-wrapper.component';
import { DrugHeroProps } from 'components/drug-hero/drug-hero.props';
import 'components/drug-hero/drug-hero.style.scss';
import DrugInfo from 'components/drug-info/drug-info.component';
import DrugLookupForm, {
    extractPackageOptions,
    extractStrengths
} from 'components/drug-lookup-form/drug-lookup-form.component';
import 'components/get-birdi-price/get-birdi-price.style.scss';
import 'components/hero/hero.style.scss';

import { PackageOption, setDrugDetailValues } from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { addTransferPrescriptionDrugDetailsSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { DosageFormsObjectPayload, DrugDescriptionObjectPayload } from 'state/drug/drug.services';

import { formatPrice } from 'schema/price.schema';

import storageHelper from 'util/storageHelper';

import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import useBirdiPrice from 'hooks/useBirdiPrice';

const DrugHero: FC<DrugHeroProps> = ({
    backgroundColor,
    image,
    imageAltText,
    drugSelectOptions,
    initialSelectValues,
    initialDrugDetails,
    imagePosition,
    title,
    sectionIndex,
    headerColor,
    forceLegacyPrescriptionInfo
}: DrugHeroProps): ReactElement => {
    const formName = 'DrugHeroForm';
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const requestFormValues = useSelector(addTransferPrescriptionDrugDetailsSelector);
    const [birdiPrice, setBirdiPrice] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);
    const [formError, setFormError] = useState('');
    const [drugDescription, setDrugDescription] = useState(initialDrugDetails ? initialDrugDetails : '');
    const { getPrescriptionPrice } = useBirdiPrice();
    const { resetForm } = useAddTransferPrescription();
    const [selectedDrug, setSelectedDrug] = useState(initialSelectValues);

    useEffect(() => {
        let pkgOptions: PackageOption[] = [];
        const initalFormOption = drugSelectOptions.find(
            (format: DosageFormsObjectPayload) => format.dosageForm === initialSelectValues.drugForm
        );
        const strength = extractStrengths(initalFormOption.strengths);
        // DRX-944: Default the Quantity to the quantity90days value.
        const selectedStrength = strength.find(
            (drugStrength: any) => drugStrength.value === initialSelectValues.strength
        );
        if (selectedStrength) {
            if (selectedStrength.drugCode) {
                initialSelectValues.ndc = selectedStrength.drugCode;
            }
            if (selectedStrength.quantity90days) {
                initialSelectValues.qty = selectedStrength.quantity90days;
            } else {
                // DRX-1056 enhancement
                pkgOptions = selectedStrength.packageOptions ? extractPackageOptions(selectedStrength) : [];
                if (pkgOptions && !initialSelectValues.ndc) {
                    initialSelectValues.ndc = pkgOptions[0].drugCode;
                    initialSelectValues.qty = pkgOptions[0].quantity90days;
                }
            }
        }

        dispatch(
            setDrugDetailValues({
                drugFormOptions: drugSelectOptions,
                drugStrengthOptions: strength,
                drugPackageOptions: pkgOptions // DRX-1056 enhancement
            })
        );
        getPrescriptionPrice({
            values: initialSelectValues,
            handlePriceSuccess: setBirdiPrice,
            handleDescriptionSuccess: handleDescriptionSuccess
        });
    }, []);

    useEffect(() => {
        if (!birdiPrice) {
            getPrescriptionPrice({
                values: initialSelectValues,
                handlePriceSuccess: setBirdiPrice,
                handleDescriptionSuccess: handleDescriptionSuccess
            });
        }
    }, [requestFormValues]);

    const RenderFormikFunction = (formik: any) => {
        return (
            <DrugLookupForm
                isDrugNameEditable={false}
                drugSelectOptions={drugSelectOptions}
                formName={formName}
                formik={formik}
                isFormValid={isFormValid}
                setIsFormValid={setIsFormValid}
                setFormError={setFormError}
                handleReset={resetForm}
                variant="gbp"
                className="has-blue-bg"
                footer={footer}
                forceLegacyPrescriptionInfo={forceLegacyPrescriptionInfo}
            />
        );
    };
    const footer = () => {
        return <div className={`h6 hero-footer`}> {t('components.drugHero.footerText')}</div>;
    };
    const handleSelectedDrug = (values: DosageFormsObjectPayload) => {
        setSelectedDrug(values);
    };
    const handleDescriptionSuccess = (response: DrugDescriptionObjectPayload) => {
        const description = response ? response.htmlDesc : t('components.getBirdiPrice.descrError');
        setDrugDescription(description);
    };
    return (
        <>
            <Container
                fluid
                className={`hero drug-hero main-container ${backgroundColor ?? ''} ${imagePosition ?? ''} ${
                    headerColor ? `header-color-${headerColor}` : ''
                }`}
                data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
                data-ga-location={'Drug Hero'}
                data-ga-title={title}
            >
                <Row>
                    <Col xs="12" md="12" lg="7" className="d-flex align-items-center">
                        {formError && (
                            <Row>
                                <Col>
                                    <p className="prescription-error">{formError}</p>
                                </Col>
                            </Row>
                        )}
                        <div className="hero-content pr-0">
                            {sectionIndex === 0 && <h1 className="hero-title h2">{title}</h1>}
                            {sectionIndex > 0 && <h2 className="hero-title">{title}</h2>}
                            <div className="spacer" />
                            <div className="hero-price h2">
                                {birdiPrice !== '' && (
                                    <>
                                        {formatPrice(birdiPrice)}{' '}
                                        <span className="h4 cart-total--description text-uppercase mr-0 ">
                                            {t('components.drugHero.includesShipping')}
                                        </span>
                                    </>
                                )}
                                <LoadingMessage
                                    className={'h4 m-0'}
                                    isVisible={birdiPrice === ''}
                                    text={t('components.drugHero.fetchingPrice')}
                                />
                            </div>
                            <div className={`h4 hero-text mb-0 pr-0`}> {t('components.drugHero.body')}</div>
                            <DrugFormWrapper
                                isDrugNameEditable={false}
                                initialSelectValues={initialSelectValues}
                                renderFormikFunction={RenderFormikFunction}
                                handlePriceSuccess={setBirdiPrice}
                                handleSelectedDrug={handleSelectedDrug}
                            />
                        </div>
                    </Col>
                    <Col xs="12" md="12" lg="5" className={`d-lg-flex d-none hero-image ${imagePosition ?? ''}`}>
                        <Row>
                            <Col className="drug-hero-image-container" xs={{ span: 10, offset: 1 }}>
                                <div className="hero-image-container">
                                    <GatsbyImage image={getImage(image)} alt={imageAltText} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <Row xs={1} lg={2} xl={2} className="get-birdi-price-ctas my-5">
                            <Col className="mb-lg-0 mb-4">
                                <DashboardCtaBlock
                                    icon={'plane'}
                                    title={t('components.dashboardCtaBlock.titles.transferPre')}
                                    bodyText={t('components.dashboardCtaBlock.body.transferPre')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.transferPre'),
                                            variant: 'dark',
                                            dataGALocation: 'DrugHero',
                                            onClick: () => {
                                                storageHelper.session.setSelectedDrug(selectedDrug);
                                                storageHelper.session.setPrescriptionFlowType('Transfer');
                                                navigate('/get-started');
                                            }
                                        }
                                    ]}
                                />
                            </Col>
                            <Col className="mb-lg-0 mb-4">
                                <DashboardCtaBlock
                                    icon={'pill-bottle'}
                                    title={t('components.dashboardCtaBlock.titles.reqNew')}
                                    bodyText={t('components.dashboardCtaBlock.body.reqNew')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.reqNew'),
                                            variant: 'dark',
                                            dataGALocation: 'DrugHero',
                                            onClick: () => {
                                                storageHelper.session.setSelectedDrug(selectedDrug);
                                                storageHelper.session.setPrescriptionFlowType('New');
                                                navigate('/get-started');
                                            }
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="get-birdi-price-results-title">
                                    <h2 className="h3">{t('components.getBirdiPrice.drugInformation')}</h2>
                                </div>
                                <DrugInfo
                                    details={drugDescription}
                                    className="get-birdi-price-results-description large"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default DrugHero;
