export enum PRICING_API_LOCATION {
    CART = 'Cart',
    MEDICINE_CABINET = 'MedCabinet'
}

export enum PRICING_ACCESS_TYPE {
    AUTO_REFILL = 'autoRefill',
    EASY_REFILL = 'easyRefill',
    LOGGED_IN = 'logged'
}

export enum PRICING_UNAUTH_AREA {
    AUTO_REFILL = 'auto-refill',
    EASY_REFILL = 'easy-refill'
}

export enum PRICING_FLOWS {
    MEDICINE_CABINET = 'MEDICINE_CABINET',
    AUTO_REFILL = 'AUTO_REFILL',
    EASY_REFILL = 'EASY_REFILL',
    TRANSFER_RX = 'TRANSFER_RX',
    NONE = 'NONE'
}
