import { createSelector } from '@reduxjs/toolkit';

import { DrupalState } from 'state/drupal/drupal.reducers';
import { RootState } from 'state/store';

export const drupalStateSelector = (state: RootState): DrupalState => state.drupalReducer;

export const drupalCSRFSelector = createSelector(drupalStateSelector, (drupal) => drupal.csrfToken);

export const drupalIsMaintenanceCheckedSelector = createSelector(
    drupalStateSelector,
    (drupal) => drupal.maintenance.isChecked
);

export const drupalIsMaintenanceModeSelector = createSelector(
    drupalStateSelector,
    (drupal) => drupal.maintenance.maintenanceMode
);
