import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
// Components
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { accountProfileAddressesSelector } from 'state/account/account.selectors';
// State
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartCompleteOrderRoutine } from 'state/cart/cart.routines';
import { cartIsBusySelector, cartOrderPaymentCardSelector } from 'state/cart/cart.selectors';

// Schema
import { formatPrice } from 'schema/price.schema';

import { ExtendedCartObjectPayload } from 'types/cart';
import { RxDetails } from 'types/prescription';

// Utils
import { isAxiosError } from 'util/axiosClient';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

// Hooks
import { useHealthConditionsByPatientNum } from 'hooks/useHealthConditionsByPatientNum';
import { usePatientsInCart } from 'hooks/usePatientsInCart';

import './cart-total.style.scss';

interface ReviewCartTotalProps {
    isUnknownPrice: boolean;
    initialOrderPrice?: string;
    currentShippingPrice: number;
    isInsuranceAccount: boolean;
    isBirdiCash: boolean;
    cartObject?: ExtendedCartObjectPayload[];
    prescriptionsObject: RxDetails[];
}

export const CartTotal = ({
    isUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    isInsuranceAccount,
    cartObject,
    prescriptionsObject,
    isBirdiCash
}: ReviewCartTotalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isOneClickOrderAvailable, setIsOneClickOrderAvailable] = useState<boolean>(false);

    const selectedPaymentMethod = useSelector(cartOrderPaymentCardSelector);
    const profileAddresses = useSelector(accountProfileAddressesSelector);

    const [usersInCart] = usePatientsInCart();
    const { isMissingHealthConditions } = useHealthConditionsByPatientNum(
        usersInCart.map((user) => user.epostPatientNum)
    );

    const isBusy = useSelector(cartIsBusySelector);

    useEffect(() => {
        setIsOneClickOrderAvailable(
            !isMissingHealthConditions && !!selectedPaymentMethod && profileAddresses.length > 0
        );
    }, [selectedPaymentMethod, isMissingHealthConditions, profileAddresses]);

    const showPaymentError = () => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.paymentMethods.paymentError.title')} icon="alert" />
                ),
                bodyContent: (
                    <BirdiModalErrorContent
                        description={t('modals.paymentMethods.paymentError.description')}
                        ctaText={t('modals.paymentMethods.paymentError.cta')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleSubmitOrderClick = () => {
        dispatch(
            cartCompleteOrderRoutine({
                currentShippingPrice: Number(currentShippingPrice),
                lineItems: cartObject?.flatMap((rx) => rx?.extendedRefillRxs).filter((item) => !!item) || [],
                onSuccess: () => {
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: cartObject || [],
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: String(currentShippingPrice),
                        accountHasInsurance: isInsuranceAccount
                    });
                    navigate('/secure/cart/order-confirmation');
                },
                onFailure: (data: unknown) => {
                    if (
                        data &&
                        typeof data === 'object' &&
                        'response' in data &&
                        isAxiosError(data.response) &&
                        data.response.response?.data.SystemMessage === 'PRE-AUTH-DENIED'
                    ) {
                        showPaymentError();
                    } else {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.cart.error.errorTitle`)}
                                        body={t(`pages.cart.error.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.cart.error.cta`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                }
            })
        );
    };

    return (
        <div className="cart-total">
            <div className="cart-total__calculations">
                <div className="cart-total__calculations__subtotal">
                    <div>
                        {t('pages.cart.subTotal')}
                        {isUnknownPrice ? <sup>*</sup> : ''}:
                    </div>
                    <div>{formatPrice(initialOrderPrice)}</div>
                </div>

                <div className="cart-total__calculations__shipping">
                    <div>{t('pages.cart.shipping')}</div>
                    <div>{formatPrice(currentShippingPrice)}</div>
                </div>
            </div>

            <div className="cart-total__total">
                <div className="cart-total__total__title">
                    {cartObject?.some((cart) => cart.showEstimatedTotal)
                        ? t('components.medicineCabinetCart.newCartTotal.estTotal')
                        : t('components.medicineCabinetCart.newCartTotal.total')}
                </div>
                <div className="cart-total__total__price">
                    {formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}
                </div>
            </div>

            <div className="cart-total__container-notes">
                {isInsuranceAccount ? (
                    <ToastBox icon="default" variant="info">
                        <div className="cart-total__note">
                            {t('components.medicineCabinetCart.newCartTotal.insuranceDisclaimer')}
                        </div>
                    </ToastBox>
                ) : (
                    !isInsuranceAccount &&
                    isBirdiCash && (
                        <ToastBox icon="default" variant="info">
                            <div className="cart-total__note">
                                {t('components.medicineCabinetCart.newCartTotal.birdiPriceDisclaimer')}
                            </div>
                        </ToastBox>
                    )
                )}
            </div>
            <div className="cart-total__ctas">
                <Button
                    async
                    isBusy={isBusy}
                    disabled={!isOneClickOrderAvailable || isBusy}
                    type="button"
                    className="btn btn-primary no-min-width btn-bold"
                    label={t('components.medicineCabinetCart.newCartTotal.submit')}
                    onClick={handleSubmitOrderClick}
                />

                <Button
                    customFontSize="18"
                    type="button"
                    variant="text"
                    className="btn btn-text text-cerulean no-min-width"
                    label={t('components.medicineCabinetCart.newCartTotal.review')}
                    onClick={() => navigate('/secure/cart/review')}
                    disabled={isBusy}
                />

                <div className="cart-total__ctas__disclaimer">
                    {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                </div>
            </div>
            <br />
        </div>
    );
};
